import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebcamImage } from 'ngx-webcam';
import { IntentosModel, PreguntaResultadoModel, SeccionResultadoModel, UsuarioPruebaModel } from 'src/app/models/resultados.model';
import { PreguntaModel, PruebaModel, SeccionModel } from 'src/app/models/test.model';
import { FilesService } from 'src/app/services/files.service';
import { FlujoService } from 'src/app/services/flujo.service';
import { TestService } from 'src/app/services/test.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  prueba: PruebaModel;
  seccionActual: number;
  seccion: SeccionModel = new SeccionModel();
  iniciarTest: boolean;
  preguntaActual: number;
  esMovil:boolean;
  intervalo: any;
  segundos: number = 0;
  segundosReloj: number = 0;
  minutosReloj: number = 0;
  tiempoTotal: number = 0;
  dosRespuestas: number[] = [];
  instruccionesPrueba: boolean = true;
  numeroRandom: number = this.getRandomInt(6000,180000);

  resultado: UsuarioPruebaModel;
  intento: number = 1;

  icons: string[] = ['here','none','none','none','none','none','none','none','none','none'];
  titulos: string[] = ['seccion 1','seccion 2','seccion 3','seccion 4','seccion 5','seccion 6','seccion 7','seccion 8','seccion 9','seccion 10'];
  constructor(private testService: TestService, private router: Router, private usuarioService: UsuarioService,
              private flujoService: FlujoService, private filesService: FilesService) {
    this.prueba = new PruebaModel();
    this.seccionActual = 1;
    this.seccion.instrucciones = '';
    let p: PreguntaModel = new PreguntaModel();
    p.opciones = [];
    this.seccion.preguntas=[p];
    this.iniciarTest = false;
    this.preguntaActual = 0;
    this.resultado = new UsuarioPruebaModel();

    this.esMovil = (screen.width < 576) ? true : false;

    testService.getTest().subscribe(test => { 
      this.prueba = test;
      this.seccion = this.prueba.secciones[this.seccionActual-1];
      this.resultado.idPrueba = test.id;
      this.resultado.secciones = [];
      this.resultado.secciones.push(new SeccionResultadoModel());
      this.resultado.secciones[this.seccionActual-1].numeroSeccion=this.seccionActual;
      this.resultado.secciones[this.seccionActual-1].preguntas=[];
      this.resultado.secciones[this.seccionActual-1].preguntas.push(new PreguntaResultadoModel());
      this.resultado.secciones[this.seccionActual-1].preguntas[0].intentos=[];
      this.resultado.secciones[this.seccionActual-1].preguntas[0].numeroPregunta=1;

      this.prueba.secciones.forEach(seccion => {
        this.tiempoTotal += seccion.tiempoSegundos;
      });
        this.tiempoTotal += 600;

    });
    usuarioService.usuario.subscribe(usuario => this.resultado.idUsuario = usuario.id);
   }

  ngOnInit(): void {
    document.getElementById('btnModalCamara').click();
  }

  
  getRandomInt(min: number, max: number): number{
    return Math.floor(Math.random() * (max - min)) + min;
  }

  returnPerfiil(): void{
    window.location.replace(environment.urlApp+'/perfil')
  }

  startTest(): void{
    
    this.iniciarTest = true;
    this.preguntaActual++;

    this.intervalo = setInterval( () => {
      
      this.segundos++;
      this.segundosReloj++;
      if(this.segundos === this.seccion.tiempoSegundos){
        this.intento = 1;
        this.siguienteSeccion();
      }
      if ( this.segundosReloj === 60 ){
        this.segundosReloj = 0;
      }
      this.minutosReloj = Math.floor(this.segundos/60);
    }, 1000);

  }

  clickOpcion(value: number): void{
    this.resultado.secciones[this.seccionActual-1]
          .preguntas[this.preguntaActual-1]
          .intentos
          .push(new IntentosModel(this.seccion.preguntas[this.preguntaActual].opciones[value].opcion, this.intento));
    this.intento++;
  }

  clickOpcionString(value: string): void{
    this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos.push(new IntentosModel(value, this.intento));
    this.intento++;
  }

  clickDosCheckbox(number: number): void{

    let bandera: boolean = true;

    this.dosRespuestas.forEach(element => {
      if (element === number){
        bandera = false;
      }
    });

    if (bandera){
      if (this.dosRespuestas.length == 2){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Solo 2 respuestas'
        });
        //@ts-ignore
        document.getElementById("chk"+number).checked=false;
      }else{
        this.dosRespuestas.push(number);
      }
    }else{
      let aux: number[] = [];
      this.dosRespuestas.forEach(element => {
        if (element != number){
          aux.push(element)
        }
      });
      this.dosRespuestas = aux;
    }
    let aux: number = 0;
    let aux2: string = '';
    this.dosRespuestas.forEach(element => {
      if(aux === 1){
        aux2+='-';
      }
      aux2 += this.seccion.preguntas[this.preguntaActual].opciones[element].opcion;
      aux++;
    });
    this.resultado.secciones[this.seccionActual-1]
          .preguntas[this.preguntaActual-1]
          .intentos
          .push(new IntentosModel(aux2, this.intento));
    this.intento++;
  }

  clickSiguientePregunta(): void{

    console.log(this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos);
    console.log(this.intento);
    if(this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos.length == 0 ){
      this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos.push(new IntentosModel('',1))
      this.intento++;
    }

    if (this.seccion.preguntas[this.preguntaActual].tipo == 4){
    //@ts-ignore
    document.getElementById("respuesta").value='';
  }if (this.seccion.preguntas[this.preguntaActual].tipo == 3 && this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos.length>0){
      let resp: string[] = this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos[this.intento-2].respuesta.toLocaleLowerCase().split('-');
      if (resp.length === 2){
        let resp2 = this.prueba.secciones[this.seccionActual-1].preguntas[this.preguntaActual].respuesta.toLocaleLowerCase().split('-');
        let corr: number = 0;
        resp.forEach(respuesta => {
          resp2.forEach(correcta => {
            if ( respuesta == correcta ){
              corr++;
            }
          });
        });
        if ( corr >= 2 ){
          this.resultado.calificacion ++;
          this.resultado.secciones[this.seccionActual-1].calificacion++;
          this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].correcta = true;
        }
      }
    }
    if (this.seccion.preguntas[this.preguntaActual].tipo == 7){
      //@ts-ignore
      document.getElementById("Respuesta1").value='';
      //@ts-ignore
      document.getElementById("Respuesta2").value='';
    }

    if(this.seccion.preguntas[this.preguntaActual].tipo != 3){
      if(this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos[this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos.length-1].respuesta.toLocaleLowerCase()
      === this.prueba.secciones[this.seccionActual-1].preguntas[this.preguntaActual].respuesta.toLocaleLowerCase()){
        this.resultado.calificacion ++;
        this.resultado.secciones[this.seccionActual-1].calificacion++;
        this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].correcta = true;
      }else if( this.seccion.numeroSeccion === 3 || this.seccion.numeroSeccion === 6 || this.seccion.numeroSeccion === 8 ){
        if (this.resultado.calificacion > 0)
          this.resultado.calificacion --;
        if (this.resultado.secciones[this.seccionActual-1].calificacion > 0)
          this.resultado.secciones[this.seccionActual-1].calificacion--;
      }
    }

    

    this.dosRespuestas = [];
    this.intento = 1;
    if( this.preguntaActual === this.seccion.preguntas.length-1 ){
      this.siguienteSeccion();
    }else{
    this.preguntaActual++;
    this.resultado.secciones[this.seccionActual-1].preguntas.push(new PreguntaResultadoModel());
    this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos=[];
    this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].numeroPregunta=this.preguntaActual;
    }
    if (this.seccion.preguntas[this.preguntaActual].tipo == 2 && this.seccion.preguntas[this.preguntaActual].numero != 0){
        //@ts-ignore
        document.getElementById('opuesto').checked = false;
        //@ts-ignore
        document.getElementById('igual').checked = false;
    }
    if (this.seccion.preguntas[this.preguntaActual].tipo == 5 && this.seccion.preguntas[this.preguntaActual].numero != 0){
        //@ts-ignore
        document.getElementById('si').checked = false;
        //@ts-ignore
        document.getElementById('no').checked = false;
    }
    if (this.seccion.preguntas[this.preguntaActual].tipo == 6 && this.seccion.preguntas[this.preguntaActual].numero != 0){
        //@ts-ignore
        document.getElementById('verdadero').checked = false;
        //@ts-ignore
        document.getElementById('falso').checked = false;
    }
        console.log(this.resultado.calificacion)
        console.log(this.resultado.secciones[this.seccionActual-1].calificacion)
  }

  changeInput(value: string){
    this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos.push(new IntentosModel(value, this.intento));
    this.intento++;
  }

  change2Input(number:number, value: string){
    if (this.dosRespuestas.length === 0){
      this.dosRespuestas.push(0);
      this.dosRespuestas.push(0);
    }
    this.dosRespuestas[number]=Number(value);
    this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual-1].intentos.push(new IntentosModel(this.dosRespuestas[0]+'-'+this.dosRespuestas[1], this.intento));
    this.intento++;
  }

  private siguienteSeccion(){
    this.preguntaActual = 0;
    this.segundos = 0;
    this.segundosReloj = 0;
    this.minutosReloj = 0;
      this.iniciarTest = false;
      clearInterval(this.intervalo);
      if( this.prueba.secciones.length === this.seccionActual ){
        this.testService.postResultado(this.resultado);
      }else{
        this.icons[this.seccionActual-1]='success';
        this.icons[this.seccionActual]='here';
        this.flujoService.$icons.emit(this.icons);
        this.seccion = this.prueba.secciones[this.seccionActual];
        this.seccionActual ++;
        this.resultado.secciones.push(new SeccionResultadoModel());
        this.resultado.secciones[this.seccionActual-1].numeroSeccion=this.seccionActual;
        this.resultado.secciones[this.seccionActual-1].preguntas=[];
        this.resultado.secciones[this.seccionActual-1].preguntas.push(new PreguntaResultadoModel());
        this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].intentos=[];
        this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].numeroPregunta=1;
      }
  }


  webcamImage: WebcamImage = null;

  handleImage(webcamImage: WebcamImage) {

  this.webcamImage = webcamImage;

  //@ts-ignore
  this.filesService.uploadImageTermanTest(this.webcamImage.imageAsBase64, webcamImage._mimeType, this.resultado.idUsuario).subscribe( res => console.log('ok'), err => console.log(err))

  }

}

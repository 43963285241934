<!--====================================================================================================-->
<!--================================   HTML de la sección de pruebas    ================================-->
<!--====================================================================================================-->

<!-- Contenedor -->
<div class="container-fluid bg-white h-100 position-fixed">

    <!--==============================================-->
    <!-- Header (Nombre y Logo) ======================-->
    <!--==============================================-->
    <div class="row border-bottom d-flex flex-column flex-sm-row align-items-center py-2">
        <div class="col-6 col-sm-5 col-md-4 col-lg-2">
            <img class="img-fluid" src="../../../assets/img/TruemedGroupLogo.jpeg">
        </div>
        <div class="col display-6 my-2 my-sm-auto text-center text-sm-start d-none d-md-block">
            {{prueba.nombre}}
        </div>
    </div>

    <!-- Panel del test (izqierda) y panel derecho-->
    <div class="row justify-content-center h-100">

        <!--==============================================-->
        <!-- Sección del test ============================-->
        <!--==============================================-->
        <div class="col-12 col-md-8 pt-4 ps-4 h-100">
            <div [ngClass]="{'d-none': testIniciado}">
                <h6 class="fs-4 fw-normal">Instrucciones</h6>
                <hr>
                <p class="fs-5 mb-4 mt-4 text-center">
                    A continuación se presentan grupos de cuatro palabras.
                    Examine cada palabra y seleccione inciso correspondiente a la palabra que mejor
                    lo describa. Posteriormente elija la palabra que menos lo describa de cada grupo y seleccione.
                </p>
                <div class="w-100 d-flex justify-content-center">
                    <button class="btn btn-outline-primary w-50 float-center" type="button"
                    [ngClass]="{'btn-sm': width < 576, 'btn-lg': width > 768}"
                        (click)="IniciarPrueba()">
                        Iniciar Test
                    </button>
                </div>
            </div>
            <div [ngClass]="{'d-none': !testIniciado}">
                <h6 class="fs-4 fw-normal">{{'Sección ' + seccionActual.numeroSeccion + ' - Pregunta ' +
                    preguntaActual.numero}}</h6>
                <hr>
                <p class="fs-5 mb-4 text-center">{{preguntaActual.pregunta}}</p>
                <div class="d-flex flex-column col-10 col-sm-8 col-md-6 col-lg-4 mx-auto">
                    <button class="btn btn-outline-secondary mb-2"
                        *ngFor="let opcion of preguntaActual.opciones; let i=index" [ngClass]="{'btn-outline-success border-3': indexMas === i, 'btn-outline-danger border-2': indexMenos === i,
                                    'btn-sm': width < 576, 'btn-lg': width > 768}" (click)="seleccionCleaver(i)">
                        <i class="fas fa-plus" *ngIf="indexMas === i"></i>
                        {{opcion.opcion}}
                        <i class="fas fa-minus" *ngIf="indexMenos === i"></i>
                    </button>
                </div>
                <div>
                    <!-- <button class="btn btn-outline-primary btn-lg float-start col-5 col-lg-4 mt-4"
                        (click)="preguntaAnterior()" >
                    <i class="ms-1 fas fa-arrow-left d-none d-sm-inline"></i>
                    Anterior
                </button> -->
                    <button class="btn btn-outline-primary float-end col-5 col-lg-4 mt-4"
                        [ngClass]="{'btn-sm': width < 576, 'btn-lg': width > 768}" (click)="siguientePregunta()"
                        *ngIf="!reintentarEnvio">
                        Siguiente
                        <i class="ms-1 fas fa-arrow-right d-none d-sm-inline"></i>
                    </button>
                    <button class="btn btn-outline-primary float-end col-5 col-lg-4 mt-4"
                        [ngClass]="{'btn-sm': width < 576, 'btn-lg': width > 768}" (click)="enviarResultados()"
                        *ngIf="reintentarEnvio">
                        Reintentar Envio
                        <i class="ms-1 fas fa-sync d-none d-sm-inline"></i>
                    </button>
                </div>
            </div>

        </div>

        <!--==============================================-->
        <!-- Panel derecho ===============================-->
        <!--==============================================-->
        <div class="col-4 d-none d-md-block border-start m-0 p-0">

            <!-- Temporizador / Cronómetro -->
            <div class="card p-0 m-0">
                <h6 class="card-header fs-4">Cronómetro</h6>
                <p class="text-center fs-1 my-3">{{minutos | number: '2.0'}} : {{segundos | number :'2.0'}}</p>
            </div>

            <!-- Avance de las preguntas -->
            <div class="card p-0 m-0">
                <h6 class="card-header fs-4">Preguntas</h6>
                <div class="card-body row justify-content-center">
                    <div class="border borde-2 border-secondary d-flex justify-content-center align-items-center m-2 fs-5"
                        style="width: 30px; height: 40px;" *ngFor="let pregunta of seccionActual.preguntas; let i=index"
                        [ngClass]="{'bg-success text-white': preguntaActual.numero > i+1}">
                        {{i+1}}
                    </div>
                </div>
            </div>

            <!-- Avance de las secciones -->
            <div class="card p-0 m-0" *ngIf="prueba.secciones.length > 1">
                <h6 class="card-header fs-4">Secciones</h6>
                <div class="card-body row justify-content-center">
                    <div class="border borde-2 border-secondary d-flex justify-content-center align-items-center m-2 fs-5"
                        style="width: 30px; height: 40px;" *ngFor="let seccion of prueba.secciones; let i=index">
                        {{i+1}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
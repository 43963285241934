<!-- ============================= -->
<!-- ===== COMPONENTE CAMARA ===== -->
<!-- ============================= -->
<div style="text-align:center">
    <div>
        <webcam [height]="300" [width]="300" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
            *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)"></webcam>
        <br />
        <button class="btn btn-primary bg-tm" (click)="showNextWebcam(true);"><i
                class="fas fa-exchange-alt me-2"></i>Cambiar camara</button>
    </div>
</div>
<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
    <li>{{error | json}}</li>
</ul>
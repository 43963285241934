import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AdminGuadGuard } from './guards/admin-guad.guard';
import { AuthGuard } from './guards/auth.guard';
import { CandidatoComponent } from './pages/admin-pages/candidato/candidato.component';
import { PostulacionComponent } from './pages/admin/postulacion/postulacion.component';
import { AdministradorEmpleosComponent } from './pages/administrador-empleos/administrador-empleos.component';
import { CleaverTemporalComponent } from './components/cleaver-temporal/cleaver-temporal.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DetallePruebaComponent } from './pages/detalle-prueba/detalle-prueba.component';
import { EmpleosComponent } from './pages/empleos/empleos.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PruebasComponent } from './pages/pruebas/pruebas.component';
import { RecuperacionPasswordComponent } from './pages/recuperacion-password/recuperacion-password.component';
import { ResultadosComponent } from './pages/resultados/resultados.component';
import { TestComponent } from './pages/test/test.component';
import { CamaraComponent } from './shared/camara/camara.component';
import { PostulacionesComponent } from './pages/postulaciones/postulaciones.component';
import { Inglesb12Component } from './pages/inglesb12/inglesb12.component';
import { VerificarTelefonoComponent } from './pages/verificar-telefono/verificar-telefono.component';
import { PostulacionRapidaComponent } from './pages/postulacion-rapida/postulacion-rapida.component';

const MAIN_ROUTES: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'verificar',
    component: VerificarTelefonoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'perfil',
    component: PerfilComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'empleos',
    component: EmpleosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'test',
    component: TestComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'prueba/:prueba',
    component: PruebasComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'AdminEmpleos',
    component: AdministradorEmpleosComponent,
    canActivate: [AdminGuadGuard]
  },
  {
    path: 'resultados',
    component: ResultadosComponent,
    canActivate: [AdminGuadGuard]
  },
  {
    path: 'resultados/:id',
    component: DetallePruebaComponent,
    canActivate: [AdminGuadGuard]
  },
  {
    path: 'candidato/:id/:postulacion',
    component: CandidatoComponent,
    canActivate: [AdminGuadGuard]
  },
  {
    path: 'postulacion/:idUsuario/:idEmpleo',
    component: PostulacionComponent,
    canActivate: [AdminGuadGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AdminGuadGuard]
  },
  {
    path: 'cleaverTemporal',
    component: CleaverTemporalComponent,
    canActivate: [AdminGuadGuard]
  },
  {
    path: 'dashboard/:empleo/:id',
    component: DashboardComponent,
    canActivate: [AdminGuadGuard]
  },
  {
    path: 'changePassword/:token',
    component: RecuperacionPasswordComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'postulaciones',
    component: PostulacionesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inglesb2',
    component: Inglesb12Component,
    canActivate: [AuthGuard]
  },
  {
    path: 'postulacion-rapida',
    component: PostulacionRapidaComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(MAIN_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

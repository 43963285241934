export class DireccionModel{

    id: number;
	calle: string;
	noExterior: string;
	noInterior: string;
	colonia: string;
	delegacion: string;
	municipio: string;
	estado: string;
	codigoPostal: number;
	activo: boolean;

}
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  //private urlBase: string = 'http://localhost:8090';
  private urlBase: string = environment.urlApi;

  constructor(private http: HttpClient, private authService: AuthService) { }

  uploadImageTermanTest(imagen: string, formato: string, idUsuario: string): Observable<any>{
    
    const formData = new FormData();
    formData.append('image', imagen);
    formData.append('formato', formato);
    formData.append('UsuarioId', idUsuario.toString());
    formData.append('Prueba', 'Terman')

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.post(this.urlBase+'/usuarios/uploadTestImageBase64', formData   , {headers: httpHeaders, responseType: 'text'});

  }

  getImagesTermanTest(idUsuario: number): Observable<any[]>{

    const params: HttpParams = new HttpParams().set('idUsuario',idUsuario.toString());

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<any[]>(this.urlBase+'/usuarios/getTestImageBase64', {headers: httpHeaders, params: params});

  }
}

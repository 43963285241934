import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HabilidaesModel } from '../models/habilidades.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HabilidadesService {

  //private urlBase: string = 'http://localhost:8090/usuarios';

  private urlBase: string = environment.urlApi+'/usuarios';

  constructor(private authService:AuthService, private http: HttpClient) { }

  getResultados(): Observable<HabilidaesModel[]>{
    
    const urlEndpoint = this.urlBase + '/habilidades';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<HabilidaesModel[]>(urlEndpoint,{headers: httpHeaders});

  }
}

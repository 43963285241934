import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { WebcamImage } from 'ngx-webcam';
import { UsuarioPruebaModel } from 'src/app/models/resultados.model';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { FilesService } from 'src/app/services/files.service';
import { ResultadosService } from 'src/app/services/resultados.service';

@Component({
  selector: 'app-detalle-prueba',
  templateUrl: './detalle-prueba.component.html',
  styleUrls: ['./detalle-prueba.component.css']
})
export class DetallePruebaComponent implements OnInit {

  resultado: UsuarioPruebaModel;

  public images: string[] = [];

  constructor(private rutaActiva: ActivatedRoute, private resultadosService: ResultadosService, private filesServce: FilesService) { }

  ngOnInit(): void {

    this.resultado = new UsuarioPruebaModel();
    this.resultado.usuario = new UsuarioModel();
    this.resultado.usuario.nombre = '';
    this.resultadosService.getResultado(this.rutaActiva.snapshot.params.id, null).subscribe(resultado => { console.log(resultado)
      this.resultado = resultado;
      let data: number[] = [null];
      switch (this.resultado.secciones[0].calificacion) {
        case 16:
          data.push(7)
          break;
        case 15:
          data.push(6)
          break;
        case 14:
          data.push(5)
          break;
        case 13:
        case 12:
          data.push(4)
          break;
        case 11:
        case 10:
          data.push(3)
          break;
         case 9:
         case 8:
          data.push(2)
          break;
        default:
          data.push(1)
          break;
      }
      switch (this.resultado.secciones[1].calificacion*2) {
        case 22:
          data.push(7)
          break;
        case 21:
        case 20:
          data.push(6)
          break;
        case 19:
        case 18:
        case 17:
          data.push(5)
          break;
        case 16:
        case 15:
        case 14:
        case 13:
        case 12:
          data.push(4)
          break;
        case 11:
        case 10:
          data.push(3)
          break;
         case 9:
        case 8:
        case 7:
          data.push(2)
          break;
        default:
          data.push(1)
          break;
      }
      switch (this.resultado.secciones[2].calificacion) {
        case 29:
        case 30:
          data.push(7)
          break;
        case 27:
        case 28:
          data.push(6)
          break;
        case 26:
        case 25:
        case 24:
        case 23:
          data.push(5)
          break;
        case 22:
        case 21:
        case 20:
        case 19:
        case 18:
        case 17:
        case 16:
        case 15:
        case 14: 
          data.push(4)
          break;
        case 13:
        case 12:
          data.push(3)
          break;
        case 11:
        case 10:
        case 9:
        case 8:
          data.push(2)
          break;
        default:
          data.push(1)
          break;
      }
      switch (this.resultado.secciones[3].calificacion) {
        case 18:
          data.push(7)
          break;
        case 17:
        case 16:
          data.push(6)
          break;
        case 15:
        case 14:
          data.push(5)
          break;
        case 13:
        case 12:
        case 11:
        case 10:
          data.push(4)
          break;
        case 9:
        case 8:
        case 7:
          data.push(3)
          break;
         case 6 :
          data.push(2)
          break;
        default:
          data.push(1)
          break;
      }
      switch (this.resultado.secciones[4].calificacion*2) {
        case 24:
        case 23:
          data.push(7)
          break;
        case 22:
        case 21:
          data.push(6)
          break;
        case 20:
        case 19:
        case 18:
        case 17:
          data.push(5)
          break;
        case 16:
        case 15:
        case 14:
        case 13:
        case 12:
        case 11:
        case 10:
          data.push(4)
          break;
        case 9:
        case 8:
        case 7: 
          data.push(3)
          break;
         case 6 :
        case 5:
          data.push(2)
          break;
        default:
          data.push(1)
          break;
      }
      switch (this.resultado.secciones[5].calificacion) {
        case 20:
          data.push(7)
          break;
        case 19:
        case 18:
          data.push(6)
          break;
        case 17:
        case 16:
        case 15:
          data.push(5)
          break;
        case 14:
        case 13:
        case 12:
        case 11:
        case 10:
        case 9:
          data.push(4)
          break;
        case 8:
        case 7:
          data.push(3)
          break;
         case 6:
        case 5:
          data.push(2)
          break;
        default:
          data.push(1)
          break;
      }
      switch (this.resultado.secciones[6].calificacion) {
        case 19:
        case 20:
          data.push(7)
          break;
        case 18:
          data.push(6)
          break;
        case 17:
        case 16:
          data.push(5)
          break;
        case 15:
        case 14:
        case 13:
        case 12:
        case 11:
        case 10:
        case 9:
          data.push(4)
          break;
        case 8:
        case 6:
        case 7:
          data.push(3)
          break;
         case 5:
          data.push(2)
          break;
        default:
          data.push(1)
          break;
      }
      switch (this.resultado.secciones[7].calificacion) {
        case 17:
          data.push(7)
          break;
        case 16:
        case 15:
          data.push(6)
          break;
        case 14:
        case 13:
          data.push(5)
          break;
        case  12:
        case 11:
        case 10:
        case 9:
        case 8:
          data.push(4)
          break;
        case 7:
          data.push(3)
          break;
         case 6:
          data.push(2)
          break;
        default:
          data.push(1)
          break;
      }
      switch (this.resultado.secciones[8].calificacion) {
        case 18:
          data.push(7)
          break;
        case 17:
          case 16:
          data.push(6)
          break;
        case 15:
          data.push(5)
          break;
        case  14:
        case 13:
        case 12:
        case 11:
        case 10:
        case 9:
          data.push(4)
          break;
        case 8:
          data.push(3)
          break;
         case 7:
        case 6:
          data.push(2)
          break;
        default:
          data.push(1)
          break;
      }
      switch (this.resultado.secciones[9].calificacion*2) {
        case 22:
        case 21:
        case 20:
          data.push(7)
          break;
        case 19:
        case 18:
          data.push(6)
          break;
        case 17:
        case 16:
          data.push(5)
          break;
        case  15:
        case 14:
        case 13:
        case 12:
        case 11:
        case 10:
          data.push(4)
          break;
        case 9:
        case 8:
          data.push(3)
          break;
         case 7:
        case 6:
        case 5:
          data.push(2)
          break;
        default:
          data.push(1)
          break;
      }
      data.push(null);
      console.log(data)
      this.lineChartData = [{data: data}];
      this.chart.update();
      this.filesServce.getImagesTermanTest(this.resultado.idUsuario).subscribe(res => {
      res.forEach(element => {
        this.images.push('data:'+element.type+';base64,'+element.image);
      });
    }, err => console.log(err))
    });

    
      
  }


  public lineChartData: ChartDataSets[] = [
    { data: [null, 1, 2, 3, 4, 5, 6,4,6,2,5,null] }
  ];
  public lineChartLabels: Label[] = ['', 'Información', 'Juicio', 'Vocabulario', 'Síntesis', 'Concentración', 'Análisis', 'Analogías',
                                     'Planeación', 'Clasificación', 'Atención',''];
  public lineChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [],
      yAxes: [
        {
          id: 'y-axis-1',
          position: 'left',
          stacked: true,
          bounds: 'yAlign: center',
          ticks: {
            max: 7,
            callback: function(value) {

               switch (value) {
                case 1:
                  return  'Deficiente'
                case 2:
                   return 'Bajo'
                case 3:
                   return 'Término Medio Bajo'
                case 4:
                   return 'Término Medio'
                case 5:
                   return 'Normal'
                case 6:
                   return 'Superior'
                case 7:
                   return 'Sobresalnte'
                 default:
                  return null;
               }
            }
        }
        }
      ]
    }
  };
  public lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'transparent',
      borderWidth: 3,
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

}

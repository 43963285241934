<!--===================================-->
<!--======= Inyeccion de NavBar =======-->
<!--===================================-->
<app-navbar></app-navbar>

<!--===================================-->
<!--=== Modal donde se ve la imagen ===-->
<!--===================================-->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="Visor de imagen" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border border-1 border-white" style="background-color: rgba(0, 0, 0, 0);">
            <div class="modal-header">
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <img [src]="imgSelect" class="img-fluid" data-bs-toggle="modal" data-bs-target="#exampleModal" />
        </div>
    </div>
</div>

<!--=============================================-->
<!--=== Modal detalles de otras postulaciones ===-->
<!--=============================================-->
<div class="modal fade" id="detallesPostulaciones" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Postulaciones del candidato</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="list-group">
                    <a role="button" class="list-group-item list-group-item-action d-flex w-100"
                        [ngClass]="{'border border-3 border-warning bg-warning-light': postulacion.estatus === 0}"
                        *ngFor="let postulacion of postulacion.usuario.postulaciones2; let i = index"
                        (click)="cambiarPostulacion(postulacion.empleo.id)">
                        <div class="w-75">
                            <h5 class="mb-1">
                                {{postulacion.empleo.puesto}}
                            </h5>
                            <p class="mb-1">Sueldo pretendido: {{postulacion.sueldoPretendido | currency}}</p>
                        </div>
                        <div class="d-flex align-content-between flex-wrap justify-content-end w-25">
                            <small class="text-muted float-end">{{postulacion.fecha | date: 'mediumDate'}}</small>
                            <span class="badge bg-warning text-dark float-end mt-auto"
                                *ngIf="postulacion.estatus === 0">No revisado</span>
                        </div>
                    </a>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"
                    id="closeModalPostulaciones">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<!--=============================================-->
<!--=== Modal vacantes activas ===-->
<!--=============================================-->
<div class="modal fade" id="vacantesActivas" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Vacantes activas</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h5 class="card-title">Selecciona la vacante que consideras adecuada para el candidato</h5>
                <div class="list-group">
                    <div *ngFor="let vacante of vacantesActivas; let i = index">
                        <a role="button" class="list-group-item list-group-item-action d-flex w-100"
                            *ngIf="vacante.id != postulacion.empleo.id" (click)="reasignarPostulacion(vacante.id)">

                            <div class="w-75">
                                <h5 class="mb-1">
                                    {{vacante.puesto}}
                                </h5>
                                <p class="mb-1">Sueldo minimo: {{vacante.sueldoMinimo | currency}}</p>
                                <p class="mb-1">Sueldo maximo: {{vacante.sueldoMaximo | currency}}</p>
                            </div>
                            <div class="d-flex align-content-between flex-wrap justify-content-end w-25">
                                <small class="text-muted float-end">{{vacante.fechaPublicacion | date:
                                    'mediumDate'}}</small>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"
                    id="closeModalVacantesActivas">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<!--===================================-->
<!--======= Página candidato ==========-->
<!--===================================-->
<div class="container-fluid p-0 px-2 mb-4">

    <!--=========================================-->
    <!--======= Alerta de postulaciones ==========-->
    <!--=========================================-->
    <div class="alert alert-danger alert-dismissible fade show my-1" role="alert"
        *ngIf="postulacion.usuario.postulaciones2.length > 1">
        Este usuario cuenta con {{postulacion.usuario.postulaciones2.length}} postulaciones
        <a class="alert-link" role="button" data-bs-toggle="modal" data-bs-target="#detallesPostulaciones">Ver
            detalles</a>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>

    <!--===================================-->
    <!--===== Tarjeta Nombre e Imagen =====-->
    <!--===================================-->
    <div class="row w-100 p-0 m-0">
        <div class="card p-0">
            <!--===== Imagen tipo portada =========-->
            <div class="card-img-top"
                style="background-image: url(../../../../assets/img/fondo-candidatos.png); height: 200px;">
            </div>
            <!--===== Icono foto de perfil =========-->
            <div class="d-flex">
                <div class="mx-md-4 mx-auto d-flex justify-content-center align-items-center icon-foto bg-secondary border border-2 border-white shadow-sm"
                    style="height: 150px; width: 150px; margin-top: -75px; border-radius: 75px;">
                    <i class="fas fa-user-alt fa-6x text-white"></i>
                </div>

                <div class="w-50 ms-auto text-white shadow p-2 d-flex"
                    style="margin-top: -140px; height: 140px; background-color: rgb(2, 163, 181,.15)">
                    <div>
                        <h5 class="fs-2">Puesto: {{postulacion.empleo.puesto}}</h5>
                        <p class="fs-4">Sueldo pretendido: {{postulacion.sueldoPretendido | currency}}</p>
                    </div>
                    <div class="ms-auto d-flex flex-column" *ngIf="postulacion.activo">
                        <button class="btn btn-outline-light mb-2" *ngIf="vacantesActivas.length" data-bs-toggle="modal"
                            data-bs-target="#vacantesActivas">Reasignar</button>
                        <button class="btn btn-outline-light"
                            *ngIf="postulacion.estatus === 1 || postulacion.estatus === 0"
                            (click)="cambiarEstatus(2)">Entevistar</button>
                        <button class="btn btn-outline-light mt-2"
                            *ngIf="postulacion.estatus === 1 || postulacion.estatus === 0"
                            (click)="cambiarEstatus(3)">Descartar</button>
                        <button class="btn btn-outline-light" *ngIf="postulacion.estatus === 3"
                            (click)="cambiarEstatus(1)">Reconsiderar candidato</button>
                        <button class="btn btn-outline-light mt-2" *ngIf="postulacion.estatus === 2"
                            (click)="cambiarEstatus(1)">Cancelar entrevista</button>
                    </div>
                </div>
            </div>
            <!--===== Datos del usuario ============-->
            <div class="card-body">
                <div class="row w-100">
                    <div class="col-12 col-md-5 px-md-4">
                        <h4 class="mx-auto text-center text-md-start">{{postulacion.usuario.nombre | undefined}}</h4>
                        <p class="card-text mb-1 text-center text-md-start"><i
                                class="fas fa-map-marker-alt me-2"></i>{{postulacion.usuario.direccion}}</p>
                        <p class="card-text mb-1 text-center text-md-start"><i
                                class="fas fa-birthday-cake me-2"></i>{{postulacion.usuario.fechaNacimiento | date}}</p>
                        <span [ngSwitch]="postulacion.usuario.sexo">
                            <p class="card-text mb-1 text-center text-md-start" *ngSwitchCase="'H'">
                                <i class="fas fa-mars me-2"></i>Hombre
                            </p>
                            <p class="card-text mb-1 text-center text-md-start" *ngSwitchCase="'M'">
                                <i class="fas fa-venus me-2"></i>Mujer
                            </p>
                            <p class="card-text mb-1 text-center text-md-start" *ngSwitchDefault>
                                <i class="fas fa-venus-mars me-2"></i>Otro
                            </p>
                        </span>
                    </div>
                    <div class="col-12 col-md-7 mt-4 mt-md-0">
                        <h6 class="mx-auto text-center text-md-start">Descripción del candidato</h6>
                        <p class="card-text mb-0">{{postulacion.usuario.descripcion}}</p>
                        <ul class="list-group my-2" *ngIf="showHistorialDescripcion">
                            <li class="list-group-item" *ngFor="let descripcion of historialDescripcion">{{descripcion}}
                            </li>
                        </ul>
                        <button class="btn btn-outline-secondary btn-sm float-end" type="button"
                            *ngIf="historialDescripcion.length > 0 && !showHistorialDescripcion"
                            (click)="showHistorialDescripcion = true"><i class="fas fa-history me-2"></i>Ver historial
                            de decripciones</button>
                        <button class="btn btn-outline-secondary btn-sm float-end" type="button"
                            *ngIf="showHistorialDescripcion" (click)="showHistorialDescripcion = false"><i
                                class="fas fa-eye-slash me-2"></i>Ocultar historial de decripciones</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--==========================================-->
    <!--===== Contenedor todas las tarjetas ======-->
    <!--==========================================-->
    <div class="row w-100 p-0 m-0">

        <!--=============================-->
        <!--===== Tarjetas grandes ======-->
        <!--=============================-->
        <div class="col-12 col-md-8 mt-1 mx-0 p-0">

            <!--==============================-->
            <!--===== Tarjetas Estudios ======-->
            <!--==============================-->
            <div class="card">
                <div class="card-body">
                    <h5>Educación</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex align-items-center mt-2"
                            *ngFor="let estudio of postulacion.usuario.estudios">
                            <i class="fas fa-graduation-cap fa-2x me-4"></i>
                            <div class="d-flex flex-column">
                                <h6 class="card-title">{{estudio.escuela}}</h6>
                                <p class="card-text" *ngIf="estudio.titulo != 'NO APLICA'">{{estudio.nivel+' -
                                    '+estudio.titulo}}
                                </p>
                                <p class="card-text" *ngIf="estudio.titulo === 'NO APLICA'">{{estudio.nivel}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!--======================================-->
            <!--===== Tarjetas Razón Aplicacion ======-->
            <!--======================================-->
            <div class="card mt-1">
                <div class="card-body">
                    <h5>Porqué el candidato es ideal para el puesto</h5>
                    <p class="card-text mb-0">
                        {{postulacion.usuario.razonAplicacion}}
                    </p>
                    <ul class="list-group my-2" *ngIf="showHistorialRazon">
                        <li class="list-group-item" *ngFor="let razon of histoialRazon">{{razon}}</li>
                    </ul>
                    <button class="btn btn-outline-secondary btn-sm float-end" type="button"
                        *ngIf="histoialRazon.length > 0 && !showHistorialRazon" (click)="showHistorialRazon = true"><i
                            class="fas fa-history me-2"></i>Ver historial</button>
                    <button class="btn btn-outline-secondary btn-sm float-end" type="button" *ngIf="showHistorialRazon"
                        (click)="showHistorialRazon = false"><i class="fas fa-eye-slash me-2"></i>Ocultar
                        historial</button>
                </div>
            </div>

            <!--=========================================-->
            <!--===== Tarjetas Experiencia Laboral ======-->
            <!--=========================================-->
            <div class="card mt-1">
                <div class="card-body">
                    <h5>Experiencia</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex align-items-center mt-2"
                            *ngFor="let trabajo of postulacion.usuario.experienciasLaborales">
                            <i class="fas fa-briefcase fa-2x me-4"></i>
                            <div class="row w-100 align-items-center">
                                <div class="d-flex flex-column">
                                    <div class="d-flex">
                                        <h6 class="card-title">{{trabajo.puesto}}</h6>
                                        <p class="card-text ms-auto d-none d-lg-block" *ngIf="trabajo.actual">
                                            {{trabajo.desde|date:'mediumDate'}} - Actualidad</p>
                                        <p class="card-text ms-auto d-none d-lg-block" *ngIf="!trabajo.actual">
                                            {{trabajo.desde|date:'mediumDate'}} - {{trabajo.hasta|date:'mediumDate'}}
                                        </p>
                                    </div>
                                    <div class="d-flex">
                                        <p class="card-text fst-italic mb-1">{{trabajo.empresa}}</p>
                                        <p class="card-text ms-auto d-none d-lg-block mb-1">
                                            {{trabajo.sueldoInicial|currency}} - {{trabajo.sueldoFinal|currency}}</p>
                                    </div>
                                    <p class="card-text d-block d-lg-none mb-1" *ngIf="trabajo.actual"><i
                                            class="fas fa-calendar-alt me-2"></i>{{trabajo.desde|date:'mediumDate'}} -
                                        Actualidad
                                    </p>
                                    <p class="card-text d-block d-lg-none mb-1" *ngIf="!trabajo.actual"><i
                                            class="fas fa-calendar-alt me-2"></i>{{trabajo.desde|date:'mediumDate'}} -
                                        {{trabajo.hasta|date:'mediumDate'}}
                                    </p>
                                    <p class="card-text mb-1 "><i
                                            class="fas fa-map-marker-alt me-2"></i>{{trabajo.municipio+',
                                        '+trabajo.estado}}
                                    </p>
                                    <p class="card-text d-block d-lg-none mb-1"><i
                                            class="fas fa-dollar-sign me-2"></i>{{trabajo.sueldoInicial|currency}} -
                                        {{trabajo.sueldoFinal|currency}}
                                    </p>
                                    <p class="card-text"><strong>Descripción</strong><br>{{trabajo.descripcion}}</p>
                                    <p class="card-text"><strong>Problema al que se
                                            enfrentó</strong><br>{{trabajo.descripcionProblema}}</p>
                                    <p class="card-text"><strong>Solución del
                                            problema</strong><br>{{trabajo.solucionProblema}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!--======================================-->
            <!--===== Tarjetas Resultado Terman ======-->
            <!--======================================-->
            <div class="card mt-1" [ngClass]="{'d-none': resultadoTerman === undefined || resultadoTerman === null}">
                <div class="card-body">
                    <h5 class="mb-3">Test de Terman</h5>
                    <canvas baseChart height="100" [datasets]="lineChartData" [labels]="lineChartLabels"
                        [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType">
                    </canvas>
                    <div
                        class="w-100 mt-3 mb-0 d-flex align-items-center justify-content-center text-center contenedor">
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq > 70}">{{iq | number:
                                '1.0-0'}}
                            </p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-white text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;"
                                    [ngClass]="{'bg-danger ': iq <= 70}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">deficiente</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 70 || iq > 79}">{{iq
                                | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;"
                                    [ngClass]="{'bg-danger ': iq > 70 && iq <=79}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">Bajo</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 79 || iq > 89}">{{iq
                                | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;"
                                    [ngClass]="{'bg-danger ': iq > 79 && iq <=89}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">A.T.M.</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 89 || iq > 110}">
                                {{iq | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;"
                                    [ngClass]="{'bg-danger ': iq > 89 && iq <=110}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">T.M.B</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 110 || iq > 119}">
                                {{iq | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;"
                                    [ngClass]="{'bg-danger ': iq > 110 && iq <=119}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">Normal</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 119 || iq > 125}">
                                {{iq | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;"
                                    [ngClass]="{'bg-danger ': iq > 119 && iq <=125}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">Superior</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 125}">{{iq | number:
                                '1.0-0'}}
                            </p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;"
                                    [ngClass]="{'bg-danger ': iq > 125}">.</div>
                            </div>
                            <p style="font-size: 10px;">Sobresaliente</p>
                        </div>
                    </div>

                    <div class="w-100 mx-auto contenedor" *ngIf="resultadoTerman != null">
                        <table class="table table-secondary text-center" style="font-size: 12px;">
                            <thead>
                                <tr>
                                    <th scope="col p-1">Puntaje</th>
                                    <th scope="col">CI Terman</th>
                                    <th scope="col">Interpretación CI</th>
                                    <th scope="col">Capacidad de aprendizaje</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{resultadoTerman.calificacion}}</td>
                                    <td>{{iq | number: '1.0-0'}}</td>
                                    <ng-container *ngIf="iq <= 70">
                                        <td>Deficiente</td>
                                        <td>Deficiente</td>
                                    </ng-container>
                                    <ng-container *ngIf="iq > 70 && iq <=79">
                                        <td>Inferior</td>
                                        <td>Bajo</td>
                                    </ng-container>
                                    <ng-container *ngIf="iq > 79 && iq <=89">
                                        <td>Inferior al termino medio</td>
                                        <td>Término Medio Bajo</td>
                                    </ng-container>
                                    <ng-container *ngIf="iq > 89 && iq <=110">
                                        <td>Termino medio bajo</td>
                                        <td>Término Medio</td>
                                    </ng-container>
                                    <ng-container *ngIf="iq > 110 && iq <=119">
                                        <td>Normal</td>
                                        <td>Termino medio alto</td>
                                    </ng-container>
                                    <ng-container *ngIf="iq > 119 && iq <=125">
                                        <td>Sobresaliente</td>
                                        <td>Superior</td>
                                    </ng-container>
                                    <ng-container *ngIf="iq > 125">
                                        <td>Sobresaliente</td>
                                        <td>Excelente</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="d-grid gap-2">
                        <button class="btn btn-outline-secondary btn-sm float-end mt-3"
                            (click)="verDetallesTerman = true" *ngIf="!verDetallesTerman"><i
                                class="fas fa-search-plus me-2"></i>Ver detalles</button>
                    </div>

                    <div class="accordion mt-3" id="acordionPadre" *ngIf="verDetallesTerman">
                        <div class="accordion-item" *ngFor="let seccion of resultado.secciones; let i=index">
                            <h2 class="accordion-header" id="headingSeccion{{seccion.numeroSeccion}}">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#seccion' + seccion.numeroSeccion" aria-expanded="false">
                                    Sección {{seccion.numeroSeccion}}
                                </button>
                            </h2>
                            <div id="seccion{{seccion.numeroSeccion}}" class="accordion-collapse collapse"
                                [attr.aria-labelledby]="'headingSeccion'+seccion.numeroSeccion"
                                data-bs-parent="#acordionPadre">
                                <div class="accordion-body" id="acordionSeccion{{seccion.numeroSeccion}}">
                                    <strong>Calificación de la sección: {{seccion.calificacion}}</strong>
                                    <div class="accordion mt-3" id="accordionExample">
                                        <div class="accordion-item" *ngFor="let pregunta of seccion.preguntas">
                                            <h2 class="accordion-header"
                                                id="headingPregunta{{pregunta.numeroPregunta}}">
                                                <button class="accordion-button collapsed" type="button"
                                                    data-bs-toggle="collapse"
                                                    [attr.data-bs-target]="'#pregunta' + pregunta.numeroPregunta"
                                                    aria-expanded="false"
                                                    [ngStyle]="{ border: pregunta.correcta ? '2px solid #5cb85c' : '2px solid #f44336' }">
                                                    <i class="fas fa-check-circle me-2 " style="color: #5cb85c"
                                                        *ngIf="pregunta.correcta"></i>
                                                    <i class="fas fa-times-circle me-2 " style="color: #f44336"
                                                        *ngIf="!pregunta.correcta"></i>
                                                    Pregunta {{pregunta.numeroPregunta}}
                                                </button>
                                            </h2>
                                            <div id="pregunta{{pregunta.numeroPregunta}}"
                                                class="accordion-collapse collapse"
                                                [attr.aria-labelledby]="'headingPregunta'+pregunta.numeroPregunta"
                                                [attr.data-bs-parent]="'#acordionSeccion' + seccion.numeroSeccion">
                                                <div class="accordion-body">
                                                    <strong>Intentos</strong>
                                                    <ul class="list-group mt-2">
                                                        <li class="list-group-item disabled" aria-disabled="true"
                                                            *ngFor="let intento of pregunta.intentos">
                                                            {{intento.respuesta}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <!--=======================================-->
            <!--===== Tarjetas Resultado Cleaver ======-->
            <!--=======================================-->
            <app-resultado-cleaver *ngIf="mostrarClaver" [idUsuario]="idUsuario" (mostrar)="mostrarClaver = $event">
            </app-resultado-cleaver>

            <!--=======================================-->
            <!--===== Tarjetas Resultado Ingles ======-->
            <!--=======================================-->
            <div class="card mt-1" *ngIf="resultadoIngles.realizado">
                <div class="card-body">
                    <h5 class="mb-3">Test de Ingles</h5>
                    <div class="d-flex justify-content-evenly">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Seccion</th>
                                    <th scope="col">Puntaje</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Seccion 1</td>
                                    <td> {{ resultadoIngles.seccion1 }} / 10</td>
                                </tr>
                                <tr>
                                    <td>Seccion 2</td>
                                    <td> {{ resultadoIngles.seccion2 }} / 5 </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><strong>Puntaje total</strong></td>
                                    <td> {{ resultadoIngles.calificacionFinal }} / 15</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!--=======================================-->


        </div>

        <!--==============================-->
        <!--===== Tarjetas pequeñas ======-->
        <!--==============================-->
        <div class="col-12 col-md-4 mt-1 mx-0 p-0 ps-md-1">

            <!--=====================================-->
            <!--===== Tarjeta de observaciones ======-->
            <!--=====================================-->
            <div class="card mt-1">
                <div class="card-body">
                    <h5>Observaciones</h5>
                    <ul class="list-group mb-2">
                        <li class="list-group-item" *ngFor="let observacion of postulacion.observaciones; let i=index">
                            <div class="d-flex w-100 justify-content-between">
                                <p class="mb-1">{{observacion.observacion}}.</p>
                                <small class="text-muted">{{observacion.fecha | date: 'mediumDate'}}</small>
                            </div>
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <small class="text-muted fw-bold">{{observacion.creador | undefined}}</small>
                                <div>
                                    <button class="btn btn-outline-primary btn-sm me-1"
                                        (click)="prepararObservacionEditar(i)"
                                        *ngIf="idAdmin === observacion.idUsuarioCreador"><i
                                            class="fas fa-pen"></i></button>
                                    <button class="btn btn-outline-danger btn-sm" (click)="deleteObservacion(i)"
                                        *ngIf="idAdmin === observacion.idUsuarioCreador"><i
                                            class="fas fa-trash"></i></button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <form class="my-3" *ngIf="agregarObservacion" (keydown.enter)="$event.preventDefault()">
                        <input type="text" class="form-control form-control-sm mb-2" id="inputObservacion"
                            placeholder="Observación" #inputObservacion>
                        <div class="d-grid gap-2">
                            <div>
                                <button type="button" class="btn btn-outline-secondary btn-sm w-50"
                                    (click)="editarObservacion(inputObservacion.value)"
                                    *ngIf="indexObservacion != null">Aceptar</button>
                                <button type="button" class="btn btn-outline-secondary btn-sm w-50"
                                    (click)="indexObservacion = null; agregarObservacion = false"
                                    *ngIf="indexObservacion != null">Cancelar</button>
                            </div>
                            <button type="button" class="btn btn-outline-secondary btn-sm"
                                (click)="addObservacion(inputObservacion.value)"
                                *ngIf="indexObservacion === null">Aceptar</button>
                        </div>
                    </form>
                    <div class="d-grid gap-2" *ngIf="!agregarObservacion">
                        <button class="btn btn-outline-secondary" (click)="agregarObservacion = true"><i
                                class="fas fa-plus me-2"></i>Agregar Observación</button>
                    </div>
                </div>
            </div>

            <!--=================================-->
            <!--===== Tarjeta de contactos ======-->
            <!--=================================-->
            <div class="card mt-1">
                <div class="card-body">
                    <h5>Información de contacto</h5>
                    <p class="card-text mb-1 text-center text-md-start"><i
                            class="fas fa-envelope me-2"></i>{{postulacion.usuario.correos}}</p>
                    <p class="card-text mb-1 text-center text-md-start"><i
                            class="fas fa-phone me-2"></i>{{postulacion.usuario.telefonos}}</p>
                </div>
            </div>

            <!--=====================================-->
            <!--===== Tarjeta datos personales ======-->
            <!--=====================================-->
            <div class="card mt-1 ">
                <div class="card-body">
                    <h5>Datos personales</h5>
                    <p class="card-text mb-1 text-center text-md-start"><i
                            class="fas fa-ring me-2"></i>{{postulacion.usuario.estadoCivil}}</p>
                    <p class="card-text mb-1 text-center text-md-start"><i
                            class="fas fa-home me-2"></i>{{postulacion.usuario.viveCon}}</p>
                    <h6 class="text-center text-md-start my-3"
                        *ngIf="postulacion.usuario.dependeConyuge||postulacion.usuario.dependenHijos || postulacion.usuario.dependenOtros || postulacion.usuario.dependenPadres">
                        Personas dependientes</h6>
                    <ul class="list-group">
                        <li class="list-group-item" *ngIf="postulacion.usuario.dependenPadres">Padres</li>
                        <li class="list-group-item" *ngIf="postulacion.usuario.dependeConyuge">Conyugue</li>
                        <li class="list-group-item" *ngIf="postulacion.usuario.dependenHijos">Hijos</li>
                        <li class="list-group-item" *ngIf="postulacion.usuario.dependenOtros">Otros</li>
                    </ul>
                </div>
            </div>

            <!--=============================-->
            <!--===== Tarjeta palabras ======-->
            <!--=============================-->
            <div class="card mt-1 ">
                <div class="card-body row w-100 m-0">
                    <h5>Palabras encontradas</h5>
                    <ul class="list-group">
                        <ng-container *ngFor="let habilidad of postulacion.usuario.habilidades">
                            <ng-container *ngIf="!habilidad.habilidad.idioma">
                                <li class="list-group-item">
                                    {{habilidad.habilidad.habilidad}}
                                </li>
                            </ng-container>
                        </ng-container>

                    </ul>
                </div>
            </div>

            <!--============================-->
            <!--===== Tarjeta Idiomas ======-->
            <!--============================-->
            <div class="card mt-1 ">
                <div class="card-body row w-100 m-0">
                    <h5>Idiomas</h5>
                    <ul class="list-group">
                        <ng-container *ngFor="let habilidad of postulacion.usuario.habilidades">
                            <ng-container *ngIf="habilidad.habilidad.idioma">
                                <li class="list-group-item d-flex justify-content-between">
                                    <p class="p-0 m-0">{{habilidad.habilidad.habilidad}}</p>
                                    <p class="p-0 m-0">{{habilidad.porcentaje + ' %'}}</p>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ul>
                </div>
            </div>

            <!--====================================-->
            <!--===== Tarjeta Imagenes Terman ======-->
            <!--====================================-->
            <div class="card mt-1 " *ngIf="resultadoTerman != undefined && resultadoTerman != null">
                <div class="card-body row w-100 m-0">
                    <h5>Imagenes Terman</h5>
                    <div class="col-4 m-0 p-1 text-center" *ngFor="let image of images" role="button">
                        <img [src]="image" class="img-fluid img" (click)="imgSelect = image" data-bs-toggle="modal"
                            data-bs-target="#exampleModal" />
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
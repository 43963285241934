import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmpleosModel, PostulcionModel, Test } from '../models/empleos.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EmpleosService {

  //private urlBase: string = 'http://localhost:8090/usuarios';

  private urlBase: string = environment.urlApi+'/usuarios';

  constructor(private http: HttpClient, private authService: AuthService) { }

  getEmpleos(): Observable<EmpleosModel[]> {

    const urlEndpoint = this.urlBase + '/empleos';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<EmpleosModel[]>(urlEndpoint,{headers: httpHeaders});
    
  }

  getEmpleo(id: number): Observable<EmpleosModel> {

    const urlEndpoint = this.urlBase + '/empleos/'+id;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<EmpleosModel>(urlEndpoint,{headers: httpHeaders});
    
  }

  publicarEmpleo(empleo: EmpleosModel): Observable<EmpleosModel> {

    const urlEndpoint = this.urlBase + '/empleos';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    empleo.activo = true;

    return this.http.post<EmpleosModel>(urlEndpoint,empleo,{headers: httpHeaders});
    
  }

  borrarEmpleo(empleo: EmpleosModel): Observable<EmpleosModel> {

    const urlEndpoint = this.urlBase + '/empleos/'+empleo.id;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.delete<EmpleosModel>(urlEndpoint,{headers: httpHeaders});
    
  }

  actulizarEmpleo(empleo: EmpleosModel): Observable<EmpleosModel>{

    const urlEndpoint = this.urlBase + '/empleos/'+empleo.id;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.put<EmpleosModel>(urlEndpoint,empleo,{headers: httpHeaders});

  }

  postularse(postulacion: PostulcionModel): Observable<any>{
    const urlEndpoint = this.urlBase + '/postulaciones';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.post(urlEndpoint,postulacion,{headers: httpHeaders});
  }

  getVacantesActivas(): Observable<EmpleosModel[]> {
    const urlEndpoint = this.urlBase + '/admin/vacantes';

    return this.http.get<EmpleosModel[]>(urlEndpoint);
  }

  postPostulacionRapida(data: any): Observable<any> {
    const urlEndpoint = this.urlBase + '/admin/postulacion_rapida';

    const formData: FormData = new FormData();

    let nombreCompleto = `${data.nombre} ${data.apellidoPaterno} ${data.apellidoMaterno}`;
    formData.append('nombre_completo', nombreCompleto);
    formData.append('archivo', data.archivo);
    formData.append('type', data.type);
    formData.append('idEmpleo', data.idEmpleo);
    formData.append('telefono', data.telefono);
    formData.append('bodyPreguntas', data.respuestasBodyEmail);
    formData.append('inlineImages', data.inlineImages.toString());

    return this.http.post<any>(urlEndpoint, formData);
  }

  getTestRapidos(): Observable<Test[]> {
    const urlEndpoint = this.urlBase + '/admin/tests_rapidos';

    return this.http.get<Test[]>(urlEndpoint);
  }

  getCorreosNotificacion(): Observable<string[]> {
    const urlEndpoint = this.urlBase + '/admin/correos_notificacion';

    return this.http.get<string[]>(urlEndpoint);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmpleosModel, PostulcionModel } from 'src/app/models/empleos.model';
import { UsuarioHabildadModel, UsuarioModel } from 'src/app/models/usuario.model';
import { EmpleosService } from 'src/app/services/empleos.service';
import { FlujoService } from 'src/app/services/flujo.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-empleos',
  templateUrl: './empleos.component.html',
  styleUrls: ['./empleos.component.css']
})
export class EmpleosComponent implements OnInit {

  empleos: EmpleosModel[];

  empleoInfo: EmpleosModel;

  screenHeight: number;

  montoNoValido: boolean = false;

  usuario: UsuarioModel;

  constructor(private empleosService: EmpleosService, private usuarioService: UsuarioService,
    private router: Router, private flujoService: FlujoService) {

    this.usuario = new UsuarioModel();
    this.usuario.cvCompleto = true;

    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();

    this.screenHeight = screen.height - 160;
    this.empleos = [];

    this.empleoInfo = new EmpleosModel();

    empleosService.getEmpleos().subscribe(empleos => {
      empleos.forEach(empleo => {
        if (empleo.activo) {
          this.empleos.push(empleo);
        }
      });

      if (this.empleos.length >= 1) {
        this.empleoInfo = this.empleos[0];
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por el momento no hay vacantes disponibles'
        }).then(() => {
          router.navigate(['perfil'])
        })

      }

      this.usuarioService.usuario.subscribe(usuario => {

        this.usuario = usuario;

        if (this.usuario.cvCompleto) {
          if (this.usuario.postulaciones.length > 0) {
            flujoService.$icons.emit(['success', 'success', 'success'])
          } else {
            flujoService.$icons.emit(['success', 'success', 'here'])
          }
        } else {
          if (this.usuario.postulaciones.length > 0) {
            flujoService.$icons.emit(['success', 'error', 'success'])
          } else {
            flujoService.$icons.emit(['success', 'error', 'here'])
          }
        }

        Swal.close();

      }, (err) => console.log(err));

    });

  }

  ngOnInit(): void {
  }

  clickMasInformacion(num: number): void {
    this.empleoInfo = this.empleos[num];
    console.log(this.empleoInfo);
    console.log(this.usuario.cvCompleto);
    if (screen.width < 768)
      document.getElementById('opendetalle').click();
  }

  postular() {
    document.getElementById('closeDetalle').click();
  }

  clickPostularse(sueldo: string = '5000'): void {

    if (Number(sueldo) < 2000 || Number(sueldo) > 150000) {
      this.montoNoValido = true;
      return;
    }

    this.montoNoValido = false;
    document.getElementById('btnCloseModalSueldo2').click();

    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();

    if (!this.usuario.cvCompleto) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Completa tu perfil para poder aplicar a esta vacante'
      });
      return;
    }

    let postulacion: PostulcionModel = new PostulcionModel();
    postulacion.idEmpleo = this.empleoInfo.id;
    this.usuarioService.usuario.subscribe(usuario => {

      postulacion.idUsuario = usuario.id

      let bandera: boolean = true;

      if(usuario.postulaciones.length >  0) {
        bandera = false;
        Swal.fire({
          icon: 'info',
          text: 'No se puede aplicar a mas de una vacante'
        })
      }
      
      usuario.postulaciones.forEach(postulacion => {
        if (bandera) {
          if (postulacion.id === this.empleoInfo.id) {
            bandera = false;
            Swal.fire({
              icon: 'info',
              text: 'Ya has aplicado a esta vacante'
            })
          }
          console.log(postulacion);
          let fecha1: Date = new Date(postulacion.fechaPublicacion);
          console.log(fecha1)
          let fecha2: Date = new Date()
          let resta = fecha2.getTime() - fecha1.getTime()
          console.log(resta);
          if (Math.round(resta / (1000 * 60 * 60 * 24)) <= 30) {
            bandera = false ;
            Swal.fire({
              icon: 'info',
              text: 'Solo puedes aplicar a 1 vacante cada 30 dias, si lo prefieres' +
                'puedes cancelar tu postulacion actual en el menú de postulaciones'
            })
          }
        }
      });
      console.log(this.empleoInfo.pruebas)
      if (bandera) {
        postulacion.sueldoPretendido = Number(sueldo);
        this.empleosService.postularse(postulacion).subscribe(
          res => {
            Swal.fire({
              icon: 'success',
              text: 'Postulación exitosa',
              showConfirmButton: false,
              timer: 1500
            }); console.log(this.empleoInfo.pruebas)
            if (this.empleoInfo.pruebas === undefined || this.empleoInfo.pruebas === null || this.empleoInfo.pruebas.length <= 0) {
              Swal.fire({
                icon: 'success',
                text: 'Gracias por aplicar, para continuar con el proceso favor de dirigirse al menú de "Postulaciones" y realizar las baterías que se solicitan.',
                allowOutsideClick: false
              })
            } else {
              this.empleoInfo.pruebas.forEach(prueba => {
                if (!res.existTest && prueba.id == 1)
                  this.router.navigate(['test'])
                else if (!res.existTestCleaver && prueba.id == 2)
                  this.router.navigate(['prueba.clever'])
                else {
                  Swal.fire({
                    icon: 'success',
                    text: 'Gracias por aplicar, para continuar con el proceso favor de dirigirse al menú de "Postulaciones" y realizar las baterías que se solicitan.',
                    allowOutsideClick: false
                  })
                }
              });
            }
          },
          err => console.log(err)
        );
        //this.usuarioService.actualizarUsuario2(usuario).subscribe(res => null, err => console.log(err));
      }
    }, (err) => console.log(err));

  }

  cancelarPostulacion(): void {
    this.empleoInfo = new EmpleosModel();
  }

}

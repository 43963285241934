import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observacion, PostulacionModel } from '../modelos/postulacion.model';
import { TermanResultModel } from '../modelos/terman.model';
import { UsuarioPruebaModel } from '../models/resultados.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  //private urlBase: string = 'http://localhost:8090/usuarios/admin';
  private urlBase: string = environment.urlApi + '/usuarios/admin';

  constructor(private authService: AuthService, private http: HttpClient) { }

  getDashboardInfo(): Observable<any> {

    const urlEndpoint = this.urlBase + '/dashboard';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<any>(urlEndpoint, { headers: httpHeaders })

  }

  getDashboardInfoArchivada(idVacante: number): Observable<any> {

    const urlEndpoint = this.urlBase + '/dashboard/' + idVacante;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<any>(urlEndpoint, { headers: httpHeaders })

  }

  getPostulacion(idUsuario: number, idEmpleo: number): Observable<PostulacionModel> {
    const urlEndpoint = this.urlBase + '/postulacion/' + idUsuario + '/' + idEmpleo;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<PostulacionModel>(urlEndpoint, { headers: httpHeaders })
  }

  reasignarPostulacion(oldEmpleo: number, newEmpleo: number, observacion: string, postulanteCorreo: string) {
    let urlEndpoint = this.urlBase + '/postulacion/reasignar';
    let formData: FormData = new FormData();
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    formData.append("oldEmpleo", oldEmpleo.toString());
    formData.append("newEmpleo", newEmpleo.toString());
    formData.append("observacion", observacion);
    formData.append("postulante", postulanteCorreo);

    return this.http.put(urlEndpoint, formData, { headers });
  }

  changeEstatusPostulacion(idUsuario: number, idEmpleo: number, estatus: number): Observable<any> {
    const urlEndpoint = this.urlBase + '/postulacion';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    let body: ChangePostulacionDTO = {
      idUsuario: idUsuario,
      idEmpleo: idEmpleo,
      estatus: estatus
    }

    return this.http.put<any>(urlEndpoint, body, { headers: httpHeaders })
  }

  cambiarEstatusVacante(idEmpleo: number, estatus: number): Observable<any> {
    const urlEndpoint = this.urlBase + '/vacante/estatus/' + idEmpleo;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    const params = new HttpParams().set('estatus', estatus.toString())

    return this.http.put<any>(urlEndpoint, null, { headers: httpHeaders, params: params })
  }

  getTermanResult(idUsuario: number): Observable<any> {
    const urlEndpoint = this.urlBase + '/resultados/terman/' + idUsuario;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<any>(urlEndpoint, { headers: httpHeaders })
  }


  getInglesResult(idUsuario: number): Observable<any> {
    const urlEndpoint = this.urlBase + '/resultados/inglesb2/' + idUsuario;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<any>(urlEndpoint, { headers: httpHeaders })
  }

  deleteObservacion(idObservacion: number): Observable<boolean> {
    const urlEndpoint = this.urlBase + '/postulacion/observacion/' + idObservacion;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.delete<boolean>(urlEndpoint, { headers: httpHeaders })
  }

  agregarObservacion(observacion: Observacion, idUsuario: number, idEmpleo: number): Observable<Observacion> {
    const urlEndpoint = this.urlBase + '/postulacion/observacion/' + idUsuario + '/' + idEmpleo;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.post<Observacion>(urlEndpoint, observacion, { headers: httpHeaders })
  }

  editarObservacion(observacion: Observacion): Observable<Observacion> {
    const urlEndpoint = this.urlBase + '/postulacion/observacion/' + observacion.id;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.put<Observacion>(urlEndpoint, observacion, { headers: httpHeaders })
  }

  asignarPrueba(idUsuario: number, idPrueba: number): Observable<boolean> {
    const urlEndpoint = this.urlBase + '/asignarPrueba';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    let asignacion: UsuarioPruebaModel = new UsuarioPruebaModel();

    asignacion.idUsuario = idUsuario;
    asignacion.idPrueba = idPrueba;

    return this.http.post<boolean>(urlEndpoint, asignacion, { headers: httpHeaders })
  }

}

interface ChangePostulacionDTO {
  idUsuario: number;
  idEmpleo: number;
  estatus: number;
}

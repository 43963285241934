<!-- Boton invisible del modal de camara -->
<button type="button" class="d-none" id=btnModalCamara data-bs-toggle="modal" data-bs-target="#staticBackdrop"></button>
  
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Uso de la cámara</h5>
        </div>
        <div class="modal-body">
            <p class="card-text">
                El presente test necesita la cámara encendida para poder monitorear que la persona que realiza el test esté
                en todo momento interesada a obtener el puesto solicitado. 
            </p>
            <p class="card-text">
                Por favor selecciona la cámara frontal de tu dispositivo,
                de no seleccionar la cámara correcta, los evaluadores podrían descartar tu postulación.
            </p>
            <app-camara [tiempoCaptura]="numeroRandom" (pictureTaken)="handleImage($event)" ></app-camara>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="returnPerfiil()">Volver a perfil</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
<div class="container-fluid h-100 " *ngIf="instruccionesPrueba">
    <div class="row d-flex justify-content-center h-100">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 d-flex flex-column justify-content-center h-100">
            <div class="bg-white shadow d-flex align-items-center flex-column text-center px-3" [ngClass]="{'h-100':esMovil}">
                <div class="w-50">
                    <img class="img-fluid" src="../../../assets/img/TruemedGroupLogo.jpeg">
                </div>
                <h5 class="card-title mt-2">Test {{prueba.nombre|uppercase}}</h5>
                <p class="card-text mb-3 fw-bold">Duración Aproximada: {{tiempoTotal/60}} minutos</p>
                <b>Instrucciones</b> 
                <p class="card-text">
                    La presente prueba consta de 10 Secciones, cada sección tendrá un tiempo determinado y éste se le hará saber
                    al inicio de cada sección.
                </p>
                <p class="card-text">
                    Asegúrate de estar en un lugar tranquilo y sin distracciones, una vez comenzada la prueba no se podrá pausar ni cancelar.
                </p>
                <p class="card-text">
                    Esta prueba es de aplicación única por lo que no se podrá repetir.
                </p>
                <button type="button" class="btn btn-info text-white bg-tm mb-3" (click)="instruccionesPrueba = false"> Iniciar Prueba</button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid h-100" *ngIf="!iniciarTest && !instruccionesPrueba">
    <div class="row d-flex justify-content-center h-100">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 d-flex flex-column justify-content-center h-100">
            <div class="d-flex justify-content-center mb-5 d-none d-sm-block">
                <app-flujo [icons]="icons" [size]="'small'" 
                            [titulos]="titulos"></app-flujo>
            </div>
            <div class="bg-white shadow d-flex align-items-center flex-column text-center" [ngClass]="{'h-100':esMovil}">
                <div class="d-flex justify-content-center mb-2 mt-3 d-block d-sm-none">
                    <app-flujo [icons]="icons" [size]="'small'" 
                                [titulos]="titulos"></app-flujo>
                </div>
                <div class="w-50">
                    <img class="img-fluid" src="../../../assets/img/TruemedGroupLogo.jpeg">
                </div>
                <h5 class="card-title mt-2">Test {{prueba.nombre|uppercase}}</h5>
                <p class="card-text mb-3 fw-bold">Sección {{seccionActual}}</p>
                <p class="card-text mb-3 fw-bold">Duración: {{seccion.tiempoSegundos/60}} minutos</p>
                <b>Instrucciones</b> 
                <p class="card-text mb-3">{{seccion.instrucciones}}</p>
                <b>Ejemplo</b> 
                <p class="card-text mb-3">{{seccion.preguntas[0].pregunta}}</p>
                <div class="d-flex">
                    <div *ngFor="let opcion of seccion.preguntas[0].opciones; let i = index" 
                        class="card-text mb-3 me-3">
                        {{i + '.- '+opcion.opcion}}
                    </div>
                </div>
                <p class="card-text mb-3"><b>Respuesta: </b>{{seccion.preguntas[0].respuesta}}</p>
                <button type="button" class="btn btn-info text-white bg-tm mb-3" (click)="startTest()"> Iniciar Sección</button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid h-100" *ngIf="iniciarTest">
    <div class="row d-flex justify-content-center h-100">
        <div class="col-12 col-sm-10 col-lg-6 d-flex flex-column justify-content-center h-100">
            <div class="d-flex justify-content-center mb-5 d-none d-sm-block">
                <app-flujo [icons]="icons" [size]="'small'" 
                            [titulos]="titulos"></app-flujo>
            </div>
            <div class="bg-white shadow d-flex align-items-center flex-column text-center" [ngClass]="{'h-100':esMovil}">
                <div class="d-flex justify-content-center mb-2 mt-3 d-block d-sm-none">
                    <app-flujo [icons]="icons" [size]="'small'" 
                                [titulos]="titulos"></app-flujo>
                </div>
                <div class="w-50">
                    <img class="img-fluid" src="../../../assets/img/TruemedGroupLogo.jpeg">
                </div>
                <h5 class="card-title mt-2">Pregunta {{preguntaActual}}</h5>
                <p class="card-text mb-3 fw-bold">{{minutosReloj|number:'2.0'}}:{{segundosReloj|number:'2.0'}}</p>
                <p class="card-text mb-3">{{seccion.preguntas[preguntaActual].pregunta}}</p>

                <div class="d-flex" [ngClass]="{'flex-column':esMovil}" *ngIf="seccion.preguntas[preguntaActual].tipo == 1">
                    <div class="form-check mb-3" *ngFor="let opcion of seccion.preguntas[preguntaActual].opciones; let i = index" >
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" [value]="opcion.opcion" (click)="clickOpcion(i)">
                        <label class="form-check-label me-3">{{opcion.opcion}}</label>
                    </div>
                </div>
                <div class="d-flex" [ngClass]="{'flex-column':esMovil}" *ngIf="seccion.preguntas[preguntaActual].tipo == 2">
                    <div class="form-check mb-3" >
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="igual"
                        [value]="'igual'" (click)="clickOpcionString('igual')">
                    <label class="form-check-label me-3">Igual</label>
                    </div>
                    <div class="form-check mb-3" >
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="opuesto"
                            [value]="'opuesto'" (click)="clickOpcionString('opuesto')">
                        <label class="form-check-label me-3">Opuesto</label>
                        </div>
                </div>
                <div class="d-flex" [ngClass]="{'flex-column':esMovil}" *ngIf="seccion.preguntas[preguntaActual].tipo == 3">
                    <div class="form-check mb-3" *ngFor="let opcion of seccion.preguntas[preguntaActual].opciones; let i = index" >
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [id]="'chk'+ i" (click)="clickDosCheckbox(i)">
                            <label class="form-check-label" for="flexCheckDefault">
                              {{opcion.opcion}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="d-flex" [ngClass]="{'flex-column':esMovil}" *ngIf="seccion.preguntas[preguntaActual].tipo == 4">
                    <div class="mb-3">
                        <input type="number" id="respuesta" class="form-control" placeholder="Respuesta" (change)="changeInput(Respuesta.value)" #Respuesta>
                    </div>
                </div>
                <div class="d-flex" [ngClass]="{'flex-column':esMovil}" *ngIf="seccion.preguntas[preguntaActual].tipo == 5">
                    <div class="form-check mb-3" >
                    <input class="form-check-input" type="radio" name="inlineRadioOptions"  id="si"
                        [value]="'Si'" (click)="clickOpcionString('Si')">
                    <label class="form-check-label me-3">Si</label>
                    </div>
                    <div class="form-check mb-3" >
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id = "no"
                            [value]="'No'" (click)="clickOpcionString('No')">
                        <label class="form-check-label me-3">No</label>
                        </div>
                </div>
                <div class="d-flex" [ngClass]="{'flex-column':esMovil}" *ngIf="seccion.preguntas[preguntaActual].tipo == 6">
                    <div class="form-check mb-3" >
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id = "verdadero"
                        [value]="'verdadero'" (click)="clickOpcionString('verdadero')">
                    <label class="form-check-label me-3">verdadero</label>
                    </div>
                    <div class="form-check mb-3" >
                        <input class="form-check-input" type="radio" name="inlineRadioOptions"  id="falso"
                            [value]="'falso'" (click)="clickOpcionString('falso')">
                        <label class="form-check-label me-3">falso</label>
                        </div>
                </div>
                <div class="d-flex" [ngClass]="{'flex-column':esMovil}" *ngIf="seccion.preguntas[preguntaActual].tipo == 7">
                    <div class="mb-3 me-2">
                        <input type="number" class="form-control" id="Respuesta1" placeholder="Respuesta 1" (change)="change2Input(0,Respuesta1.value)" #Respuesta1>
                    </div>
                    <div class="mb-3">
                        <input type="number" class="form-control" id="Respuesta2" placeholder="Respuesta 2" (change)="change2Input(1,Respuesta2.value)" #Respuesta2>
                    </div>
                </div>
                <button type="button" class="btn btn-info text-white bg-tm mb-3" (click)="clickSiguientePregunta()">Siguiente</button>
            </div>
        </div>
    </div>
</div>
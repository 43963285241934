import { UsuarioModel } from "./usuario.model";

export class UsuarioPruebaModel{
    id: number;
    idUsuario: number;
    usuario: UsuarioModel;
    idPrueba: number;
    calificacion: number;
    secciones:SeccionResultadoModel[];
    resultado: any;

    constructor(){
        this.idUsuario = null;
        this.idPrueba = null;
        this.calificacion = 0;
        this.secciones = [];
    }
}

export class SeccionResultadoModel{
    id: number;
    idUsuarioPrueba: number;
    numeroSeccion: number;
    calificacion: number = 0;
    preguntas: PreguntaResultadoModel[];

    constructor(){
        this.idUsuarioPrueba = null;
        this.numeroSeccion = 1;
        this.calificacion = 0;
        this.preguntas = [];
    }
}

export class PreguntaResultadoModel{
    id: number;
    idSeccionesResultados: number;
    numeroPregunta: number;
    correcta: boolean = false;
    intentos: IntentosModel[];
    constructor(){
        this.idSeccionesResultados = null;
        this.numeroPregunta = 1;
        this.correcta = false;
        this.intentos = [];
    }
}

export class IntentosModel{
    
    id: number;
    idPreguntaresultado: number;
    numero: number;
    respuesta: string

    constructor(respuesta: string, intento: number){
        this.respuesta = respuesta;
        this.numero = intento;
    }

}

// const urlBase: string = 'http://localhost';

// export const environment = {
//   production: true,
//   urlApp: urlBase+':4200',
//   urlApi: urlBase+':8090'
// };

const urlBase: string = 'https://reclutamiento.truemedgroup.com';

export const environment = {
  production: true,
  urlApp: urlBase,
  urlApi: urlBase + ':7000'
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GraficaCleaverComponent } from 'src/app/shared/grafica-cleaver/grafica-cleaver.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cleaver-temporal',
  templateUrl: './cleaver-temporal.component.html',
  styleUrls: ['./cleaver-temporal.component.css']
})
export class CleaverTemporalComponent implements OnInit {

  @ViewChild('GraficaM', { static: false }) graficaM: GraficaCleaverComponent;  
  @ViewChild('GraficaI', { static: false }) graficaI: GraficaCleaverComponent;  
  @ViewChild('GraficaT', { static: false }) graficaT: GraficaCleaverComponent;  

  resultado: any = null;

  M_DISC: number[] = [];
  L_DISC: number[] = [];
  T_DISC: number[] = [];


  constructor(private http: HttpClient, private authService: AuthService) { 

    this.getTest(55248);

  }

  ngOnInit(): void {


  }

  getTest(idUsuario:number): void {
    const urlEndpoint =  environment.urlApi + '/usuarios/admin/resultados/cleaver/'+idUsuario;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });
    this.M_DISC = [];
    this.L_DISC = [];
    this.T_DISC = [];
    this.http.get<any>(urlEndpoint,{headers: httpHeaders}).subscribe(res => {
      this.resultado = res;
      this.M_DISC.push(res.GM.D);
      this.M_DISC.push(res.GM.I);
      this.M_DISC.push(res.GM.S);
      this.M_DISC.push(res.GM.C);

      this.L_DISC.push(res.GL.D);
      this.L_DISC.push(res.GL.I);
      this.L_DISC.push(res.GL.S);
      this.L_DISC.push(res.GL.C);

      this.T_DISC.push(res.GT.D);
      this.T_DISC.push(res.GT.I);
      this.T_DISC.push(res.GT.S);
      this.T_DISC.push(res.GT.C);

      this.graficaM.actualizar();
      this.graficaI.actualizar();
      this.graficaT.actualizar();
    });
  }

}

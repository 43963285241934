import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmpleosModel, Test } from 'src/app/models/empleos.model';
import { HabilidaesModel } from 'src/app/models/habilidades.model';
import { PruebaModel } from 'src/app/models/test.model';
import { AdminService } from 'src/app/services/admin.service';
import { EmpleosService } from 'src/app/services/empleos.service';
import { HabilidadesService } from 'src/app/services/habilidades.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-administrador-empleos',
  templateUrl: './administrador-empleos.component.html',
  styleUrls: ['./administrador-empleos.component.css']
})
export class AdministradorEmpleosComponent implements OnInit {


  formularioAgregarEmpleo: FormGroup;

  empleos: EmpleosModel[] = [];

  empleosInactivos: EmpleosModel[] = [];

  empleosActivos: EmpleosModel[] = [];

  agregar: boolean;

  empleoActualizar: EmpleosModel;

  habilidades: HabilidaesModel[] = [];
  estados: string[];
  municipios: string[];
  HABILIDADES: HabilidaesModel[] = [];
  testsRapidos: Test[] = [];
  mostrarSelectTestRapido: boolean = false;
  correosNotificacion: string[] = [];

  constructor(private empleosService: EmpleosService, private formBuilder: FormBuilder,
              private habilidadesService: HabilidadesService,
              private adminService: AdminService,
              private authService:AuthService,
              private router: Router) {

    this.agregar = true;

    habilidadesService.getResultados().subscribe(res => this.HABILIDADES = res)

    empleosService.getEmpleos().subscribe( empleos => {
      empleos.forEach(empleo => {
        if (empleo.activo){
          this.empleosActivos.push(empleo);
          this.empleos = this.empleosActivos;
        }else 
          this.empleosInactivos.push(empleo);
      });
    });

    empleosService.getTestRapidos().subscribe(res => this.testsRapidos = res)

    empleosService.getCorreosNotificacion().subscribe(res => this.correosNotificacion = res)
   }

   //urlBase: string = 'http://localhost:8090';
   urlBase: string = environment.urlApi;



  ngOnInit(): void {

    /*fetch('https://api.copomex.com/query/get_estados?token=72fae93a-a828-4c6b-b58f-db8930e4fad7')
    .then(response => response.json())
    .then(data =>{
      if ( !data.error ){
       this.estados = data.response.estado;
    }});
    fetch('https://api.copomex.com/query/get_municipio_por_estado/Ciudad de México?token=72fae93a-a828-4c6b-b58f-db8930e4fad7')
    .then(response => response.json())
    .then(data =>{
      if ( !data.error ){
        this.municipios = data.response.municipios;
    }});
    */

    fetch(this.urlBase  + '/usuarios/get_estados',{headers: {
      'Authorization': 'Bearer ' + this.authService.readToken()
    }})
    .then(response => response.json())
    .then(data =>{
      if ( !data.error ){
       this.estados = data;
    }});

    fetch(this.urlBase  + '/usuarios/get_municipio_por_estado/Ciudad de México',{headers: {
      'Authorization': 'Bearer ' + this.authService.readToken()
    }})
    .then(response => response.json())
    .then(data =>{
      if ( !data.error ){
       this.municipios = data;
    }});


    this.iniciarFomularios();
  }

  changeCorreoNotificacion(e: any): void {
    this.formularioAgregarEmpleo.get('correoNotificacion').setValue(e.target.value)
  }

  changeEstadoEP(estado: string): void{
    /*fetch('https://api.copomex.com/query/get_municipio_por_estado/'+estado+'?token=72fae93a-a828-4c6b-b58f-db8930e4fad7')
    .then(response => response.json())
    .then(data =>{
      if ( !data.error ){
       this.municipios = data.response.municipios;
       this.formularioAgregarEmpleo.get('municipio').setValue(this.municipios[0]);
    }});*/
    fetch(this.urlBase  + '/usuarios/get_municipio_por_estado/'+estado,{headers: {
      'Authorization': 'Bearer ' + this.authService.readToken()
    }})
    .then(response => response.json())
    .then(data =>{
      if ( !data.error ){
       this.municipios = data;
       this.formularioAgregarEmpleo.get('municipio').setValue(this.municipios[0]);
    }});
  }

  iniciarFomularios(): void {
    this.formularioAgregarEmpleo = this.formBuilder.group({
      puesto: ['',Validators.required],
      correoNotificacion: ['', [Validators.required, Validators.email]],
      estado: ['Ciudad de México',Validators.required],
      municipio: ['Álvaro Obregón',Validators.required],
      descripcion: ['',Validators.required],
      terman: [false],
      cleaver: [false],
      inglesb2: [false],
      sueldoMinimo: ['', [Validators.required, Validators.min(0), Validators.max(150000)]],
      sueldoMaximo: ['', [Validators.required, Validators.min(0), Validators.max(150000)]],
      edadMinima: ['', [Validators.required, Validators.min(16), Validators.max(70)]],
      edadMaxima: ['', [Validators.required, Validators.min(16), Validators.max(70)]],
      test: [0]
    });
  }

  agregarEmpleo(): void{ 
    if (this.formularioAgregarEmpleo.valid){
      Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();

    const empleo: EmpleosModel = this.formularioAgregarEmpleo.value;
    let findTest = this.testsRapidos.find(test => test.id == this.formularioAgregarEmpleo.get('test').value);
    if (findTest) {
      empleo.test = findTest;
    } else {
      empleo.test = null
    }
    empleo.habilidades = this.habilidades;
    this.habilidades = [];

    if (this.formularioAgregarEmpleo.get('terman').value){
      if (empleo.pruebas === undefined || empleo.pruebas === null)
        empleo.pruebas = [];
      let prueba: PruebaModel = new PruebaModel();
      prueba.id = 1;
      empleo.pruebas.push(prueba)
    }
    if(this.formularioAgregarEmpleo.get('cleaver').value) {
      if (empleo.pruebas === undefined || empleo.pruebas === null) empleo.pruebas = [];
      let prueba: PruebaModel = new PruebaModel();
      prueba.id = 2;
      empleo.pruebas.push(prueba);
    }
    if(this.formularioAgregarEmpleo.get('inglesb2').value) {
      if (empleo.pruebas === undefined || empleo.pruebas === null) empleo.pruebas = [];
      let prueba: PruebaModel = new PruebaModel();
      prueba.id = 1002;
      empleo.pruebas.push(prueba);
    }

    if(!this.formularioAgregarEmpleo.get('inglesb2').value && !this.formularioAgregarEmpleo.get('cleaver').value  && !this.formularioAgregarEmpleo.get('terman').value){
      empleo.pruebas = [];
    }
      
      this.empleosService.publicarEmpleo(empleo).subscribe( empleo =>{
        this.empleos.push(empleo);
        this.formularioAgregarEmpleo.reset();
        Swal.fire({
          icon: 'success',
          text: 'Empleo Agregado correctamente',
          showConfirmButton: false,
          timer: 1500
        })
      }, err => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error, si este persiste contacte a sistemas'
        });
      });
      document.getElementById('cerrarModal').click();
    }else {
      return Object.values(this.formularioAgregarEmpleo.controls).forEach( control => control.markAsTouched() );
    }
  }


  borrarEmpleo(index: number): void{
    Swal.fire({
      title: '¿Seguro que quiere archivar la vacante?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
    this.adminService.cambiarEstatusVacante(this.empleos[index].id, 0).subscribe(() => {
      this.empleosInactivos.push(this.empleosActivos[index]);
      this.empleosActivos.splice(index,1);
      Swal.fire({
        icon: 'success',
        text: 'Empleo Activo',
        showConfirmButton: false,
        timer: 1500
      })
    }, err => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error, si este persiste contacte a sistemas'
      });
      console.log(err)
    });
  }});
  }

  cambiarEstatusEmpleo(index: number): void{
    Swal.fire({
      title: '¿Seguro que quiere reactivar la vacante?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
    this.adminService.cambiarEstatusVacante(this.empleos[index].id, 1).subscribe(() => {
      this.empleosActivos.push(this.empleosInactivos[index]);
      this.empleosInactivos.splice(index,1);
      Swal.fire({
        icon: 'success',
        text: 'Empleo Activo',
        showConfirmButton: false,
        timer: 1500
      })
    }, err => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error, si este persiste contacte a sistemas'
      });
      console.log(err)
    });
  }});
  }

  actualizarEmpleo(): void{
    document.getElementById('cerrarModal').click();
    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();

    let id: number = this.empleoActualizar.id;

    this.empleoActualizar = this.formularioAgregarEmpleo.value;
    let findTest = this.testsRapidos.find(test => test.id == this.formularioAgregarEmpleo.get('test').value);
    if (findTest) {
      this.empleoActualizar.test = findTest;
    } else {
      this.empleoActualizar.test = null
    }
    this.empleoActualizar.habilidades = this.habilidades;

    this.empleoActualizar.pruebas = [];
    if (this.formularioAgregarEmpleo.get('terman').value){
      let prueba: PruebaModel = new PruebaModel();
      prueba.id = 1;
      this.empleoActualizar.pruebas.push(prueba)
    }
    
    if(this.formularioAgregarEmpleo.get('cleaver').value) {
      let prueba: PruebaModel = new PruebaModel();
      prueba.id = 2;
      this.empleoActualizar.pruebas.push(prueba);
    }

    if(this.formularioAgregarEmpleo.get('inglesb2').value) {
      let prueba: PruebaModel = new PruebaModel();
      prueba.id = 1002;
      this.empleoActualizar.pruebas.push(prueba);
    }

    this.habilidades = [];
    this.empleoActualizar.id = id;
    this.empleoActualizar.activo = true;
   
    this.empleosService.actulizarEmpleo(this.empleoActualizar).subscribe( res => {

      this.empleoActualizar = res;

      let i = 0;

      this.empleos.forEach(empleo => {
        
        if (empleo.id === this.empleoActualizar.id){
          this.empleos[i] = res;
        }

        i++;

      });

      Swal.fire({
        icon: 'success',
        text: 'Empleo Actualzado',
        showConfirmButton: false,
        timer: 1500
      });

    }, err => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error, si este persiste contacte a sistemas'
      });
    });

  }

  prepararModalParaActualizar(index: number){

    this.agregar = false;

    this.empleoActualizar = this.empleos[index];

    console.log(this.empleoActualizar);

    this.habilidades = this.empleoActualizar.habilidades;

    console.log(this.formularioAgregarEmpleo);


    // Inhabilita los checks
    this.formularioAgregarEmpleo.get('terman').setValue(false);
    this.formularioAgregarEmpleo.get('cleaver').setValue(false);
    this.formularioAgregarEmpleo.get('inglesb2').setValue(false);


    if (this.empleoActualizar.pruebas.length > 0){
      this.empleoActualizar.pruebas.forEach(prueba => {
        prueba.id == 1 ? this.formularioAgregarEmpleo.get('terman').setValue(true) : '';
        prueba.id == 2 ? this.formularioAgregarEmpleo.get('cleaver').setValue(true) : '';
        prueba.id == 1002 ? this.formularioAgregarEmpleo.get('inglesb2').setValue(true) : '';

      });
    }
    if(this.empleos[index].test) {
      this.mostrarSelectTestRapido = true;
      this.formularioAgregarEmpleo.get('test').setValue(this.empleos[index].test.id);
    }

    this.formularioAgregarEmpleo.get('puesto').setValue(this.empleos[index].puesto);
    this.formularioAgregarEmpleo.get('correoNotificacion').setValue(this.empleos[index].correoNotificacion);
    this.formularioAgregarEmpleo.get('estado').setValue(this.empleos[index].estado);
    this.changeEstadoEP(this.empleos[index].estado);
    this.formularioAgregarEmpleo.get('municipio').setValue(this.empleos[index].municipio);
    this.formularioAgregarEmpleo.get('sueldoMinimo').setValue(this.empleos[index].sueldoMinimo);
    this.formularioAgregarEmpleo.get('sueldoMaximo').setValue(this.empleos[index].sueldoMaximo);
    this.formularioAgregarEmpleo.get('descripcion').setValue(this.empleos[index].descripcion);
    this.formularioAgregarEmpleo.get('edadMinima').setValue(this.empleos[index].edadMinima);
    this.formularioAgregarEmpleo.get('edadMaxima').setValue(this.empleos[index].edadMaxima);

  }

  agregarHabilidad(habilidad: string){
    let index: number = null;
    let aux: number = 0;
    this.HABILIDADES.forEach(element => {
      if (element.habilidad.toLowerCase() === habilidad.toLowerCase()){
        index = aux;
      }
      aux++;
    });

    let hab: HabilidaesModel = new HabilidaesModel();
    if (index === null){
      hab.habilidad = habilidad;
      hab.idioma = false;
    }else {
      hab = this.HABILIDADES[index];
    }
    //@ts-ignore
    //document.getElementById('habilidadInput').value = '';
    let bandera:boolean = true;
    this.habilidades.forEach(element => {
      if (element.habilidad.toLowerCase() === habilidad.toLowerCase()){
        bandera = false;
      }
    });
    if (bandera)
      this.habilidades.push(hab);
  }

  eliminarHabilidad(index: number){
    this.habilidades.splice(index,1);
  }

  agregarBtn(): void{
    this.agregar = true;
    this.formularioAgregarEmpleo.reset();
    this.habilidades =[];
  }

  checkNegativeNums(Id:string, value: string): void{
    if (Number(value) < 0){
      //@ts-ignore
      document.getElementById(Id).value = 0;
    } else if ( value === null || value === undefined ){
      //@ts-ignore
      document.getElementById(Id).value = 0;
    }
  }

  checkCampo(nombre: string): boolean{
    return this.formularioAgregarEmpleo.get(nombre).invalid && this.formularioAgregarEmpleo.get(nombre).touched; 
  }

  crearHabilidad(): void{
    var habilitadText = window.getSelection().toString().trim().toUpperCase();

    if (habilitadText.length >  1){
      //this.agregarHabilidad(window.getSelection().toString());
      this.agregarHabilidad(habilitadText);
      window.getSelection().empty();
    }else{
      if(habilitadText.length === 0){
        Swal.fire({
          icon: 'error',
          title: 'Longitud no valida',
          text: 'No se puede agregar como habilidad los espacios en blanco'
        });
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Longitud no valida',
          text: 'No se puede agregar como habilidad una palabra tan corta'
        });
      }
      window.getSelection().empty();
    }

  }

  verEmpleoArchivado(index: number){
    this.router.navigate(['/dashboard',this.empleos[index].puesto,this.empleos[index].id]);
  }

  checkboxTestRapido(): void {
    this.mostrarSelectTestRapido = !this.mostrarSelectTestRapido;
    if (this.mostrarSelectTestRapido == false) {
      this.formularioAgregarEmpleo.get('test').setValue(null);
    } else {
      let primerTest = this.testsRapidos[0];
      this.formularioAgregarEmpleo.get('test').setValue(primerTest.id);
    }
    
  }

  changeTestRapido(e: any): void {
    let findTest = this.testsRapidos[e.target.value];
    this.formularioAgregarEmpleo.get('test').setValue(findTest.id);
  }

}

import { HabilidaesModel } from "./habilidades.model";
import { PruebaModel } from "./test.model";

export class EmpleosModel{

    id: number;
    puesto: string;
    estado: string;
    municipio: string;
    descripcion:string;
    sueldoMinimo: number;
    sueldoMaximo: number;
    edadMinima: number;
    edadMaxima: number;
    fechaPublicacion: Date;
    habilidades: HabilidaesModel[];
    pruebas: PruebaModel[];
    activo: boolean;
    test?: Test;
    correoNotificacion?: string;

}

export class PostulcionModel{

    idUsuario: number;
    idEmpleo: number;
    sueldoPretendido: number;
    revisado: boolean = false;
    activo: boolean = true;

}

export class Test{
    id: number;
    nombre: string;
    preguntas: Pregunta[];
}

export class Pregunta{
    id: number;
    idClasificacion: number;
    idTipo: number;
    pregunta: string;
    opciones: Respuesta[];
    respuestas?: any;
    archivos: any[];
    maxCheckboxSelect?: number;
}

export class Respuesta{
    id: number;
    respuesta: string;
    esCorrecta: boolean;
    checked?: boolean;
    archivos: any[];
}

<app-navbar></app-navbar>
<!--===============================================================================================================-->
<!-- Modal Agregar Experiencia Laboral ============================================================================-->
<!--===============================================================================================================--> 
<div class="modal fade" id="agregarExperiencia" tabindex="-1" aria-labelledby="agregarExperiencia" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Agregar Experiencia Laboral</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="limpiarFormularioExperienciaLaboral()"></button>
        </div>
        <form class="modal-body" id="experienciaForm"
              [formGroup]="formularioAgregarxperiencia">
                <div class="mb-2">
                    <label for="agregarPuesto" class="form-label">Puesto</label>
                    <input type="text" id="puesto" class="form-control form-control-sm" formControlName="puesto" autocomplete="off" list="puestosList">
                    <datalist id="puestosList" >d
                        <option *ngFor="let puesto of puestos; let i = index" value="{{puesto | uppercase}}">
                    </datalist>
                </div>
                <div class="mb-2">
                    <label for="agregarEmpresa" class="form-label">Empresa</label>
                    <input type="text" class="form-control form-control-sm" id="agregarEmpresa"
                        formControlName="empresa" [class.is-invalid]="campoNoValido(formularioAgregarxperiencia,'empresa')">
                        <small *ngIf="campoNoValido(formularioAgregarxperiencia,'empresa')"class="text-danger">Ingresa empresa válida</small>
                </div>
                <div class="mb-2">
                    <label for="agregarCiudad" class="form-label">Estado</label>
                    <select class="form-select" (change)="changeEstadoEP(selectorEstado.value)" formControlName="estado" #selectorEstado>
                        <option *ngFor="let estado of estados; let i = index">{{estado}}</option>
                    </select>
                </div>
                <div class="mb-2">
                    <label class="form-label">Municipio</label>
                    <select class="form-select" formControlName="municipio">
                        <option *ngFor="let municipio of municipios; let i = index">{{municipio}}</option>
                    </select>
                </div>
                <label class="form-label">Desde</label>
                <div class="input-group mb-2">
                    <select class="form-select" (change)="clickSelectorMesDesde(selectorMesDesde.value)" #selectorMesDesde>
                        <option *ngFor="let mes of meses; let i = index" [value]=i> {{mes}}</option>
                    </select>
                    <select class="form-select"(change)="clickSelectorAnioDesde(selectorAnioDesde.value)" #selectorAnioDesde>
                        <option *ngFor="let anio of anios">{{anio}}</option>
                    </select>
                </div>
                <label class="form-label">Hasta</label>
                <div class="input-group mb-2">
                    <select class="form-select" (change)="clickSelectorMesHasta(selectorMesHasta.value)" #selectorMesHasta>
                        <option *ngFor="let mes of meses; let i = index" [value]=i> {{mes}}</option>
                    </select>
                    <select class="form-select"(change)="clickSelectorAnioHasta(selectorAnioHasta.value)" #selectorAnioHasta>
                        <option *ngFor="let anio of anios">{{anio}}</option>
                    </select>
                </div>
                <div class="form-check mb-2">
                    <input class="form-check-input" type="checkbox" value="" id="trabajoAqui"
                        formControlName="actual">
                    <label class="form-check-label" for="trabajoAqui">
                      Trabajo Aqui Actualmente
                    </label>
                </div>
                <div class="mb-2">
                    <label class="form-label">Sueldos Mensual(En peso mexicano)</label>
                    <div class="input-group">
                        <input type="number" class="form-control form-control-sm" min="0" max="150000" placeholder="Inicial" formControlName="sueldoInicial"  [class.is-invalid]="campoNoValido(formularioAgregarxperiencia,'sueldoInicial')">
                        <input type="number" class="form-control form-control-sm" min="0" max="150000" placeholder="Final" formControlName="sueldoFinal" [class.is-invalid]="campoNoValido(formularioAgregarxperiencia,'sueldoFinal')">
                    </div>
                </div>
                <div class="mb-2">
                    <label for="agregarDescripcion" class="form-label">Breve descripción</label>
                    <textarea type="text" class="form-control form-control-sm" id="agregarDescripcion"
                        formControlName="descripcion"[class.is-invalid]="campoNoValido(formularioAgregarxperiencia,'descripcion')">
                        </textarea>  
                        <small *ngIf="campoNoValido(formularioAgregarxperiencia,'descripcion')"class="text-danger">Ingresa al menos 20 caractéres</small>
                </div>
                <div class="mb-2">
                    <label class="form-label">¿Cuál fue el mayor reto al que te enfrentaste?</label>
                    <textarea type="text" class="form-control form-control-sm" 
                        formControlName="descripcionProblema" [class.is-invalid]="campoNoValido(formularioAgregarxperiencia,'descripcionProblema')">
                        </textarea>  
                        <small *ngIf="campoNoValido(formularioAgregarxperiencia,'descripcionProblema')"class="text-danger">Ingresa al menos 20 caractéres</small>
                </div>
                <div class="mb-2">
                    <label class="form-label">¿Cómo lo resolviste?</label>
                    <textarea type="text" class="form-control form-control-sm" 
                        formControlName="solucionProblema" [class.is-invalid]="campoNoValido(formularioAgregarxperiencia,'solucionProblema')">
                        </textarea>  
                        <small *ngIf="campoNoValido(formularioAgregarxperiencia,'solucionProblema')"class="text-danger">Ingresa al menos 20 caractéres</small>
                </div>
            <button type="submit" (click)="agregarExperiencia()" class="btn btn-primary float-end ms-2">Aceptar</button>
            <button type="button" id="cerrarExperiencia" class="btn btn-secondary float-end" data-bs-dismiss="modal" (click)="limpiarFormularioExperienciaLaboral()">Close</button>
        </form>
      </div>
    </div>
</div>

<!--===============================================================================================================-->
<!-- Modal Agregar Familiares =====================================================================================-->
<!--===============================================================================================================--> 
<!-- <div class="modal fade" id="agregarFamiliar" tabindex="-1" aria-labelledby="agregarFamiliar" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Agregar Familiar</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form class="modal-body"
              [formGroup]="formularioAgregarFamiliar">
                <div class="mb-2">
                    <label for="agregarNombre" class="form-label">Nombre</label>
                    <input type="text" class="form-control form-control-sm" id="agregarNombre"
                        formControlName="nombre">
                </div>
                <div class="mb-2">
                    <label for="agregarOcupacion" class="form-label">Ocupacion</label>
                    <input type="text" class="form-control form-control-sm" id="agregarOcupacion"
                        formControlName="ocupacion">
                </div>
                <div class="mb-2">
                    <label for="agregarParentesco" class="form-label">Parentesco</label>
                    <input type="text" class="form-control form-control-sm" id="agregarParentesco"
                        formControlName="parentesco">
                </div>
                <div class="form-check mb-2">
                    <input class="form-check-input" type="checkbox" value="" id="vive"
                        formControlName="vive">
                    <label class="form-check-label" for="vive">
                      Vive
                    </label>
                </div>
            <button type="submit" (click)="agregarFamiliar()" class="btn btn-primary float-end ms-2" data-bs-dismiss="modal">Aceptar</button>
            <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal">Close</button>
        </form>
      </div>
    </div>
</div> -->

<!--===============================================================================================================-->
<!-- Modal Agregar Estudio ========================================================================================-->
<!--===============================================================================================================--> 
<div class="modal fade" id="agregarEstudio" tabindex="-1" aria-labelledby="agregarEstudio" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Agregar Estudio</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="limpiarFormularioEstudios()"></button>
        </div>
        <form class="modal-body"
              [formGroup]="formularioEstudio">
                <div class="mb-3">
                    <label for="nivel" class="form-label">Nivel de estudio</label>
                    <select class="form-select" (change)="changeNivelEscolar(selectorNivelEscolar.value)" formControlName="nivel" #selectorNivelEscolar>
                        <option *ngFor="let nivel of nivelesEstudio; let i = index">{{nivel}}</option>
                    </select>
                    <label for="escuela" class="form-label mt-2">Escuela</label>
                    <input type="text" class="form-control form-control-sm" id="escuela"
                        formControlName="escuela" [class.is-invalid]="campoNoValido(formularioEstudio,'escuela')">
                    <small *ngIf="campoNoValido(formularioEstudio,'escuela')" class="text-danger">Ingresa una escuela válida</small>
                    <br *ngIf="campoNoValido(formularioEstudio,'escuela')">
                    <label for="titulo" class="form-label mt-2">Area de estudio</label>
                    <input type="text" id="titulo" class="form-control form-control-sm" formControlName="titulo" autocomplete="off" list="carrerasList">
                    <datalist id="carrerasList" >d
                        <option *ngFor="let carrera of carreras; let i = index" value="{{carrera}}">
                    </datalist>
                    <small *ngIf="campoNoValido(formularioEstudio,'titulo')" class="text-danger">Ingresa un título válido</small>
                </div>
            <button type="submit" (click)="agregarEstudio()" class="btn btn-primary float-end ms-2">Aceptar</button>
            <button type="button" id="cerrarAgregarEstudio" class="btn btn-secondary float-end" data-bs-dismiss="modal" (click)="limpiarFormularioEstudios()">Close</button>
        </form>
      </div>
    </div>
</div>

<form class="container mt-2 mb-0" *ngIf="usuario.passTemporal" [formGroup]="formularioPassword" (ngSubmit)="actualiizarPassword()">
    <div class="row">
        <!--===============================================================================================================-->
        <!-- Solicitud de contraseña cuando acceden por una red social ====================================================-->
        <!--===============================================================================================================-->  
        <div class="col">
            <div class="alert alert-danger shadow" role="alert">
                Por favor proporciona una contraseña para tu cuenta, esta no estará relaconada a ninguna red social y será utilizada 
                solo si se accede a través de nuestro logín.
                <br class="mt-2">
                <input type="password" id="pass" class="form-control form-control-sm mt-2" placeholder="Contraseña" autocomplete="on"
                    formControlName="password">
                <input type="password" id="passConfirm" class="form-control form-control-sm mt-2" placeholder="Confirmar Contraseña" autocomplete="on"
                    formControlName="confirm">
                <div class="d-flex">
                    <div class="d-grid gap-2 ms-auto col-3">
                        <button class="btn btn-danger btn-sm mt-2">Aceptar</button>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</form>
<!--===============================================================================================================-->
<!-- Formulario Adaptable a web y móvil ===========================================================================-->
<!--===============================================================================================================-->      
<form class="container-lg mt-2 mb-4"
      [formGroup]="formularioUsuario"
      (ngSubmit)="actualizarInformacionUsuario()">
    <div class="row mb-2">
        <div class="col d-flex justify-content-center">
            <app-flujo class="my-2" [icons]="['success','here', 'none']" [titulos]="['Crear cuenta', 'Completar perfil', 'Postularse']"></app-flujo>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-4">
            <!--===============================================================================================================-->
            <!-- Tarjeta de datos básicos del usuario =========================================================================-->
            <!--===============================================================================================================-->                  
            <div *ngIf="usuario.cvCompleto; else elseTemplate" class="card shadow mt-2">
                <div class="card-body d-flex flex-column align-items-center">
                    <button type="button" (click)="editarDatosBasicos()" class="btn btn-outline-secondary btn-sm align-self-end"><i class="fas fa-pen"></i></button>
                    <div class="d-flex justify-content-center align-items-center mb-3 icon-foto">
                        <i class="fas fa-user-alt fa-6x icon-light-color"></i>
                    </div>
                    <h5 class="card-title">{{usuario.nombre}}</h5>
                    <p class="card-text mb-1">{{usuario.sexo + ' - '+getEdad(usuario.fechaNacimiento) }}</p>
                    <p class="card-text">{{usuario.direccion.municipio+', '+usuario.direccion.estado}}</p>
                </div>
            </div>
            <!-- ================================= -->
            <!-- Tarjeta de datos básicos editable -->
            <!-- ================================= -->
            <ng-template #elseTemplate>
                <div class="card shadow " [ngClass]="{'border border-danger border-2': datosBascosValidacion()}">
                    <div class="card-body mt-2">
                        <span class="badge rounded-pill bg-danger float-end" *ngIf="datosBascosValidacion()">información incompleta</span> <br>
                        <label for="nombre" class="form-label">Nombre</label>
                        <input type="text" id="nombre" class="form-control form-control-sm"
                        formControlName="nombre">
                        <label for="apellidoPaterno" class="form-label">Apellido Paterno</label>
                        <input type="text" id="apellidoPaterno" class="form-control form-control-sm"
                        formControlName="apellidoPaterno">
                        <label for="apellidoMaterno" class="form-label">Apelido Materno</label>
                        <input type="text" id="apellidoMaterno" class="form-control form-control-sm"
                        formControlName="apellidoMaterno">
                        <label class="form-label mt-2">Fecha de nacimiento</label>
                        <div class="input-group">
                            <select class="form-select" id="dia" (change)="clickSelectorDia(selectorDia.value)" #selectorDia >
                                <option *ngFor="let dia of dias; let i= index" [id]="'dia'+(i+1)">{{dia}}</option>
                            </select>
                            <select class="form-select" id="mes" (change)="clickSelectorMes(selectorMes.value)" #selectorMes>
                                <option *ngFor="let mes of meses; let i = index" [value]=i [id]="'mes'+i"> {{mes}}</option>
                            </select>
                            <select class="form-select" id="anio" (change)="clickSelectorAnio(selectorAnio.value)" #selectorAnio>
                                <option *ngFor="let anio of anios; let i = index" [id]="'anio'+anio">{{anio}}</option>
                            </select>
                        </div>
                        <label class="form-label mt-2 w-100">Género</label>
                        <div class="btn-group d-flex" role="group" aria-label="Basic radio toggle button group">
                            <input type="radio" class="btn-check" name="btnradio" id="btnradio1" 
                            autocomplete="off" (click)="clickGenero('H')" [checked]="usuario.sexo==='H'">
                            <label class="btn btn-outline-secondary btn-sm" for="btnradio1">Hombre</label>
                          
                            <input type="radio" class="btn-check" name="btnradio" id="btnradio2" 
                            autocomplete="off" (click)="clickGenero('M')" [checked]="usuario.sexo==='M'">
                            <label class="btn btn-outline-secondary btn-sm" for="btnradio2">Mujer</label>
                          
                            <input type="radio" class="btn-check" name="btnradio" id="btnradio3" 
                            autocomplete="off" (click)="clickGenero('O')" [checked]="usuario.sexo==='O'">
                            <label class="btn btn-outline-secondary btn-sm" for="btnradio3">Otro</label>
                        </div>
                        <div formGroupName="direccion" class="mt-3">
                            <div class="row">
                                <div class="col">
                                    <label for="cp" class="form-label">Codigo Postal</label>
                                    <input type="number" id="cp" class="form-control form-control-sm"
                                        formControlName="codigoPostal" autocomplete="off" list="cpList" (keyup)="chngeCP(cp.value)" #cp>
                                        <datalist id="cpList" >
                                            <option *ngFor="let cp of codigosPostales" value="{{cp}}">
                                        </datalist>
                                </div>
                                
                                <div class="col">
                                    <label for="estado" class="form-label">Estado</label>
                                    <input type="text" id="estado" readonly class="form-control form-control-sm"
                                        formControlName="estado">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <label for="calle" class="form-label">Calle</label>
                                    <input type="text" id="calle" class="form-control form-control-sm"
                                        formControlName="calle">
                                </div>
                                <div class="col">
                                    <label for="noExterior" class="form-label">No Exterior</label>
                                    <input type="text" id="noExterior" class="form-control form-control-sm"
                                        formControlName="noExterior">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <label for="noInterior" class="form-label">No Interior</label>
                                    <input type="text" id="noInterior" class="form-control form-control-sm"
                                        formControlName="noInterior">
                                </div>
                                <div class="col">
                                    <label for="colonia" class="form-label">Colonia</label>
                                    <select class="form-select" id="colonia" (change)="clickSelectorColonia(selectorColonia.value)" #selectorColonia formControlName="colonia">
                                        <option *ngFor="let colonia of colonias; let i= index" [id]="'colonia'+(i+1)">{{colonia}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <label for="delegacion" class="form-label">Delegación</label>
                                    <input type="text" id="delegacion" class="form-control form-control-sm"
                                        formControlName="delegacion">
                                </div>
                                <div class="col">
                                    <label for="municipio" class="form-label">Municipio</label>
                                    <input type="text" id="municipio" readonly class="form-control form-control-sm"
                                        formControlName="municipio">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <!--===============================================================================================================-->
            <!-- Tarjeta de Información de contacto ===========================================================================-->
            <!--===============================================================================================================-->     
            <div class="card shadow mt-2">
                <div class="d-flex card-header justify-content-between align-items-center">
                    <h5 class="m-0">Información de contacto</h5>
                    <!-- <button class="btn btn-outline-secondary btn-sm align-self-end m-0"><i class="fas fa-pen"></i></button> -->
                </div>
                <div class="card-body">
                    <h5 class="card-title">Correo electrónico</h5>
                    <input type="text" class="form-control fo-control-sm" [value]="usuario.correos[0].correo" (change)="changeCorreo(correo.value)" #correo>
                    
                    <h5 class="card-title">Telefono</h5>
                    <input type="number" class="form-control fo-control-sm" min="1000000000" max="9999999999" [value]="usuario.telefonos[0].numero" (change)="changeTelefono(tel.value)" #tel>
                </div>
            </div>
            <!--===============================================================================================================-->
            <!-- Tarjeta de Datos personales ==================================================================================-->
            <!--===============================================================================================================--> 
            <div class="card shadow mt-2" [ngClass]="{'border border-2 border-danger': datosPersonalesValidacion()}">
                    <div class="d-flex align-items-center card-header">
                        <h5>Datos Personales</h5>
                        <span class="badge rounded-pill bg-danger float-end ms-auto" *ngIf="datosPersonalesValidacion() && (width >= 1000 || width < 768)">información incompleta</span>
                        <span class="badge rounded-pill bg-danger float-end ms-auto" *ngIf="datosPersonalesValidacion() && (width < 1000 && width > 767)">incompleto</span>
                    </div>
                    
                <div class="card-body">
                    <label for="estadoCivil" class="form-label">Estado Civil</label>
                    <select class="form-select" id="estadoCivil" (change)="clickSelectorEstadoCivil(selectorEstadoCivil.value)" #selectorEstadoCivil>
                        <option *ngFor="let ec of estadosCivilesArray; let i = index" [value] = i+1 
                            [id]="'estadoCivil'+ (i+1)">{{ec}}</option>
                    </select>
                    <label for="viveCon" class="form-label">Vive con</label>
                    <select class="form-select form-select-sm" 
                            aria-label=".form-select-sm example" 
                            formControlName="viveCon"
                            (change)=clickSelectorViveCon(selectorViveCon.value)
                            #selectorViveCon>
                        <option *ngFor="let item of viveConArray">{{item}}</option>
                    </select>
                    <p class="card-text mt-2 mb-1">Personas que dependen de usted</p>
                    <div class="row">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="checkConyuge"
                                        formControlName="dependeConyuge">
                                <label class="form-check-label" for="checkConyuge">
                                  Conyuge
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="checkHijos"
                                formControlName="dependenHijos">
                                <label class="form-check-label" for="checkHijos">
                                  Hijos
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="checkPadres"
                                    formControlName="dependenPadres">
                                <label class="form-check-label" for="checkPadres" >
                                  Padres
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="checkOtros"
                                    formControlName="dependenOtros">
                                <label class="form-check-label" for="checkOtros">
                                  Otros
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-8">
            <!--===============================================================================================================-->
            <!-- Tarjeta de Escolaridad =======================================================================================-->
            <!--===============================================================================================================--> 
            <div class="card shadow" [ngClass]="{'border border-2 border-danger': usuario.estudios.length === 0}">
                <div class="d-flex align-items-center card-header">
                    <h5>Escolaridad</h5>
                    <span class="badge rounded-pill bg-danger float-end ms-auto" *ngIf="usuario.estudios.length === 0">información incompleta</span>
                </div>
                <div class="card-body">
                    <ng-container *ngFor="let estudio of usuario.estudios; let i=index">
                      <div class="row mb-2">
                          <div class="col-10">
                                <h5 class="card-title">{{estudio.escuela + ' (' + estudio.nivel + ')'}}</h5>
                                <p class="card-text">{{'Título obtenido: '+estudio.titulo}}</p>
                          </div>
                          <div class="col-2 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-primary btn-sm me-2"
                            data-bs-toggle="modal" data-bs-target="#agregarEstudio" (click)="actualizarEstudio(i)"><i class="fas fa-pen"></i></button>
                            <button type="button" class="btn btn-danger btn-sm"
                                    (click)="borrarEstudio(i)"><i class="fas fa-trash"></i></button>
                        </div>
                      </div>
                      
                    </ng-container>
                    <hr>
                    <button  class="btn btn-primary btn-sm mt-0" data-bs-toggle="modal" data-bs-target="#agregarEstudio" (click)="updateEstudio=false" type="button"><i class="fas fa-plus me-2"></i>Agregar Estudio</button>
                </div>
            </div>
            <!--===============================================================================================================-->
            <!-- Tarjeta de Experiencia Profesional ===========================================================================-->
            <!--===============================================================================================================--> 
            <div class="card shadow mt-2">
                <div class="card-header">
                    <h5> Experiencia profesional </h5>
                    <p class="card-text">Agrega tus experiencias laborales más recientes (máximo 3)</p>
                </div>
                <div class="card-body">
                    
                    <div class="mt-2" *ngFor="let experiencia of usuario.experienciasLaborales; let i = index">
                        <div class="row" >
                            <div class="col-10">
                                <h5 class="card-title">{{experiencia.puesto}}</h5>
                            </div>
                            <div class="col-2 d-flex justify-content-end align-items-center">
                                <button type="button" class="btn btn-primary btn-sm me-2"
                                data-bs-toggle="modal" data-bs-target="#agregarExperiencia" (click)="actualizarExperiencia(i)"><i class="fas fa-pen"></i></button>
                                <button type="button" class="btn btn-danger btn-sm"
                                        (click)="borrarExperiencia(i)"><i class="fas fa-trash"></i></button>
                            </div>
                        </div>
                        <p class="card-text mb-1">{{experiencia.empresa}}</p>
                        <p *ngIf="!experiencia.actual" class="card-text mb-2">{{experiencia.desde | date: 'LLL YYYY'}} - {{experiencia.hasta | date: 'LLL YYYY'}}</p>
                        <p *ngIf="experiencia.actual" class="card-text mb-2">{{experiencia.desde | date: 'LLL YYYY'}} - Actualidad </p>
                        <p class="card-text">{{experiencia.descripcion}}</p>
                        <hr>
                    </div>
                    <button id="abrirExperienciaLabral" type="button" data-bs-toggle="modal" data-bs-target="#agregarExperiencia" class="d-none"></button>
                    <button type="button" class="btn btn-primary btn-sm mt-0"  (click)="abrirModalExperienciaLaboral()"><i class="fas fa-plus me-2"></i>Agregar Trabajo</button>
                </div>
            </div>
            <!--===============================================================================================================-->
            <!-- Tarjeta de Datos Familares ===================================================================================-->
            <!--===============================================================================================================--> 
            <!-- <div class="card shadow mt-2" [ngClass]="{'border border-2 border-danger': datosFamiliaresValidacion()}">
                <div class="d-flex align-items-center card-header">
                    <h5>Datos familiares</h5>
                    <span class="badge rounded-pill bg-danger float-end ms-auto" *ngIf="datosFamiliaresValidacion()">información incompleta</span>
                </div>
                <div class="card-body">
                    <div class="row mt-2" *ngFor="let familiar of usuario.familiares; let i = index">
                        <div class="col-12 col-md-4 mt-2">
                            <input type="text"  
                                    class="form-control form-control-sm" 
                                    placeholder="Nombre de {{familiar.parentesco}}"
                                    [value]="familiar.nombre"
                                    (keyup)="keyUpFamiliar(true,nombre.value, i)"
                                    #nombre >
                        </div>
                        <div class="col-12 col-md-4 mt-2">
                            <input type="text" 
                                    class="form-control form-control-sm" 
                                    placeholder="Ocupación de {{familiar.parentesco}}"
                                    [value]="familiar.ocupacion"
                                    (keyup)="keyUpFamiliar(false,ocupacion.value, i)"
                                    #ocupacion>
                        </div>
                        <div class="col-10 col-md-2 mt-2">
                            <input type="text" 
                                    class="form-control form-control-sm" 
                                    placeholder="Parentesco"
                                    [value]="familiar.parentesco"
                                    readonly >
                        </div>
                        <div class="form-check col-2 col-md-2 mt-2  mb-md-0">
                            <input class="form-check-input" type="checkbox" [checked]="familiar.vive" (click)="clickFamiliarVive(i)">
                            <label class="form-check-label" for="flexCheckDefault">
                              Vive
                            </label>
                          </div>
                        <div class="d-block d-md-none"><hr class="border border-dark bg-dark"></div>
                    </div>
                </div>
            </div> -->
            
            <!--===============================================================================================================-->
            <!-- Tarjeta de Otros =============================================================================================-->
            <!--===============================================================================================================--> 
            <div class="card shadow mt-2">
                    <h5 class="card-header"> Otros </h5>
                <div class="card-body">
                    <label class="form-label">Escribe una breve descripción personal de tí</label>
                    <textarea type="text" class="form-control form-control-sm" (keydown)="keyPressDescripcion($event)"
                        formControlName="descripcion" [class.is-invalid]="campoNoValido(formularioUsuario,'descripcion')" #descripcion>
                    </textarea>  
                    <small *ngIf="campoNoValido(formularioUsuario,'descripcion')" class="text-danger">Ingresa al menos 50 caractéres</small>
                    <label class="form-label mt-2">Brevemente dinos ¿porque eres el candidato ideal para el puesto?</label>
                    <textarea type="text" class="form-control form-control-sm" (keydown)="keyPressRazon($event)"
                    formControlName="razonAplicacion" [class.is-invalid]="campoNoValido(formularioUsuario,'razonAplicacion')">
                    </textarea>  
                    <small *ngIf="campoNoValido(formularioUsuario,'razonAplicacion')" class="text-danger">Ingresa al menos 50 caractéres</small>
                </div>
            </div>

            <div class="card shadow mt-2" [ngClass]="{'border border-2 border-danger': !checkIdiomasCompletos()}">
                <div class="d-flex align-items-center card-header">
                    <h5>Idiomas</h5>
                    <span class="badge rounded-pill bg-danger float-end ms-auto" *ngIf="!checkIdiomasCompletos()">información incompleta</span>
                </div>
                <div class="card-body">
                    <ng-container *ngFor="let idioma of usuario.habilidades; let i=index">
                       
                        <div class="mb-2 input-group" *ngIf="idioma.habilidad.idioma">
                            <!-- <input type="text" class="form-control form-control-sm" placeholder="Idioma" [value]="idioma.habilidad.habilidad" (keyup)="cambiarIdioma(idiomaInput.value, i)" #idiomaInput> -->
                            <select class="form-select form-select-sm" placeholder="Idioma" [value]="idioma.habilidad.habilidad" (change)="cambiarIdioma(idiomaInput.value, i)" #idiomaInput>
                                <option>Inglés</option>
                                <option>Francés</option>
                                <option>Italiano</option>
                                <option>Portugués</option>
                                <option>Chino mandarín</option>
                                <option>Hindi</option>
                                <option>Árabe</option>
                                <option>Bengalí</option>
                                <option>Ruso</option>
                                <option>Indonesio</option>
                                <option>Alemán</option>
                                <option>Japonés</option>
                            </select>
                            <input type="number" class="form-control form-control-sm" [id]="'porcentaje'+i" (keyup)="checkNegativeNums(porcentaje.id, porcentaje.value)" min="0" max="100" placeholder="Porcentaje" [value]="idioma.porcentaje" (keyup)="cambiarPorcentaje(porcentaje.value,i)" #porcentaje>
                            <button type="button" class="btn btn-danger btn-sm ms-1 rounded"
                                        (click)="eliminarHabilidad(i)"><i class="fas fa-trash"></i></button>
                        </div>
                        
                    </ng-container>
                    <hr>
                    <button  class="btn btn-primary btn-sm mt-0" (click)="agregarIdioma()" type="button"><i class="fas fa-plus me-2"></i>Idioma</button>
                </div>
            </div>

 <!--            <div class="card shadow mt-2">
                <div class="d-flex align-items-center card-header">
                    <h5>Habilidades</h5>
                </div>
                <div class="card-body row">
                    <div class="input-group mb-2">
                        <select class="form-select" #selectorHabilidad>
                            <ng-container *ngFor="let habilidad of habilidades; let i=index">
                                <option *ngIf="!habilidad.idioma" [value]="i">{{habilidad.habilidad}}</option>
                            </ng-container>
                      </select>
                      <button class="btn btn-primary" type="button" (click)="agregarHabilidad(selectorHabilidad.value)" >Agregar</button>
                    </div>
                    
                    <ng-container *ngFor="let habilidad of usuario.habilidades; let i=index">
                        <div class="text-white m-1 p-2 w-auto rounded d-flex align-items-center" *ngIf="!habilidad.habilidad.idioma" style="background-color: rgba(2, 163, 181,.7)">
                            {{habilidad.habilidad.habilidad}}
                            <button type="button" class="btn-close btn-close-white ms-2" (click)="eliminarHabilidad(i)" aria-label="Close"></button>
                        </div>
                    </ng-container>
                </div>
            </div> -->
            <div class="row">
                <div class="ms-auto col-12 col-md-6 col-xl-4">
                    <button class="btn w-100 btn-info bg-tm border-0 text-white float-end mt-2 shadow-sm">
                        <i class="fa fa-save me-1"></i>
                        Guardar información
                    </button>
                </div>
            </div>
        </div>
        
    </div>
</form>
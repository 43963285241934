import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ValidacionesService } from 'src/app/services/validaciones.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recuperacion-password',
  templateUrl: './recuperacion-password.component.html',
  styleUrls: ['./recuperacion-password.component.css']
})
export class RecuperacionPasswordComponent implements OnInit {

  token: string;

  screenWidth = screen.width;

  formulario: FormGroup;

  constructor(private rutaActiva: ActivatedRoute, private formBuiler: FormBuilder,
              private validaciones: ValidacionesService,private usuariosService: UsuarioService,
              private router: Router) { 
      this.token = this.rutaActiva.snapshot.params.token;
      Swal.fire({
        text:'Cargando',
        allowOutsideClick: false
      })
      Swal.showLoading();
      this.formulario = formBuiler.group({
        password: ['',[Validators.required, Validators.minLength(8)]],
        confirm: ['',[Validators.required, Validators.minLength(8)]]
      },{
        validators: this.validaciones.passwordsIguales('password', 'confirm')
      })

      usuariosService.revisarToken(this.token).subscribe(res =>{
        this.token = res;
        Swal.close();
      }, err => {
        Swal.fire({
          icon: 'error',
          allowOutsideClick: false,
          text: 'No tienes acceso a este recurso'
        }).then(() => this.router.navigate([ 'login' ]));
      })
  }

  ngOnInit(): void {
  }

  cambiarPassword(): void{
    if(this.formulario.invalid){
      return Object.values(this.formulario.controls).forEach( control => control.markAsTouched() );
    } else {
      this.usuariosService.cambiarPassword(this.token,this.formulario.get('password').value).subscribe(() => {
        Swal.fire({
          icon: 'success',
          text: 'Contraseña cambiada con exito'
        }).then(() => this.router.navigate(['login']))
      }, err => {
        Swal.fire({
          icon: 'error',
          text: err
        })
      })
    }
  }

  public campoRegistroNoValido(campo: string): boolean{ 
    return this.formulario.get(campo).invalid && this.formulario.get(campo).touched; 
  }

}

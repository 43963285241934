<div class="container-fluid h-100 " *ngIf="instruccionesPrueba">
    <div class="row d-flex justify-content-center h-100">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 d-flex flex-column justify-content-center h-100">
            <div class="bg-white shadow d-flex align-items-center flex-column text-center px-3" [ngClass]="{'h-100':esMovil}">
                <div class="w-50">
                    <img class="img-fluid" src="../../../assets/img/TruemedGroupLogo.jpeg">
                </div>
                <!-- <h5 class="card-title mt-2">Test {{prueba.nombre|uppercase}}</h5> -->
                <h5 class="card-title mt-2">Test Ingles B2</h5>

                <p class="card-text mb-3 fw-bold">Duración Aproximada: {{tiempoTotal/60}} minutos</p>
                <b>Instrucciones</b>
                <p class="card-text">
                    La presene prueba consta de 2 Secciones, cada sección tendrá un tiempo determinado y éste se le hará saber al inicio de cada sección.
                </p>
                <p class="card-text">
                    Asegúrate de estar en un lugar tranquilo y sin distracciónes, una véz comenzada la prueba no se podrá pausar ni cancelar.
                </p>
                <p class="card-text">
                    Esta prueba es de aplicación única por lo que no se podrá repetir
                </p>
                <button type="button" class="btn btn-info text-white bg-tm mb-3" (click)="instruccionesPrueba = false"> Iniciar Prueba</button>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid h-100" *ngIf="!instruccionesPrueba && !testIniciado">
    <div class="row d-flex justify-content-center h-100">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 d-flex flex-column justify-content-center h-100">

            <div class="bg-white shadow d-flex align-items-center flex-column text-center" [ngClass]="{'h-100':esMovil}">
                <div class="w-50">
                    <img class="img-fluid" src="../../../assets/img/TruemedGroupLogo.jpeg">
                </div>
                <h5 class="card-title mt-2">Test {{prueba.nombre|uppercase}}</h5>
                <p class="card-text mb-3 fw-bold">Seccion {{seccionActual}}</p>
                <p class="card-text mb-3 fw-bold">Duración: {{seccion.tiempoSegundos/60}} minutos</p>
                <b>Instrucciones</b>
                <p class="card-text mb-3">{{seccion.instrucciones}}</p>
                <b>Ejemplo</b>
                <p class="card-text mb-3">{{seccion.preguntas[0].pregunta}}</p>
                <div class="d-flex">
                    <div *ngFor="let opcion of seccion.preguntas[0].opciones; let i = index" class="card-text mb-3 me-3">
                        {{'- '+opcion.opcion}}
                    </div>
                </div>
                <p class="card-text mb-3"><b>Respuesta: </b>{{seccion.preguntas[0].respuesta}}</p>
                <button type="button" class="btn btn-info text-white bg-tm mb-3" (click)="startTest()"> Iniciar Sección</button>
            </div>
        </div>
    </div>
</div>


<!-- Esto es lo de la seccion uno  -->
<div class="container-fluid h-100" *ngIf="testIniciado && seccionActual === 1">
    <div class="row d-flex justify-content-center h-100">
        <div class="col-12 col-sm-10 col-lg-6 d-flex flex-column justify-content-center h-100">
            <div class="bg-white shadow d-flex align-items-center flex-column text-center" [ngClass]="{'h-100':esMovil}">
                <div class="w-50">
                    <img class="img-fluid" src="../../../assets/img/TruemedGroupLogo.jpeg">
                </div>

                <p class="card-text mb-3 fw-bold">{{minutosReloj|number:'2.0'}}:{{segundosReloj|number:'2.0'}}</p>

                <h5 class="card-title mt-2">Read the next text : </h5>
                <p class="card-text m-3 text-justify">
                    "Mummy! Mummy!" shouted little Murna racing from the front door through to the kitchen. "There's a parcel. The postman's brought a parcel!" Her mother, Savni, looked at her in surprise. She had no idea who could have sent them a parcel. Maybe it was a
                    mistake. She hurried to the door to find out. Sure enough, the postman was there, holding a parcel about the size of a small brick. "From America, madam," he said. "See! American stamps." It was true. In the top right-hand corner of
                    the brown paper parcel were three strange-looking stamps, showing a man's head. The package was addressed to Savni, in big, clear black letters. "Well, I suppose it must be from Great-Aunt Pasni," said Savni to herself, as the postman
                    went on his way down the street, whistling. "Although it must be twenty years since we heard anything from her. I thought she would have been dead by now." Savni's husband Jornas and her son Arinas were just coming in from the garden,
                    where Murna had run to tell them about the parcel. "Well, open it then!" said Arinas impatiently. "Let's see what's inside!" Setting the parcel down in the middle of the table, Savni carefully began to tear open the paper. Inside,
                    there was a large silver container with a hinged lid, which was taped shut. There was also a letter. "What is it? What is it?" demanded Murna impatiently. "Is it a present?" "I have no idea," said Savni in confusion. "I think it must
                    be from Great-Aunt Pasni. She went to America almost thirty years ago now. But we haven't heard from her in twenty years. Perhaps the letter will tell us." She opened the folded page cautiously, then looked up in dismay. "Well, this
                    is no help!" she said in annoyance. "It's written in English! How does she expect us to read English? We're poor people, we have no education. Maybe Pasni has forgotten her native language, after thirty years in America." "Well, open
                    the pot, anyway," said Jornas. "Let's see what's inside." Cautiously, Savni pulled the tape from the neck of the silver pot, and opened the lid. Four heads touched over the top of the container, as their owners stared down inside.
                    "Strange," said Arinas. "All I see is powder." The pot was about one-third full of a kind of light-grey powder. "What is it?" asked Murna, mystified. "We don't know, darling," said Savni, stroking her daughter's hair. "What do you
                    think?" Murna stared again into the pot. "I think it's coffee," she announced, finally."American coffee." "It's the wrong colour for coffee, darling," said Jornas thoughtfully. "But maybe she's on the right track. It must be some kind
                    of food." Murna, by now, had her nose right down into the pot. Suddenly, she lifted her head and sneezed loudly. "That's it!" said Arinas. "It must be pepper! Let me try some." Dipping a finger into the powder, he licked it. "Yes,"
                    he said, "it's pepper all right. Mild, but quite tasty. It's American pepper." "All right," said Savni, "we'll try it on the stew tonight. We'll have American-style stew!" That evening, the whole family agreed that the American pepper
                    had added a special extra taste to their usual evening stew. They were delighted with it. By the end of the week, there was only a teaspoonful of the grey powder left in the silver container. Then Savni called a halt. "We're saving
                    the last bit for Sunday. Dr. Haret is coming to dinner, and we'll let him have some as a special treat. Then it will be finished." The following Sunday, the whole family put on their best clothes, ready for dinner with Dr. Haret. He
                    was the local doctor, and he had become a friend of the family many years before, when he had saved Arinas's life after an accident. Once every couple of months, Savni invited the doctor for dinner, and they all looked forward to his
                    entertaining stories of his youth at the university in the capital. During dinner, Savni explained to the doctor about the mysterious American pepper, the last of which she had put in the stew they were eating, and the letter they
                    could not read. "Well, give it to me, give it to me!" said the doctor briskly. "I speak English! I can translate it for you." Savni brought the letter, and the family waited, fascinated, as the doctor began to translate. "Dear Savni:
                    you don't know me, but I am the son of your old Great-Aunt Pasni. She never talked much to us about the old country, but in her final illness earlier this year, she told us that after her death, she wanted her ashes to be sent back
                    home to you, so that you could scatter them on the hills of the country where she was born. My mother died two weeks ago, and her funeral and cremation took place last week. I am sending her ashes to you in a silver casket. Please
                    do as she asked, and spread them over the ground near where she was born. Your cousin, George Leary." (MDH 1995 -- from a common urban legend)
                </p>

                <h5 class="card-title mt-2">Instrucciones</h5>
                <p class="card-text mb-3">{{seccion.instrucciones}}</p>

                <h5 class="card-title mt-2">Pregunta {{preguntaActual + 1}}</h5>
                <p class="card-text mb-3">{{seccion.preguntas[preguntaActual].pregunta}}</p>

                <div class="d-flex" [ngClass]="{'flex-column':esMovil}" *ngIf="seccion.preguntas[preguntaActual].tipo == 1">
                    <div class="form-check mb-3" *ngFor="let opcion of seccion.preguntas[preguntaActual].opciones; let i = index">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" [value]="opcion.opcion" (click)="clickOpcion(i)">
                        <label class="form-check-label me-3">{{opcion.opcion}}</label>
                    </div>
                </div>
                <button type="button" class="btn btn-info text-white bg-tm mb-3" (click)="clickSiguientePregunta()" *ngIf="preguntaActual + 1 <  seccion.preguntas.length">Siguiente</button>
                <button type="button" class="btn btn-info text-white bg-tm mb-3" (click)="clickSiguienteSeccion()" *ngIf="preguntaActual + 1 === seccion.preguntas.length">Terminar Sección</button>

            </div>
        </div>
    </div>
</div>


<!-- Esto es lo de la seccion dos  -->
<div class="container-fluid h-100" *ngIf="testIniciado && seccionActual === 2">
    <div class="row d-flex justify-content-center h-100">
        <div class="col-12 col-sm-10 col-lg-6 d-flex flex-column justify-content-center h-100">
            <div class="bg-white shadow d-flex align-items-center flex-column text-center" [ngClass]="{'h-100':esMovil}">
                <div class="w-50">
                    <img class="img-fluid" src="../../../assets/img/TruemedGroupLogo.jpeg">
                </div>

                <p class="card-text mb-3 fw-bold">{{minutosReloj|number:'2.0'}}:{{segundosReloj|number:'2.0'}}</p>

                <h5 class="card-title mt-2">The following text is given:</h5>
                <p class="card-text m-3 text-justify">
                    Reading as a teen leads to success. When teens read more than just their classroom assignments, research clearly shows that they generally do well in school. ____ (1) It also shows them how different writers put down their thoughts leading to better writing
                    skills. And teens who read more serious literary works gain skills in handling complex ideas. The more teens read, ____ (2) This leads to a solid core of knowledge that is useful in a wide variety of classes. For example, the teen
                    who reads biographies has a better understanding of prominent people studied in history classes. Another big advantage of reading as a teen is a good score on the verbal section of a college admission test. No other activity builds
                    the vocabulary and comprehension skills needed to do well on these tests as well as reading ____ (3) reading also helps them expand their horizons as they learn more about people and the world. Plus, reading can show teens that everyone
                    has problems in their life and may even help teens see solutions to their own problems. Parents can encourage their children to stay involved with reading by expressing interest in what they are reading and tying it to other activities.
                    If a teen likes a book ____ (4) they should make sure the teen sees the movie. (Family Education - Benefits of reading as a teen)
                </p>

                <h5 class="card-title mt-2">Instrucciones</h5>
                <p class="card-text mb-3">{{seccion.instrucciones}}</p>

                <h5 class="card-title mt-2">Pregunta {{preguntaActual + 1}}</h5>
                <p class="card-text mb-3">{{seccion.preguntas[preguntaActual].pregunta}}</p>

                <div class="d-flex w-100" [ngClass]="{'flex-column':esMovil}" *ngIf="seccion.preguntas[preguntaActual].tipo == 7">
                    <div class="m-3 w-100">
                        <input type="number" min="1" max="4" class="form-control" id="Respuesta1" placeholder="Answer (1,2,3,4 or leave blank)" #Respuesta1>
                    </div>
                </div>
                <button type="button" class="btn btn-info text-white bg-tm mb-3" (click)="clickSiguientePregunta()" *ngIf="preguntaActual + 1 <  seccion.preguntas.length">Siguiente</button>
                <button type="button" class="btn btn-info text-white bg-tm mb-3" (click)="clickSiguienteSeccion()" *ngIf="preguntaActual + 1 === seccion.preguntas.length">Terminar Test</button>
            </div>
        </div>
    </div>
</div>
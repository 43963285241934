<nav class="d-flex align-items-center p-1 shadow-sm fixed-top" style="z-index: 1000; height: 55px;">
    <div class="d-flex flex-row-reverse w-100 align-items-center">
        <!-- Movil -->
        <div class="d-block d-md-none w-25" *ngIf="!isAuth()"></div>
        <button class="btn p-0 icon d-block d-md-none w-25 icon-white" (click)="showSideBar = !showSideBar" *ngIf="isAuth()" ><i class="fas fa-bars fa-2x"></i></button>
        <div class="mx-auto d-block d-md-none p-2" style="width: 150px">
            <img src="../../../assets/img/TruemedGroupLogoOscuro.png" class="img-fluid" alt="">
        </div>
        <div class="d-block d-md-none w-25"></div>

        <!-- Version Web -->
        <div *ngIf="isAuth()">
            <button (click)="salir()" class="btn btn-outline-secondary btn-sm me-2 d-none d-md-block"><i class="fas fa-power-off me-2"></i>Salir</button>
        </div>
        <a class="nav-link me-auto text-secondary d-none d-md-block" [routerLink]="['/dashboard']" routerLinkActive="text-dark" *ngIf="esAdmin">Dashboard</a>
        <a class="nav-link text-secondary d-none d-md-block" [routerLink]="['/resultados']" routerLinkActive="text-dark" *ngIf="esAdmin">Candidatos</a>
        <a class="nav-link text-secondary d-none d-md-block" [routerLink]="['/AdminEmpleos']" routerLinkActive="text-dark" *ngIf="esAdmin" >Vacantes</a>
        <!-- <a class="nav-link text-secondary d-none d-md-block" [routerLink]="['/cleaverTemporal']" routerLinkActive="text-dark" *ngIf="esAdmin" >Cleaver</a> -->
        <a class="nav-link text-secondary d-none d-md-block px-2 me-auto" [routerLink]="['/postulaciones']" routerLinkActive="text-dark"*ngIf="!esAdmin && isAuth()">Postulaciones</a>
        <a class="nav-link text-secondary d-none d-md-block pe-2" [routerLink]="['/empleos']" routerLinkActive="text-dark" *ngIf="!esAdmin && isAuth()">Empleos</a>
        <a class="nav-link text-secondary d-none d-md-block pe-2" [routerLink]="['/perfil']" routerLinkActive="text-dark" *ngIf="!esAdmin && isAuth()">Perfil</a>
        <div class="ms-3  border-start border-dark d-none d-md-block align-self-center" style="width: 1px; height: 35px;" *ngIf="isAuth()">
        </div>
        <div class="ms-3 d-none d-md-block" style="width: 115px;" [ngClass]="{'me-auto': !isAuth()}">
            <img src="../../../assets/img/TruemedGroupLogo.jpeg" class="img-fluid" alt="">
        </div>
            
    </div>
</nav>
<div style="height: 55px;"></div>

<!-- ================================ SideBar ==================================================================================================== -->
<div id="sidebar" class="h-100 w-100 fixed-top text-white d-block d-md-none" [ngClass]="{ 'showSideBar': showSideBar }" (click)="showSideBar = !showSideBar">
    <ul class="w-75 h-100 d-flex flex-column pb-2 ul" style="background-color: #212121;">
        <li *ngIf="!esAdmin" class="w-100 m-0 p-0 li" [routerLink]="['/perfil']" >
            <a class="w-100 h-100 d-flex align-items-center ps-3" routerLinkActive="text-white"><i class="fas fa-user me-3 p-2" style="font-size: 20px;"></i>Perfil</a>
        </li>
        <li *ngIf="!esAdmin" class="w-100 m-0 p-0 li" [routerLink]="['/empleos']">
            <a class="w-100 h-100 d-flex align-items-center ps-3" routerLinkActive="text-white"><i class="fas fa-briefcase me-3 p-2" style="font-size: 20px;"></i>Ofertas de empleo</a>
        </li>
        <li *ngIf="!esAdmin" class="w-100 m-0 p-0 mb-auto li" [routerLink]="['/postulaciones']">
            <a class="w-100 h-100 d-flex align-items-center ps-3"><i class="fas fa-file-alt me-3 p-2" style="font-size: 20px;"></i>Postulaciones</a>
        </li>
        <li *ngIf="esAdmin" class="w-100 m-0 p-0 li" [routerLink]="['/dashboard']">
            <a class="w-100 h-100 d-flex align-items-center ps-3" routerLinkActive="text-white"><i class="fas fa-tachometer-alt me-3 p-2" style="font-size: 20px;"></i>Dashboard</a>
        </li>
        <li *ngIf="esAdmin" class="w-100 m-0 p-0 li" [routerLink]="['/resultados']" >
            <a class="w-100 h-100 d-flex align-items-center ps-3" routerLinkActive="text-white"><i class="fas fa-poll me-3 p-2" style="font-size: 20px;"></i>Candidatos</a>
        </li>
        <li *ngIf="esAdmin" class="w-100 m-0 p-0 mb-auto li" [routerLink]="['/AdminEmpleos']">
            <a class="w-100 h-100 d-flex align-items-center ps-3" routerLinkActive="text-white"><i class="fas fa-briefcase me-3 p-2" style="font-size: 20px;"></i>Vacantes</a>
        </li>
        <li class="w-100 m-0 p-0 align-self-end li" (click)="salir()">
            <a class="w-100 h-100 d-flex align-items-center ps-3 fs-6"><i class="fas fa-power-off me-3 p-2" style="font-size: 20px;"></i>Salir</a>
        </li>
    </ul>
</div>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { UsuarioModel } from '../models/usuario.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string;

  //private urlService: string = 'https://reclutamiento.truemedgroup.com/security';

  private urlService: string = environment.urlApi + '/security';

  constructor(private http: HttpClient, private router: Router) {
    this.token = this.readToken();
  }

  /* Método que hace la solicitud al API para entrar en la aplicación
     Para el login, se pide un objeto de tupo UsuarioModel que contiene
     su username y password */
  login(usuario: UsuarioModel): Observable<UsuarioModel> {

    const urlEndpoint = this.urlService + '/oauth/token';

    const credenciales = btoa('tmapp' + ':' + 'Ag785.-4$795Tyui');

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + credenciales
    });

    let params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', usuario.correos[0].correo);
    params.set('password', usuario.password);

    return this.http.post<UsuarioModel>(urlEndpoint, params.toString(), { headers: httpHeaders })
      .pipe(
        map(resp => {
          this.saveToken(resp['access_token'], resp['expires_in']);
          localStorage.setItem('username', usuario.correos[0].correo);
          return resp;
        })
      );
  }


  loginFacebook(token: string, correo: string): Observable<any> {

    const urlEndpoint = this.urlService + '/facebook';

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(urlEndpoint, { 'value': token }, { headers: httpHeaders })
      .pipe(
        map(resp => {
          // tslint:disable-next-line: no-string-literal
          this.saveToken(resp['access_token'], resp['expires_in']);
          localStorage.setItem('username', correo);
          return resp;
        })
      );
  }

  getRoles(): string[] {

    return JSON.parse(window.atob(this.readToken().split('.')[1])).authorities;

  }

  /* Método que se encarga de almacenar el token y la fecha de
   expiración del mismo en el localStorage*/
  saveToken(token: string, expiresIn: number): void {

    this.token = token;
    localStorage.setItem('token', token);
    let date = new Date();
    date.setSeconds(expiresIn);
    localStorage.setItem('expiration', date.getTime().toString());

  }

  /* Método que se encarga de validar si ya hay un token existente
  e inicializa la variable local con el token o con una cadena vacia*/
  readToken(): string {

    if (localStorage.getItem('token'))
      this.token = localStorage.getItem('token');
    else
      this.token = '';

    return this.token;

  }


  /* Método que verifica si hay un token existente y en caso que si lo haya,
  verifica que el mismo no haya expirado  */
  isAuth(): boolean {

    if (this.token.length < 2) {
      return false;
    }

    if (new Date(Number(localStorage.getItem('expiration')) - 310) <= new Date()) {
      return false;
    }

    return true;
  }

  /* Método que elimina el token y su fecha de expiración
  del localStorage */
  logout(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('expiration');
    this.readToken();
    this.router.navigate(['/login'])
  }

  verificarTwilio(codigo: any) {
    const urlEndpoint = this.urlService + '/verifySMS';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.readToken()
    });

    let formData: FormData = new FormData();
    formData.append("codigo", codigo.codigo);

    return this.http.post(urlEndpoint, formData, { headers: httpHeaders });
  }

  reenviarTwilio() {
    const urlEndpoint = this.urlService + '/resendSMS';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.readToken()
    });

    return this.http.post(urlEndpoint, null, { headers: httpHeaders });
  }

}

import { UrlResolver } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { DireccionModel } from 'src/app/models/direccion.model';
import { UsuarioPruebaModel } from 'src/app/models/resultados.model';
import { CorreoModel, TelefonoModel, UsuarioModel,EstadoCivilModel } from 'src/app/models/usuario.model';
import { EmpleosService } from 'src/app/services/empleos.service';
import { FilesService } from 'src/app/services/files.service';
import { ResultadosService } from 'src/app/services/resultados.service';


@Component({
  selector: 'app-candidato',
  templateUrl: './candidato.component.html',
  styleUrls: ['./candidato.component.css']
})
export class CandidatoComponent implements OnInit {

  usuario: UsuarioModel = new UsuarioModel();
  resultado: UsuarioPruebaModel;
  verDetallesTerman: boolean = false;
  iq: number = 0;
  numeroHabilidades: number = 0;
  numeroHabilidadesImportantes: number = 0;
  historialDescripcion: string[] = [];
  showHistorialDescripcion = false;
  histoialRazon: string[] = [];
  showHistorialRazon = false;
  public images: string[] = [];

  imgSelect: string;

  constructor(private activeRoute: ActivatedRoute, private empleosService: EmpleosService, private resultadosService: ResultadosService, private filesServce: FilesService) { 
    this.usuario.correos.push(new CorreoModel('',null));
    this.usuario.telefonos.push(new TelefonoModel('',null));
    this.usuario.direccion = new DireccionModel();
    this.usuario.estadoCivil = new EstadoCivilModel();
    /* this.usuarioService.getUsuario(this.activeRoute.snapshot.params.id).subscribe(res => this.usuario = res) */
  }

  ngOnInit(): void {
    
    this.resultado = new UsuarioPruebaModel();
    this.resultado.usuario = new UsuarioModel();
    this.resultado.usuario.nombre = '';
    this.resultadosService.getResultado(this.activeRoute.snapshot.params.id, this.activeRoute.snapshot.params.postulacion).subscribe(resultado => {

      this.resultado = resultado.resultado;
      this.usuario = resultado.usuario;
      if (this.usuario.razonAplicacion != null && this.usuario.razonAplicacion.indexOf('--') >= 0){
        this.histoialRazon = this.usuario.razonAplicacion.split('--');
        this.histoialRazon.splice(0,1);
        this.usuario.razonAplicacion = this.usuario.razonAplicacion.substr(0,this.usuario.razonAplicacion.indexOf('--'))
      }
      if (this.usuario.descripcion != null && this.usuario.descripcion.indexOf('--') >= 0){ 
        this.historialDescripcion = this.usuario.descripcion.split('--');
        this.historialDescripcion.splice(0,1);
        this.usuario.descripcion = this.usuario.descripcion.substr(0,this.usuario.descripcion.indexOf('--'))
      }
      this.usuario.telefonos.push(new TelefonoModel('NO ENCONTRADO',null));
      if (this.activeRoute.snapshot.params.postulacion != 'null'){
        this.resultadosService.getPostulacion(this.activeRoute.snapshot.params.postulacion).subscribe(res => {
          this.empleosService.getEmpleo(res.idEmpleo).subscribe(res => {
            res.habilidades.forEach(hab => {
              this.usuario.habilidades.forEach(element => {
                if (hab.habilidad === element.habilidad.habilidad){
                  /* this.numeroHabilidades ++; console.log(hab.requerida)
                  if (hab.requerida){console.log('entro')
                    this.numeroHabilidadesImportantes++;
                  } */
                }
              });
            });
          })
          
        });
      }
      let data: number[] = [null]
      if (this.resultado != undefined && this.resultado != null){
        switch (this.resultado.secciones[0].calificacion) {
          case 16:
            data.push(7)
            break;
          case 15:
            data.push(6)
            break;
          case 14:
            data.push(5)
            break;
          case 13:
          case 12:
            data.push(4)
            break;
          case 11:
          case 10:
            data.push(3)
            break;
           case 9:
           case 8:
            data.push(2)
            break;
          default:
            data.push(1)
            break;
        }
        switch (this.resultado.secciones[1].calificacion) {
          case 22:
            data.push(7)
            break;
          case 21:
          case 20:
            data.push(6)
            break;
          case 19:
          case 18:
          case 17:
            data.push(5)
            break;
          case 16:
          case 15:
          case 14:
          case 13:
          case 12:
            data.push(4)
            break;
          case 11:
          case 10:
            data.push(3)
            break;
           case 9:
          case 8:
          case 7:
            data.push(2)
            break;
          default:
            data.push(1)
            break;
        }
        switch (this.resultado.secciones[2].calificacion) {
          case 29:
          case 30:
            data.push(7)
            break;
          case 27:
          case 28:
            data.push(6)
            break;
          case 26:
          case 25:
          case 24:
          case 23:
            data.push(5)
            break;
          case 22:
          case 21:
          case 20:
          case 19:
          case 18:
          case 17:
          case 16:
          case 15:
          case 14: 
            data.push(4)
            break;
          case 13:
          case 12:
            data.push(3)
            break;
          case 11:
          case 10:
          case 9:
          case 8:
            data.push(2)
            break;
          default:
            data.push(1)
            break;
        }
        switch (this.resultado.secciones[3].calificacion) {
          case 18:
            data.push(7)
            break;
          case 17:
          case 16:
            data.push(6)
            break;
          case 15:
          case 14:
            data.push(5)
            break;
          case 13:
          case 12:
          case 11:
          case 10:
            data.push(4)
            break;
          case 9:
          case 8:
          case 7:
            data.push(3)
            break;
           case 6 :
            data.push(2)
            break;
          default:
            data.push(1)
            break;
        }
        switch (this.resultado.secciones[4].calificacion) {
          case 24:
          case 23:
            data.push(7)
            break;
          case 22:
          case 21:
            data.push(6)
            break;
          case 20:
          case 19:
          case 18:
          case 17:
            data.push(5)
            break;
          case 16:
          case 15:
          case 14:
          case 13:
          case 12:
          case 11:
          case 10:
            data.push(4)
            break;
          case 9:
          case 8:
          case 7: 
            data.push(3)
            break;
           case 6 :
          case 5:
            data.push(2)
            break;
          default:
            data.push(1)
            break;
        }
        switch (this.resultado.secciones[5].calificacion) {
          case 20:
            data.push(7)
            break;
          case 19:
          case 18:
            data.push(6)
            break;
          case 17:
          case 16:
          case 15:
            data.push(5)
            break;
          case 14:
          case 13:
          case 12:
          case 11:
          case 10:
          case 9:
            data.push(4)
            break;
          case 8:
          case 7:
            data.push(3)
            break;
           case 6:
          case 5:
            data.push(2)
            break;
          default:
            data.push(1)
            break;
        }
        switch (this.resultado.secciones[6].calificacion) {
          case 19:
          case 20:
            data.push(7)
            break;
          case 18:
            data.push(6)
            break;
          case 17:
          case 16:
            data.push(5)
            break;
          case 15:
          case 14:
          case 13:
          case 12:
          case 11:
          case 10:
          case 9:
            data.push(4)
            break;
          case 8:
          case 6:
          case 7:
            data.push(3)
            break;
           case 5:
            data.push(2)
            break;
          default:
            data.push(1)
            break;
        }
        switch (this.resultado.secciones[7].calificacion) {
          case 17:
            data.push(7)
            break;
          case 16:
          case 15:
            data.push(6)
            break;
          case 14:
          case 13:
            data.push(5)
            break;
          case  12:
          case 11:
          case 10:
          case 9:
          case 8:
            data.push(4)
            break;
          case 7:
            data.push(3)
            break;
           case 6:
            data.push(2)
            break;
          default:
            data.push(1)
            break;
        }
        switch (this.resultado.secciones[8].calificacion) {
          case 18:
            data.push(7)
            break;
          case 17:
            case 16:
            data.push(6)
            break;
          case 15:
            data.push(5)
            break;
          case  14:
          case 13:
          case 12:
          case 11:
          case 10:
          case 9:
            data.push(4)
            break;
          case 8:
            data.push(3)
            break;
           case 7:
          case 6:
            data.push(2)
            break;
          default:
            data.push(1)
            break;
        }
        switch (this.resultado.secciones[9].calificacion) {
          case 22:
          case 21:
          case 20:
            data.push(7)
            break;
          case 19:
          case 18:
            data.push(6)
            break;
          case 17:
          case 16:
            data.push(5)
            break;
          case  15:
          case 14:
          case 13:
          case 12:
          case 11:
          case 10:
            data.push(4)
            break;
          case 9:
          case 8:
            data.push(3)
            break;
           case 7:
          case 6:
          case 5:
            data.push(2)
            break;
          default:
            data.push(1)
            break;
        }
        data.push(null);
        this.lineChartData = [{data: data}];
          this.chart.update();
        let cal: number = this.resultado.calificacion; 
  
        if ( cal <= 48 ){
          cal = cal + 88;
        }else if (cal <= 60){
          cal = cal + 87;
        }else if (cal <= 68){
          cal = cal + 86;
        }else if (cal <= 73){
          cal = cal + 85;
        }else if (cal <= 77){
          cal = cal + 84;
        }else if (cal <= 79){
          cal = cal + 83;
        }else if (cal <= 84){
          cal = cal + 82;
        }else if (cal <= 87){
          cal = cal + 81;
        }else if (cal <= 89){
          cal = cal + 80;
        }else if (cal <= 92){
          cal = cal + 79;
        }else if (cal <= 95){
          cal = cal + 78;
        }else if (cal <= 98){
          cal = cal + 77;
        }else if (cal <= 101){
          cal = cal + 76;
        }else if (cal <= 107){
          cal = cal + 75;
        }else if (cal <= 109){
          cal = cal + 74;
        }else if (cal <= 112){
          cal = cal + 73;
        }else if (cal <= 114){
          cal = cal + 72;
        }else if (cal <= 118){
          cal = cal + 71;
        }else if (cal <= 120){
          cal = cal + 70;
        }else if (cal <= 122){
          cal = cal + 69;
        }else if (cal <= 124){
          cal = cal + 68;
        }else if (cal <= 126){
          cal = cal + 67;
        }else if (cal <= 128){
          cal = cal + 66;
        }else if (cal <= 130){
          cal = cal + 65;
        }else if (cal <= 132){
          cal = cal + 64;
        }else if (cal <= 134){
          cal = cal + 63;
        }else if (cal <= 136){
          cal = cal + 62;
        }else if (cal <= 138){
          cal = cal + 61;
        }else if (cal <= 140){
          cal = cal + 60;
        }else if (cal <= 142){
          cal = cal + 59;
        }else if (cal <= 144){
          cal = cal + 58;
        }else if (cal <= 146){
          cal = cal + 57;
        }else if (cal <= 148){
          cal = cal + 56;
        }else if (cal <= 150){
          cal = cal + 55;
        }else if (cal <= 152){
          cal = cal + 54;
        }else if (cal <= 155){
          cal = cal + 53;
        }else if (cal <= 157){
          cal = cal + 52;
        }else if (cal <= 160){
          cal = cal + 51;
        }else if (cal <= 163){
          cal = cal + 50;
        }else if (cal <= 165){
          cal = cal + 49;
        }else if (cal <= 168){
          cal = cal + 48;
        }else if (cal <= 172){
          cal = cal + 47;
        }else if (cal <= 178){
          cal = cal + 46;
        }else if (cal <= 181){
          cal = cal + 45;
        }else if (cal <= 186){
          cal = cal + 44;
        }else if (cal <= 193){
          cal = cal + 43;
        }else if (cal <= 198){
          cal = cal + 42;
        }else if (cal <= 202){
          cal = cal + 41;
        }else {
          cal = cal + 40;
        }
        cal = (cal / 192) * 100;
      this.iq = cal;
      this.filesServce.getImagesTermanTest(this.resultado.idUsuario).subscribe(res => {
      res.forEach(element => {
        this.images.push('data:'+element.type+';base64,'+element.image);
      });
    }, err => console.log(err))
      }
  });

  }

  getEmpleo(id: number): string{
    let nombreEmpleo:string = '';
    this.usuario.postulaciones.forEach(empleo => {
      if (empleo.id === id)
        nombreEmpleo = empleo.puesto;
    });
    return nombreEmpleo;
  }
  

  public lineChartData: ChartDataSets[] = [
    { data: [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null] }
  ];
  public lineChartLabels: Label[] = ['', 'Información', 'Juicio', 'Vocabulario', 'Síntesis', 'Concentración', 'Análisis', 'Analogías',
                                     'Planeación', 'Clasificación', 'Atención',''];
  public lineChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [],
      yAxes: [
        {
          id: 'y-axis-1',
          position: 'left',
          stacked: true,
          bounds: 'yAlign: center',
          ticks: {
            max: 7,
            callback: function(value) {

               switch (value) {
                case 1:
                  return  'Deficiente'
                case 2:
                   return 'Bajo'
                case 3:
                   return 'Término Medio Bajo'
                case 4:
                   return 'Término Medio'
                case 5:
                   return 'Normal'
                case 6:
                   return 'Superior'
                case 7:
                   return 'Sobresalnte'
                 default:
                  return null;
               }
            }
        }
        }
      ]
    }
  };
  public lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'transparent',
      borderWidth: 3,
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

}

import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from, Subscription } from 'rxjs';
import { HabilidaesModel } from 'src/app/models/habilidades.model';
import { EstadoCivilModel, EstudiosModel, ExperienciasLaboralesModel, FamiliaresModel, UsuarioModel, CorreoModel, TelefonoModel, UsuarioHabildadModel } from 'src/app/models/usuario.model';
import { AuthService } from 'src/app/services/auth.service';
import { FlujoService } from 'src/app/services/flujo.service';
import { HabilidadesService } from 'src/app/services/habilidades.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ValidacionesService } from 'src/app/services/validaciones.service';
import { AREAS_ESTUDIO, PUESTOS_LABORALES } from 'src/assets/data/data';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  formularioUsuario: FormGroup;

  formularioAgregarxperiencia: FormGroup;

  formularioAgregarFamiliar: FormGroup;

  formularioPassword: FormGroup;

  formularioEstudio: FormGroup;

  modificarInfoContacto: boolean;

  modificarInfoUsuaro: boolean;

  usuario: UsuarioModel;

  dias: number[];

  meses: string[];

  anios: number[];

  viveConArray: string[];

  estadosCivilesArray: string[];

  nivelesEstudio: string[];

  ultimoEstudio: EstudiosModel;

  otrosEstudios: EstudiosModel[];

  codigosPostales: number[] = [];

  colonias: string[] = [];

  estados: string[] = [];

  municipios: string[] = [];

  carreras: string[] = AREAS_ESTUDIO;

  puestos: string[]= PUESTOS_LABORALES;

  width = screen.width;

  habilidades: HabilidaesModel[] = [];

  constructor(private formBuilder: FormBuilder, private usuarioService: UsuarioService,
              private router: Router, private validaciones: ValidacionesService,
              private flujoService: FlujoService, private habilidadesService: HabilidadesService,
              private authService:AuthService) { 
              
    this.usuario = new UsuarioModel();
    this.otrosEstudios = [];
    this.usuario.fechaNacimiento = new Date();
    this.usuario.estudios = [];
    this.usuario.familiares = [];
    this.dias = [];
    this.usuario.correos = [new CorreoModel('', null)];
    this.usuario.telefonos = [new TelefonoModel('', null)];
    for (let index = 1; index < 32; index++) {
      this.dias.push(index);
    }

    this.meses = ['Enero','Febrero','Marzo','Abril','Mayo','Junio',
                  'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']

    this.anios = [];

    for (let index = new Date().getFullYear(); index > new Date().getFullYear() - 65; index--) {
      this.anios.push(index);
    }

    this.viveConArray = ['Padres', 'Familia (Esposa / Hijos)', 'Parientes', 'Solo'];

    this.estadosCivilesArray = ['Soltero@', 'Casad@', 'Divorciad@', 'Separad@', 'Union Libre', 'Viud@', 'Otro']

    this.nivelesEstudio = ['Primaria', 'Secundaria', 'Bachillerato Trunco o en curso',
                           'Bachillerato Terminado', 'Licenciatura Trunca o en curso',
                          'Licenciatura Terminada', 'Posgrado', 'Certificado', 'Diplomado', 'Curso'];

    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();

    this.modificarInfoContacto = false;
    let suscription: Subscription = usuarioService.usuario
    .subscribe( usuario =>{ 

      if ( usuario ){
        
        if ( usuario.estadoCivil ){
            document.getElementById('estadoCivil'+usuario.estadoCivil.id).setAttribute('selected','true');
          }else{
            let ec: EstadoCivilModel = new EstadoCivilModel();

            ec.id = 1;
            ec.activo = true;

            usuario.estadoCivil = ec;
          }

          if( !usuario.fechaNacimiento ){
          usuario.fechaNacimiento = new Date();
        }

        const date: Date = new Date(usuario.fechaNacimiento);
        document.getElementById('dia'+date.getDate()).setAttribute('selected','true');
        document.getElementById('mes'+date.getMonth()).setAttribute('selected','true');
        document.getElementById('anio'+date.getUTCFullYear()).setAttribute('selected','true');
        this.usuario = usuario
        if (this.usuario.descripcion != null && this.usuario.descripcion.indexOf('--') >= 0){
          this.descripcion = usuario.descripcion;
           this.auxDescrpcion= this.usuario.descripcion.split('--');
          this.auxDescrpcion.splice(0,1);
          this.usuario.descripcion = this.usuario.descripcion.substring(0,this.usuario.descripcion.indexOf('--')) 
        }
        
        if (this.usuario.razonAplicacion != null && this.usuario.razonAplicacion.indexOf('--') >= 0){
          this.descripcionRazon = usuario.razonAplicacion;
          this.auxDescrpcionRazon= this.usuario.razonAplicacion.split('--');
          this.auxDescrpcionRazon.splice(0,1);
          this.usuario.razonAplicacion = this.usuario.razonAplicacion.substring(0,this.usuario.razonAplicacion.indexOf('--'))
        }


        if ( this.usuario.correos === undefined || this.usuario.correos.length === 0){
          this.usuario.correos = [new CorreoModel('',null)]
        }
        if ( this.usuario.telefonos === undefined || this.usuario.telefonos.length === 0){
          this.usuario.telefonos = [new TelefonoModel('',null)]
        }
        if ( this.usuario.cvCompleto ){
          if (this.usuario.postulaciones.length > 0){
            flujoService.$icons.emit(['success','success','success'])
          }else{
            flujoService.$icons.emit(['success','success','none'])
          }
        }else {
          if (this.usuario.postulaciones.length > 0){
            flujoService.$icons.emit(['success','error','success'])
          }else{
            flujoService.$icons.emit(['success','error','none'])
          }
        }
        
        if (this.usuario.direccion.codigoPostal === 0) this.usuario.direccion.codigoPostal = null;
        usuario.estudios.forEach(estudio => {
          
          if( !this.ultimoEstudio ){
            this.ultimoEstudio = estudio;
          } else{
            if( estudio.ultimo ){
              this.otrosEstudios.push(this.ultimoEstudio);
              this.ultimoEstudio = estudio;
            }else{
              this.otrosEstudios.push(estudio)
            }
          }

        });
        
        this.formularioUsuario.patchValue(this.usuario)

        if ( this.usuario.familiares.length === 0 ){
          this.usuario.familiares.push(new FamiliaresModel());
          this.usuario.familiares[0].parentesco = 'Madre'
          this.usuario.familiares.push(new FamiliaresModel());
          this.usuario.familiares[1].parentesco = 'Padre'
        }

      }

      console.log(this.usuario);
      if ( this.usuario.direccion.codigoPostal != 0 && this.usuario.direccion.codigoPostal != null && this.usuario.direccion.codigoPostal != undefined){
        if (this.usuario.direccion.codigoPostal < 10000)
          this.chngeCP('0'+this.usuario.direccion.codigoPostal.toString());
        else
          this.chngeCP(this.usuario.direccion.codigoPostal.toString());
      }

      habilidadesService.getResultados().subscribe(res => {

        res.forEach(element => {
          let ban: boolean = true;
          this.usuario.habilidades.forEach(habilidad => {
            if ( element.habilidad === habilidad.habilidad.habilidad){
              ban = false;
            }
          });
          if (ban)
            this.habilidades.push(element);
        });
  
      })

      Swal.close();
      suscription.unsubscribe();

    } );
    this.crearFormulario();
  }

  //urlBase: string = 'http://localhost:8090';
  urlBase: string = environment.urlApi;

  ngOnInit(): void {

  
    fetch(this.urlBase  + '/usuarios/get_estados',{headers: {
      'Authorization': 'Bearer ' + this.authService.readToken()
    }})
    .then(response => response.json())
    .then(data =>{
      if ( !data.error ){
       this.estados = data;
    }});

    fetch(this.urlBase  + '/usuarios/get_municipio_por_estado/Ciudad de México',{headers: {
      'Authorization': 'Bearer ' + this.authService.readToken()
    }})
    .then(response => response.json())
    .then(data =>{
      if ( !data.error ){
       this.municipios = data;
    }});

  }

  changeEstadoEP(estado: string): void{

    fetch(this.urlBase  + '/usuarios/get_municipio_por_estado/'+estado,{headers: {
      'Authorization': 'Bearer ' + this.authService.readToken()
    }})
    .then(response => response.json())
    .then(data =>{
      if ( !data.error ){
       this.municipios = data;
       this.formularioAgregarxperiencia.get('estado').setValue(estado);
       this.formularioAgregarxperiencia.get('municipio').setValue(this.municipios[0]);
    }});
  }

  actualizarEscuela(value: string, num: number = null){

    if(num === null){
      this.ultimoEstudio.escuela = value;
    } else{
      //@ts-ignore
      this.otrosEstudios[num].escuela = document.getElementById('escuela'+num).value;
    }

  }

  actualizarNivel(value: string, num: number = null){
    
    if(num === null){
      this.ultimoEstudio.nivel = value;
    } else{
      //@ts-ignore
      this.otrosEstudios[num].nivel = document.getElementById('nivel'+num).value;
    }

  }

  editarDatosBasicos(): void{
    this.usuario.cvCompleto = !this.usuario.cvCompleto;
    const date: Date = new Date(this.usuario.fechaNacimiento);

      setTimeout( () => {
        document.getElementById('dia'+date.getDate()).setAttribute('selected','true');
        document.getElementById('mes'+date.getMonth()).setAttribute('selected','true');
        document.getElementById('anio'+date.getUTCFullYear()).setAttribute('selected','true');
      },100);
        
  }

  private crearFormulario(): void{

    this.formularioUsuario = this.formBuilder.group({
      nombre   : [''],
      apellidoPaterno: [''],
      apellidoMaterno: [''],
      telefono : [''],
      viveCon: [''],
      sexo: [''],
      descripcion: ['', [Validators.required, Validators.minLength(50)]],
      fechaNacimiento: [''],
      estadoCivil: [''],
      dependenPadres: [false],
      dependenHijos: [false],
      dependeConyuge: [false],
      dependenOtros: [false],
      razonAplicacion: ['', [Validators.required, Validators.minLength(50)]],
      direccion: this.formBuilder.group({
        id: [''],
        calle: ['', Validators.required],
        noExterior: ['', Validators.required],
        noInterior: [''],
        colonia: ['', Validators.required],
        delegacion: [''],
        municipio: ['', Validators.required],
        estado: [''],
        codigoPostal: ['', Validators.required],
        activo: [true, Validators.required],
      })
    });

    this.formularioAgregarFamiliar = this.formBuilder.group({
      nombre: [''],
      ocupacion: [''],
      vive: [false],
      parentesco:[''],
      activo: [true]
    });

    this.formularioPassword = this.formBuilder.group({
      password : ['',[Validators.required, Validators.minLength(8)]],
      confirm  : ['',[Validators.required, Validators.minLength(8)]]
    },{
      validators: this.validaciones.passwordsIguales('password', 'confirm')
    });

    this.crearFormularioEstudio();
    this.crearFormularioExperienciaLaboral();
    
  }

  crearFormularioExperienciaLaboral(): void{
    this.formularioAgregarxperiencia = this.formBuilder.group({
      puesto: ['', [Validators.required, Validators.minLength(5)]],
      empresa: ['', [Validators.required, Validators.minLength(3)]],
      estado: ['Ciudad de México', [Validators.required, Validators.minLength(5)]],
      municipio: ['', [Validators.required, Validators.minLength(5)]],
      sueldoInicial: ['', [Validators.required, Validators.min(0), Validators.max(150000)]],
	    sueldoFinal: ['', [Validators.required, Validators.min(0), Validators.max(150000)]],
	    descripcionProblema: ['', [Validators.required, Validators.minLength(20)]],
	    solucionProblema: ['', [Validators.required, Validators.minLength(20)]],
      desde: [new Date()],
      hasta: [new Date()],
      actual: [false, Validators.required],
      descripcion: ['', [Validators.required, Validators.minLength(20)]],
      activo: [true, Validators.required]
      });
  }

  crearFormularioEstudio(): void{
    this.formularioEstudio = this.formBuilder.group({
      titulo : ['',[Validators.required, Validators.minLength(5)]],
      escuela : ['',[Validators.required, Validators.minLength(3)]],
      desde : [new Date(),[Validators.required]],
      hasta : [new Date(),[Validators.required]],
      nivel : ['Primaria', [Validators.required]],
      actualidad:[false, [Validators.required]],
      ultimo: [false, [Validators.required]],
      activo: [true, Validators.required]
    });
  }

  changeNivelEscolar(nivel: string): void{
    this.formularioEstudio.get('nivel').setValue(nivel);
  }
  changeCarrera(carrera: string): void{
    this.formularioEstudio.get('titulo').setValue(carrera);
  }

  limpiarFormularioEstudios(): void{
    this.formularioEstudio.reset();
    this.crearFormularioEstudio();
  }

  limpiarFormularioExperienciaLaboral(): void{
    this.formularioAgregarxperiencia.reset();
    this.crearFormularioExperienciaLaboral();
  }

  actualiizarPassword(){
    Swal.fire({
      text: 'Cargando',
      allowOutsideClick: false
    })
    Swal.showLoading();
    if (this.formularioPassword.valid){
      this.usuario.password = this.formularioPassword.get('password').value;
      this.usuario.passTemporal = false;
      this.usuarioService.actualizarUsuario(this.usuario, false);
    }else{
      Swal.fire({
        icon : "error",
        text : "Error en los passwords, recuerda que deben tener una longitud de al menos 8 caracteres y que denen ser iguales" 
      });
    }

  }

  agregarFamiliar(): void{
    
    this.usuario.familiares.push(this.formularioAgregarFamiliar.value)

    this.formularioAgregarFamiliar.reset();

  }

  actualizarInformacionUsuario(): void{

    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();

    if (!this.checkIdiomasCompletos()){
      Swal.fire({
        icon: 'error',
        text: 'Completa la información de idiomas'
      })
    }else{

      let user: UsuarioModel = this.formularioUsuario.value;
  
      user.id = this.usuario.id;
  
      user.correos = this.usuario.correos;
  
      user.activo = this.usuario.activo;
  
      user.telefonos = this.usuario.telefonos;
  
      user.estudios = this.usuario.estudios
  
      user.experienciasLaborales = this.usuario.experienciasLaborales;
  
      user.familiares = this.usuario.familiares;
  
      user.habilidades = this.usuario.habilidades;
      console.log(user.habilidades);
      this.auxDescrpcion.forEach(element => {
        user.descripcion += '--' + element;
      });

      if (user.descripcion != undefined && user.descripcion != null && user.descripcion.length > 8000)
        user.descripcion = user.descripcion.substr(0,7999);

      this.auxDescrpcionRazon.forEach(element => {
         user.razonAplicacion += '--' + element;
      });
  
      if (user.razonAplicacion != undefined && user.razonAplicacion != null && user.razonAplicacion.length > 8000)
        user.razonAplicacion = user.razonAplicacion.substr(0,7999);
      
      this.usuarioService.existsCorreo(user.correos[0].correo).subscribe(res => {
        if (res && localStorage.getItem('username') != user.correos[0].correo)
          Swal.fire({
            icon: 'error',
            text: 'El correo seleccionado ya existe'
          });
        else{
        this.usuarioService.actualizarUsuario(user);
        this.usuarioService.usuario.subscribe( usuario =>{ this.usuario = usuario; console.log(usuario)})
      }});

      

    }


  }

  borrarFamiliar(num: number): void{
    this.usuario.familiares.splice( num, 1 );
  }

  borrarExperiencia(num: number): void{
    this.usuario.experienciasLaborales.splice( num, 1 );
  }

  borrarEstudio(num: number): void{
    this.usuario.estudios.splice( num, 1 );
  }

  agregarEstudio(): void{
    
    if ( this.formularioEstudio.valid ){

      if (!this.updateEstudio){
        this.usuario.estudios.push(this.formularioEstudio.value);
      }else {
        this.usuario.estudios[this.tmpEstudio] = this.formularioEstudio.value;
      }
      //@ts-ignore
      document.getElementById('cerrarAgregarEstudio').click();

    }else{
      return Object.values(this.formularioEstudio.controls).forEach( control => control.markAsTouched() );
    }
    
  }

  campoNoValido(formulario: FormGroup, campo: string):boolean{

    return formulario.get(campo).invalid && formulario.get(campo).touched; 

  }

  keyUpFamiliar(campo: boolean = true, value: string, index: number = 0){

    if (campo){
      this.usuario.familiares[index].nombre = value;
    }else {
      this.usuario.familiares[index].ocupacion = value;
    }
  }

  clickFamiliarVive(index: number){
    this.usuario.familiares[index].vive = !this.usuario.familiares[index].vive;
  }

  clickSelectorMes(value: string): void{
    
    let fecha: Date = new Date(this.formularioUsuario.get('fechaNacimiento').value);

    fecha.setUTCMonth(Number(value))

    this.formularioUsuario.get('fechaNacimiento').setValue( fecha ); 

  }

  clickSelectorEstadoCivil(value: string): void{
    
    if ( Number( value ) === 2 ){
      this.usuario.familiares.push(new FamiliaresModel());
      this.usuario.familiares[2].parentesco = 'Espos@'
    }else if( this.usuario.familiares.length === 3 ){
      this.usuario.familiares.pop();
    }

    let ec: EstadoCivilModel = new EstadoCivilModel();

    ec.id = Number( value );
    ec.activo = true;

    this.formularioUsuario.get('estadoCivil').setValue( ec )

  }

  clickSelectorDia(value: string): void{
    
    let fecha: Date = new Date(this.formularioUsuario.get('fechaNacimiento').value);

    fecha.setUTCDate(Number(value))

    this.formularioUsuario.get('fechaNacimiento').setValue( fecha );
  }

  clickSelectorAnio(value: string): void{
    
    let fecha: Date = new Date(this.formularioUsuario.get('fechaNacimiento').value);

    fecha.setUTCFullYear(Number(value))

    this.formularioUsuario.get('fechaNacimiento').setValue( fecha );
  }

  clickSelectorViveCon(value: string): void{
    this.formularioUsuario.get('viveCon').setValue(value);
    this.usuario.viveCon = value;
  }

  clickGenero(value: string): void{
    this.formularioUsuario.get('sexo').setValue(value);
  }

  agregarExperiencia(){
    
   if (this.formularioAgregarxperiencia.valid){
      if(!this.updateEL){
        this.usuario.experienciasLaborales.push(this.formularioAgregarxperiencia.value); 
      }else{
        let id: number = this.usuario.experienciasLaborales[this.tmpEL].id;
        this.usuario.experienciasLaborales[this.tmpEL] = this.formularioAgregarxperiencia.value;
        this.usuario.experienciasLaborales[this.tmpEL].id = id;
      }
      this.limpiarFormularioExperienciaLaboral();
        //@ts-ignore
        document.getElementById('cerrarExperiencia').click();
    }else{
      let form = document.getElementById('experienciaForm');
      let firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
      firstInvalidControl.scrollIntoView();
      // (firstInvalidControl as HTMLElement).focus();
      return Object.values(this.formularioAgregarxperiencia.controls).forEach( control => control.markAsTouched() );
    }

  }

  clickSelectorAnioDesde(value: string): void{
    
    let fecha: Date = new Date(this.formularioAgregarxperiencia.get('desde').value);

    fecha.setUTCFullYear(Number(value))

    this.formularioAgregarxperiencia.get('desde').setValue( fecha );
  }

  clickSelectorAnioHasta(value: string): void{
    
    let fecha: Date = new Date(this.formularioAgregarxperiencia.get('hasta').value);

    fecha.setUTCFullYear(Number(value))

    this.formularioAgregarxperiencia.get('hasta').setValue( fecha );
  }

  clickSelectorMesDesde(value: string): void{
    
    let fecha: Date = new Date(this.formularioAgregarxperiencia.get('desde').value);

    fecha.setUTCMonth(Number(value))

    this.formularioAgregarxperiencia.get('desde').setValue( fecha ); 

  }

  clickSelectorPuestoLaboral(value:string): void{
    this.formularioAgregarxperiencia.get('puesto').setValue( value ); 
  }

  clickSelectorMesHasta(value: string): void{
    
    let fecha: Date = new Date(this.formularioAgregarxperiencia.get('hasta').value);

    fecha.setUTCMonth(Number(value))

    this.formularioAgregarxperiencia.get('hasta').setValue( fecha ); 

  }

  getEdad(fechaNacimiento: any): string{

    const date: Date = new Date(fechaNacimiento);

    if (date.getMonth() > new Date().getMonth()){
      return (new Date().getUTCFullYear() - 1 - date.getUTCFullYear()) + ' Años';
    }else{
      return (new Date().getUTCFullYear() - date.getUTCFullYear()) + ' Años';
    }


  }

  chngeCP(value: string){

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });


    if(value != null && value.length > 0 && Number(value) > 0 ){

    fetch(this.urlBase  + '/usuarios/search_cp/'+value,{headers: {
      'Authorization': 'Bearer ' + this.authService.readToken()
    }})
    .then(response => response.json())
    .then(data =>{
      this.codigosPostales = data
    });

    if(value.length === 5){
      fetch(this.urlBase  + '/usuarios/info_cp/'+value,{headers: {
        'Authorization': 'Bearer ' + this.authService.readToken()
      }})
      .then(response => response.json())
      .then(data => {
          if (!data.error){
          this.formularioUsuario.get('direccion').get('estado').setValue(data.estado)
          this.formularioUsuario.get('direccion').get('municipio').setValue(data.municipio)
          this.colonias = data.asentamiento;
          if (this.colonias.length === 0){
            this.formularioUsuario.get('direccion').get('colonia').setValue('')
          }else{
            this.formularioUsuario.get('direccion').get('colonia').setValue(this.colonias[0])
          }}
          else{
            Swal.fire(data.error,data.message,'error');
          }
      });
    }
  }
}

clickSelectorColonia(value: string): void{
  this.formularioUsuario.get('direccion').get('colonia').setValue(value)
}

datosBascosValidacion(): boolean{
  if (  this.validCampo( this.formularioUsuario.get('nombre').value ) && this.validCampo( this.formularioUsuario.get('fechaNacimiento').value )  && this.formularioUsuario.get('sexo').value &&
        this.validCampo( this.formularioUsuario.get('direccion').get('codigoPostal').value )  && this.validCampo( this.formularioUsuario.get('direccion').get('estado').value ) && this.validCampo( this.formularioUsuario.get('direccion').get('calle').value ) &&
        this.validCampo( this.formularioUsuario.get('direccion').get('noExterior').value )    && this.validCampo( this.formularioUsuario.get('direccion').get('colonia').value ) && this.validCampo( this.formularioUsuario.get('direccion').get('municipio').value ))
        return false;
  else
        return true;
}

datosPersonalesValidacion(): boolean{
  if (  this.validCampo( this.usuario.viveCon ))
        return false;
  else
        return true;
}

datosFamiliaresValidacion(): boolean{
  let result: boolean = false;
  this.usuario.familiares.forEach(familiar => {
    if ( !this.validCampo(familiar.nombre) || !this.validCampo(familiar.ocupacion))
      result = true;
  });
  return result;
}

private validCampo(object: any): boolean{

  if (object === null || object === undefined  || object === '')
    return false;
  else
    return true;

}

abrirModalExperienciaLaboral(): void{
  this.updateEL=false;
  this.changeEstadoEP('Ciudad de México');
  if ( this.usuario.experienciasLaborales.length < 3 ){
    document.getElementById('abrirExperienciaLabral').click();
  } else{
    Swal.fire({
      icon: 'info',
      text: 'No puedes agregar más de 3 experiencias laborales'
    });
  }
}

updateEL: boolean = false;
tmpEL: number;

actualizarExperiencia(index: number): void{
  this.updateEL = true;
  this.changeEstadoEP(this.usuario.experienciasLaborales[index].estado);
  this.formularioAgregarxperiencia.patchValue(this.usuario.experienciasLaborales[index])
  this.tmpEL = index;
}

updateEstudio: boolean = false;
tmpEstudio: number;

actualizarEstudio(index: number): void{
  this.updateEstudio = true;
  this.formularioEstudio.patchValue(this.usuario.estudios[index])
  this.tmpEstudio = index;
}

agregarIdioma(): void{
  let habilidad: UsuarioHabildadModel = new UsuarioHabildadModel();
  habilidad.habilidad.idioma = true;
  habilidad.habilidad.habilidad = '';
  this.usuario.habilidades.push(habilidad);
}

cambiarIdioma(text:string,index: number){
  this.usuario.habilidades[index].habilidad.habilidad = text;
}

cambiarPorcentaje(text:string,index: number){ console.log('s')
  this.usuario.habilidades[index].porcentaje = Number(text);
  this.usuario.habilidades;
}

checkIdiomasCompletos (): boolean{
  let isComplete: boolean = true;

  if (this.usuario.habilidades === undefined)
  return false;

  this.usuario.habilidades.forEach(idioma => {
    if (idioma.habilidad.idioma){
      if (idioma.habilidad === undefined || idioma.habilidad === null || idioma.habilidad.habilidad === '' ||
          idioma.porcentaje === undefined || idioma.porcentaje === null || Math.floor(idioma.porcentaje) === 0)
          isComplete = false;
    }
  });
  return isComplete;
}

changeCorreo(value: string): void{
  this.usuario.correos[0].correo = value;
  this.usuarioService.existsCorreo(value).subscribe(res => {
    if (res && localStorage.getItem('username') != value)
      Swal.fire({
        icon: 'error',
        text: 'El correo seleccionado ya existe'
      });
  });
}

changeTelefono(value: string): void{
  this.usuario.telefonos[0].numero = value;
}

checkNegativeNums(Id:string, value: string): void{
  if (Number(value) < 0 || Number(value) > 100){
    //@ts-ignore
    document.getElementById(Id).value = 0;
  } else if ( value === null || value === undefined ){
    //@ts-ignore
    document.getElementById(Id).value = 0;
  }
  console.log(this.usuario.habilidades);
}

agregarHabilidad(index){
  let hab : UsuarioHabildadModel =  new UsuarioHabildadModel();
  hab.porcentaje = 0;
  hab.habilidad = this.habilidades[index];
  this.usuario.habilidades.push(hab);
  this.habilidades.splice(index,1);
}

eliminarHabilidad(index){
  this.habilidades.push(this.usuario.habilidades[index].habilidad);
  this.usuario.habilidades.splice(index,1);
}


backSpace: boolean = false;
contadorBackSpace: number = 0;
descripcion: string;
auxDescrpcion: string[] = [];
keyPressDescripcion(event): void{
  
  if (event.code === 'Backspace'){
    if (!this.backSpace){
      this.descripcion = this.formularioUsuario.get('descripcion').value;
    }
    this.backSpace = true;
    this.contadorBackSpace++;
  }else{
    if (this.backSpace){
      if (this.contadorBackSpace > 5)
        this.auxDescrpcion.push(this.descripcion)
      this.backSpace = false;
      this.contadorBackSpace = 0;
    }
  }

}

backSpaceRazon: boolean = false;
contadorBackSpaceRazon: number = 0;
descripcionRazon: string;
auxDescrpcionRazon: string[] = [];
keyPressRazon(event): void{
  
  if (event.code === 'Backspace'){
    if (!this.backSpaceRazon){
      this.descripcionRazon = this.formularioUsuario.get('razonAplicacion').value;
    }
    this.backSpaceRazon = true;
    this.contadorBackSpaceRazon++;
  }else{
    if (this.backSpaceRazon){
      if (this.contadorBackSpaceRazon > 5)
        this.auxDescrpcionRazon.push(this.descripcionRazon)
      this.backSpaceRazon = false;
      this.contadorBackSpaceRazon = 0;
    }
  }

}

}

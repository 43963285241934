import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SeccionResultadoModel, UsuarioPruebaModel, PreguntaResultadoModel, IntentosModel } from 'src/app/models/resultados.model';
import { PreguntaModel, PruebaModel, SeccionModel } from 'src/app/models/test.model';
import { PruebaService } from 'src/app/services/prueba.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pruebas',
  templateUrl: './pruebas.component.html',
  styleUrls: ['./pruebas.component.css']
})
export class PruebasComponent implements OnInit, OnDestroy {

  //Variables que continen la prueba que se esta aplicando y la pregunta y seccion actual
  prueba: PruebaModel = new PruebaModel();
  seccionActual: SeccionModel = new SeccionModel();
  preguntaActual: PreguntaModel = new PreguntaModel();

  //Variable para saber si se deben mostrar las instrucciones de la prueba
  testIniciado = false;

  //Varables  para el cronómetro / temporizador
  minutos: number = 0;
  segundos: number = 0;
  cronometro : boolean = false;

  // Variables que contienen el index de la opcion que describe más 
  // y ménos a los usuarios en el test de cleaver
  indexMas: number = -1;
  indexMenos: number = -1;

  //Variable donde se almacena el resultado que se eviará al servidor
  resultado = new UsuarioPruebaModel();

  //Variable para Reintentar Envio
  reintentarEnvio: boolean = false;

  //Variable que guardará el intervalo del cronómetro
  intervalo: any;

  //Ancho de la pantalla
  width = screen.width;

  constructor(private pruebasService: PruebaService,
              private activeRoute: ActivatedRoute,
              private router: Router,
              private usuarioService: UsuarioService) { }

  ngOnInit(): void {

      // Abrir el Swal de carga
      Swal.fire({ text: 'Cargando', allowOutsideClick: false });
      Swal.showLoading();

      //Obtener la prueba del servidor
      this.pruebasService.getPrueba(this.activeRoute.snapshot.params.prueba).subscribe(
        res => {
          //Inicializamos la prueba qu se implementará y ejecutara
          this.prueba = res;
          this.prueba.secciones.sort((a, b) => a.numeroSeccion - b.numeroSeccion);
          this.seccionActual = this.prueba.secciones[0];
          this.seccionActual.preguntas.sort((a,b) => a.numero - b.numero);
          this.preguntaActual = this.seccionActual.preguntas[0];
          this.resultado.secciones.push(new SeccionResultadoModel());
          this.resultado.secciones[0].preguntas.push(new PreguntaResultadoModel())
          this.resultado.idPrueba = res.id;
          Swal.close();
        }, 
        err => Swal.fire({
          icon: 'error',
          text: 'Un error ha ocurrido, por favor reintenta más tarde'
        }).then( () => { this.router.navigate([ 'empleos' ]); } )
      );

      //obtener el id del usuario para colocarlo en el resultado
      this.usuarioService.usuario.subscribe(res => this.resultado.idUsuario = res.id, error => window.location.reload());

  }

  ngOnDestroy(): void {
    clearInterval(this.intervalo);
  }


  /* ------------------------------------------------------------------------- */
  /* -------------------------- Funciones públicas --------------------------  */
  /* ------------------------------------------------------------------------- */

  /**
   * Función que oculta las instrucciones y comienza con el cronómetro
   */
  public IniciarPrueba() {

    this.testIniciado = true;

    this.intervalo = setInterval( () => {
      this.segundos++;
      if ( this.segundos === 60 ){
        this.segundos = 0;
        this.minutos ++;
      }
    }, 1000);
  }


  /**
   * Función que llama la siguiente pregunta de la sección
   */
  public siguientePregunta(): void{

    //Revisamos si es una pregunta del tipo cleaver
    if ( this.preguntaActual.tipo == 8 ){

      //Revisamos si selecciono las 2 opciones necesarias
      if ( this.indexMas != -1 && this.indexMenos != -1 ){

        //Enviamos la respuesta en un string de la forma: (palabra que mas descrbe // palabra que menos describe)
        this.resultado.secciones[this.seccionActual.numeroSeccion-1]
                  .preguntas[this.preguntaActual.numero-1]
                  .intentos.push(new IntentosModel(this.preguntaActual.opciones[this.indexMas].opcion+'//'+this.preguntaActual.opciones[this.indexMenos].opcion,1));

        //Reseteamos los valores de los index
        this.indexMas = -1;
        this.indexMenos = -1;

      }else{
        //Mostramos el error
        Swal.fire({icon: 'info', text: 'Por favor selecciona las palabras que más y menos te describan'});

        //Interrumpimos la funcion
        return;
      }
    }

    if (this.preguntaActual.numero < this.seccionActual.preguntas.length){
      this.preguntaActual = this.seccionActual.preguntas[this.preguntaActual.numero];
      this.resultado.secciones[this.seccionActual.numeroSeccion-1].preguntas.push(new PreguntaResultadoModel());
    }else
      this.siguinteSeccion();

  }

  /**
   * Función que llama a la siguiente sección de la prueba
   */
  public siguinteSeccion(): void{

    if (this.seccionActual.numeroSeccion < this.prueba.secciones.length)
      this.seccionActual = this.prueba[this.seccionActual.numeroSeccion];
    else
      this.enviarResultados();

  }

  /**
   * Función que selecciona las opciones que más y menos describen al usuario
   * en cleaver recibiendo el index de la opción seleccionada
   */
  public seleccionCleaver(index: number): void{

    if (this.indexMas === -1)
      this.indexMas = index;

    else if ((this.indexMenos === -1) && (this.indexMas != index))
      this.indexMenos = index;

    else{
      this.indexMas = index;
      this.indexMenos = -1;
    }


  }
  /**
   * Función que envia los resultados al servidor
   */
  public enviarResultados(): void{
    Swal.fire({ text: 'Cargando', allowOutsideClick: false });
    Swal.showLoading();

    this.pruebasService.postResultado(this.activeRoute.snapshot.params.prueba, this.resultado).subscribe(
      res =>{
        Swal.fire({
          icon: 'success',
          text: 'Prueba finalizada, en breve nos pondremos en contacto contigo via correo electrónico o teléfono',
          allowOutsideClick: false
        }).then((result) => {
          this.router.navigate([ 'perfil' ]);
      });
      }, err => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: err.error.error
        });
        this.reintentarEnvio = true;
      }
    );
  }

}

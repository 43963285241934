export class Inglesb2ResultModel {

    calificacionFinal: number;

    realizado: boolean;

    seccion1: number;
    
    seccion2: number;

    constructor() {
        this.calificacionFinal = 0;
        this.realizado = false;
        this.seccion1 = 0;
        this.seccion2 = 0;
    }

}

<app-navbar></app-navbar>

<div class="card shadow p-2">
    <div class="btn-group w-25 mx-auto mb-3" role="group" aria-label="Basic radio toggle button group">
        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" (click)="getTest(55248)" checked>
        <label class="btn btn-outline-secondary btn-sm" for="btnradio1">Resultado Mayra</label>
      
        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" (click)="getTest(55249)">
        <label class="btn btn-outline-secondary btn-sm" for="btnradio2">Resultado Claudia</label>
      </div>

    <div class="d-flex justify-content-center">
        <app-grafica-cleaver [DISC]="M_DISC" #GraficaM class="w-auto"></app-grafica-cleaver>
        <app-grafica-cleaver [DISC]="L_DISC" [titulo]="'Conducta Bajo Presión (L)'"  #GraficaI></app-grafica-cleaver>
        <app-grafica-cleaver [DISC]="T_DISC" [titulo]="'Comportamiento Diario (T)'"#GraficaT></app-grafica-cleaver>
    </div>
    <table class="table table-bordered text-center mt-3 table-striped shadow-sm mx-auto" style="max-width: 750px;" *ngIf="resultado!= null">
        <thead>
            <tr>
                <th scope="col"></th>
                <th scope="col">D (Dominio)</th>
                <th scope="col">I (Influencia)</th>
                <th scope="col">S (Constancia)</th>
                <th scope="col">C (Apego)</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row">M</th>
                <td>{{resultado.M.D}}</td>
                <td>{{resultado.M.I}}</td>
                <td>{{resultado.M.S}}</td>
                <td>{{resultado.M.C}}</td>
            </tr>
            <tr>
                <th scope="row">L</th>
                <td>{{resultado.L.D}}</td>
                <td>{{resultado.L.I}}</td>
                <td>{{resultado.L.S}}</td>
                <td>{{resultado.L.C}}</td>
            </tr>
            <tr>
                <th scope="row">Total</th>
                <td>{{resultado.TOTAL.D}}</td>
                <td>{{resultado.TOTAL.I}}</td>
                <td>{{resultado.TOTAL.S}}</td>
                <td>{{resultado.TOTAL.C}}</td>
            </tr>
        </tbody>
    </table>
</div>
<div class="card mt-1">
    <div class="card-body">
        <h5 class="mb-3">Test de Cleaver</h5>
        <div class="d-flex justify-content-evenly">
            <app-grafica-cleaver [DISC]="M_DISC" #GraficaM class="w-auto"></app-grafica-cleaver>
            <app-grafica-cleaver [DISC]="L_DISC" [titulo]="'Conducta Bajo Presión (L)'" #GraficaI></app-grafica-cleaver>
            <app-grafica-cleaver [DISC]="T_DISC" [titulo]="'Comportamiento Diario (T)'" #GraficaT></app-grafica-cleaver>
        </div>
        <table class="table table-bordered text-center mt-4 table-striped shadow-sm mx-auto" style="max-width: 750px;"
            *ngIf="resultado!= null">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">D (Dominio)</th>
                    <th scope="col">I (Influencia)</th>
                    <th scope="col">S (Constancia)</th>
                    <th scope="col">C (Apego)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">M</th>
                    <td>{{resultado.M.D}}</td>
                    <td>{{resultado.M.I}}</td>
                    <td>{{resultado.M.S}}</td>
                    <td>{{resultado.M.C}}</td>
                </tr>
                <tr>
                    <th scope="row">L</th>
                    <td>{{resultado.L.D}}</td>
                    <td>{{resultado.L.I}}</td>
                    <td>{{resultado.L.S}}</td>
                    <td>{{resultado.L.C}}</td>
                </tr>
                <tr>
                    <th scope="row">Total</th>
                    <td>{{resultado.TOTAL.D}}</td>
                    <td>{{resultado.TOTAL.I}}</td>
                    <td>{{resultado.TOTAL.S}}</td>
                    <td>{{resultado.TOTAL.C}}</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
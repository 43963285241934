<app-navbar></app-navbar>
<!--===============================================================================================================-->
<!-- Modal Agregar Experiencia Laboral ============================================================================-->
<!--===============================================================================================================-->
<div class="modal fade" id="agregarEmpleo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="agregarEmpleo" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Agregar Empleo</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form class="modal-body" [formGroup]="formularioAgregarEmpleo" (keydown.enter)="$event.preventDefault()">
                <div class="mb-2">
                    <label for="agregarPuesto" class="form-label">Puesto</label>
                    <input type="text" class="form-control form-control-sm" id="agregarPuesto" [class.is-invalid]="checkCampo('puesto')" formControlName="puesto">
                    <small class="text-danger" *ngIf="checkCampo('puesto')">Puesto requerido</small>
                </div>
                <div class="mb-2">
                    <label for="agregarCorreoNotificacion" class="form-label">Correo donde llegarán las postulaciones rápidas</label>
                    <select class="form-select form-select-sm" (change)="changeCorreoNotificacion($event)" formControlName="correoNotificacion" #selectorCorreoNotificacion [class.is-invalid]="checkCampo('correoNotificacion')">
                        <option *ngFor="let correo of correosNotificacion" value="{{correo}}">{{correo}}</option>
                    </select>
                    <small class="text-danger" *ngIf="checkCampo('correoNotificacion')">Correo requerido</small>
                </div>
                <div class="mb-2">
                    <label for="agregarCiudad" class="form-label">Estado</label>
                    <select class="form-select form-select-sm" (change)="changeEstadoEP(selectorEstado.value)" formControlName="estado" #selectorEstado>
                        <option *ngFor="let estado of estados; let i = index">{{estado}}</option>
                    </select>
                </div>
                <div class="mb-2">
                    <label class="form-label">Municipio</label>
                    <select class="form-select form-select-sm" formControlName="municipio">
                        <option *ngFor="let municipio of municipios; let i = index">{{municipio}}</option>
                    </select>
                </div>
                <label class="form-label">Sueldos</label>
                <div class="mb-2 input-group">
                    <input type="number" class="form-control form-control-sm" id="sueldoMin" [class.is-invalid]="checkCampo('sueldoMinimo')" formControlName="sueldoMinimo" min="0" max="150000" placeholder="Mínimo" (keyup)="checkNegativeNums(sueldoMin.id, sueldoMin.value)"
                        #sueldoMin>
                    <input type="number" class="form-control form-control-sm" id="sueldoMax" [class.is-invalid]="checkCampo('sueldoMaximo')" formControlName="sueldoMaximo" min="0" max="150000" placeholder="Máximo" (keyup)="checkNegativeNums(sueldoMax.id, sueldoMax.value)"
                        #sueldoMax>
                </div>
                <small class="text-danger" *ngIf="checkCampo('sueldoMinimo') || checkCampo('sueldoMaximo')">El sueldo debe estar etre 0 y 150000<br></small>
                <label class="form-label">Edad</label>
                <div class="mb-2 input-group">
                    <input type="number" class="form-control form-control-sm" id="edadMin" [class.is-invalid]="checkCampo('edadMinima')" formControlName="edadMinima" placeholder="Mínima" min="16" max="70" (keyup)="checkNegativeNums(edadMin.id, edadMin.value)" #edadMin>
                    <input type="number" class="form-control form-control-sm" id="edadMax" [class.is-invalid]="checkCampo('edadMaxima')" formControlName="edadMaxima" placeholder="Máxima" min="16" max="70" (keyup)="checkNegativeNums(edadMax.id, edadMax.value)" #edadMax>
                </div>
                <small class="text-danger" *ngIf="checkCampo('edadMinima') || checkCampo('edadMaxima')">La edad debe ser entre 16 y 70</small>
                <!-- <div class="mb-2">
                    <label for="agregarPuesto" class="form-label">Agregar habilidades necesarias</label>
                    <div class="input-group ">
                        <input type="text" class="form-control form-control-sm" id="habilidadInput" autocomplete="off" list="habilidadesList"
                        (keyup.enter)="agregarHabilidad(habilidadInput.value)" #habilidadInput>
                    <datalist id="habilidadesList" >
                        <ng-container *ngFor="let habilidad of HABILIDADES; let i = index" >
                            <option value="{{habilidad.habilidad | uppercase}}" *ngIf="!habilidad.idioma" >
                        </ng-container>
                    </datalist>
                        <button type="button" class="btn btn-sm btn-primary" (click)="agregarHabilidad(habilidadInput.value)">Agregar</button>
                    </div>
                </div> -->
                <div class="mb-2">
                    <label for="agregarDescripcion" class="form-label">Descripción</label>
                    <textarea type="text" class="form-control form-control-sm" id="agregarDescripcion" (select)="crearHabilidad()" #descripcion formControlName="descripcion" [class.is-invalid]="checkCampo('descripcion')"></textarea>
                    <small class="text-danger" *ngIf="checkCampo('descripcion')">Campo requrido</small>
                </div>
                <div class="row mx-1">
                    <div class="text-white m-1 p-2 w-auto rounded d-flex align-items-center" style="background-color: rgba(2, 163, 181,.7)" *ngFor="let habilidad of habilidades; let i = index">
                        <!-- <input class="form-check-input me-2" type="checkbox" (change)="habilidadNecesaria(i)" > [checked]=habilidad.requerida  -->
                        {{habilidad.habilidad | uppercase}}
                        <button type="button" class="btn-close btn-close-white ms-2" (click)="eliminarHabilidad(i)" aria-label="Close"></button>
                    </div>
                </div>
                <input class="form-check-input" type="checkbox" formControlName="terman"> Aplicar Terman Test
                <br />
                <input class="form-check-input" type="checkbox" formControlName="cleaver"> Aplicar Cleaver Test
                <br />
                <input class="form-check-input" type="checkbox" formControlName="inglesb2"> Aplicar Test Ingles B2
                <br />
                <input class="form-check-input" type="checkbox" (click)="checkboxTestRapido()" [attr.checked]="mostrarSelectTestRapido ? true : null"> Aplicar Test Rapido
                <div class="mb-2" [ngClass]="{'d-none': !mostrarSelectTestRapido}">
                    <label for="agregarCorreoNotificacion" class="form-label">Selecciona un "test rápido"</label>
                    <select class="form-select form-select-sm" (change)="changeTestRapido($event)" formControlName="test" id="idTestRapido">
                        <option value="0" selected disabled>Selecciona una opcion</option>
                        <option *ngFor="let test of testsRapidos; let i = index" value="{{test.id}}">{{test.nombre}}</option>
                    </select>
                </div>
                <button type="submit" class="btn btn-primary bg-tm float-end ms-2" (click)="agregarEmpleo()" *ngIf="agregar">Aceptar</button>
                <button type="submit" class="btn btn-primary bg-tm float-end ms-2" (click)="actualizarEmpleo()" *ngIf="!agregar">Aceptar</button>
                <button type="button" class="btn btn-secondary float-end" data-bs-dismiss="modal" id="cerrarModal">Close</button>
            </form>
        </div>
    </div>
</div>


<div class="container">
    <div class="row my-3">
        <div class="col">
            <h5>Administrador de empleos</h5>
            <div class="d-flex justify-content-between w-100 ">
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#agregarEmpleo" (click)="agregarBtn()"><i class="fa fa-plus me-2"></i>Agregar</button>
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" (click)="empleos = empleosActivos" checked>
                    <label class="btn btn-outline-secondary" for="btnradio1">Vacantes activas</label>

                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" (click)="empleos = empleosInactivos">
                    <label class="btn btn-outline-secondary" for="btnradio2">Vacantes archivadas</label>
                </div>
            </div>

        </div>
    </div>
    <div class="row">
        <ng-container *ngIf="empleos === empleosActivos">
            <div class="col-12 col-md-4 empleos" *ngFor="let empleo of empleos; let i = index">
                <div class="card shadow mb-3">
                    <div class="card-body ">
                        <div class="d-flex">
                            <h5 class="card-title">{{empleo.puesto}}</h5>
                            <button class="btn btn-primary btn-sm me-1 ms-auto my-auto" (click)="prepararModalParaActualizar(i)" data-bs-toggle="modal" data-bs-target="#agregarEmpleo" title="Editar empleo"><i class="fas fa-pen"></i></button>
                            <button class="btn btn-info btn-sm bg-tm me-1 text-white my-auto" (click)="prepararModalParaActualizar(i); agregar=true" data-bs-toggle="modal" data-bs-target="#agregarEmpleo" title="Copiar empleo"><i class="fas fa-copy"></i></button>
                            <button class="btn btn-secondary btn-sm my-auto" (click)="borrarEmpleo(i)" title="Archivar empleo"><i class="fas fa-folder"></i></button>
                        </div>
                        <h6 class="card-subtitle mb-2 text-muted">TruemedGroup</h6>
                        <h6 class="card-subtitle mb-2 text-muted">{{empleo.municipio+ ', '+ empleo.estado}}</h6>
                        <p class="card-text">{{empleo.descripcion}}</p>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="empleos === empleosInactivos">
            <div class="col-12 col-md-4 empleos" *ngFor="let empleo of empleos; let i = index">
                <div class="card shadow mb-3">
                    <div class="card-body">
                        <div class="d-flex">
                            <h5 class="card-title">{{empleo.puesto}}</h5>
                            <button class="btn btn-info btn-sm bg-tm me-1 ms-auto text-white my-auto" (click)="prepararModalParaActualizar(i); agregar=true" data-bs-toggle="modal" data-bs-target="#agregarEmpleo" title="Copiar empleo"><i class="fas fa-copy"></i></button>
                            <button class="btn btn-success btn-sm my-auto" (click)="cambiarEstatusEmpleo(i)" title="Reactivar empleo"><i class="fas fa-folder-open"></i></button>
                        </div>
                        <h6 class="card-subtitle mb-2 text-muted">TruemedGroup</h6>
                        <h6 class="card-subtitle mb-2 text-muted">{{empleo.municipio+ ', '+ empleo.estado}}</h6>
                        <p class="card-text">{{empleo.descripcion}}</p>
                        <div class="d-grid gap-2">
                            <button class="btn btn-outline-secondary" (click)="verEmpleoArchivado(i)"><i class="fa fa-eye me-2"></i>Ver candidatos</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
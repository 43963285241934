import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


// Importación de los modelos
import { UsuarioModel } from '../models/usuario.model';
import { AuthService } from './auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  /**
   * Variable que contiene la información del
   * usuario que está usando el sistema
   * 
   * @type {UsuarioModel}
   */
  private _usuario: UsuarioModel;

  /**
   * Url base del API que maneja la información
   * del sistema
   * 
   * @type {string}
   */
  //private urlBase: string = 'http://localhost:8090/usuarios'
  private urlBase: string = environment.urlApi+'/usuarios'


  constructor(private http: HttpClient, private authService: AuthService,
    private router: Router) { 

    if ( !this._usuario && localStorage.getItem('username') && authService.isAuth()){

        this.getUsuarioFromServer();

    }

  }

  /**
   * Funcion que retorna el Usuario
   */
  get usuario(): Observable<UsuarioModel>{
    let newEmail = localStorage.getItem('username') || '';
    if (this._usuario && this._usuario.correos && this._usuario.correos[0].correo != newEmail) {
      this.getUsuarioFromServer();
    }
    
    if ( !this._usuario && localStorage.getItem('username') && this.authService.isAuth()){

      this.getUsuarioFromServer();

  }

    return new Observable<UsuarioModel>( observer => {
      let i: number = 0;
      const intervalo = setInterval( () => {

        i++;

        if (i === 10 || this._usuario){
          observer.next(this._usuario);
          clearInterval(intervalo);
          observer.complete();
        }

      }, 10000);
    });

    } 

  crearUsuario(usuario: UsuarioModel): Observable<UsuarioModel> {
    
    const urlEndpoint = this.urlBase + '/';

    usuario.passTemporal = false;

    return this.http.post<UsuarioModel>(urlEndpoint, usuario);

  }

  getUsuarioFromServer(): void {

    const urlEndpoint = this.urlBase + '/search/correo';

    const params = new HttpParams().set('correo', localStorage.getItem('username'));

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    this.http.get<UsuarioModel>(urlEndpoint,{headers: httpHeaders, params: params}).subscribe(res => {
      //console.log(res);
        this._usuario = res;
    },(err) => console.log(err))
    
  }

  isAdmin(tipo: number): Promise<boolean>{
    return new Promise<boolean>( (resolve, reject) => {
        this.usuario.subscribe(res => {

          if(res){
            if (res.correos[0].correo === 'tmAdmin'){
              Swal.close();
              
              resolve( true )
            }else{

              if (tipo === 1){
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Acceso denegado'
                }).then(() => {
                  this.router.navigate(['perfil']);
                });
              }else {
                resolve(false);
              }
            }
        }else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Acceso denegado'
          }).then(() => {
            this.router.navigate(['perfil']);
          });
        }
        })
    })
  }

  actualizarUsuario(usuario: UsuarioModel, redirect: boolean = true): void{
    const urlEndpoint = this.urlBase + '/' + usuario.id;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
  });

    this.http.put<UsuarioModel>(urlEndpoint,usuario,{headers: httpHeaders}).subscribe(res => {
      this._usuario = res;
      Swal.fire({
        icon: 'success',
        text: 'Usuario Actualizado',
        showConfirmButton: false,
        timer: 1500
      }).then(() => {
        if (redirect && this._usuario.cvCompleto){
          this.router.navigate([ 'empleos' ]);
        }
    });
    },(err) => console.log(err))
  }

  actualizarUsuario2(usuario: UsuarioModel, redirect: boolean = true): Observable<UsuarioModel>{
    const urlEndpoint = this.urlBase + '/' + usuario.id;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
  });

    return this.http.put<UsuarioModel>(urlEndpoint,usuario,{headers: httpHeaders});
  }

  private correo: string = '';

  solicitarRecuperacionPassword(correo: string): Observable<boolean>{
    
    this.correo = correo;

    const urlEndpoint = this.urlBase + '/recuperar/password';

    const params: HttpParams = new HttpParams().set('correo', correo);

    return this.http.post<boolean>(urlEndpoint,null,{params: params});

  }

  reenviarCorreo(): Observable<boolean>{
    
    return this.solicitarRecuperacionPassword(this.correo);

  }

  revisarToken(token: string): Observable<string>{

    const urlEndpoint = this.urlBase + '/recuperar/password';

    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    const params: HttpParams = new HttpParams().set('token', token);

    return this.http.get(urlEndpoint,{headers:headers, params: params, responseType:'text'});

  }

  cambiarPassword(token: string, password: string): Observable<any>{

    const urlEndpoint = this.urlBase + '/restorePassword';

    const params: HttpParams = new HttpParams().set('token', token)
                                               .set('password', password);

    return this.http.put(urlEndpoint,null,{params: params});

  }

  getUsuario(id: number): Observable<UsuarioModel>{
    const urlEndpoint = this.urlBase + '/'+id;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<UsuarioModel>(urlEndpoint,{headers: httpHeaders});
  }

  test(id: number): Observable<boolean>{
    const urlEndpoint = this.urlBase + '/test/'+id;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<boolean>(urlEndpoint,{headers: httpHeaders});
  }

  existsCorreo(correo: string):Observable<boolean>{
    return this.http.get<boolean>(this.urlBase+'/exist/correo?correo='+correo);
  }

}

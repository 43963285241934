<app-navbar></app-navbar>

<div class="container-fluid px-md-5">

    <!--==============================================-->
    <!-- Contendor de postulaciones ==================-->
    <!--==============================================-->
    <div class="row mt-2 card">
        <div class="card-body mt-2">

            <h5 class="card-title">Postulaciones</h5>

            <div class="list-group mt-3">
                <div class="list-group-item align-items-center justify-content-between pt-3 d-md-flex"
                    *ngFor="let postulacion of postulaciones; let i=index">
                    <div class="col-md-4 d-flex align-items-center">
                        <i class="far fa-building fa-2x me-4"></i>
                        <div>
                            <p class="fs-5 m-0">{{postulacion.empleo.puesto}}</p>
                            <p class="fs-6 m-0">{{postulacion.sueldoPretendido|currency}}</p>
                        </div>
                        <p class="text-muted d-none d-sm-block d-md-none ms-auto">{{postulacion.fecha | date:
                            'longDate'}}</p>
                    </div>
                    <p class="card-text col-3 d-none d-md-block">
                        <i class="fas fa-map-marker-alt me-2"></i>
                        {{postulacion.empleo.estado + ' , ' + postulacion.empleo.municipio}}
                    </p>
                    <p class="card-text col-md-3 mt-2 mt-md-0"
                        [ngClass]="{'text-danger' : postulacion.estatus === 3, 'text-success' : postulacion.estatus === 2, 'text-secondary' : postulacion.estatus === 0}">
                        <ng-container *ngIf="postulacion.estatus === 0">Postulado</ng-container>
                        <ng-container *ngIf="postulacion.estatus === 1">
                            <i class="far fa-eye"></i>
                            Revisado
                        </ng-container>
                        <ng-container *ngIf="postulacion.estatus === 2">
                            <i class="far fa-check-circle"></i>
                            Entrevista
                        </ng-container>
                        <ng-container *ngIf="postulacion.estatus === 3">
                            <i class="far fa-times-circle"></i>
                            Lo sentimos, no continuas en el proceso
                        </ng-container>
                    </p>
                    <div class="d-flex flex-column align-items-md-end col-md-2">
                        <p class="text-muted mb-2 d-none d-md-block">{{postulacion.fecha | date: 'longDate'}}</p>
                        <button type="button" class="btn btn-outline-danger btn-sm" (click)="cancelarPostulacion(i)">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--==============================================-->
    <!-- Contendor de Pruebas ========================-->
    <!--==============================================-->
    <div class="row mt-2 card">
        <div class="card-body mt-2">

            <h5 class="card-title">Pruebas</h5>

            <div class="list-group mt-3">
                <div class="list-group-item justify-content-between pt-3 d-md-flex"
                    *ngFor="let prueba of pruebas; let i=index">
                    <div class="col-md-4 d-flex align-items-center">
                        <i class="far fa-file-alt fa-2x me-4"></i>
                        <p class="fs-5 m-0">Prueba: {{prueba.prueba.nombre | uppercase}}</p>
                    </div>
                    <p class="fs-5 m-0 col-md-4 d-none d-md-block">
                        <ng-container *ngIf="prueba.realizada; else elseTemplate">
                            REALIZADA
                        </ng-container>
                        <ng-template #elseTemplate>
                            PENDIENTE
                        </ng-template>
                    </p>
                    <div class="d-flex flex-column align-items-md-end col-md-4 mt-3 mt-md-0">
                        <button type="button" class="btn btn-outline-secondary" *ngIf="!prueba.realizada"
                            [routerLink]="[prueba.prueba.url]">Realizar prueba</button>

                        <p class="fs-5 m-0 text-success" *ngIf="prueba.realizada">
                            <i class="far fa-check-circle"></i>
                            Prueba Completada
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { Inglesb2ResultModel } from 'src/app/modelos/inglesb2.model';
import { Observacion, PostulacionModel } from 'src/app/modelos/postulacion.model';
import { TermanResultModel } from 'src/app/modelos/terman.model';
import { AdminService } from 'src/app/services/admin.service';
import { FilesService } from 'src/app/services/files.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';
import { EmpleosModel } from '../../../models/empleos.model';
import { EmpleosService } from '../../../services/empleos.service';
import { PostulacionesService } from '../../../services/postulaciones.service';

@Component({
  selector: 'app-postulacion',
  templateUrl: './postulacion.component.html',
  styleUrls: ['./postulacion.component.css']
})
export class PostulacionComponent implements OnInit {

  postulacion: PostulacionModel;

  historialDescripcion: string[] = [];

  showHistorialDescripcion = false;

  histoialRazon: string[] = [];

  showHistorialRazon = false;

  images: string[] = [];

  imgSelect: string = '';

  resultadoTerman: TermanResultModel;

  iq: number = 0;

  agregarObservacion: boolean = false;

  creadorObservaciones: string = '';

  indexObservacion: number = null;

  mostrarClaver: boolean = true;

  idUsuario: number = this.activeRoute.snapshot.params.idUsuario;

  idAdmin: number = null;


  mostrarIngles: boolean = false;
  resultadoIngles: Inglesb2ResultModel;
  vacantesActivas: EmpleosModel[] = [];


  constructor(private activeRoute: ActivatedRoute,
    private adminService: AdminService,
    private filesService: FilesService,
    private router: Router,
    private usuarioService: UsuarioService,
    private empleosService: EmpleosService) {

    this.resultadoIngles = new Inglesb2ResultModel;

    usuarioService.usuario.subscribe(
      res => {
        this.idAdmin = res.id;
      }
    );

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.postulacion = new PostulacionModel();

    if (localStorage.getItem('creadorObservaciones'))
      this.creadorObservaciones = localStorage.getItem('creadorObservaciones');

    this.empleosService.getEmpleos().subscribe(empleos => {
      empleos.forEach(empleo => {
        if (empleo.activo) {
          this.vacantesActivas.push(empleo);
        }
      });
      console.log(this.vacantesActivas);
    });
  }

  ngOnInit(): void {

    this.adminService.getPostulacion(this.activeRoute.snapshot.params.idUsuario, this.activeRoute.snapshot.params.idEmpleo).subscribe(
      res => {
        this.postulacion = res;
        console.log(res);
        if (this.postulacion.usuario.descripcion != null && this.postulacion.usuario.descripcion.indexOf('--') >= 0) {
          this.historialDescripcion = this.postulacion.usuario.descripcion.split('--');
          this.postulacion.usuario.descripcion = this.historialDescripcion[0];
          this.historialDescripcion.splice(0, 1);
        }
        if (this.postulacion.usuario.razonAplicacion != null && this.postulacion.usuario.razonAplicacion.indexOf('--') >= 0) {
          this.histoialRazon = this.postulacion.usuario.razonAplicacion.split('--');
          this.postulacion.usuario.razonAplicacion = this.histoialRazon[0];
          this.histoialRazon.splice(0, 1);
        }
      }, err => {
        console.log(err)
      }
    )


    console.log("Ingles")
    this.adminService.getInglesResult(this.activeRoute.snapshot.params.idUsuario).subscribe(
      res => {
        this.resultadoIngles = res;
        console.log(this.resultadoIngles);
      }, err => {
        console.log(err);
      }
    )


    this.adminService.getTermanResult(this.activeRoute.snapshot.params.idUsuario).subscribe(
      res => {
        this.resultadoTerman = res.resultado;
        let data: number[] = [null]
        if (this.resultadoTerman != undefined && this.resultadoTerman != null) {
          switch (this.resultadoTerman.secciones[0].calificacion) {
            case 16:
              data.push(7)
              break;
            case 15:
              data.push(6)
              break;
            case 14:
              data.push(5)
              break;
            case 13:
            case 12:
              data.push(4)
              break;
            case 11:
            case 10:
              data.push(3)
              break;
            case 9:
            case 8:
              data.push(2)
              break;
            default:
              data.push(1)
              break;
          }
          switch (this.resultadoTerman.secciones[1].calificacion) {
            case 22:
              data.push(7)
              break;
            case 21:
            case 20:
              data.push(6)
              break;
            case 19:
            case 18:
            case 17:
              data.push(5)
              break;
            case 16:
            case 15:
            case 14:
            case 13:
            case 12:
              data.push(4)
              break;
            case 11:
            case 10:
              data.push(3)
              break;
            case 9:
            case 8:
            case 7:
              data.push(2)
              break;
            default:
              data.push(1)
              break;
          }
          switch (this.resultadoTerman.secciones[2].calificacion) {
            case 29:
            case 30:
              data.push(7)
              break;
            case 27:
            case 28:
              data.push(6)
              break;
            case 26:
            case 25:
            case 24:
            case 23:
              data.push(5)
              break;
            case 22:
            case 21:
            case 20:
            case 19:
            case 18:
            case 17:
            case 16:
            case 15:
            case 14:
              data.push(4)
              break;
            case 13:
            case 12:
              data.push(3)
              break;
            case 11:
            case 10:
            case 9:
            case 8:
              data.push(2)
              break;
            default:
              data.push(1)
              break;
          }
          switch (this.resultadoTerman.secciones[3].calificacion) {
            case 18:
              data.push(7)
              break;
            case 17:
            case 16:
              data.push(6)
              break;
            case 15:
            case 14:
              data.push(5)
              break;
            case 13:
            case 12:
            case 11:
            case 10:
              data.push(4)
              break;
            case 9:
            case 8:
            case 7:
              data.push(3)
              break;
            case 6:
              data.push(2)
              break;
            default:
              data.push(1)
              break;
          }
          switch (this.resultadoTerman.secciones[4].calificacion) {
            case 24:
            case 23:
              data.push(7)
              break;
            case 22:
            case 21:
              data.push(6)
              break;
            case 20:
            case 19:
            case 18:
            case 17:
              data.push(5)
              break;
            case 16:
            case 15:
            case 14:
            case 13:
            case 12:
            case 11:
            case 10:
              data.push(4)
              break;
            case 9:
            case 8:
            case 7:
              data.push(3)
              break;
            case 6:
            case 5:
              data.push(2)
              break;
            default:
              data.push(1)
              break;
          }
          switch (this.resultadoTerman.secciones[5].calificacion) {
            case 20:
              data.push(7)
              break;
            case 19:
            case 18:
              data.push(6)
              break;
            case 17:
            case 16:
            case 15:
              data.push(5)
              break;
            case 14:
            case 13:
            case 12:
            case 11:
            case 10:
            case 9:
              data.push(4)
              break;
            case 8:
            case 7:
              data.push(3)
              break;
            case 6:
            case 5:
              data.push(2)
              break;
            default:
              data.push(1)
              break;
          }
          switch (this.resultadoTerman.secciones[6].calificacion) {
            case 19:
            case 20:
              data.push(7)
              break;
            case 18:
              data.push(6)
              break;
            case 17:
            case 16:
              data.push(5)
              break;
            case 15:
            case 14:
            case 13:
            case 12:
            case 11:
            case 10:
            case 9:
              data.push(4)
              break;
            case 8:
            case 6:
            case 7:
              data.push(3)
              break;
            case 5:
              data.push(2)
              break;
            default:
              data.push(1)
              break;
          }
          switch (this.resultadoTerman.secciones[7].calificacion) {
            case 17:
              data.push(7)
              break;
            case 16:
            case 15:
              data.push(6)
              break;
            case 14:
            case 13:
              data.push(5)
              break;
            case 12:
            case 11:
            case 10:
            case 9:
            case 8:
              data.push(4)
              break;
            case 7:
              data.push(3)
              break;
            case 6:
              data.push(2)
              break;
            default:
              data.push(1)
              break;
          }
          switch (this.resultadoTerman.secciones[8].calificacion) {
            case 18:
              data.push(7)
              break;
            case 17:
            case 16:
              data.push(6)
              break;
            case 15:
              data.push(5)
              break;
            case 14:
            case 13:
            case 12:
            case 11:
            case 10:
            case 9:
              data.push(4)
              break;
            case 8:
              data.push(3)
              break;
            case 7:
            case 6:
              data.push(2)
              break;
            default:
              data.push(1)
              break;
          }
          switch (this.resultadoTerman.secciones[9].calificacion) {
            case 22:
            case 21:
            case 20:
              data.push(7)
              break;
            case 19:
            case 18:
              data.push(6)
              break;
            case 17:
            case 16:
              data.push(5)
              break;
            case 15:
            case 14:
            case 13:
            case 12:
            case 11:
            case 10:
              data.push(4)
              break;
            case 9:
            case 8:
              data.push(3)
              break;
            case 7:
            case 6:
            case 5:
              data.push(2)
              break;
            default:
              data.push(1)
              break;
          }
          data.push(null);
          this.lineChartData = [{ data: data }];
          this.chart.update();
          let cal: number = this.resultadoTerman.calificacion;

          if (cal <= 48) {
            cal = cal + 88;
          } else if (cal <= 60) {
            cal = cal + 87;
          } else if (cal <= 68) {
            cal = cal + 86;
          } else if (cal <= 73) {
            cal = cal + 85;
          } else if (cal <= 77) {
            cal = cal + 84;
          } else if (cal <= 79) {
            cal = cal + 83;
          } else if (cal <= 84) {
            cal = cal + 82;
          } else if (cal <= 87) {
            cal = cal + 81;
          } else if (cal <= 89) {
            cal = cal + 80;
          } else if (cal <= 92) {
            cal = cal + 79;
          } else if (cal <= 95) {
            cal = cal + 78;
          } else if (cal <= 98) {
            cal = cal + 77;
          } else if (cal <= 101) {
            cal = cal + 76;
          } else if (cal <= 107) {
            cal = cal + 75;
          } else if (cal <= 109) {
            cal = cal + 74;
          } else if (cal <= 112) {
            cal = cal + 73;
          } else if (cal <= 114) {
            cal = cal + 72;
          } else if (cal <= 118) {
            cal = cal + 71;
          } else if (cal <= 120) {
            cal = cal + 70;
          } else if (cal <= 122) {
            cal = cal + 69;
          } else if (cal <= 124) {
            cal = cal + 68;
          } else if (cal <= 126) {
            cal = cal + 67;
          } else if (cal <= 128) {
            cal = cal + 66;
          } else if (cal <= 130) {
            cal = cal + 65;
          } else if (cal <= 132) {
            cal = cal + 64;
          } else if (cal <= 134) {
            cal = cal + 63;
          } else if (cal <= 136) {
            cal = cal + 62;
          } else if (cal <= 138) {
            cal = cal + 61;
          } else if (cal <= 140) {
            cal = cal + 60;
          } else if (cal <= 142) {
            cal = cal + 59;
          } else if (cal <= 144) {
            cal = cal + 58;
          } else if (cal <= 146) {
            cal = cal + 57;
          } else if (cal <= 148) {
            cal = cal + 56;
          } else if (cal <= 150) {
            cal = cal + 55;
          } else if (cal <= 152) {
            cal = cal + 54;
          } else if (cal <= 155) {
            cal = cal + 53;
          } else if (cal <= 157) {
            cal = cal + 52;
          } else if (cal <= 160) {
            cal = cal + 51;
          } else if (cal <= 163) {
            cal = cal + 50;
          } else if (cal <= 165) {
            cal = cal + 49;
          } else if (cal <= 168) {
            cal = cal + 48;
          } else if (cal <= 172) {
            cal = cal + 47;
          } else if (cal <= 178) {
            cal = cal + 46;
          } else if (cal <= 181) {
            cal = cal + 45;
          } else if (cal <= 186) {
            cal = cal + 44;
          } else if (cal <= 193) {
            cal = cal + 43;
          } else if (cal <= 198) {
            cal = cal + 42;
          } else if (cal <= 202) {
            cal = cal + 41;
          } else {
            cal = cal + 40;
          }
          cal = (cal / 192) * 100;
          this.iq = cal;
          this.filesService.getImagesTermanTest(this.activeRoute.snapshot.params.idUsuario).subscribe(res => {
            res.forEach(element => {
              this.images.push('data:' + element.type + ';base64,' + element.image);
            });
          }, err => console.log(err))
        }
      }, error => console.log(error));

  }

  deleteObservacion(index: number): void {
    this.adminService.deleteObservacion(this.postulacion.observaciones[index].id).subscribe(
      res => {
        if (!res)
          Swal.fire({
            icon: 'error',
            text: 'La observación que intenta eliminar no existe'
          });
        else
          this.postulacion.observaciones.splice(index, 1);
      },
      error => console.error(error)
    );
  }

  addObservacion(observacion: string): void {

    let aux: Observacion = new Observacion();

    aux.observacion = observacion;

    this.adminService.agregarObservacion(aux, this.activeRoute.snapshot.params.idUsuario, this.activeRoute.snapshot.params.idEmpleo).subscribe(
      res => {
        this.postulacion.observaciones.push(res); console.log(res)
        this.agregarObservacion = false;
      },
      error => Swal.fire({
        icon: 'error',
        text: error.error.message
      })
    );
  }

  editarObservacion(nuevaObservacion: string) {
    let observacion: Observacion = JSON.parse(JSON.stringify(this.postulacion.observaciones[this.indexObservacion]));

    observacion.observacion = nuevaObservacion;

    this.adminService.editarObservacion(observacion).subscribe(
      res => {
        this.postulacion.observaciones[this.indexObservacion].observacion = nuevaObservacion;
        this.indexObservacion = null;
        this.agregarObservacion = false;
      },
      error => Swal.fire({
        icon: 'error',
        text: 'Algo salio mal, por favor reintente, si el error persiste contacte a sistemas'
      })
    )

  }

  prepararObservacionEditar(index: number): void {

    this.indexObservacion = index;
    this.agregarObservacion = true;

    setTimeout(() => {
      //@ts-ignore
      document.getElementById('inputObservacion').value = this.postulacion.observaciones[index].observacion;
    }, 100);

  }

  public lineChartData: ChartDataSets[] = [
    { data: [null, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, null] }
  ];
  public lineChartLabels: Label[] = ['', 'Información', 'Juicio', 'Vocabulario', 'Síntesis', 'Concentración', 'Análisis', 'Analogías',
    'Planeación', 'Clasificación', 'Atención', ''];
  public lineChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [],
      yAxes: [
        {
          id: 'y-axis-1',
          position: 'left',
          stacked: true,
          bounds: 'yAlign: center',
          ticks: {
            max: 7,
            callback: function (value) {

              switch (value) {
                case 1:
                  return 'Deficiente'
                case 2:
                  return 'Bajo'
                case 3:
                  return 'Término Medio Bajo'
                case 4:
                  return 'Término Medio'
                case 5:
                  return 'Normal'
                case 6:
                  return 'Superior'
                case 7:
                  return 'Sobresalnte'
                default:
                  return null;
              }
            }
          }
        }
      ]
    }
  };
  public lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'transparent',
      borderWidth: 3,
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  cambiarEstatus(estatus: number) {
    this.adminService.changeEstatusPostulacion(this.activeRoute.snapshot.params.idUsuario, this.activeRoute.snapshot.params.idEmpleo, estatus).subscribe(
      res => { console.log(res); this.postulacion.estatus = estatus },
      err => console.log(err)
    );
  }

  cambiarPostulacion(idEmpleo: number) {
    document.getElementById('closeModalPostulaciones').click();
    this.router.navigate(['/postulacion', this.activeRoute.snapshot.params.idUsuario, idEmpleo]);

  }

  reasignarPostulacion(idEmpleo: number) {
    console.log(this.postulacion.empleo.id);
    console.log(idEmpleo);

    let oldEmpleo = this.postulacion.empleo.id;
    let newEmpleo = idEmpleo;
    let postulanteCorreo = this.postulacion.usuario.correos;
    document.getElementById('closeModalVacantesActivas').click();
    Swal.fire({
      title: '<strong>¿Alguna observación?</strong>',
      input: 'textarea',
      inputLabel: '(opcional)',
      showCancelButton: true,
      confirmButtonColor: '#02a3b5',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Reasignar',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    }).then(res => {
      if (res.isConfirmed) {
        let observacion = res.value.length ? res.value : '';
        this.adminService.reasignarPostulacion(oldEmpleo, newEmpleo, observacion, postulanteCorreo).subscribe(res => {
          this.router.navigate(['dashboard']);
        }, err => {
          Swal.fire({
            icon: 'error',
            text: err.error.message
          });
        })
      }
    });
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {WebcamModule} from 'ngx-webcam';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider} from 'angularx-social-login';
import { ChartsModule } from 'ng2-charts';

//Componentes
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { EmpleosComponent } from './pages/empleos/empleos.component';
import { ReduceTextPipe } from './pipes/reduce-text.pipe';
import { TestComponent } from './pages/test/test.component';
import { UndefinedPipe } from './pipes/undefined.pipe';
import { ResultadosComponent } from './pages/resultados/resultados.component';
import { DetallePruebaComponent } from './pages/detalle-prueba/detalle-prueba.component';
import { AdministradorEmpleosComponent } from './pages/administrador-empleos/administrador-empleos.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { FlujoComponent } from './components/flujo/flujo.component';
import { RecuperacionPasswordComponent } from './pages/recuperacion-password/recuperacion-password.component';
import { CamaraComponent } from './shared/camara/camara.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CandidatoComponent } from './pages/admin-pages/candidato/candidato.component';
import { PostulacionComponent } from './pages/admin/postulacion/postulacion.component';
import {ShContextMenuModule} from 'ng2-right-click-menu';
import { PruebasComponent } from './pages/pruebas/pruebas.component';
import { CleaverTemporalComponent } from './components/cleaver-temporal/cleaver-temporal.component';
import { GraficaCleaverComponent } from './shared/grafica-cleaver/grafica-cleaver.component';
import { ResultadoCleaverComponent } from './components/resultado-cleaver/resultado-cleaver.component';
import { PostulacionesComponent } from './pages/postulaciones/postulaciones.component';
import { Inglesb12Component } from './pages/inglesb12/inglesb12.component';
import { VerificarTelefonoComponent } from './pages/verificar-telefono/verificar-telefono.component';
import { PostulacionRapidaComponent } from './pages/postulacion-rapida/postulacion-rapida.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    PerfilComponent,
    EmpleosComponent,
    ReduceTextPipe,
    TestComponent,
    UndefinedPipe,
    ResultadosComponent,
    DetallePruebaComponent,
    AdministradorEmpleosComponent,
    PrivacyPolicyComponent,
    FlujoComponent,
    RecuperacionPasswordComponent,
    CamaraComponent,
    DashboardComponent,
    CandidatoComponent,
    PostulacionComponent,
    PruebasComponent,
    CleaverTemporalComponent,
    GraficaCleaverComponent,
    ResultadoCleaverComponent,
    PostulacionesComponent,
    Inglesb12Component,
    VerificarTelefonoComponent,
    PostulacionRapidaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocialLoginModule,
    WebcamModule,
    ChartsModule,
    ShContextMenuModule,
    FormsModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('878801776244064')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

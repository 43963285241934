import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { Router } from '@angular/router';
import { ValidacionesService } from '../../services/validaciones.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-verificar-telefono',
  templateUrl: './verificar-telefono.component.html',
  styleUrls: ['./verificar-telefono.component.css']
})
export class VerificarTelefonoComponent implements OnInit {

  /**
   * Propiedad que indica si el dispositivo es móvil o web
   * se usa 1 para movil, 2 pantalla media y 3 web
   * @type {number}
   */
  esMovil: number;

  /**
   * Formulario reactivo que contiene la información
   * de verificacion de numero
   * 
   * @type {FormGroup}
   */
  formulario: FormGroup;
  numero: string;

  constructor(private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private authService: AuthService,
    private router: Router) {
    if (this.authService.getRoles() && this.authService.getRoles().indexOf("ROLE_ADMIN") >= 0) {
      this.router.navigate(['dashboard']);
    } else {
      this.authService.reenviarTwilio().subscribe(succ => console.log);
    }
    this.crarFormularios();
    this.esMovil = (screen.width < 768) ? 2 : 3;
    if (screen.width < 576) this.esMovil = 1;
  }

  ngOnInit(): void {
    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();

    let suscription: Subscription = this.usuarioService.usuario
      .subscribe(usuario => {
        console.log(usuario);
        if (usuario) {
          if (usuario.telefonos) {
            console.log(usuario.telefonos)
            let telefono = usuario.telefonos[0].numero
            this.numero = '+52XXXXXXX' + telefono.substr(telefono.length - 3);
            Swal.close();
          } else {
            Swal.close();
            this.router.navigate(['perfil']);
          }
          suscription.unsubscribe();
        }
      });
  }

  /**
   * Funcion para inicializar lo formularios
   * y configurar sus validaciones
   */
  crarFormularios(): any {
    /* Creación y configuración del formulario */
    this.formulario = this.formBuilder.group({
      codigo: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  verificarCodigo(): void {
    if (this.formulario.valid) {
      Swal.fire({
        allowOutsideClick: false,
        text: 'Cargando'
      });
      Swal.showLoading();
      this.authService.verificarTwilio(this.formulario.value).subscribe((res: any) => {
        if (res.status) {
          if (res.status == 'approved') {
            Swal.fire({
              icon: 'success',
              title: 'Validación exitosa.',
              allowOutsideClick: false,
            }).then(res => {
              if (res.isConfirmed) {
                this.router.navigate(['perfil']);
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Código invalido.',
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Codigo vencido, te llegará uno nuevo.',
            allowOutsideClick: false
          }).then(res => {
            if (res.isConfirmed) {
              this.reenviarCodigo();
            }
          });
        }
      }, err => {
        console.log(err);
      });
    } else {
      return this.markFormGroupTouched(this.formulario);
    }
  }

  public reenviarCodigo(): void {
    this.authService.reenviarTwilio().subscribe(res => {
      Swal.fire({
        icon: 'success',
        text: 'Código enviado',
        showConfirmButton: false,
        timer: 1500
      });
    }, err => {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error'
      });
    });
  }

  public isValid(form: FormGroup, field: string): boolean {
    //@ts-ignore
    return form.get(field).invalid && form.get(field).touched;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    //@ts-ignore
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}

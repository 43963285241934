export const AREAS_ESTUDIO: string[] = [  'NO APLICA'
                                    ,'Administración y gestión de empresas'
                                    ,'Contabilidad y fiscalización'
                                    ,'Finanzas, banca y seguros'
                                    ,'Mercadotecnia y publicidad'
                                    ,'Negocios y administración, programas multidisciplinarios o generales'
                                    ,'Negocios y comercio'
                                    ,'Ciencias políticas'
                                    ,'Economía'
                                    ,'Psicología'
                                    ,'Sociología y antropología'
                                    ,'Trabajo y atención social'
                                    ,'Comunicación y periodismo'
                                    ,'Criminología'
                                    ,'Derecho'
                                    ,'Arquitectura y urbanismo'
                                    ,'Construcción e ingeniería civil'
                                    ,'Electricidad y generación de energía'
                                    ,'Electrónica y automatización'
                                    ,'Ingeniería de vehículos de motor, barcos y aeronaves'
                                    ,'Ingeniería industrial, mecánica, electrónica y tecnología'
                                    ,'Ingeniería mecánica y metalurgia'
                                    ,'Ingeniería química'
                                    ,'Tecnología y protección del medio ambiente'
                                    ,'Tecnologías de la información y comunicación'
                                    ,'Industria de la alimentación'
                                    ,'Manufacturas y procesos'
                                    ,'Minería y extracción'
                                    ,'Ciencias de la educación'
                                    ,'Didáctica, pedagogía y currículo'
                                    ,'Orientación y asesoría educativa'
                                    ,'Biología y bioquímica'
                                    ,'Ciencias ambientales'
                                    ,'Redes Computacionales'
                                    ,'Desarrollo de Software'
                                    ,'Algoritmia'
                                    ,'Inteligencia artificial'
                                    ,'Seguridad informática'
                                    ,'Ciencias de la computación'
                                    ,'Ciencias de la tierra y la atmósfera'
                                    ,'Física'
                                    ,'Química'
                                    ,'Estadística'
                                    ,'Matemáticas'
                                    ,'Diseño'
                                    ,'Música y artes escénicas'
                                    ,'Técnicas audiovisuales y producción de medios'
                                    ,'Enfermería y cuidados'
                                    ,'Estomatología y odontología'
                                    ,'Medicina'
                                    ,'Salud pública'
                                    ,'Terapia y rehabilitación'
                                    ,'Producción y explotación agrícola y ganadera'
                                    ,'Veterinaia'
                                    ,'Otro'
]

export const PUESTOS_LABORALES: string[] = ['MECANICO MATRICERO'
                                    ,'MECANICO CNC'
                                    ,'OBRERO GENERAL'
                                    ,'PULIDOR'
                                    ,'HOSTESS'
                                    ,'AUDITOR'
                                    ,'INSTRUCTOR'
                                    ,'ESCOLTA'
                                    ,'POLICIA'
                                    ,'MILITAR'
                                    ,'MARINO'
                                    ,'MAESTRO/A'
                                    ,'ALBAÑIL'
                                    ,'ARQUITECTO'
                                    ,'CONTRALOR'
                                    ,'CREDITO Y COBRANZA'
                                    ,'BECARIO'
                                    ,'CUIDADOR'
                                    ,'COCINERO'
                                    ,'CHEF'
                                    ,'INSTITUTRIZ'
                                    ,'ELECTRICO'
                                    ,'MECATRONICO'
                                    ,'AMA DE CASA '
                                    ,'VELADOR '
                                    ,'COMMUNITY MANAGER'
                                    ,'TROQUELADOR    '
                                    ,'Gerente Comercial'
                                    ,'Gerente de Ventas'
                                    ,'Supervisor de Ventas'
                                    ,'Vendedor'
                                    ,'Asistente de Ventas'
                                    ,'Ejecutivo de Ventas/ Cuentas'
                                    ,'Ejecutivo de Marketing'
                                    ,'Community Manager'
                                    ,'Brand Manager'
                                    ,'Gerente de Canal'
                                    ,'Supervisor de Telemarketing'
                                    ,'Telemarketero'
                                    ,'Gerente Administrativo y Financiero'
                                    ,'Gerente Administrativo/ Operacional'
                                    ,'Auxiliar Administrativo'
                                    ,'Contador General'
                                    ,'Auxiliar Contable'
                                    ,'Analista de Impuestos'
                                    ,'Encargado de Cuentas a Pagar'
                                    ,'Encargado/ Auxiliar de Patrimonio'
                                    ,'Analista de Finanzas'
                                    ,'Encargado de Facturación y/o Cuentas Corrientes'
                                    ,'Encargado/ Agente de Cobranzas'
                                    ,'Tesorero'
                                    ,'Consultor Gerente/ Jefe de Planificación'
                                    ,'Gerente de Relaciones Públicas'
                                    ,'Encargado de Informática'
                                    ,'Arquitecto de Sistemas Informáticos'
                                    ,'Analista de Sistemas Informáticos'
                                    ,'Analista Programador'
                                    ,'Programador Senior'
                                    ,'Programador Junior'
                                    ,'Tester de Control de Calidad de Software'
                                    ,'Técnico en Implementación de Sistemas Informáticos'
                                    ,'Encargado en Infraestructura Tecnológica'
                                    ,'Administrador de Base de Datos'
                                    ,'Administrador de Redes/ Servidores'
                                    ,'Webmaster'
                                    ,'Auditor Informático'
                                    ,'Desarrollador Web'
                                    ,'Desarrollador Web Junior'
                                    ,'Gerente de Producción Industrial'
                                    ,'Supervisor de Producción Industrial'
                                    ,'Gerente de Investigación y Desarrollo Industrial'
                                    ,'Asistente de Investigación y Desarrollo Industrial'
                                    ,'Proyectista/ Diseñador Industrial'
                                    ,'Proyectista / Diseñador Industrial - Asistente'
                                    ,'Gerente de Planta Industrial'
                                    ,'Jefe de Planificación de la Producción Industrial'
                                    ,'Supervisor de Planta Industrial'
                                    ,'Asistente de Planta Industrial'
                                    ,'Jefe de Turno (Producción Industrial)'
                                    ,'Operario de Industria (general) *'
                                    ,'Operador de Máquinas/ Equipamiento Industriales *'
                                    ,'Técnico en Mantenimiento Industrial'
                                    ,'Gerente de Control de Calidad Industrial'
                                    ,'Técnico en Control de Calidad Industrial'
                                    ,'Ingeniero Industrial Junior'
                                    ,'Ingeniero de Producción Industrial'
                                    ,'Ingeniero de Producción Industrial Junior'
                                    ,'Ingeniero de Producción Industrial Senior'
                                    ,'Ingeniero Industrial'
                                    ,'Encargado de Recursos Humanos'
                                    ,'Encargado de Reclutamiento y/o Selección'
                                    ,'Gerente de Compras'
                                    ,'Jefe Comercio Exteror'
                                    ,'Encargado de Compras'
                                    ,'Supervisor de Licitaciones'
                                    ,'Auxiliar de Licitaciones'
                                    ,'Jefe de Logística'
                                    ,'Auxiliar de Logística'
                                    ,'Jefe de Depósito'
                                    ,'Auxiliar de Depósito'
                                    ,'Administrativo Área Stock'
                                    ,'Gerente de Servicios Generales'
                                    ,'Secretario(a) General '
                                    ,'Secretario(a)'
                                    ,'Atención al Cliente/ Recepcionista'
                                    ,'Chofer'
                                    ,'Encargado(a) de Limpieza *'
                                    ,'Operador de Call Center'
                                    ,'Agente de Servicios Post Venta'
                                    ,'Dibujante Técnico Arquitectura'
                                    ,'Diseñador Gráfico'
                                    ,'Diseñador Gráfico Junior'
                                    ,'Diagramador'
                                    ,'Economista'
                                    ,'Abogado'
                                    ,'Matemático'
                                    ,'Ingeniero Mecánico'
                                    ,'Ingeniero Mecánico Junior/ Pasante'
                                    ,'Técnico Mecánico Industrial'
                                    ,'Asistente Técnico Área Mecánica Industrial'
                                    ,'Médico'
                                    ,'Instrumentador Quirúrgico'
                                    ,'Salud - Puestos varios'
                                    ,'Enfermero'
                                    ,'Paramédico'
                                    ,'Camillero'
                                    ,'Vigilante'
                                    ,'Gerente de Proyectos de Telecomunicaciones'
                                    ,'Ingeniero en Telecomunicaciones'
                                    ,'Ingeniero en Telecomunicaciones Junior'
                                    ,'Operador de Activaciones'
                                    ,'Operador de Monitoreo'
                                    ,'Redes/ Telecomunicaciones - Técnico'
                                    ,'Soporte a Usuarios - Telecomunicaciones'
                                    ,'Asistente de Proyectos de Telecomunicaciones'
                                    ,'Traductor de Idiomas'
                                    ,'Visitador Médico'
                                    ,'Veterinario'
                                    ,'Zootecnista'
                                    ,'Otro'
]
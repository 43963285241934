export class PostulacionModel {

    fecha: Date;

    usuario: UsuarioSummary;

    sueldoPretendido: number;

    empleo: { puesto: string, id: number };

    estatus: number;

    activo: boolean;

    observaciones: Observacion[];

    constructor() {
        this.fecha = new Date();
        this.usuario = new UsuarioSummary();
        this.sueldoPretendido = 0;
        this.empleo = { puesto: '', id: 0 };
        this.estatus = 1;
        this.observaciones = [];
    }

}

export class Observacion {

    id: number;

    observacion: string;

    creador: string;

    idUsuarioCreador: number;

    fecha: Date;

    constructor() {

        this.id = null;
        this.idUsuarioCreador = null;
        this.creador = 'tmAdmin'
        this.observacion = '';
        this.fecha = new Date();

    }
}

class UsuarioSummary {

    postulaciones2: PostulacionModel[];
    nombre: string;
    sexo: string;
    fechaNacimiento: Date;
    direccion: string;
    descripcion: string;
    correos: string;
    telefonos: string;
    estudios: EstudiosModel[];
    experienciasLaborales: ExperienciasLaboralesModel[];
    estadoCivil: string;
    viveCon: string;
    dependenPadres: boolean;
    dependeConyuge: boolean;
    dependenHijos: boolean;
    dependenOtros: boolean;
    razonAplicacion: string;
    habilidades: UsuarioHabildadModel[];

    constructor() {
        this.nombre = '';
        this.sexo = 'H';
        this.fechaNacimiento = new Date();
        this.direccion = '';
        this.descripcion = '';
        this.estudios = [];
        this.experienciasLaborales = [];
        this.correos = '';
        this.telefonos = '';
        this.estadoCivil = '';
        this.viveCon = '';
        this.dependenPadres = false;
        this.dependeConyuge = false;
        this.dependenHijos = false;
        this.dependenOtros = false;
        this.razonAplicacion = '';
        this.habilidades = [];
        this.postulaciones2 = [];
    }

}

class EstudiosModel {

    titulo: string;
    escuela: string;
    desde: Date;
    hasta: Date;
    nivel: string;
    actualidad: boolean;
    ultimo: boolean;
    activo: boolean;

}

class ExperienciasLaboralesModel {

    id: number;
    puesto: string;
    empresa: string;
    municipio: string;
    estado: string;
    sueldoInicial: number;
    sueldoFinal: number;
    descripcionProblema: string;
    solucionProblema: string;
    desde: Date;
    hasta: Date;
    actual: boolean;
    descripcion: string;
    idUsuario: number;
    activo: boolean;

}

class UsuarioHabildadModel {

    habilidad: HabilidaesModel = new HabilidaesModel();
    porcentaje: number;

}

class HabilidaesModel {

    id: number;
    habilidad: string;
    idioma: boolean = false;

}
import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'Reclutamiento Truemed';

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    window.location.reload();
  }

  constructor() {

  }

}

import { Component, OnInit } from '@angular/core';
import { EmpleosModel } from 'src/app/models/empleos.model';
import { UsuarioPruebaModel } from 'src/app/models/resultados.model';
import { EmpleosService } from 'src/app/services/empleos.service';
import { ResultadosService } from 'src/app/services/resultados.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.css']
})
export class ResultadosComponent implements OnInit {

  constanteResultados: UsuarioPruebaModel[];

  resultados: any[];

  table: boolean = true;

  vacantes: EmpleosModel[];

  filtroNombre: string = '';

  constructor(private resultadosService: ResultadosService, private empleosService: EmpleosService) {

    Swal.fire({
      text: 'Obteniendo información'
    })
    Swal.showLoading();
    
    this.resultados = [];

    resultadosService.getResultados().subscribe( resultados => {
      resultados.forEach(element => {
        if (element.calificacion && element.calificacion != null){
        if (element.calificacion.toString() != 'NO APLICA'){
        let cal: number = element.calificacion; 
  
        if ( cal <= 48 ){
          cal = cal + 88;
        }else if (cal <= 60){
          cal = cal + 87;
        }else if (cal <= 68){
          cal = cal + 86;
        }else if (cal <= 73){
          cal = cal + 85;
        }else if (cal <= 77){
          cal = cal + 84;
        }else if (cal <= 79){
          cal = cal + 83;
        }else if (cal <= 84){
          cal = cal + 82;
        }else if (cal <= 87){
          cal = cal + 81;
        }else if (cal <= 89){
          cal = cal + 80;
        }else if (cal <= 92){
          cal = cal + 79;
        }else if (cal <= 95){
          cal = cal + 78;
        }else if (cal <= 98){
          cal = cal + 77;
        }else if (cal <= 101){
          cal = cal + 76;
        }else if (cal <= 107){
          cal = cal + 75;
        }else if (cal <= 109){
          cal = cal + 74;
        }else if (cal <= 112){
          cal = cal + 73;
        }else if (cal <= 114){
          cal = cal + 72;
        }else if (cal <= 118){
          cal = cal + 71;
        }else if (cal <= 120){
          cal = cal + 70;
        }else if (cal <= 122){
          cal = cal + 69;
        }else if (cal <= 124){
          cal = cal + 68;
        }else if (cal <= 126){
          cal = cal + 67;
        }else if (cal <= 128){
          cal = cal + 66;
        }else if (cal <= 130){
          cal = cal + 65;
        }else if (cal <= 132){
          cal = cal + 64;
        }else if (cal <= 134){
          cal = cal + 63;
        }else if (cal <= 136){
          cal = cal + 62;
        }else if (cal <= 138){
          cal = cal + 61;
        }else if (cal <= 140){
          cal = cal + 60;
        }else if (cal <= 142){
          cal = cal + 59;
        }else if (cal <= 144){
          cal = cal + 58;
        }else if (cal <= 146){
          cal = cal + 57;
        }else if (cal <= 148){
          cal = cal + 56;
        }else if (cal <= 150){
          cal = cal + 55;
        }else if (cal <= 152){
          cal = cal + 54;
        }else if (cal <= 155){
          cal = cal + 53;
        }else if (cal <= 157){
          cal = cal + 52;
        }else if (cal <= 160){
          cal = cal + 51;
        }else if (cal <= 163){
          cal = cal + 50;
        }else if (cal <= 165){
          cal = cal + 49;
        }else if (cal <= 168){
          cal = cal + 48;
        }else if (cal <= 172){
          cal = cal + 47;
        }else if (cal <= 178){
          cal = cal + 46;
        }else if (cal <= 181){
          cal = cal + 45;
        }else if (cal <= 186){
          cal = cal + 44;
        }else if (cal <= 193){
          cal = cal + 43;
        }else if (cal <= 198){
          cal = cal + 42;
        }else if (cal <= 202){
          cal = cal + 41;
        }else {
          cal = cal + 40;
        }
        cal = (cal / 192) * 100;
        element.calificacion = Math.floor(cal);
      }}
      });
      this.resultados = resultados;
      this.constanteResultados = resultados;
      this.ordenar('Puntaje Terman');
      Swal.close();
    });

    empleosService.getEmpleos().subscribe(res => this.vacantes = res);

   }

  ngOnInit(): void {
  }

  getEdad(fechaNacimiento: any): string{

    const date: Date = new Date(fechaNacimiento);

    if (date.getMonth() > new Date().getMonth()){
      return (new Date().getUTCFullYear() - 1 - date.getUTCFullYear()).toString();
    }else{
      return (new Date().getUTCFullYear() - date.getUTCFullYear()).toString();
    }


  }

  filtrar(genero: string, vacante: string): void{

    this.resultados = [];

    this.constanteResultados.forEach( result => {
      if ( result.usuario.sexo != null && result.usuario.sexo.indexOf(genero) >= 0){

        let bandera: boolean = false;

        result.usuario.postulaciones.forEach(element => {
          if(element.puesto.indexOf(vacante) >= 0){
            bandera = true;
          }
        });
        
        if ( bandera ){
          this.resultados.push(result);
        }

      }
    });

    this.resultados = this.resultados.filter(res => {

      if (res.usuario.nombre === undefined || res.usuario.nombre === null)
        res.usuario.nombre = '';
      if (res.usuario.apellidoPaterno === undefined || res.usuario.apellidoPaterno === null)
        res.usuario.apellidoPaterno = '';
      if (res.usuario.apellidoMaterno === undefined || res.usuario.apellidoMaterno === null)
        res.usuario.apellidoMaterno = '';

      return  res.usuario.nombre.toLowerCase().indexOf(this.filtroNombre.toLocaleLowerCase()) >= 0 || 
              res.usuario.apellidoPaterno.toLowerCase().indexOf(this.filtroNombre.toLocaleLowerCase()) >= 0 ||
              res.usuario.apellidoMaterno.toLowerCase().indexOf(this.filtroNombre.toLocaleLowerCase()) >= 0

    }) ;

  }

  ordenar(ordenarPor: string): void{
    if (ordenarPor === 'Puntaje Terman'){
      this.resultados.sort((a: UsuarioPruebaModel, b: UsuarioPruebaModel): number => {
        let num1: number;
        let num2: number;
        if (a != null && a.calificacion != null && a.calificacion.toString() === 'NO APLICA') num1 = -500;
        else num1 = a.calificacion;
        if (b != null && b.calificacion != null && b.calificacion.toString() === 'NO APLICA') num2 = -500;
        else num2 = b.calificacion;

        return num2 - num1;
      });
    } else if (ordenarPor === 'Nombre'){
      this.resultados.sort((a: UsuarioPruebaModel, b: UsuarioPruebaModel): number => {
        
       return a.usuario.nombre.localeCompare(b.usuario.nombre);

      });
    }else if (ordenarPor === 'Edad'){
      this.resultados.sort((a: UsuarioPruebaModel, b: UsuarioPruebaModel): number => {
        
       return this.getEdad(b.usuario.fechaNacimiento).localeCompare(this.getEdad(a.usuario.fechaNacimiento));
       
      });
    }

  }

}

<app-navbar></app-navbar>

<sh-context-menu #menu>
    <ng-template shContextMenuItem let-data (click)="clickMenu($event,0)" *ngIf="!soloVer">
        <div>
            Descartar
        </div>
    </ng-template>
    <ng-template shContextMenuItem let-data (click)="clickMenu($event,1)" *ngIf="!soloVer">
        <div>
            Entrevistar
        </div>
    </ng-template>
    <ng-template shContextMenuItem let-data (click)="asignarTest($event,1, postulacionesActuales)" *ngIf="!soloVer">
        <div>
            Asignar Terman
        </div>
    </ng-template>
    <ng-template shContextMenuItem let-data (click)="asignarTest($event,2, postulacionesActuales)" *ngIf="!soloVer">
        <div>
            Asignar Cleaver
        </div>
    </ng-template>
</sh-context-menu>
<sh-context-menu #menu2>
    <ng-template shContextMenuItem let-data (click)="clickMenu($event,2)" *ngIf="!soloVer">
        <div>
            Reconsiderar candidato
        </div>
    </ng-template>
</sh-context-menu>
<sh-context-menu #menu3>
    <ng-template shContextMenuItem let-data (click)="clickMenu($event,3)" *ngIf="!soloVer">
        <div>
            Cancelar entrevista
        </div>
    </ng-template>
    <ng-template shContextMenuItem let-data (click)="asignarTest($event,1, postulacionesEntrevistas)" *ngIf="!soloVer">
        <div>
            Asignar Terman
        </div>
    </ng-template>
    <ng-template shContextMenuItem let-data (click)="asignarTest($event,2, postulacionesEntrevistas)" *ngIf="!soloVer">
        <div>
            Asignar Cleaver
        </div>
    </ng-template>
</sh-context-menu>

<div class="modal fade" id="datosContacto" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Candidatos a entrevistar (Contactos)</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="list-group">
                    <li class="list-group-item d-flex w-100" *ngFor="let postulacion of postulacionesEntrevistas; let i = index">
                        <div class="w-75">
                            <h5 class="mb-1">
                                {{postulacion.nombre + ' '+ (postulacion.apellidoPaterno | undefined) + ' '+ (postulacion.apellidoMaterno | undefined)}}
                            </h5>
                            <p class="mb-1"><i class="fas fa-envelope me-2"></i>{{postulacion.correos[0] | undefined}}</p>
                            <p class="mb-1"><i class="fas fa-phone me-2"></i>{{postulacion.telefonos[0] | undefined}}</p>
                        </div>
                        <div class="d-flex align-content-between flex-wrap justify-content-end w-25">
                            <small class="text-muted float-end">{{getEdad(postulacion.fechaNacimiento) + ' años'}}</small>
                        </div>
                    </li>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="closeModalPostulaciones">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<div class="card position-fixed without-scroll-bar"
    [ngStyle]="{'height': altoScreen+'px', 'width': mostrarMenu ? (anchoScreen*.25)+'px' : '0px'}"
    style="overflow-y: scroll; z-index: 11;">
    <div class="card-body">
        <p class="fs-5 mb-1"><i class="fas fa-venus-mars"></i> Género</p>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="Hombres"
                (click)="hombres = !hombres; filtrar()" [checked]="hombres">
            <label class="form-check-label" for="inlineCheckbox1">Hombres</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="Mujeres"
                (click)="mujeres = !mujeres; filtrar()" [checked]="mujeres">
            <label class="form-check-label" for="inlineCheckbox2">Mujeres</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="Otros"
                (click)="otros = !otros; filtrar()" [checked]="otros">
            <label class="form-check-label" for="inlineCheckbox3">Otros</label>
        </div>
        <div class="dropdown mt-2">
            <div class="d-grid gap-2">
                <button class="btn btn-outline-secondary dropdown-toggle" role="button" id="dropdownMenuLink"
                    data-bs-toggle="dropdown" aria-expanded="false" [disabled]="soloVer">
                    <i class="fas fa-briefcase me-1"></i>
                    Seleccionar Vacante
                </button>

                <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuLink">
                    <li role="button"
                        class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                        *ngFor="let vacante of vacantes"
                        (click)="seleccionarVacante(vacante.vacante)">
                        <div class=" d-flex flex-column">
                            <p class="card-text mb-1">{{vacante.vacante.puesto}}</p>
                            <p class="card-text mb-0 fst-italic">Publicado: {{vacante.vacante.fechaPublicacion | date:
                                'mediumDate'}}</p>
                        </div>
                        <span class="badge bg-primary rounded-pill">{{vacante.noPostulaciones}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="dropdown mt-2">
            <div class="d-grid gap-2">
                <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-sort me-1"></i>
                    Ordenar por
                </button>
                <ul class="dropdown-menu w-100">
                    <li><button class="dropdown-item" (click)="ordenarPostulaciones(0)"
                            [ngClass]="{ 'active' : ordenamiento === 0 }">Sueldo</button></li>
                    <li><button class="dropdown-item" (click)="ordenarPostulaciones(1)"
                            [ngClass]="{ 'active' : ordenamiento === 1 }">Puntaje Terman</button></li>
                    <li><button class="dropdown-item" (click)="ordenarPostulaciones(2)"
                            [ngClass]="{ 'active' : ordenamiento === 2 }">Edad</button></li>
                    <li><button class="dropdown-item" (click)="ordenarPostulaciones(3)"
                            [ngClass]="{ 'active' : ordenamiento === 3 }">Numero de palabras</button></li>
                </ul>
            </div>
        </div>
        <div class="input-group mt-2 mb-1">
            <input class="form-control" list="datalistOptions" id="inputFiltroHabilidad"
                placeholder="Filtrar por habilidad" (keydown.enter)="filtrarPorHabilidades(filtroHabilidad.value)"
                #filtroHabilidad>
            <datalist id="datalistOptions">
                <option *ngFor="let habilidad of habilidades;" [value]="habilidad">
            </datalist>
            <button class="btn btn-sm btn-outline-secondary" (click)="filtrarPorHabilidades(filtroHabilidad.value)"><i
                    class="fas fa-search"></i></button>
        </div>
        <div class="text-white p-1 ps-2 mb-1 w-auto rounded d-flex align-items-center bg-secondary"
            *ngFor="let habilidad of filtroHabilidades; let i=index">
            <!-- <input class="form-check-input me-2" type="checkbox" (change)="habilidadNecesaria(i)" > [checked]=habilidad.requerida  -->
            {{habilidad | uppercase}}
            <button type="button" class="btn-close btn-close-white ms-auto" (click)="quitarHabilidad(i)"
                aria-label="Close"></button>
        </div>
        <div class="input-group mt-2 mb-1">
            <input class="form-control" id="inputFiltrotrabajos" placeholder="Filtrar trabajos anteriores" list="datalistOptions2"
                (keydown.enter)="filtrarPorTrabajos(filtroExperiencias.value)" #filtroExperiencias>
            <datalist id="datalistOptions2">
                <option *ngFor="let trabajo of trabajos;" [value]="trabajo">
            </datalist>
            <button class="btn btn-sm btn-outline-secondary" (click)="filtrarPorTrabajos(filtroExperiencias.value)"><i
                    class="fas fa-search"></i></button>
        </div>
        <div class="text-white p-1 ps-2 mb-1 w-auto rounded d-flex align-items-center"
            style="background-color: rgba(2, 163, 181,.7)" *ngFor="let trabajo of filtroTrabajos; let i=index">
            {{trabajo | uppercase}}
            <button type="button" class="btn-close btn-close-white ms-auto" (click)="quitarFiltroTrabajo(i)"
                aria-label="Close"></button>
        </div>
        <input class="form-control mt-2" id="inputFiltroNombre" placeholder="Filtrar por nombre"
            (keyup)="config.nombre = filtroNombre.value; filtrar()" #filtroNombre>

        <!-- Filtro de sueldo mímin y sueldo máximo -->
        <div class="input-group mt-2">

            <input class="form-control" id="inputFiltroSueldoMinimo" placeholder="Sueldo mínimo" type="number"
            (keyup)="config.sueldoMinimo = filtroSueldoMinimo.value; filtrar()" #filtroSueldoMinimo>

            <input class="form-control" id="inputFiltroSueldoMaximo" placeholder="Sueldo máximo" type="number"
            (keyup)="config.sueldoMaximo = filtroSueldoMaximo.value; filtrar()" #filtroSueldoMaximo>

        </div>

        <!-- Botón de reiniciar filtros -->
        <div class="d-grid gap-2 mt-2">
            <button type="button" class="btn btn-outline-secondary" (click)="reiniciarFiltros()">
                <i class="fas fa-undo me-1"></i>Borrar filtros
            </button>
        </div>
    </div>
</div>

<div class="bg-white position-fixed d-flex justify-content-end align-items-center shadow-sm"
    [ngStyle]="{'height': '30px', 'width': (mostrarMenu) ? (anchoScreen*.25+80)+'px' : '80px'}" style="z-index: 10;"
    role="button" (click)="mostrarMenu = !mostrarMenu">
    <i class="fas me-1" style="font-size: 20px;"
        [ngClass]="{'fa-arrow-alt-circle-left':mostrarMenu,'fa-filter':!mostrarMenu}"></i>
    <p class="my-auto me-2">Filtros</p>
</div>
<div class="container-fluid py-3">
    <div class="row mt-2 justify-content-end mx-0 px-0">
        <div class="w-25" *ngIf="mostrarMenu"></div>
        <div class="card col-12 col-sm-10 col-md-4 m-0 p-0 mt-2" [ngStyle]="{'height': altoScreen-50+'px','max-height': altoScreen-50+'px'}" style="overflow-y: scroll;"
            *ngIf="!mostrarMenu">
            <div class="card-body">
                <h5 class="card-title mb-3">Potulaciones Rechazadas</h5>
                <div class="list-group">
                    <a role="button" class="list-group-item list-group-item-action d-flex w-100" [ngClass]="{'border border-3 border-warning bg-warning-light': postulacion.estatus === 0,
                    'border border-2 border-danger bg-danger-light': postulacion.idResultados === null && postulacion.estatus != 0}"
                        *ngFor="let postulacion of postulacionesDescartadas; let i = index" (click)="verResultado(postulacion)"
                        [shAttachMenu]="menu2" [shMenuData]="i">
                        <div style="width: 40%;">
                            <h5 class="mb-1">
                                <!-- <i class="fas fa-user me-2"></i> -->{{postulacion.nombre + ' '+
                                (postulacion.apellidoPaterno | undefined) + ' '+ (postulacion.apellidoMaterno |
                                undefined)}}
                            </h5>

                            <!-- <small class="text-muted">3 days ago</small> -->
                            <p class="mb-1">Sueldo pretendido: {{postulacion.sueldoPretendido | currency}}</p>
                            <small class="mb-1" *ngIf="postulacion.terman != null">CI Terman: {{postulacion.terman |
                                number: '1.0-0'}}</small>
                        </div>
                        <div style="width: 40%;" class="w-100">
                            <!-- <div *ngIf="postul acion.trabajos.length > 0"> -->
                            <!-- <small class="text-muted me-1" *ngIf="postulacion.trabajos.length > 0">Trabajos anteriores:</small> -->
                            <p class="text-muted text-end pe-4">{{getEdad(postulacion.fechaNacimiento) + '
                                años'}}</p>
                            <p class="badge ms-1" *ngFor="let trabajo of postulacion.trabajos; let i = index"
                                style="background-color: rgba(2, 163, 181,.7);">{{trabajo}}</p>
                            <br>
                            <span class="badge bg-secondary ms-1"
                                *ngFor="let habilidad of postulacion.palbras; let i = index">{{habilidad}}</span>
                            <!--  </div> -->
                        </div>
                        <div style="width: 20%;" class="d-flex align-content-between flex-wrap justify-content-end">
                            <small class="text-muted float-end">{{postulacion.fecha | date}}</small>
                            <span class="badge bg-warning text-dark float-end mt-auto"
                                *ngIf="postulacion.estatus == 0 && postulacion.idResultados != null">No revisado</span>
                            <span class="badge bg-danger float-end mt-auto"
                                *ngIf="postulacion.idResultados === null">Falta Test</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="card col-12 col-sm-10 col-md-4 m-0 p-0 mt-2" [ngStyle]="{'height': altoScreen-50+'px','max-height': altoScreen-50+'px'}" style="overflow-y: scroll;"
            [ngClass]="{'col-md-4': !mostrarMenu, 'col-md-9': mostrarMenu}">
            <div class="card-body">
                <div class="d-flex justify-content-between">
                    <h5 class="card-title mb-3 col-10">Potulaciones {{vacanteSeleccionada}}</h5>
                    <button class="btn btn-outline-secondary btn-sm mb-auto" title="Archivar vacante" (click)="cambiarEstatusEmpleo(0)" *ngIf="!soloVer"><i class="fas fa-folder" [ngClass]="{'me-2': mostrarMenu}" ></i><ng-container *ngIf="mostrarMenu">Archivar empleo</ng-container></button>
                    <button class="btn btn-outline-secondary btn-sm mb-auto" title="Reactivar vacante" (click)="cambiarEstatusEmpleo(1)" *ngIf="soloVer"><i class="fas fa-folder-open" [ngClass]="{'me-2': mostrarMenu}" ></i><ng-container *ngIf="mostrarMenu">Reactivar empleo</ng-container></button>
                </div>

                <div class="w-100 d-flex ms-1 mb-3" [ngClass]="{'justify-content-end': mostrarMenu}">
                    <div class="d-flex me-3" role="button"
                    (click)="config.noRevisadas = !config.noRevisadas; filtrar()">
                        <div style="width: 25px; height: 25px;" class="border border-2 border-warning bg-warning-light me-2 d-flex justify-content-center align-items-center">
                            <i class="fas fa-check" *ngIf="config.noRevisadas"></i>
                        </div>
                        No revisadas
                    </div>
                    <div class="d-flex me-3" role="button"
                    (click)="config.sinTest = !config.sinTest; filtrar()">
                        <div style="width: 25px; height: 25px;" class="border border-2 border-danger bg-danger-light me-2 d-flex justify-content-center align-items-center">
                            <i class="fas fa-check" *ngIf="config.sinTest"></i>
                        </div>
                        Revisadas sin test
                    </div>
                    <div class="d-flex me-2" role="button"
                    (click)="config.revisadas = !config.revisadas; filtrar()">
                        <div style="width: 25px; height: 25px;" class="border border-2 me-2 d-flex justify-content-center align-items-center">
                            <i class="fas fa-check" *ngIf="config.revisadas"></i>
                        </div>
                        Revisadas
                    </div>
                </div>

                <div class="list-group">
                    <a role="button" class="list-group-item list-group-item-action d-flex w-100" [ngClass]="{'border border-3 border-warning bg-warning-light': postulacion.estatus === 0,
                    'border border-2 border-danger bg-danger-light': postulacion.idResultados === null && postulacion.estatus != 0}"
                        *ngFor="let postulacion of postulacionesActuales; let i = index" (click)="verResultado(postulacion)"
                        [shAttachMenu]="menu" [shMenuData]="i">
                        <div style="width: 40%;">
                            <h5 class="mb-1">
                                <!-- <i class="fas fa-user me-2"></i> -->{{postulacion.nombre + ' '+
                                (postulacion.apellidoPaterno | undefined) + ' '+ (postulacion.apellidoMaterno |
                                undefined)}}
                            </h5>

                            <!-- <small class="text-muted">3 days ago</small> -->
                            <p class="mb-1">Sueldo pretendido: {{postulacion.sueldoPretendido | currency}}</p>
                            <small class="mb-1" *ngIf="postulacion.terman != null">CI Terman: {{postulacion.terman |
                                number: '1.0-0'}}</small>
                        </div>
                        <div style="width: 40%;" class="w-100">
                            <!-- <div *ngIf="postul acion.trabajos.length > 0"> -->
                            <!-- <small class="text-muted me-1" *ngIf="postulacion.trabajos.length > 0">Trabajos anteriores:</small> -->
                            <p class="text-muted text-end pe-4">{{getEdad(postulacion.fechaNacimiento) + '
                                años'}}</p>
                            <span class="badge ms-1" *ngFor="let trabajo of postulacion.trabajos; let i = index"
                                style="background-color: rgba(2, 163, 181,.7)">{{trabajo}}</span>
                            <br>
                            <span class="badge bg-secondary ms-1"
                                *ngFor="let habilidad of postulacion.palbras; let i = index">{{habilidad}}</span>
                            <!--  </div> -->
                        </div>
                        <div style="width: 20%;" class="d-flex align-content-between flex-wrap justify-content-end">
                            <small class="text-muted float-end">{{postulacion.fecha | date}}</small>
                            <div class="d-flex">
                                <span class="badge bg-danger float-end mt-auto"
                                [ngClass]="{'me-1': postulacion.estatus === 0}"
                                *ngIf="postulacion.idResultados === null">Falta Test</span>
                            <span class="badge bg-warning text-dark float-end mt-auto"
                                *ngIf="postulacion.estatus === 0">No revisado</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="card col-12 col-sm-10 col-md-4 m-0 p-0 mt-2" *ngIf="!mostrarMenu"
            [ngStyle]="{'height': altoScreen-50+'px','max-height': altoScreen-50+'px'}" style="overflow-y: scroll;">
            <div class="card-body">
                <div class="d-flex justify-content-between mb-3">
                    <h5 class="card-title col-10">Candidatos a entrevistar</h5>
                    <button class="btn btn-outline-secondary btn-sm mb-auto" title="Información de contacto" data-bs-toggle="modal" data-bs-target="#datosContacto"><i class="fas fa-id-card"></i></button>
                </div>
                <div class="list-group">
                    <a role="button" class="list-group-item list-group-item-action d-flex w-100" [ngClass]="{'border border-3 border-warning bg-warning-light': postulacion.estatus === 0,
                    'border border-2 border-danger bg-danger-light': postulacion.idResultados === null && postulacion.estatus != 0}"
                        *ngFor="let postulacion of postulacionesEntrevistas; let i = index" (click)="verResultado(postulacion)"
                        [shAttachMenu]="menu3" [shMenuData]="i">
                        <div style="width: 40%;">
                            <h5 class="mb-1">
                                <!-- <i class="fas fa-user me-2"></i> -->{{postulacion.nombre + ' '+
                                (postulacion.apellidoPaterno | undefined) + ' '+ (postulacion.apellidoMaterno |
                                undefined)}}
                            </h5>

                            <!-- <small class="text-muted">3 days ago</small> -->
                            <p class="mb-1">Sueldo pretendido: {{postulacion.sueldoPretendido | currency}}</p>
                            <small class="mb-1" *ngIf="postulacion.terman != null">CI Terman: {{postulacion.terman |
                                number: '1.0-0'}}</small>
                        </div>
                        <div style="width: 40%;" class="w-100">
                            <!-- <div *ngIf="postul acion.trabajos.length > 0"> -->
                            <!-- <small class="text-muted me-1" *ngIf="postulacion.trabajos.length > 0">Trabajos anteriores:</small> -->
                            <p class="text-muted text-end pe-4">{{getEdad(postulacion.fechaNacimiento) + '
                                años'}}</p>
                            <span class="badge ms-1" *ngFor="let trabajo of postulacion.trabajos; let i = index"
                                style="background-color: rgba(2, 163, 181,.7)">{{trabajo}}</span>
                            <br>
                            <span class="badge bg-secondary ms-1"
                                *ngFor="let habilidad of postulacion.palbras; let i = index">{{habilidad}}</span>
                            <!--  </div> -->
                        </div>
                        <div style="width: 20%;" class="d-flex align-content-between flex-wrap justify-content-end">
                            <small class="text-muted float-end">{{postulacion.fecha | date}}</small>
                            <span class="badge bg-warning text-dark float-end mt-auto"
                                *ngIf="postulacion.estatus === 0 && postulacion.idResultados != null">No revisado</span>
                            <span class="badge bg-danger float-end mt-auto"
                                *ngIf="postulacion.idResultados === null">Falta Test</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!--========================================= Código HTML del Login ===============================================-->

<!--===============================================================================================================-->
<!-- Contenedor del fondo que abarca toda la pantalla y centra en horizontal ======================================-->
<!--===============================================================================================================-->
<div class="d-flex fondo-login justify-content-center align-items-center">

    <!--===============================================================================================================-->
    <!-- Contenedor que hace adaptable a dispositivos móviles =========================================================-->
    <!--===============================================================================================================-->
    <div class="container h-100" [ngClass]="{'h-100':esMovil===1}">
        <div class="row d-flex justify-content-center" [ngClass]="{'h-100':esMovil===1}">
            <!--===============================================================================================================-->
            <!-- Formulario de Login =======================================================================================-->
            <!--===============================================================================================================-->
            <div class="col-12 col-md-8 col-lg-5 bg-white p-4 px-5 shadow rounded">
                <div class="w-100 d-flex flex-column justify-content-center " style="height: 100%;">
                    <div class="ps-4 d-flex flex-column justify-content-center" style="height: 80%;">
                        <img class="img-fluid align-self-center m-2" style="width: 250px;"
                            src="../../../assets/img/TruemedGroupLogo.jpeg">
                        <h4>Hemos enviado un código de verificación al numero: </h4>
                        <h5 class="text-secondary">{{numero}}</h5>
                        <div class="d-flex mt-3 align-items-center justify-content-between mx-2 text-secondary">
                            <hr class="border border-dark" style="width: 46%;">
                            O
                            <hr class="border border-dark" style="width: 46%;">
                        </div>
                        <form class="my-3 mx-2" [formGroup]="formulario" (ngSubmit)="verificarCodigo()">

                            <input type="text" placeholder="Codigo de 6 dígitos" class="form-control mb-3" id="codigo"
                                formControlName="codigo" autocomplete="off"
                                [class.is-invalid]="isValid(formulario,'codigo')">
                            <div class="invalid-feedback mb-2" *ngIf="isValid(formulario,'codigo')">
                                El codigo debe ser de 6 dígitos
                            </div>
                            <button type="submit" class="btn btn-primary bg-tm float-end w-100">Verificar</button>
                        </form>
                        <p class="mx-auto">
                            <a class="link-primary text-decoration-none" (click)="reenviarCodigo()">
                                ¿Aún no recibes ningún código? Reenviar
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UsuarioPruebaModel } from '../models/resultados.model';
import { PruebaModel } from '../models/test.model';
import { AuthService } from './auth.service';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class PruebaService {

  /**
   * Url base del API que maneja la información
   * del sistema
   * 
   * @type {string}
   */
   //private urlBase: string = 'http://localhost:8090/usuarios/pruebas'
   private urlBase: string = environment.urlApi+'/usuarios/pruebas'



  constructor(private http: HttpClient, 
              private authService: AuthService,
              private usuarioService: UsuarioService) {}


  getPrueba(prueba: string): Observable<PruebaModel>{
    
    let urlEndpoint; 

    if (prueba === 'cleaver')
      urlEndpoint = this.urlBase + '/cleaver';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<PruebaModel>(urlEndpoint,{headers: httpHeaders});

  }

  postResultado(prueba: string, body: UsuarioPruebaModel): Observable<boolean>{

    let urlEndpoint; 

    if (prueba === 'cleaver')
      urlEndpoint = this.urlBase + '/cleaver';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.post<boolean>(urlEndpoint, body ,{headers: httpHeaders});
  }

  getPruebasAsignadas(idUsuario: number): Observable<any>{
    
    let urlEndpoint = this.urlBase + '/asignadas'; 
    
    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    const params = new HttpParams().set("idUsuario", idUsuario.toString());

    return this.http.get<any>(urlEndpoint,{headers: httpHeaders, params: params});

  }

}

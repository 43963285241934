import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { UsuarioModel } from '../models/usuario.model';
import { AuthService } from '../services/auth.service';
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuadGuard implements CanActivate {
  
  constructor(private authService: AuthService,
              private router: Router){}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    
    let roles: string[] = this.authService.getRoles();
    
    if ( roles === undefined || roles === null  || !(roles.indexOf("ROLE_ADMIN") >= 0) )
         return this.accesoDenegado();
    else  
      return true;
    
  }

  private accesoDenegado(): boolean{

    Swal.fire({
      icon: 'error',
      title: 'Acceso denegado',
      text: 'Si crees que es un error, contacta al administrador'
    }).then( () => this.router.navigate(['/login']) );

    return false;

  }
  
}

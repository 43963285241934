import { Component, OnInit } from '@angular/core';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { PostulacionesService } from 'src/app/services/postulaciones.service';
import { PruebaService } from 'src/app/services/prueba.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-postulaciones',
  templateUrl: './postulaciones.component.html',
  styleUrls: ['./postulaciones.component.css']
})
export class PostulacionesComponent implements OnInit {

  postulaciones: any[] = [];

  pruebas: any[] = [];

  usuario: UsuarioModel = null;

  constructor(private postulacionesService: PostulacionesService,
              private pruebasService: PruebaService,
              private usuarioService: UsuarioService) { }

  ngOnInit(): void {

    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();
    this.usuarioService.usuario.subscribe(usuario => {

      this.usuario = usuario;
      
      this.postulacionesService.getPostulaciones(this.usuario.id).subscribe(res => {
        this.postulaciones = res;
      })
  
      this.pruebasService.getPruebasAsignadas(this.usuario.id).subscribe(pruebas => {
        this.pruebas = pruebas;
        console.log(pruebas);
      })

      Swal.close();


    }, err => window.location.reload())
  }

  cancelarPostulacion(index: number){
    this.postulacionesService.deletePostulacion(this.usuario.id, this.postulaciones[index].empleo.id).subscribe(
      res => {
        Swal.fire({
          icon: 'success',
          text: 'Postulación cancelada con éxito'
        })
        this.postulaciones.splice(index,1);
      }, err => {
        console.log(err)
        Swal.fire({
          icon: 'error',
          title: err.error.error,
          text: err.error.message
        })
      }
    )
  }

}

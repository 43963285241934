import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmpleosModel, Pregunta, Respuesta, Test } from 'src/app/models/empleos.model';
import { EmpleosService } from 'src/app/services/empleos.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-postulacion-rapida',
  templateUrl: './postulacion-rapida.component.html',
  styleUrls: ['./postulacion-rapida.component.css']
})
export class PostulacionRapidaComponent implements OnInit {

  empleos: EmpleosModel[];
  postulacion: any;
  vacanteSeleccionada: any;
  inputTelIntl: any;
  postulacionEnviada: boolean = false;
  testRapido: Test;
  mostrarBotonEnviar: boolean = false;
  currentIndexPagination: number = 0;
  preguntaSeleccionada: Pregunta;
  opcionesPregunta: Respuesta[] = [];
  aproboExamen: boolean = false;

  constructor(private empleosService: EmpleosService, private router: Router) { 
    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();
    
    this.empleos = [];

    this.postulacion = {
      nombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      telefono: '',
      archivo: null,
      type: '',
      idEmpleo: 0,
      testRapido: Test,
      respuestasBodyEmail: '',
      inlineImages: []
    }

    empleosService.getVacantesActivas().subscribe(empleos => {
      empleos.forEach(empleo => {
        if (empleo.activo) {
          this.empleos.push(empleo);
        }
      });

      if (this.empleos.length == 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por el momento no hay vacantes disponibles'
        }).then(() => {
          router.navigate(['perfil'])
        })

      }

      Swal.close();
    }, (err) => console.log(err));
  }

  ngOnInit(): void {
    var input = document.querySelector("#telephone");
    this.inputTelIntl = (<any>window).intlTelInput(input, {
        initialCountry: "mx",
        preferredCountries: ["mx", "co", "ar" ],
        separateDialCode: true,
        utilsScript: "../../../assets/intl-tel-input/js/utils.js"
    });
  }

  enviarPostulacion(): void {
    Swal.fire({
      allowOutsideClick: false,
      text: 'Enviando...'
    });
    Swal.showLoading();
    if (this.validarPostulacion()) {
      if (this.aproboExamen) {
        this.postulacion.telefono = this.inputTelIntl.getNumber();
        this.empleosService.postPostulacionRapida(this.postulacion).subscribe(res => {
          Swal.fire({
            icon: 'success',
            title: 'Te has postulado exitosamente, pronto nos pondremos en contacto contigo.',
            allowOutsideClick: false,
          }).then(res => {
            this.postulacionEnviada = true;
            this.postulacion = {
              nombre: '',
              apellidoPaterno: '',
              apellidoMaterno: '',
              telefono: '',
              archivo: null,
              type: '',
              idEmpleo: 0,
              testRapido: Test,
              respuestasBodyEmail: '',
              inlineImages: []
            }
          });
        }, err => {
          console.log(err);
          this.mostrarAlert('Error')
        });
      } else {
        console.log('reprobado')
        Swal.fire({
          icon: 'success',
          title: 'Te has postulado exitosamente, pronto nos pondremos en contacto contigo.',
          allowOutsideClick: false,
        }).then(res => {
          this.postulacionEnviada = true;
        });
      }
    }
  }

  subirArchivo(e: any): void {
    
    this.postulacion.archivo = '';
    if (e.target.files.length) {
      const path = e.target.value.split('.');
      const extension = `${path[path.length - 1]}`;

      if (['pdf'].includes(extension)) {

        if (e.target.files[0].size > 5242880) {
          console.log(e.target.files[0].size);
          this.mostrarAlert('El archivo no debe pesar más de 5MB.')
          this.postulacion.archivo = null;
          $('#archivo').val(null);
          return;
        }
        this.postulacion.type = e.target.files[0].type;
        var reader = new FileReader();
        let postulacion = this.postulacion;
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
          postulacion.archivo = reader.result;
        };
      } else {
        this.mostrarAlert('Tipo de archivo invalido, sube un pdf.')
        this.postulacion.archivo = null;
        $('#archivo').val(null);
      }
    } else {
      this.postulacion.archivo = null;
      $('#archivo').val(null);
    }
  }

  validarPostulacion(): boolean {
    console.log(this.inputTelIntl.getSelectedCountryData())
    console.log(this.inputTelIntl.getNumber())
    if (!this.postulacion.nombre) {
      this.mostrarAlert('Ingresa tu nombre.');
      return false;
    }
    if (!this.postulacion.apellidoPaterno) {
      this.mostrarAlert('Ingresa tu apellido paterno.');
      return false;
    }
    if (!this.postulacion.apellidoMaterno) {
      this.mostrarAlert('Ingresa tu apellido materno.');
      return false;
    }
    if (!this.postulacion.archivo) {
      this.mostrarAlert('Sube tu archivo.');
      return false;
    }
    if (!this.postulacion.idEmpleo) {
      this.mostrarAlert('Selecciona una vacante.');
      return false;
    }
    if (!this.inputTelIntl.getNumber()) {
      this.mostrarAlert('Ingresa tu número telefónico.');
      return false;
    }
    if (!this.inputTelIntl.isValidNumber()) {
      this.mostrarAlert('Número telefónico inválido.');
      return false;
    }

    return true;
  }

  mostrarAlert(mensaje: string): void {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: mensaje
    });
  }

  seleccionarVacante(e: any): void {
    let empleo = this.empleos[e.target.value];
    this.postulacion.idEmpleo = empleo.id;
    this.vacanteSeleccionada = empleo;
    this.postulacion.testRapido = empleo.test;
    if (empleo.test) {
      this.testRapido = empleo.test;
      this.testRapido.preguntas = this.testRapido.preguntas.sort((a, b) => 0.5 - Math.random()).slice(0, 3);
      this.currentIndexPagination = 0;
      this.seleccionarPregunta(this.currentIndexPagination);
      this.mostrarBotonEnviar = false;
      this.aproboExamen = false;
    } else {
      this.testRapido = null;
      this.mostrarBotonEnviar = true;
      this.aproboExamen = true;
      this.postulacion.testRapido = null;
      this.opcionesPregunta = []
    }
  }

  seleccionarPregunta(idx: number): void {
    if (this.currentIndexPagination == (this.testRapido.preguntas.length)) {
      this.evaluarResultado();
    } else {
      this.preguntaSeleccionada = this.testRapido.preguntas[idx]
      console.log(this.preguntaSeleccionada);
      // this.preguntaSeleccionada.pregunta = this.preguntaSeleccionada.pregunta.replaceAll("\\n", "<br />");
      this.opcionesPregunta = this.preguntaSeleccionada.opciones.sort((a, b) => 0.5 - Math.random());
    }
  }

  guardarRespuesta(): void {
    var checkedValue = this.opcionesPregunta.filter(opt => opt.checked)

    let pregunta = {
      preguntaId: this.preguntaSeleccionada.id,
      respuestas: checkedValue 
    }
    let idx = this.postulacion.testRapido.preguntas.findIndex((preg: any) => preg.id == pregunta.preguntaId);
    this.postulacion.testRapido.preguntas[idx].respuestas = pregunta.respuestas;
    this.currentIndexPagination++;
    this.seleccionarPregunta(this.currentIndexPagination)
  }

  evaluarResultado(): void {
    let noPreguntas = this.postulacion.testRapido.preguntas.length;
    let noRespuestasCorrectas = 0;
    this.postulacion.testRapido.preguntas.forEach(preg => {
      preg.respuestas.forEach(resp => {
        if (resp.esCorrecta) {
          if (preg.idTipo == 2) {
            noRespuestasCorrectas = noRespuestasCorrectas +  (1 / preg.maxCheckboxSelect);
          } else {
            noRespuestasCorrectas++;
          }
        }
      });
    })
    this.mostrarBotonEnviar = true;
    this.aproboExamen = true;
    this.postulacion.respuestasBodyEmail = this.parsearTestString(this.postulacion.testRapido, noRespuestasCorrectas);
    // if (noPreguntas <= noRespuestasCorrectas) {
    //   this.postulacion.respuestasBodyEmail = this.parsearTestString(this.postulacion.testRapido);
    //   this.aproboExamen = true;
    // }
    Swal.fire({
      icon: 'success',
      text: 'Examen respondido, da click en "enviar mi postulacion" para terminar el proceso.',
      allowOutsideClick: false,
    })
  }

  parsearTestString(test: Test, noRespuestasCorrectas: number): string {
    let response = "<p>Respuestas del cuestionario: </p>";
    if (parseFloat(Math.floor(noRespuestasCorrectas).toFixed()) == 3) {
      response = response + '<span><p>DESEMPEÑO: </p><h1 style="color: #399223;">DESTACADO</h1></span>';
    }
    if (parseFloat(Math.floor(noRespuestasCorrectas).toFixed()) == 2) {
      response = response + '<span><p>DESEMPEÑO: </p><h1 style="color: #E8E518;">BUENO</h1></span>';
    }
    if (parseFloat(Math.floor(noRespuestasCorrectas).toFixed()) == 1) {
      response = response + '<span><p>DESEMPEÑO: </p><h1 style="color: #E58408;">REGULAR</h1></span>';
    }
    if (parseFloat(Math.floor(noRespuestasCorrectas).toFixed()) == 0) {
      response = response + '<span><p>DESEMPEÑO: </p><h1 style="color: #F70404;">MALO</h1></span>';
    }
    response = response + '<span><p>Calificación: ' + noRespuestasCorrectas.toFixed(2) + '</p></span>';
    this.postulacion.inlineImages = [];
    test.preguntas.forEach((preg, idx) => {
      response = response + "<p>" + (idx + 1) + ".- " + preg.pregunta + "</p>";
      if (preg.archivos.length) {
        let img = `<img src="cid:image00${preg.archivos[0].id}" style="width: 250px;">`;
        response = response + "<br />" + img;
        this.postulacion.inlineImages.push(preg.archivos[0].id);
      }
      preg.respuestas.forEach((resp: any) => {
        if (resp.archivos.length) {
          let img = `<img src="cid:image00${resp.archivos[0].id}" style="width: 250px;">`;
          response = response + "<p>Seleccionó:          \"" + img + (resp.esCorrecta ? "\" ( Correcta )" : "\" ( Incorrecta )") + "</p>";
          this.postulacion.inlineImages.push(resp.archivos[0].id);
        } else {
          response = response + "<p>Seleccionó:          \"" + resp.respuesta.replace("<", "").replace(">", "") + (resp.esCorrecta ? "\" ( Correcta )" : "\" ( Incorrecta )") + "</p>";
        }
      });
    })
    console.log(response);
    return response;
  }

  checkboxChangue(e: any, idx: number): void {
    var checkedValue = this.opcionesPregunta.filter(opt => opt.checked);
    if (this.preguntaSeleccionada.maxCheckboxSelect != null) {
      if (checkedValue.length > this.preguntaSeleccionada.maxCheckboxSelect) {
        this.opcionesPregunta[idx].checked = false;
      }
    }
  }

  radioChangue(idx: number): void {
    console.log(idx);
    this.opcionesPregunta.map(opt => opt.checked = false);
    this.opcionesPregunta[idx].checked = true;
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PostulacionesService {

  //private urlBase: string = 'http://localhost:8090/usuarios/postulaciones'
  private urlBase: string = environment.urlApi + '/usuarios/postulaciones'

  constructor(private authService: AuthService,
    private http: HttpClient) { }

  getPostulaciones(idUsuario: number): Observable<any> {

    let urlEndpoint = this.urlBase + '/usuario';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    const params: HttpParams = new HttpParams().set("idUsuario", idUsuario.toString())

    return this.http.get<any>(urlEndpoint, { headers: httpHeaders, params: params });
  }

  deletePostulacion(idUsuario: number, idEmpleo: number): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    const params: HttpParams = new HttpParams()
      .set("idUsuario", idUsuario.toString())
      .set("idEmpleo", idEmpleo.toString());

    return this.http.delete(this.urlBase, { headers: httpHeaders, params: params });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  showSideBar: boolean = false;

  esAdmin: boolean = false;

  constructor(private authService: AuthService, private usuarioService: UsuarioService,
              private router: Router) { }

  ngOnInit(): void {

    if (this.isAuth()) {
      let roles: string[] = this.authService.getRoles();
      
      if ( roles != undefined && roles != null  && (roles.indexOf("ROLE_ADMIN") >= 0) )
        this.esAdmin = true;
    }
    
  }

  isAuth(): boolean {
    return this.authService.isAuth();
  }

  public salir(): void{
    this.authService.logout();
  }

  navegarTest(): void{
    this.usuarioService.usuario.subscribe(usuario => {
      if (usuario.postulaciones.length > 0){
          this.usuarioService.test(usuario.id).subscribe(res => {
            if (res){
              Swal.fire({
                icon: 'error',
                text: 'Ya has realizado todas tus pruebas con éxito'
              })
            }else
            this.router.navigate(['/test']); 
          })
      }else {
        Swal.fire({
          icon: 'error',
          text: 'Necesitas estar postulado a una vacante'
        })
      }
    })
    
  }

}

<div class="container-fluid m-0 p-0 h-100 position-fixed" style="background-image: url(../../../assets/img/bg_login.png);">
    <div class="row mt-0 pt-0 p-0 d-flex flex-column align-items-center justify-content-center h-100">
        <div class="col-12 col-sm-8 col-md-6 col-lg-4 d-flex align-items-center h-100">
            <div class=" card shadow p-4 " [ngClass]="{'h-100 d-flex justify-content-center': (screenWidth < 576)}">
                <div class="w-75 mx-auto">
                <img class="img-fluid p-2" src="../../../assets/img/TruemedGroupLogo.jpeg"> 
                </div>
                <form class="my-3"
                      [formGroup] = "formulario"
                      (ngSubmit)="cambiarPassword()">
                    <p class="card-text">Ingrese su nuevo password</p>
                    <input class="form-control" type="password" placeholder="Contraseña" name="password" autocomplete="on" formControlName="password"
                    [class.is-invalid]="campoRegistroNoValido('password')" [ngClass]="{'mb-2': !campoRegistroNoValido('password')}">
                    <small *ngIf="campoRegistroNoValido('password')" class="text-danger">Ingrese al menos 8 caractéres</small>
                    <input class="form-control" type="password" placeholder="Confirmar contraseña" name="confirm" autocomplete="on" formControlName="confirm"
                    [class.is-invalid]="campoRegistroNoValido('confirm')" [ngClass]="{'mb-2': !campoRegistroNoValido('confirm')}">
                    <small *ngIf="campoRegistroNoValido('confirm')" class="text-danger">Las contraseñas no coinciden</small>
                    <button type="submit" class="btn btn-primary w-100 btn-sm bg-tm">Aceptar</button>
                </form>
             </div>
        </div>
        
    </div>
</div>

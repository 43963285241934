<app-navbar></app-navbar>

<div class="container-fluid">
  <div class="d-flex justify-content-between align-items-center">
    <h2 class="mt-4">Candidatos</h2>
    <div class="form-check form-switch mt-4">
      <label class="form-check-label">Ver en tabla</label>
      <input class="form-check-input" type="checkbox" (change)="table = !table" [checked]="table">
    </div>
  </div>
    <div class="row row-cols-1 row-cols-md-3 g-4 mt-1" *ngIf="!table; else elseTemplate">
        <div class="col" *ngFor="let resultado of resultados; let i=index">
          <div class="card shadow">
            <div class="card-body d-flex flex-column align-items-center">
                <div class="d-flex justify-content-center align-items-center mb-3 icon-foto mt-2">
                    <i class="fas fa-user-alt fa-6x icon-light-color"></i>
                </div>
                <h5 class="card-title">{{resultado.usuario.nombre}}</h5>
                <p class="card-text mb-1">{{ getEdad(resultado.usuario.fechaNacimiento) + ' años' }}</p>
                <p class="card-text">Calificación: {{' ' + resultado.calificacion + ' puntos'}}</p>
                <h6> Postulaciones </h6>
                <ul class="list-group w-100 mb-3">
                  <li class="list-group-item border-secondary" *ngFor="let postulacion of resultado.usuario.postulaciones">{{postulacion.puesto}}</li>
                </ul>
                <a class="btn btn-primary w-100 fs-6 fw-bold" [routerLink]="[ '/candidato', resultado.id, null ]"><i class="fas fa-search-plus fs-5 me-2"></i>Ver detalles de la prueba</a>
            </div>
          </div>
        </div>
        
      </div>
      <ng-template #elseTemplate>
        <div class="card shadow mt-3">
          <div class="card-body">
            <div style="overflow-x: auto;">
              <div class="row mb-2">
                <div class="col-12 col-md-3 mb-1">
                  <select class="form-select orm-select-sm" (change)="filtrar(selectorGenero.value, selectorVacante.value)" #selectorVacante>
                    <option value="" selected>Vacantes</option>
                    <option [value]="vacante.puesto" *ngFor="let vacante of vacantes">{{vacante.puesto}}</option>
                  </select>
                </div>
                <div class="col-12 col-md-3 mb-1">
                  <select class="form-select orm-select-sm" (change)="filtrar(selectorGenero.value, selectorVacante.value)"  #selectorGenero>
                    <option value="" selected>Género</option>
                    <option value="H">Hombre</option>
                    <option value="M">Mujer</option>
                    <option value="O">Otro</option>
                  </select>
                </div>
                <div class="col-12 col-md-3 mb-1">
                  <select class="form-select orm-select-sm" id="selectorOrdenamiento" (change)="ordenar(selectorOrdenamiento.value)" #selectorOrdenamiento>
                    <option selected>Ordenar por</option>
                    <option value="Nombre">Nombre</option>
                    <option value="Edad">Edad</option>
                    <option value="Puntaje Terman">CI Terman</option>
                  </select>
                </div>
                <div class="col-12 col-md-3 mb-1">
                  <input type="text" class="form-control" id="inputBuscarPorNombre" placeholder="Buscar por nombre" 
                        (keyup) = "filtroNombre = inputBuscarPorNombre.value; filtrar(selectorGenero.value, selectorVacante.value)"
                        #inputBuscarPorNombre>
                </div>
              </div>
            
          <table class="table table-bordered table-striped table-responsive text-center">
          <thead>
            <tr>
              <th scope="col" style="width: 15%;">Nombre</th>
              <th scope="col" style="width: 7%;" style="min-width: 80px !important;">Sexo</th>
              <th scope="col" style="width: 10%;" style="min-width: 90px;">Edad</th>
              <th scope="col" style="width: 15%;">Ciudad</th>
              <th scope="col" style="width: 15%; min-width: 135px;">Vacante Aplicada</th>
              <th scope="col" style="width: 8%;">CI Terman</th>
              <th scope="col" style="width: 12%;">Habilidades requeridas</th>
              <th scope="col" style="width: 12%;">Habilidades candidato</th>
              <th scope="col" style="width: 6%;">Acciones</th>
            </tr>
          </thead>
          <tbody class="body">
            <ng-container *ngFor="let resultado of resultados; let i=index">
              <tr *ngFor="let postulacion of resultado.usuario.postulaciones; let r=index">
                <td style="width: 15%;">{{resultado.usuario.nombre+' '+(resultado.usuario.apellidoPaterno|undefined)+' '+(resultado.usuario.apellidoMaterno|undefined)}}</td>
                <td  style="width: 7%;" style="min-width: 80px !important;">{{resultado.usuario.sexo}}</td>
                <td style="width: 10%;" style="min-width: 90px;">{{getEdad(resultado.usuario.fechaNacimiento) + ' años'}}</td>
                <td style="width: 15%;">{{resultado.usuario.direccion.municipio + ', '+resultado.usuario.direccion.estado}}</td>
                <td style="width: 15%; min-width: 135px;">
                  {{postulacion.puesto}}
                </td>
                <td  style="width: 8%;" >{{resultado.calificacion}}</td>
                <td  style="width: 12%;">
                  <ul class="list-group">
                    <li class="list-group-item bg-transparent" *ngFor="let habilidad of postulacion.habilidades">{{habilidad.habilidad}}</li>
                  </ul>
                </td>
                <td  style="width: 12%;">
                  <ul class="list-group">
                    <ng-container *ngFor="let habilidad of resultado.usuario.habilidades">
                        <li class="list-group-item bg-transparent" *ngIf="!habilidad.habilidad.idioma">{{habilidad.habilidad.habilidad}}</li>
                    </ng-container>
                  </ul>
                </td>
                <td style="width: 6%;">
                  <a class="btn btn-primary btn-sm" [routerLink]="[ '/candidato', resultado.id, resultado.postulaciones[r].id ]" *ngIf="resultado.postulaciones != undefined"><i class="fas fa-search-plus fs-5 me-2"></i>Ver</a>
                </td>
            </tr>
            </ng-container>
            
          </tbody>
        </table>
        </div>
          </div>
        </div>
        
           
       
      </ng-template>

</div>
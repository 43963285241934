<app-navbar></app-navbar>

<div class="container">

  <div class="card mt-3">
    <div class="card-body" >
        <h4 class="mb-5">Resultado Prueba de Terman Usuario: {{resultado.usuario.nombre}}</h4>
        <canvas baseChart height="100"
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"></canvas>
      </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card mt-2 shadow ">
                <div class="card-body">
                    <h5 class="card-title mb-4 mt-2">Calificación final: {{' '+resultado.calificacion+' '}}pts</h5>
                    <div class="accordion" id="acordionPadre">
                        <div class="accordion-item" *ngFor="let seccion of resultado.secciones; let i=index">
                            <h2 class="accordion-header" id="headingSeccion{{seccion.numeroSeccion}}">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#seccion' + seccion.numeroSeccion" aria-expanded="false" >
                                Sección {{seccion.numeroSeccion}}
                              </button>
                            </h2>
                            <div id="seccion{{seccion.numeroSeccion}}"  class="accordion-collapse collapse"  [attr.aria-labelledby]="'headingSeccion'+seccion.numeroSeccion" data-bs-parent="#acordionPadre">
                              <div class="accordion-body" id="acordionSeccion{{seccion.numeroSeccion}}">
                                <strong>Calificación de la sección: {{seccion.calificacion}}</strong>
                                <div class="accordion mt-3" id="accordionExample">
                                    <div class="accordion-item" *ngFor="let pregunta of seccion.preguntas">
                                      <h2 class="accordion-header" id="headingPregunta{{pregunta.numeroPregunta}}">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#pregunta' + pregunta.numeroPregunta" aria-expanded="false"
                                         [ngStyle]="{ border: pregunta.correcta ? '2px solid #5cb85c' : '2px solid #f44336' }">
                                            <i class="fas fa-check-circle me-2 " style="color: #5cb85c" *ngIf="pregunta.correcta"></i> 
                                            <i class="fas fa-times-circle me-2 " style="color: #f44336" *ngIf="!pregunta.correcta"></i>
                                            Pregunta {{pregunta.numeroPregunta}}
                                        </button>
                                      </h2>
                                      <div id="pregunta{{pregunta.numeroPregunta}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingPregunta'+pregunta.numeroPregunta" [attr.data-bs-parent]="'#acordionSeccion' + seccion.numeroSeccion">
                                        <div class="accordion-body">
                                            <strong>Intentos</strong>
                                            <ul class="list-group mt-2">
                                                <li class="list-group-item disabled" aria-disabled="true" *ngFor="let intento of pregunta.intentos">{{intento.respuesta}}</li>
                                            </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
      <h5 class="card-title mb-2 mt-3">Capturas tomadas durante el exámen</h5>
      <div class="col-12 col-md-4 mt-2 mb-3" *ngFor="let image of images">
          <img [src]="image" class="img-fluid"/>
      </div>
    </div>
</div>
export class PruebaModel{

    id: number;
    nombre: string;
    tipo: string;
    activo: boolean;
    secciones: SeccionModel[];

    constructor(){
        this.id = null;
        this.nombre = 'Nombre de la prueba';
        this.tipo = 'Tipo de prueba';
        this.activo = true;
        this.secciones = [];
    }

}

export class SeccionModel{

    id:number;
    numeroSeccion:number;
    tiempoSegundos:number;
    instrucciones: string;
    activo: boolean;
    preguntas: PreguntaModel[];

    constructor() {
        this.id = null;
        this.numeroSeccion = 1;
        this.tiempoSegundos = 0;
        this.instrucciones = '';
        this.preguntas = [];
    }

}

export class PreguntaModel{

    id: number;
    numero:number;
    pregunta: string;
    respuesta: string;
    tipo:number;
    activo: boolean;
    opciones: OpcionModel[];

    constructor(){
        this.numero = 0; 
        this.pregunta = '';
        this.tipo = 0;
        this.opciones = [];
    }

}

export class OpcionModel{
    
    opcion:string;

}
<!--===================================-->
<!--=== Modal donde se ve la imagen ===-->
<!--===================================-->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="Visor de imagen" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border border-1 border-white" style="background-color: rgba(0, 0, 0, 0);">
            <div class="modal-header">
                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <img [src]="imgSelect" class="img-fluid" data-bs-toggle="modal" data-bs-target="#exampleModal"/>
        </div>
    </div>
</div>
<!--===================================-->
<!--======= Inyeccion de NavBar =======-->
<!--===================================-->
<app-navbar></app-navbar>
<!--===================================-->
<!--======= Página candidato ==========-->
<!--===================================-->
<div class="container p-0 mb-4">
    <!--===================================-->
    <!--===== Notificaciones (Alerts) =====-->
    <!--===================================-->
    <div class="col-12 col-md-6 col-lg-4 position-fixed top-0 end-0 mt-5 p-4" style="z-index: 5;">
        <div class="alert alert-success alert-dismissible fade show mb-1" role="alert" *ngIf="numeroHabilidadesImportantes > 0">
            Candidato con {{numeroHabilidadesImportantes}} habilidades importantes
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div class="alert alert-primary alert-dismissible fade show mb-1" role="alert" *ngIf="numeroHabilidades > 0">
            Candidato con {{numeroHabilidades}} habilidades requeridas
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
    <!--===================================-->
    <!--===== Tarjeta Nombre e Imagen =====-->
    <!--===================================-->
    <div class="row w-100 p-0 m-0">
        <div class="card p-0" >
            <!--===== Imagen tipo portada =========-->
           <div class="card-img-top" 
                style="background-image: url(../../../../assets/img/fondo-candidatos.png); height: 200px;">
            </div>
            <!--===== Icono foto de perfil =========-->
            <div class="mx-md-4 mx-auto d-flex justify-content-center align-items-center icon-foto bg-secondary border border-2 border-white shadow-sm" style="height: 150px; width: 150px; margin-top: -75px; border-radius: 75px;">
                    <i class="fas fa-user-alt fa-6x text-white"></i>
            </div>
            <!--===== Datos del usuario ============-->
            <div class="card-body">
                <div class="row w-100">
                    <div class="col-12 col-md-5 px-md-4">
                        <h4 class="mx-auto text-center text-md-start">{{usuario.nombre+' '+(usuario.apellidoPaterno|undefined)+' '+(usuario.apellidoMaterno|undefined)}}</h4>
                        <p class="card-text mb-1 text-center text-md-start"><i class="fas fa-map-marker-alt me-2"></i>{{usuario.direccion.municipio+', '+usuario.direccion.estado}}</p>
                        <p class="card-text mb-1 text-center text-md-start"><i class="fas fa-birthday-cake me-2"></i>{{usuario.fechaNacimiento | date}}</p>
                        <span [ngSwitch]="usuario.sexo">
                        <p class="card-text mb-1 text-center text-md-start" *ngSwitchCase="'H'">
                            <i class="fas fa-mars me-2"></i>Hombre
                        </p>
                        <p class="card-text mb-1 text-center text-md-start" *ngSwitchCase="'M'">
                            <i class="fas fa-venus me-2"></i>Mujer
                        </p>
                        <p class="card-text mb-1 text-center text-md-start" *ngSwitchDefault>
                            <i class="fas fa-venus-mars me-2"></i>Otro
                        </p>
                        </span>
                    </div>
                    <div class="col-12 col-md-7 mt-4 mt-md-0">
                        <h6 class="mx-auto text-center text-md-start">Descripción del candidato</h6>
                        <p class="card-text mb-0">{{usuario.descripcion}}</p>
                        <ul class="list-group my-2" *ngIf="showHistorialDescripcion">
                            <li class="list-group-item" *ngFor="let descripcion of historialDescripcion" >{{descripcion}}</li>
                        </ul>
                        <button class="btn btn-outline-secondary btn-sm float-end" type="button" *ngIf="historialDescripcion.length > 0 && !showHistorialDescripcion"
                            (click)="showHistorialDescripcion = true"><i class="fas fa-history me-2"></i>Ver historial de decripciones</button>
                        <button class="btn btn-outline-secondary btn-sm float-end" type="button" *ngIf="showHistorialDescripcion"
                            (click)="showHistorialDescripcion = false"><i class="fas fa-eye-slash me-2"></i>Ocultar historial de decripciones</button>
                    </div>
                </div>
            </div>
          </div>
    </div>

    <div class="row w-100 p-0 m-0">
        <div class="col-12 col-md-8 mt-1 mx-0 p-0">
            <div class="card">
                <div class="card-body">
                    <h5 >Educación</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex align-items-center mt-2" *ngFor="let estudio of usuario.estudios">
                            <i class="fas fa-graduation-cap fa-2x me-4"></i> 
                            <div class="d-flex flex-column">
                                <h6 class="card-title">{{estudio.escuela}}</h6>
                                <p class="card-text" *ngIf="estudio.titulo != 'NO APLICA'">{{estudio.nivel+' - '+estudio.titulo}}</p>
                                <p class="card-text" *ngIf="estudio.titulo === 'NO APLICA'">{{estudio.nivel}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mt-1">
                <div class="card-body">
                    <h5>Porqué el candidato es ideal para el puesto</h5>
                    <p class="card-text mb-0">
                        {{usuario.razonAplicacion}}
                    </p>
                    <ul class="list-group my-2" *ngIf="showHistorialRazon">
                        <li class="list-group-item" *ngFor="let razon of histoialRazon" >{{razon}}</li>
                    </ul>
                    <button class="btn btn-outline-secondary btn-sm float-end" type="button" *ngIf="histoialRazon.length > 0 && !showHistorialRazon"
                        (click)="showHistorialRazon = true"><i class="fas fa-history me-2"></i>Ver historial</button>
                    <button class="btn btn-outline-secondary btn-sm float-end" type="button" *ngIf="showHistorialRazon"
                        (click)="showHistorialRazon = false"><i class="fas fa-eye-slash me-2"></i>Ocultar historial</button>
                </div>
            </div>
            <div class="card mt-1">
                <div class="card-body">
                    <h5 >Experiencia</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex align-items-center mt-2" *ngFor="let trabajo of usuario.experienciasLaborales">
                            <i class="fas fa-briefcase fa-2x me-4"></i>
                            <div class="row w-100 align-items-center">
                                <div class="d-flex flex-column">
                                    <div class="d-flex">
                                        <h6 class="card-title">{{trabajo.puesto}}</h6>
                                        <p class="card-text ms-auto d-none d-lg-block" *ngIf="trabajo.actual">{{trabajo.desde|date:'mediumDate'}} - Actualidad</p>
                                        <p class="card-text ms-auto d-none d-lg-block" *ngIf="!trabajo.actual">{{trabajo.desde|date:'mediumDate'}} - {{trabajo.hasta|date:'mediumDate'}}</p>
                                    </div>
                                    <div class="d-flex">
                                        <p class="card-text fst-italic mb-1">{{trabajo.empresa}}</p>
                                        <p class="card-text ms-auto d-none d-lg-block mb-1">{{trabajo.sueldoInicial|currency}} - {{trabajo.sueldoFinal|currency}}</p>
                                    </div>
                                    <p class="card-text d-block d-lg-none mb-1" *ngIf="trabajo.actual"><i class="fas fa-calendar-alt me-2"></i>{{trabajo.desde|date:'mediumDate'}} - Actualidad</p>
                                    <p class="card-text d-block d-lg-none mb-1" *ngIf="!trabajo.actual"><i class="fas fa-calendar-alt me-2"></i>{{trabajo.desde|date:'mediumDate'}} - {{trabajo.hasta|date:'mediumDate'}}</p>
                                    <p class="card-text mb-1 "><i class="fas fa-map-marker-alt me-2"></i>{{trabajo.municipio+', '+trabajo.estado}}</p>
                                    <p class="card-text d-block d-lg-none mb-1"><i class="fas fa-dollar-sign me-2"></i>{{trabajo.sueldoInicial|currency}} - {{trabajo.sueldoFinal|currency}}</p>
                                    <p class="card-text"><strong>Descripción</strong><br>{{trabajo.descripcion}}</p>
                                    <p class="card-text"><strong>Problema al que se enfrentó</strong><br>{{trabajo.descripcionProblema}}</p>
                                    <p class="card-text"><strong>Solución del problema</strong><br>{{trabajo.solucionProblema}}</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card mt-1" [ngClass]="{'d-none': resultado === undefined || resultado === null}">
                <div class="card-body">
                    <h5 class="mb-3">Test de Terman</h5>
                    <canvas baseChart height="100"
                            [datasets]="lineChartData"
                            [labels]="lineChartLabels"
                            [options]="lineChartOptions"
                            [colors]="lineChartColors"
                            [legend]="lineChartLegend"
                            [chartType]="lineChartType">
                    </canvas>
                    <div class="w-100 mt-3 mb-0 d-flex align-items-center justify-content-center text-center contenedor">
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq > 70}">{{iq | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-white text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;" [ngClass]="{'bg-danger ': iq <= 70}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">deficiente</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 70 || iq > 79}">{{iq | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;" [ngClass]="{'bg-danger ': iq > 70 && iq <=79}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">Bajo</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 79 || iq > 89}">{{iq | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;" [ngClass]="{'bg-danger ': iq > 79 && iq <=89}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">A.T.M.</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 89 || iq > 110}">{{iq | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;" [ngClass]="{'bg-danger ': iq > 89 && iq <=110}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">T.M.B</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 110 || iq > 119}">{{iq | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;" [ngClass]="{'bg-danger ': iq > 110 && iq <=119}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">Normal</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 119 || iq > 125}">{{iq | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;" [ngClass]="{'bg-danger ': iq > 119 && iq <=125}">.</div>
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                            </div>
                            <p style="font-size: 10px;">Superior</p>
                        </div>
                        <div>
                            <p style="font-size: 10px;" class="mb-0" [ngClass]="{'text-white': iq < 125}">{{iq | number: '1.0-0'}}</p>
                            <div class="d-flex d-flex align-items-center">
                                <div class="bg-secondary text-white" style="width: 20px; height: 5px;">.</div>
                                <div class="bg-secondary text-white" style="width: 15px; height: 15px;" [ngClass]="{'bg-danger ': iq > 125}">.</div>
                            </div>
                            <p style="font-size: 10px;">Sobresaliente</p>
                        </div>
                    </div>

                    <div class="w-100 mx-auto contenedor" *ngIf="resultado != null">
                       <table class="table table-secondary text-center" style="font-size: 12px;">
                        <thead>
                          <tr> 
                            <th scope="col p-1">Puntaje</th>
                            <th scope="col">CI Terman</th>
                            <th scope="col">Interpretación CI</th>
                            <th scope="col">Capacidad de aprendizaje</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{resultado.calificacion}}</td>
                            <td>{{iq | number: '1.0-0'}}</td>
                            <ng-container *ngIf="iq <= 70">
                                <td>Deficiente</td>
                                <td>Deficiente</td>
                            </ng-container>
                            <ng-container *ngIf="iq > 70 && iq <=79">
                                <td>Inferior</td>
                                <td>Bajo</td>
                            </ng-container>
                            <ng-container *ngIf="iq > 79 && iq <=89">
                                <td>Inferior al termino medio</td>
                                <td>Término Medio Bajo</td>
                            </ng-container>
                            <ng-container *ngIf="iq > 89 && iq <=110">
                                <td>Termino medio bajo</td>
                                <td>Término Medio</td>
                            </ng-container>
                            <ng-container *ngIf="iq > 110 && iq <=119">
                                <td>Normal</td>
                                <td>Termino medio alto</td>
                            </ng-container>
                            <ng-container *ngIf="iq > 119 && iq <=125">
                                <td>Sobresaliente</td>
                                <td>Superior</td>
                            </ng-container>
                            <ng-container *ngIf="iq > 125">
                                <td>Sobresaliente</td>
                                <td>Excelente</td>
                            </ng-container>
                          </tr>
                        </tbody>
                      </table> 
                    </div>
                    <div class="d-grid gap-2">
                        <button class="btn btn-outline-secondary btn-sm float-end mt-3" (click)="verDetallesTerman = true" *ngIf="!verDetallesTerman"><i class="fas fa-search-plus me-2"></i>Ver detalles</button>
                    </div>
                    
                    <div class="accordion mt-3" id="acordionPadre" *ngIf="verDetallesTerman">
                        <div class="accordion-item" *ngFor="let seccion of resultado.secciones; let i=index">
                            <h2 class="accordion-header" id="headingSeccion{{seccion.numeroSeccion}}">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#seccion' + seccion.numeroSeccion" aria-expanded="false" >
                                Sección {{seccion.numeroSeccion}}
                              </button>
                            </h2>
                            <div id="seccion{{seccion.numeroSeccion}}"  class="accordion-collapse collapse"  [attr.aria-labelledby]="'headingSeccion'+seccion.numeroSeccion" data-bs-parent="#acordionPadre">
                              <div class="accordion-body" id="acordionSeccion{{seccion.numeroSeccion}}">
                                <strong>Calificación de la sección: {{seccion.calificacion}}</strong>
                                <div class="accordion mt-3" id="accordionExample">
                                    <div class="accordion-item" *ngFor="let pregunta of seccion.preguntas">
                                      <h2 class="accordion-header" id="headingPregunta{{pregunta.numeroPregunta}}">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#pregunta' + pregunta.numeroPregunta" aria-expanded="false"
                                         [ngStyle]="{ border: pregunta.correcta ? '2px solid #5cb85c' : '2px solid #f44336' }">
                                            <i class="fas fa-check-circle me-2 " style="color: #5cb85c" *ngIf="pregunta.correcta"></i> 
                                            <i class="fas fa-times-circle me-2 " style="color: #f44336" *ngIf="!pregunta.correcta"></i>
                                            Pregunta {{pregunta.numeroPregunta}}
                                        </button>
                                      </h2>
                                      <div id="pregunta{{pregunta.numeroPregunta}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingPregunta'+pregunta.numeroPregunta" [attr.data-bs-parent]="'#acordionSeccion' + seccion.numeroSeccion">
                                        <div class="accordion-body">
                                            <strong>Intentos</strong>
                                            <ul class="list-group mt-2">
                                                <li class="list-group-item disabled" aria-disabled="true" *ngFor="let intento of pregunta.intentos">{{intento.respuesta}}</li>
                                            </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 mt-1 mx-0 p-0 ps-md-1">
            <div class="card">
                <div class="card-body">
                    <h5 >Sueldo pretedido por puesto</h5>
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between" *ngFor="let postulacion of usuario.postulaciones2">
                            <p class="p-0 m-0">{{getEmpleo(postulacion.idEmpleo)}}</p>
                            <p class="p-0 m-0">{{postulacion.sueldoPretendido | currency}}</p>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 >Información de contacto</h5>
                    <p class="card-text mb-1 text-center text-md-start"><i class="fas fa-envelope me-2"></i>{{usuario.correos[0].correo}}</p>
                    <p class="card-text mb-1 text-center text-md-start"><i class="fas fa-phone me-2"></i>{{usuario.telefonos[0].numero}}</p>
                </div>
            </div>
            <div class="card mt-1 ">
                <div class="card-body">
                    <h5>Datos personales</h5>
                    <p class="card-text mb-1 text-center text-md-start"><i class="fas fa-ring me-2"></i>{{usuario.estadoCivil.nombre}}</p>
                    <p class="card-text mb-1 text-center text-md-start"><i class="fas fa-home me-2"></i>{{usuario.viveCon}}</p>
                    <h6 class="text-center text-md-start my-3" *ngIf="usuario.dependeConyuge||usuario.dependenHijos || usuario.dependenOtros || usuario.dependenPadres">Personas dependientes</h6>
                    <ul class="list-group">
                        <li class="list-group-item" *ngIf="usuario.dependenPadres">Padres</li>
                        <li class="list-group-item" *ngIf="usuario.dependeConyuge">Conyugue</li>
                        <li class="list-group-item" *ngIf="usuario.dependenHijos">Hijos</li>
                        <li class="list-group-item" *ngIf="usuario.dependenOtros">Otros</li>
                    </ul>
                </div>
            </div>
            <div class="card mt-1 ">
                <div class="card-body row w-100 m-0">
                    <h5>Palabras encontradas</h5>
                    <ul class="list-group">
                        <ng-container *ngFor="let habilidad of usuario.habilidades">
                            <ng-container *ngIf="!habilidad.habilidad.idioma">
                                <li class="list-group-item" >
                                    {{habilidad.habilidad.habilidad}}
                                </li>
                            </ng-container>
                        </ng-container>
                        
                      </ul>
                </div>
            </div>
            <div class="card mt-1 ">
                <div class="card-body row w-100 m-0">
                    <h5>Idiomas</h5>
                    <ul class="list-group">
                        <ng-container *ngFor="let habilidad of usuario.habilidades">
                            <ng-container *ngIf="habilidad.habilidad.idioma">
                                <li class="list-group-item d-flex justify-content-between">
                                    <p class="p-0 m-0">{{habilidad.habilidad.habilidad}}</p>
                                    <p class="p-0 m-0">{{habilidad.porcentaje + ' %'}}</p>
                                </li>
                            </ng-container>
                        </ng-container>
                        
                      </ul>
                </div>
            </div>
            <div class="card mt-1 " *ngIf="resultado != undefined && resultado != null">
                <div class="card-body row w-100 m-0">
                    <h5>Imagenes Terman</h5>
                    <div class="col-4 m-0 p-1 text-center" *ngFor="let image of images">
                        <img [src]="image" class="img-fluid img" (click)="imgSelect = image" data-bs-toggle="modal" data-bs-target="#exampleModal"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
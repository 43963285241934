<!--========================================= Código HTML del Login ===============================================-->

<!--===============================================================================================================-->
<!-- Contenedor del fondo que abarca toda la pantalla y centra en horizontal ======================================-->
<!--===============================================================================================================-->
<div class="d-flex fondo-login justify-content-center align-items-center">
    
    <!--===============================================================================================================-->
    <!-- Contenedor que hace adaptable a dispositivos móviles =========================================================-->
    <!--===============================================================================================================-->
    <div class="container h-100"
         [ngClass]="{'h-100':esMovil===1}">
        <div class="row d-flex justify-content-center"
             [ngClass]="{'h-100':esMovil===1}">
            <!--===============================================================================================================-->
            <!-- Formulario de Registro =======================================================================================-->
            <!--===============================================================================================================-->                  
            <div *ngIf="!loginVisible" 
                 class="col-12 col-md-8 col-lg-5 bg-white d-flex flex-column pb-3 h-100 justify-content-center py-3 shadow rounded">
                <!-- ======================= Logotipo TruemedGroup ====================== -->
                <img class="img-fluid align-self-center m-2" 
                     style="width: 250px;" 
                     src="../../../assets/img/TruemedGroupLogo.jpeg">
                <h4 class="ms-4 mt-3">REGISTRARSE</h4>
                <form class="mt-3 mb-0 mx-4" 
                      [formGroup]="formularioRegistro"
                      (ngSubmit)="registrarUsuario()">

                    <input  type="text" placeholder="Nombre"
                            class="form-control form-control-sm"
                            [ngClass]="{'mb-3': !campoRegistroNoValido('nombre')}"
                            id="nombre-registro" 
                            formControlName="nombre"
                            [class.is-invalid]="campoRegistroNoValido('nombre')">
                    <small *ngIf="campoRegistroNoValido('nombre')" class="text-danger">Por favor ingrese nombre completo</small>
                    <input  type="email" placeholder="Correo electrónico"
                            class="form-control form-control-sm" 
                            [ngClass]="{'mb-3': !campoRegistroNoValido('correo')}"
                            id="correo-registro" 
                            formControlName="correo"
                            [class.is-invalid]="campoRegistroNoValido('correo')">
                    <small *ngIf="campoRegistroNoValido('correo')" class="text-danger">Ingrese correo valido</small>
                    <input  type="number" placeholder="Telefono"
                            class="form-control form-control-sm" 
                            [ngClass]="{'mb-3': !campoRegistroNoValido('telefono')}"
                            id="telefono-registro" 
                            formControlName="telefono"
                            [class.is-invalid]="campoRegistroNoValido('telefono')">
                    <small *ngIf="campoRegistroNoValido('telefono')" class="text-danger">Ingrese un número válido (10 dígitos)</small>
                    <input  type="password" placeholder="Contraseña"
                            class="form-control form-control-sm" 
                            [ngClass]="{'mb-3': !campoRegistroNoValido('password')}"
                            id="password-registro" 
                            formControlName="password"
                            [class.is-invalid]="campoRegistroNoValido('password')"
                            autocomplete="on">
                    <small *ngIf="campoRegistroNoValido('password')" class="text-danger">Al menos 8 caracteres </small>
                    <input  type="password" placeholder="Confirmar contraseña"
                            class="form-control form-control-sm" 
                            [ngClass]="{'mb-4': !campoRegistroNoValido('confirm')}"
                            id="confirmar-password-registro" 
                            formControlName="confirm"
                            [class.is-invalid]="campoRegistroNoValido('confirm')"
                            autocomplete="on">
                    <small *ngIf="formularioRegistro.controls.confirm.invalid &&  formularioRegistro.controls.confirm.touched" class="text-danger">
                        Los passwords no coinciden </small>
                        <br *ngIf="formularioRegistro.controls.confirm.invalid &&  formularioRegistro.controls.confirm.touched">
                        <br *ngIf="formularioRegistro.controls.confirm.invalid &&  formularioRegistro.controls.confirm.touched">
                    <button type="submit" class="btn btn-primary btn-sm bg-tm float-end mb-0 w-100">Registrarse</button>
                </form>
                <div class="d-flex mt-2 align-items-center justify-content-between mx-2 text-secondary">
                    <hr class="border border-dark" style="width: 46%;">
                        O
                    <hr class="border border-dark" style="width: 46%;">
                </div>
                <div class="d-grid gap-2 mx-2 mt-2 mb-2">
                    <button class="btn btn-primary  fs-6 d-flex align-items-center " type="button"
                        style="background-color: #3B5A94;" (click)="signInWithFB()"><i class="fab fa-facebook-square fs-4 me-2"></i> Registarse con facebook</button>
                </div>
                <p class="mx-auto mt-2">¿Ya tienes cuenta? 
                    <a class="link-primary text-decoration-none"
                       (click)="loginVisible = true" >
                        <strong>Iniciar Sesión</strong> 
                    </a> 
                </p>  
            </div>
            <!--===============================================================================================================-->
            <!-- Formulario de Login =======================================================================================-->
            <!--===============================================================================================================-->    
            <div *ngIf="loginVisible" class="col-12 col-md-8 col-lg-5 bg-white p-4 px-5 shadow rounded">
                <div class="w-100 d-flex flex-column justify-content-center " style="height: 100%;">
                    <div class="ps-4 d-flex flex-column justify-content-center" style="height: 80%;">
                        <img class="img-fluid align-self-center m-2" 
                             style="width: 250px;" 
                             src="../../../assets/img/TruemedGroupLogo.jpeg">
                        <h4>Iniciar Sesión</h4>
                        <div class="d-grid gap-2 mx-2 mt-3">
                            <button class="btn btn-primary  fs-6 d-flex align-items-center " type="button"
                                style="background-color: #3B5A94;" (click)="signInWithFB()"><i class="fab fa-facebook-square fs-4 me-2"></i> Inicie sesión con facebook</button>
                        </div>
                        <div class="d-flex mt-3 align-items-center justify-content-between mx-2 text-secondary">
                            <hr class="border border-dark" style="width: 46%;">
                            O
                            <hr class="border border-dark" style="width: 46%;">
                        </div>
                        <form class="my-3 mx-2"
                              [formGroup]="formularioLogin"
                              (ngSubmit)="iniciarSesion()">

                            <input  type="email" placeholder="Correo electrónico"
                                    class="form-control mb-3" 
                                    id="correo-login" 
                                    formControlName="correo"
                                    autocomplete="on">
                            <div class="input-group mb-3">
                                <input  [type]="tipoBotonPassword" placeholder="Contraseña"
                                        class="form-control" 
                                        id="password-login" 
                                        formControlName="password"
                                        autocomplete="on">
                                <button class="btn btn-outline-secondary" type="button" style="border: solid 1px lightgray;" (click)="cambiarInputPassword()"><i class="far" [ngClass]="{'fa-eye': tipoBotonPassword==='password', 'fa-eye-slash': tipoBotonPassword!='password'}"></i></button>
                            </div>
                                <button type="submit" class="btn btn-primary bg-tm float-end w-100">Iniciar Sesión</button>
                        </form>
                        <p class="mx-auto">
                            <a class="link-primary text-decoration-none"
                            (click)="recuperacionPassword()">
                                ¿Olvdaste tu contraseña?
                            </a> 
                        </p>  
                        <p class="mx-auto">¿No tienes cuenta? 
                            <a class="link-primary text-decoration-none"
                            (click)="loginVisible = false">
                               <strong>Registrate</strong> 
                            </a> 
                        </p>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';


interface ErrorValidate {
  [s: string]: boolean
}


@Injectable({
  providedIn: 'root'
})
export class ValidacionesService {

  constructor(private http: HttpClient) {

  }

  numero10Digitos(control: FormControl): ErrorValidate {

    if (control.value) {

      if (control.value.toString().length === 10) {

        return null;

      } else {
        return {

          longitudTelefono: true

        }
      }

    } else {
      return null;
    }

  }

  numeroNo0(control: FormControl): ErrorValidate {

    if (control.value) {

      if (control.value > 999999999) {

        return null;

      } else {
        return {

          telefono0: true

        }
      }

    } else {
      return {

        telefono0: true

      }
    }

  }

  passwordsIguales(pass1: string, pass2: string) {

    return (formGroup: FormGroup) => {

      const pass1Control = formGroup.get(pass1)
      const pass2Control = formGroup.get(pass2)

      if (pass1Control.value === pass2Control.value) {

        if (pass1Control.value.length >= 8) {
          pass2Control.setErrors(null);
        } else {
          pass2Control.setErrors({
            cadenaNoIgual: true
          });
        }

      } else {

        pass2Control.setErrors({
          cadenaNoIgual: true
        });

      }

    }

  }

}

<div class="d-flex contenedor w-100 overflow-scroll " [ngStyle]="{'max-width': anchoScreen+'px'}">
    <ng-container *ngIf="size === 'medium'; else elseTemplate">
        <div class="d-flex flex-column align-items-center" style="width: 140px" *ngFor="let icon of icons; let i=index">
            <div class="d-flex w-100 align-items-center " [ngClass]="{'justify-content-end': (i < icons.length-1)}">
                <div class="bg-light" style="width: 50px; height: 10px;" *ngIf="i != 0"></div>
                <div>
                    <div *ngIf="icon === 'none'" class="d-inlines rounded-circle w-auto bg-light m-0 p-1 d-flex align-items-center justify-content-center"><i class="fas fa-circle fa-2x"></i></div>
                    <div *ngIf="icon === 'none-dark'" class="d-inlines rounded-circle w-auto bg-secondary text-secondary m-0 p-1 d-flex align-items-center justify-content-center"><i class="fas fa-circle fa-2x"></i></div>
                    <div *ngIf="icon === 'here'" class="d-inlines rounded-circle w-auto bg-secondary text-white m-0 p-1 d-flex align-items-center justify-content-center"><i class="far fa-arrow-alt-circle-down fa-2x"></i></div>
                    <div *ngIf="icon === 'loading'" class="d-inlines rounded-circle w-auto bg-light text-white m-0 p-1 d-flex align-items-center justify-content-center"> <i class="fas fa-spinner fa-2x"></i></div>
                    <div *ngIf="icon === 'loading-dark'" class="d-inlines rounded-circle w-auto bg-secondary text-white m-0 p-1 d-flex align-items-center justify-content-center"> <i class="fas fa-spinner fa-spin fa-2x"></i></div>
                    <div *ngIf="icon === 'success'" class="d-inlines rounded-circle w-auto bg-success text-white m-0 p-1 d-flex align-items-center justify-content-center"> <i class="far fa-check-circle fa-2x"></i></div>
                    <div *ngIf="icon === 'error'" class="d-inlines rounded-circle w-auto bg-danger text-white m-0 p-1 d-flex align-items-center justify-content-center"> <i class="far fa-times-circle fa-2x"></i></div>
                </div>
                <div class="bg-light" style="width: 50px; height: 10px;" *ngIf="i < icons.length-1"></div> 
            </div>
            <p class="text-center mb-0">{{titulos[i]}}</p>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="d-flex flex-column align-items-center" style="width: 80px" *ngFor="let icon of icons; let i=index">
            <div class="d-flex w-100 align-items-center " [ngClass]="{'justify-content-end': (i < icons.length-1)}">
                <div class="bg-light" style="width: 50px; height: 10px;" *ngIf="i != 0"></div>
                <div>
                    <div *ngIf="icon === 'none'" class="d-inlines rounded-circle w-auto bg-light m-0 p-1 d-flex align-items-center justify-content-center"><i class="fas fa-circle"></i></div>
                    <div *ngIf="icon === 'none-dark'" class="d-inlines rounded-circle w-auto bg-secondary text-secondary m-0 p-1 d-flex align-items-center justify-content-center"><i class="fas fa-circle"></i></div>
                    <div *ngIf="icon === 'here'" class="d-inlines rounded-circle w-auto bg-secondary text-white m-0 p-1 d-flex align-items-center justify-content-center"><i class="far fa-arrow-alt-circle-down"></i></div>
                    <div *ngIf="icon === 'loading'" class="d-inlines rounded-circle w-auto bg-light text-white m-0 p-1 d-flex align-items-center justify-content-center"> <i class="fas fa-spinner"></i></div>
                    <div *ngIf="icon === 'loading-dark'" class="d-inlines rounded-circle w-auto bg-secondary text-white m-0 p-1 d-flex align-items-center justify-content-center"> <i class="fas fa-spinner fa-spin"></i></div>
                    <div *ngIf="icon === 'success'" class="d-inlines rounded-circle w-auto bg-success text-white m-0 p-1 d-flex align-items-center justify-content-center"> <i class="far fa-check-circle"></i></div>
                    <div *ngIf="icon === 'error'" class="d-inlines rounded-circle w-auto bg-danger text-white m-0 p-1 d-flex align-items-center justify-content-center"> <i class="far fa-times-circle"></i></div>
                </div>
                <div class="bg-light" style="width: 50px; height: 10px;" *ngIf="i < icons.length-1"></div> 
            </div>
            <p class="text-center mb-0" style="font-size: 12px;">{{titulos[i]}}</p>
        </div>
    </ng-template>
    
    
</div>


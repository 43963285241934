import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { UsuarioPruebaModel } from '../models/resultados.model';
import { PruebaModel } from '../models/test.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  //private urlBase: string = 'http://localhost:8090/usuarios'
  private urlBase: string = environment.urlApi+'/usuarios'

  constructor(private authService: AuthService,private http:HttpClient, private router: Router) {}

  getTest(): Observable<PruebaModel> {

    const urlEndpoint = this.urlBase + '/pruebas/1';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<PruebaModel>(urlEndpoint,{headers: httpHeaders});
    
  }

  getTestIngles(): Observable<PruebaModel> {

    const urlEndpoint = this.urlBase + '/pruebas/1002';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<PruebaModel>(urlEndpoint,{headers: httpHeaders});
    
  }

  postResultado(resultado: UsuarioPruebaModel): void {
    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();
    const urlEndpoint = this.urlBase + '/resultados';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    this.http.post<UsuarioPruebaModel>(urlEndpoint,resultado,{headers: httpHeaders}).subscribe(
      res =>{
        Swal.fire({
          icon: 'success',
          text: 'Prueba finalizada, en breve nos pondremos en contacto contigo via correo electrónico o teléfono',
          allowOutsideClick: false
        }).then((result) => {
          this.router.navigate([ 'postulaciones' ]);
      });
      }, err => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'ocurrio un error'
        });
        console.log(err);
      }
    );
    
  }

}

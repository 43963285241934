import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { EmpleosService } from 'src/app/services/empleos.service';
import { PostulacionesService } from 'src/app/services/postulaciones.service';
import { PruebaService } from 'src/app/services/prueba.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {


  altoScreen: number = screen.height - 165;
  anchoScreen: number = screen.width;

  soloVer: boolean = false;

  hombres: boolean = false;
  mujeres: boolean = false;
  otros: boolean = false;

  mostrarMenu: boolean = true;
  vacantes: any[];

  postulaciones: any[];

  postulacionesEntrevistas: any[];

  POSTULACIONES_ENTREVISTAS: any[];

  POSTULACIONES_DESCARTADAS: any[];

  postulacionesDescartadas: any[];

  postulacionesActuales: any[] = [];

  habilidades: string[] = [];
  trabajos: string[] = [];
  filtroHabilidades: string[] = [];
  filtroTrabajos: string[] = [];

  vacanteSeleccionada: string = '';
  vacanteActual: any;
  ordenamiento: number = 0;

  config: ConfigDashboard;

  archivadas: Boolean = false;
  idVacanteArchivadda : number;

  constructor(private adminService: AdminService, private router: Router, private empleosService: EmpleosService,
    private activeRoute: ActivatedRoute, private pruebaService: PruebaService, private postulacionesService: PostulacionesService) {



    if (localStorage.getItem('ConfigDashboard'))
      this.config = JSON.parse(localStorage.getItem('ConfigDashboard'));
    else
      this.config = new ConfigDashboard();

    if (activeRoute.snapshot.params.empleo) {
      this.config.vacante = activeRoute.snapshot.params.empleo
      this.config.idVacante = activeRoute.snapshot.params.id
      this.soloVer = true;
      this.archivadas = true
      this.idVacanteArchivadda = this.config.idVacante
    }

  }

  ngOnDestroy(): void {

    console.log("Vacante Seleccionada")
    console.log(this.vacanteSeleccionada)
    console.log(this.vacanteActual)

    this.config.hombres = this.hombres;
    this.config.mujeres = this.mujeres;
    this.config.otros = this.otros;
    this.config.vacante = this.vacanteSeleccionada;
    this.config.trabajos = this.filtroTrabajos;
    this.config.habilidades = this.filtroHabilidades;
    this.config.mostrarMenu = this.mostrarMenu;

    if (this.vacanteActual)
      this.config.idVacante = this.vacanteActual.id;
    else {
      if (this.vacantes && this.vacantes.length>0 ){
          this.config.idVacante = this.vacantes[0].vacante.id
          this.config.vacante = this.vacantes[0].vacante.puesto
      }else{
        //console.log("Infefinido")
      }
    }
    localStorage.setItem('ConfigDashboard', JSON.stringify(this.config));
  }

  ngOnInit(): void {
    
    if (this.config)
      this.mostrarMenu = this.config.mostrarMenu;

    if (!this.config.sueldoMaximo)
      this.config.sueldoMaximo = '500000';
    if (!this.config.sueldoMinimo)
      this.config.sueldoMinimo = '0';
    if (!this.config.revisadas)
      this.config.revisadas = false;
    if (!this.config.noRevisadas)
      this.config.noRevisadas = false;
    if (!this.config.sinTest)
      this.config.sinTest = false;

    if(!this.archivadas){
      // Es el normally
      //console.log("Es una consulta normal");
      this.adminService.getDashboardInfo().subscribe(res => { console.log(res)
        this.vacantes = res.vacantes;
        this.vacanteSeleccionada = this.vacantes[0].vacante.puesto.toLocaleLowerCase();
        res.vacantes.forEach(vacante => {
          if (vacante.vacante.id === this.config.idVacante) {
            this.vacanteActual = vacante.vacante;
            return;
          }
        })
        res.postulaciones.sort((a, b) => b.sueldoPretendido - a.sueldoPretendido);
        this.postulaciones = res.postulaciones
        this.postulaciones.forEach(postulacion => {
          if (postulacion.terman != null) {
            let cal: number = postulacion.terman;
  
            if (cal <= 48) {
              cal = cal + 88;
            } else if (cal <= 60) {
              cal = cal + 87;
            } else if (cal <= 68) {
              cal = cal + 86;
            } else if (cal <= 73) {
              cal = cal + 85;
            } else if (cal <= 77) {
              cal = cal + 84;
            } else if (cal <= 79) {
              cal = cal + 83;
            } else if (cal <= 84) {
              cal = cal + 82;
            } else if (cal <= 87) {
              cal = cal + 81;
            } else if (cal <= 89) {
              cal = cal + 80;
            } else if (cal <= 92) {
              cal = cal + 79;
            } else if (cal <= 95) {
              cal = cal + 78;
            } else if (cal <= 98) {
              cal = cal + 77;
            } else if (cal <= 101) {
              cal = cal + 76;
            } else if (cal <= 107) {
              cal = cal + 75;
            } else if (cal <= 109) {
              cal = cal + 74;
            } else if (cal <= 112) {
              cal = cal + 73;
            } else if (cal <= 114) {
              cal = cal + 72;
            } else if (cal <= 118) {
              cal = cal + 71;
            } else if (cal <= 120) {
              cal = cal + 70;
            } else if (cal <= 122) {
              cal = cal + 69;
            } else if (cal <= 124) {
              cal = cal + 68;
            } else if (cal <= 126) {
              cal = cal + 67;
            } else if (cal <= 128) {
              cal = cal + 66;
            } else if (cal <= 130) {
              cal = cal + 65;
            } else if (cal <= 132) {
              cal = cal + 64;
            } else if (cal <= 134) {
              cal = cal + 63;
            } else if (cal <= 136) {
              cal = cal + 62;
            } else if (cal <= 138) {
              cal = cal + 61;
            } else if (cal <= 140) {
              cal = cal + 60;
            } else if (cal <= 142) {
              cal = cal + 59;
            } else if (cal <= 144) {
              cal = cal + 58;
            } else if (cal <= 146) {
              cal = cal + 57;
            } else if (cal <= 148) {
              cal = cal + 56;
            } else if (cal <= 150) {
              cal = cal + 55;
            } else if (cal <= 152) {
              cal = cal + 54;
            } else if (cal <= 155) {
              cal = cal + 53;
            } else if (cal <= 157) {
              cal = cal + 52;
            } else if (cal <= 160) {
              cal = cal + 51;
            } else if (cal <= 163) {
              cal = cal + 50;
            } else if (cal <= 165) {
              cal = cal + 49;
            } else if (cal <= 168) {
              cal = cal + 48;
            } else if (cal <= 172) {
              cal = cal + 47;
            } else if (cal <= 178) {
              cal = cal + 46;
            } else if (cal <= 181) {
              cal = cal + 45;
            } else if (cal <= 186) {
              cal = cal + 44;
            } else if (cal <= 193) {
              cal = cal + 43;
            } else if (cal <= 198) {
              cal = cal + 42;
            } else if (cal <= 202) {
              cal = cal + 41;
            } else {
              cal = cal + 40;
            }
            cal = (cal / 192) * 100;
            postulacion.terman = cal;
          }
        });
        this.POSTULACIONES_DESCARTADAS = this.postulaciones.filter(p => p.estatus === 3);
        this.POSTULACIONES_ENTREVISTAS = this.postulaciones.filter(p => p.estatus === 2);
        this.postulaciones = this.postulaciones.filter(p => p.estatus === 1 || p.estatus === 0);
        if (this.config.hombres)
          this.hombres = this.config.hombres
        if (this.config.mujeres)
          this.mujeres = this.config.mujeres
        if (this.config.otros)
          this.otros = this.config.otros
        if ((this.config.vacante.toLowerCase() != 'na' && this.vacantes.filter(v => v.vacante.puesto.toLowerCase() === this.config.vacante.toLowerCase()).length > 0) || this.soloVer)
          this.vacanteSeleccionada = this.config.vacante;
        this.ordenarPostulaciones(this.config.ordenamiento);
        if (this.config.trabajos != undefined && this.config.trabajos != null)
          this.filtroTrabajos = this.config.trabajos;
        if (this.config.habilidades != undefined && this.config.habilidades != null)
          this.filtroHabilidades = this.config.habilidades;
        //@ts-ignore
        document.getElementById('inputFiltroNombre').value = this.config.nombre;
        setTimeout(() => {
          this.seleccionarVacante(this.vacanteActual)
        }, 1000);
      });

    }else{
      // Viene desde las archivadas
      //console.log("Es una consulta de archivadas");
      this.adminService.getDashboardInfoArchivada(this.idVacanteArchivadda).subscribe(res => { console.log(res)
        this.vacantes = res.vacantes;
        this.vacanteSeleccionada = this.vacantes[0].vacante.puesto.toLocaleLowerCase();
        res.vacantes.forEach(vacante => {
          if (vacante.vacante.id === this.config.idVacante) {
            this.vacanteActual = vacante.vacante;
            return;
          }
        })
        res.postulaciones.sort((a, b) => b.sueldoPretendido - a.sueldoPretendido);
        this.postulaciones = res.postulaciones
        this.postulaciones.forEach(postulacion => {
          if (postulacion.terman != null) {
            let cal: number = postulacion.terman;
  
            if (cal <= 48) {
              cal = cal + 88;
            } else if (cal <= 60) {
              cal = cal + 87;
            } else if (cal <= 68) {
              cal = cal + 86;
            } else if (cal <= 73) {
              cal = cal + 85;
            } else if (cal <= 77) {
              cal = cal + 84;
            } else if (cal <= 79) {
              cal = cal + 83;
            } else if (cal <= 84) {
              cal = cal + 82;
            } else if (cal <= 87) {
              cal = cal + 81;
            } else if (cal <= 89) {
              cal = cal + 80;
            } else if (cal <= 92) {
              cal = cal + 79;
            } else if (cal <= 95) {
              cal = cal + 78;
            } else if (cal <= 98) {
              cal = cal + 77;
            } else if (cal <= 101) {
              cal = cal + 76;
            } else if (cal <= 107) {
              cal = cal + 75;
            } else if (cal <= 109) {
              cal = cal + 74;
            } else if (cal <= 112) {
              cal = cal + 73;
            } else if (cal <= 114) {
              cal = cal + 72;
            } else if (cal <= 118) {
              cal = cal + 71;
            } else if (cal <= 120) {
              cal = cal + 70;
            } else if (cal <= 122) {
              cal = cal + 69;
            } else if (cal <= 124) {
              cal = cal + 68;
            } else if (cal <= 126) {
              cal = cal + 67;
            } else if (cal <= 128) {
              cal = cal + 66;
            } else if (cal <= 130) {
              cal = cal + 65;
            } else if (cal <= 132) {
              cal = cal + 64;
            } else if (cal <= 134) {
              cal = cal + 63;
            } else if (cal <= 136) {
              cal = cal + 62;
            } else if (cal <= 138) {
              cal = cal + 61;
            } else if (cal <= 140) {
              cal = cal + 60;
            } else if (cal <= 142) {
              cal = cal + 59;
            } else if (cal <= 144) {
              cal = cal + 58;
            } else if (cal <= 146) {
              cal = cal + 57;
            } else if (cal <= 148) {
              cal = cal + 56;
            } else if (cal <= 150) {
              cal = cal + 55;
            } else if (cal <= 152) {
              cal = cal + 54;
            } else if (cal <= 155) {
              cal = cal + 53;
            } else if (cal <= 157) {
              cal = cal + 52;
            } else if (cal <= 160) {
              cal = cal + 51;
            } else if (cal <= 163) {
              cal = cal + 50;
            } else if (cal <= 165) {
              cal = cal + 49;
            } else if (cal <= 168) {
              cal = cal + 48;
            } else if (cal <= 172) {
              cal = cal + 47;
            } else if (cal <= 178) {
              cal = cal + 46;
            } else if (cal <= 181) {
              cal = cal + 45;
            } else if (cal <= 186) {
              cal = cal + 44;
            } else if (cal <= 193) {
              cal = cal + 43;
            } else if (cal <= 198) {
              cal = cal + 42;
            } else if (cal <= 202) {
              cal = cal + 41;
            } else {
              cal = cal + 40;
            }
            cal = (cal / 192) * 100;
            postulacion.terman = cal;
          }
        });
        this.POSTULACIONES_DESCARTADAS = this.postulaciones.filter(p => p.estatus === 3);
        this.POSTULACIONES_ENTREVISTAS = this.postulaciones.filter(p => p.estatus === 2);
        this.postulaciones = this.postulaciones.filter(p => p.estatus === 1 || p.estatus === 0);
        if (this.config.hombres)
          this.hombres = this.config.hombres
        if (this.config.mujeres)
          this.mujeres = this.config.mujeres
        if (this.config.otros)
          this.otros = this.config.otros
        if ((this.config.vacante.toLowerCase() != 'na' && this.vacantes.filter(v => v.vacante.puesto.toLowerCase() === this.config.vacante.toLowerCase()).length > 0) || this.soloVer)
          this.vacanteSeleccionada = this.config.vacante;
        this.ordenarPostulaciones(this.config.ordenamiento);
        if (this.config.trabajos != undefined && this.config.trabajos != null)
          this.filtroTrabajos = this.config.trabajos;
        if (this.config.habilidades != undefined && this.config.habilidades != null)
          this.filtroHabilidades = this.config.habilidades;
        //@ts-ignore
        document.getElementById('inputFiltroNombre').value = this.config.nombre;
        setTimeout(() => {
          this.seleccionarVacante(this.vacanteActual)
        }, 1000);
      });
  
    }

    
  }

  public filtrar(): void {

    this.postulacionesActuales = this.filtrarOne(this.postulaciones);

    //Falta filtrar a entrevista
    this.postulacionesEntrevistas = this.filtrarOne(this.POSTULACIONES_ENTREVISTAS);

    //Falta filtrar rechazados
    this.postulacionesDescartadas = this.filtrarOne(this.POSTULACIONES_DESCARTADAS);
    
  }

  private filtrarOne(arreglo: any[]): any[] {
    let aux: any[] = [];

    if (this.config.sueldoMaximo === '')
      this.config.sueldoMaximo = '500000'
    //aux = arreglo.filter(p => p.empleo.toLocaleLowerCase() === this.vacanteSeleccionada.toLocaleLowerCase());
    if (this.vacanteActual != undefined)
      aux = arreglo.filter(p => p.idEmpleo === this.vacanteActual.id);
    else if (this.activeRoute.snapshot.params.id)
      aux = arreglo.filter(p => {
        return p.idEmpleo == this.activeRoute.snapshot.params.id
      });
    else
      aux = arreglo.filter(p => p.empleo.toLocaleLowerCase() === this.vacanteSeleccionada.toLocaleLowerCase());

    aux = this.filtrarGenero(aux, this.hombres, this.mujeres, this.otros);

    aux = this.filtrarEstado(aux, this.config.noRevisadas, this.config.revisadas, this.config.sinTest);

    aux = this.filtrarNombre(aux, this.config.nombre);

    aux = this.filtrarSueldos(aux, Number(this.config.sueldoMinimo), Number(this.config.sueldoMaximo))

    aux = this.filtrarHabilidades(aux, this.filtroHabilidades);

    aux = this.filtrarTrabajos(aux, this.filtroTrabajos);

    return aux;

  }

  private filtrarGenero(arreglo: any[], hombres: boolean = false, mujeres: boolean = false, otros: boolean = false): any[] {

    if (!hombres && !mujeres && !otros)
      return arreglo;
    else {

      let aux: any[] = [];

      if (hombres)
        aux = aux.concat(arreglo.filter(p => p.genero === 'H'))
      if (mujeres)
        aux = aux.concat(arreglo.filter(p => p.genero === 'M'))
      if (otros)
        aux = aux.concat(arreglo.filter(p => p.genero === 'O'))

      return aux;

    }

  }

  private filtrarEstado(arreglo: any[], noRevisadas: boolean = false, revisadas: boolean = false, sinTest: boolean = false): any[] {

    if ((!noRevisadas && !revisadas && !sinTest) || (noRevisadas && revisadas && sinTest))
      return arreglo;
    else {

      let aux: any[] = [];

      if (noRevisadas)
        aux = aux.concat(arreglo.filter(p => p.estatus === 0))
      if (sinTest)
        aux = aux.concat(arreglo.filter(p => p.idResultados === null && p.estatus > 0))
      if (revisadas)
        aux = aux.concat(arreglo.filter(p => p.idResultados != null && p.estatus > 0))

      return aux;

    }

  }

  private filtrarNombre(arreglo: any[], nombre: string): any[] {
    this.config.nombre = nombre;

    if (nombre === undefined || nombre === null || nombre === '')
      return arreglo;

    return arreglo.filter(p => {
      if (p.nombre === undefined || p.nombre == null) p.nombre = '';
      if (p.apellidoPaterno === undefined || p.apellidoPaterno == null) p.apellidoPaterno = '';
      if (p.apellidoMaterno === undefined || p.apellidoMaterno == null) p.apellidoMaterno = '';
      return (p.nombre.toLocaleLowerCase().indexOf(nombre.toLocaleLowerCase()) >= 0 ||
        p.apellidoPaterno.toLocaleLowerCase().indexOf(nombre.toLocaleLowerCase()) >= 0 ||
        p.apellidoMaterno.toLocaleLowerCase().indexOf(nombre.toLocaleLowerCase()) >= 0)
    })

  }

  private filtrarHabilidades(arreglo: any[], habilidades: string[]): any[] {

    if (habilidades === undefined || habilidades === null || habilidades.length == 0)
      return arreglo;

    return arreglo.filter(p => {
      let aux: boolean = false;
      habilidades.forEach(habilidad => {
        if (p.palbras.indexOf(habilidad) >= 0) {
          aux = true;
          return;
        }
      });
      return aux;
    })

  }

  private filtrarTrabajos(arreglo: any[], trabajos: string[]): any[] {

    if (trabajos === undefined || trabajos === null || trabajos.length == 0)
      return arreglo;
    return arreglo.filter(p => {
      let aux: boolean = false;
      trabajos.forEach(trabajo => {
        p.trabajos.forEach((element: string) => {
          if (element.toUpperCase().indexOf(trabajo.toUpperCase()) >= 0) {
            aux = true;
            return;
          }
        });
        if (aux)
          return;
      });
      return aux;
    });
  }

  private filtrarSueldos(arreglo: any[], sueldoMinimo: number, sueldoMaximo: number): any[] {
    return arreglo.filter(p => (p.sueldoPretendido >= sueldoMinimo) && (p.sueldoPretendido <= sueldoMaximo));
  }

  public verResultado(postulacion: any): void {
    this.router.navigate(['/postulacion', postulacion.idUsuario, postulacion.idEmpleo])
  }

  public reiniciarFiltros(): void {
    this.vacanteSeleccionada = this.vacantes[0].vacante.puesto;
    this.hombres = false;
    this.mujeres = false;
    this.otros = false;
    this.config.nombre = '';
    this.config.sueldoMaximo = '500000';
    this.config.sueldoMinimo = '0';

    //@ts-ignore
    document.getElementById('inputFiltroNombre').value = '';

    //@ts-ignore
    document.getElementById('inputFiltroSueldoMinimo').value = '';

    //@ts-ignore
    document.getElementById('inputFiltroSueldoMaximo').value = '';

    this.filtroHabilidades.every(h => this.habilidades.push(h))
    this.filtroHabilidades = [];

    this.filtroTrabajos = [];

    this.filtrar();
  }

  public filtrarPorHabilidades(habilidad: string): void {

    //@ts-ignore
    document.getElementById('inputFiltroHabilidad').value = '';

    if (this.habilidades.indexOf(habilidad) === -1) {
      return;
    }

    //Agregamos la habilidad al arreglo de habilidades que se quieren buscar
    this.filtroHabilidades.push(habilidad);

    //Eliminamos la habilidad de las sugerencias para que no se repita
    for (let _i = 0; _i < this.habilidades.length; _i++) {
      if (habilidad.toLocaleLowerCase() === this.habilidades[_i].toLocaleLowerCase())
        this.habilidades.splice(_i, 1);
    }

    //@ts-ignore
    document.getElementById('inputFiltroHabilidad').value = '';

    this.filtrar();
  }

  public quitarHabilidad(index: number): void {
    this.habilidades.push(this.filtroHabilidades[index]);
    this.filtroHabilidades.splice(index, 1);
    this.filtrar();
  }

  public filtrarPorTrabajos(trabajo: string): void {

    //@ts-ignore
    document.getElementById('inputFiltrotrabajos').value = '';

    if (trabajo.length < 3)
      return;

    this.filtroTrabajos.push(trabajo);

    this.filtrar();

  }

  public quitarFiltroTrabajo(index: number): void {
    this.filtroTrabajos.splice(index, 1);
    this.filtrar();
  }

  public ordenarPostulaciones(ordenamiento: number): void {
    
    this.postulaciones.sort((a, b) => {
      if (ordenamiento === 0)
        return b.sueldoPretendido - a.sueldoPretendido
      else if (ordenamiento === 1)
        return b.terman - a.terman
      else if (ordenamiento === 2)
        return this.getEdad(b.fechaNacimiento) - this.getEdad(a.fechaNacimiento)
      else
        return b.palbras.length - a.palbras.length
    });

    this.postulacionesActuales.sort((a, b) => {
      if (ordenamiento === 0)
        return b.sueldoPretendido - a.sueldoPretendido
      else if (ordenamiento === 1)
        return b.terman - a.terman
      else if (ordenamiento === 2)
        return this.getEdad(b.fechaNacimiento) - this.getEdad(a.fechaNacimiento)
      else
        return b.palbras.length - a.palbras.length
    });

    this.ordenamiento = ordenamiento;
    this.config.ordenamiento = ordenamiento;
    
  }

  public getEdad(fechaNacimiento: any): number {

    const date: Date = new Date(fechaNacimiento);

    if (date.getMonth() > new Date().getMonth()) {
      return (new Date().getUTCFullYear() - 1 - date.getUTCFullYear());
    } else {
      return (new Date().getUTCFullYear() - date.getUTCFullYear());
    }


  }

  public clickMenu(event: any, option: number): void {
    if (option === 0) {
      this.postulacionesService.getPostulaciones(this.postulacionesActuales[event.data].idUsuario).subscribe(res => {
        if (res != undefined && res != null && res.length > 1) { console.log(res)
          let html: string = '<p>Este candidato cuenta con las siguientes postulaciones</p><ul class="list-group">';
          res.forEach(postulacion => {
            html += '<li class="list-group-item">'+postulacion.empleo.puesto+'</li>'
          });
          html+='</ul>'
          Swal.fire({
            text: ' <br> dasdas <br>',
            html: html,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Descartar todas',
            cancelButtonText: 'Solo esta postulación',
            allowOutsideClick: false, 
            allowEscapeKey: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.adminService.changeEstatusPostulacion(this.postulacionesActuales[event.data].idUsuario, this.postulacionesActuales[event.data].idEmpleo, 3).subscribe(res => console.log(res), err => console.log(err));
              this.postulaciones = this.postulaciones.filter(p => {
                if ( (p.idUsuario === this.postulacionesActuales[event.data].idUsuario) ){
                  this.adminService.changeEstatusPostulacion(p.idUsuario, p.idEmpleo, 3).subscribe(res => console.log(res), err => console.log(err));
                  this.POSTULACIONES_DESCARTADAS.push(p);
                }
                return !(p.idUsuario === this.postulacionesActuales[event.data].idUsuario);
              })
              this.postulacionesActuales.splice(event.data, 1);
              this.filtrar();
              this.ordenarPostulaciones(this.ordenamiento);
            }else{
              this.adminService.changeEstatusPostulacion(this.postulacionesActuales[event.data].idUsuario, this.postulacionesActuales[event.data].idEmpleo, 3).subscribe(res => console.log(res), err => console.log(err));
              this.POSTULACIONES_DESCARTADAS.push(this.postulacionesActuales[event.data]);
              this.postulaciones = this.postulaciones.filter(p => !(p.idUsuario === this.postulacionesActuales[event.data].idUsuario && p.idEmpleo === this.postulacionesActuales[event.data].idEmpleo))
              this.postulacionesActuales.splice(event.data, 1);
              this.filtrar();
              this.ordenarPostulaciones(this.ordenamiento);
            }
          })
        } else {
          this.adminService.changeEstatusPostulacion(this.postulacionesActuales[event.data].idUsuario, this.postulacionesActuales[event.data].idEmpleo, 3).subscribe(res => console.log(res), err => console.log(err));
          this.POSTULACIONES_DESCARTADAS.push(this.postulacionesActuales[event.data]);
          this.postulaciones = this.postulaciones.filter(p => !(p.idUsuario === this.postulacionesActuales[event.data].idUsuario && p.idEmpleo === this.postulacionesActuales[event.data].idEmpleo))
          this.postulacionesActuales.splice(event.data, 1);
          this.filtrar();
          this.ordenarPostulaciones(this.ordenamiento);
        }
      })
    } else if (option === 1) {
      this.postulacionesService.getPostulaciones(this.postulacionesActuales[event.data].idUsuario).subscribe(res => {
        if (res != undefined && res != null && res.length > 1) { console.log(res)
          let html: string = '<p>Este candidato cuenta con las siguientes postulaciones, ' 
                            +'si continuas, se descartara el resto</p><ul class="list-group">';
          res.forEach(postulacion => {
            html += '<li class="list-group-item">'+postulacion.empleo.puesto+'</li>'
          });
          html+='</ul>'
          Swal.fire({
            text: ' <br> dasdas <br>',
            html: html,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false, 
            allowEscapeKey: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.adminService.changeEstatusPostulacion(this.postulacionesActuales[event.data].idUsuario, this.postulacionesActuales[event.data].idEmpleo, 2).subscribe(res => console.log(res), err => console.log(err));
              this.POSTULACIONES_ENTREVISTAS.push(this.postulacionesActuales[event.data]);
              this.postulaciones = this.postulaciones.filter(p => {
                if ( (p.idUsuario === this.postulacionesActuales[event.data].idUsuario) && !(p.idEmpleo === this.postulacionesActuales[event.data].idEmpleo)){
                  this.adminService.changeEstatusPostulacion(p.idUsuario, p.idEmpleo, 3).subscribe(res => console.log(res), err => console.log(err));
                  this.POSTULACIONES_DESCARTADAS.push(p);
                }
                return !(p.idUsuario === this.postulacionesActuales[event.data].idUsuario)&& !(p.idEmpleo === this.postulacionesActuales[event.data].idEmpleo);
              })
              this.postulacionesActuales.splice(event.data, 1);
              this.filtrar();
              this.ordenarPostulaciones(this.ordenamiento);
            }
          })
        } else {
          this.adminService.changeEstatusPostulacion(this.postulacionesActuales[event.data].idUsuario, this.postulacionesActuales[event.data].idEmpleo, 2).subscribe(res => console.log(res), err => console.log(err));
          this.POSTULACIONES_ENTREVISTAS.push(this.postulacionesActuales[event.data]);
          this.postulaciones = this.postulaciones.filter(p => !(p.idUsuario === this.postulacionesActuales[event.data].idUsuario && p.idEmpleo === this.postulacionesActuales[event.data].idEmpleo))
          this.postulacionesActuales.splice(event.data, 1);
          this.filtrar();
          this.ordenarPostulaciones(this.ordenamiento);
        }
      })
      
    } else if (option === 2) {
      this.adminService.changeEstatusPostulacion(this.postulacionesDescartadas[event.data].idUsuario, this.postulacionesDescartadas[event.data].idEmpleo, 1).subscribe(res => console.log(res), err => console.log(err));
      this.postulacionesActuales.push(this.postulacionesDescartadas[event.data]);
      this.postulaciones.push(this.postulacionesDescartadas[event.data]);
      this.POSTULACIONES_DESCARTADAS = this.POSTULACIONES_DESCARTADAS.filter(p => !(p.idUsuario === this.postulacionesDescartadas[event.data].idUsuario && p.idEmpleo === this.postulacionesDescartadas[event.data].idEmpleo))
      this.postulacionesDescartadas.splice(event.data, 1);
    } else if (option === 3) {
      this.adminService.changeEstatusPostulacion(this.postulacionesEntrevistas[event.data].idUsuario, this.postulacionesEntrevistas[event.data].idEmpleo, 1).subscribe(res => console.log(res), err => console.log(err));
      this.postulacionesActuales.push(this.postulacionesEntrevistas[event.data]);
      this.postulaciones.push(this.postulacionesEntrevistas[event.data]);
      this.POSTULACIONES_ENTREVISTAS = this.POSTULACIONES_ENTREVISTAS.filter(p => !(p.idUsuario === this.postulacionesEntrevistas[event.data].idUsuario && p.idEmpleo === this.postulacionesEntrevistas[event.data].idEmpleo))
      this.postulacionesEntrevistas.splice(event.data, 1);
    }
    this.filtrar();
    this.ordenarPostulaciones(this.ordenamiento);
    
  }

  public asignarTest(event: any, test: number, postulaciones: any[]): void{
    this.adminService.asignarPrueba(postulaciones[event.data].idUsuario, test).subscribe(res => {
      if (res)
        Swal.fire({
          icon: 'success',
          text: 'Test asignado'
        });
      else
      Swal.fire({
        icon: 'warning',
        text: 'el usuario ya cuenta con este test'
      });
    }, err => console.log(err))

  }

  public cambiarEstatusEmpleo(estatus: number): void {
    let titulo: string = ( estatus === 0 ) ? 'archivar': 'reactivar';
    Swal.fire({
      title: '¿Seguro que quiere '+ titulo + ' la vacante?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
      let empleo: any = null;
      this.vacantes.forEach(vacante => {
        if (vacante.vacante.puesto.toLowerCase() === this.vacanteSeleccionada.toLowerCase()) {
          empleo = vacante.vacante.id;
          return;
        }
      });
      if (empleo === null)
        empleo = this.activeRoute.snapshot.params.id;
      this.adminService.cambiarEstatusVacante(empleo, estatus).subscribe(() => {
        this.vacanteSeleccionada = 'na';
        this.vacanteActual = undefined;
        Swal.fire({
          icon: 'success',
          text: 'Estatus cambiado',
          showConfirmButton: false,
          timer: 1500
        }).then(() => this.router.navigate(['/AdminEmpleos']))
      }, err => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error, si este persiste contacte a sistemas'
        });
        console.log(err)
      });
    }
    })

  }

  public seleccionarVacante(vacante: any) {
    if (vacante != undefined){
      this.vacanteSeleccionada = vacante.puesto;
      this.vacanteActual = vacante;
    }
    this.filtrar();
    this.habilidades = [];
    this.trabajos = [];
    this.postulacionesActuales.forEach(postulacion => {
      postulacion.palbras.forEach(habilidad => {
        if (this.habilidades.indexOf(habilidad.toUpperCase()) < 0)
          this.habilidades.push(habilidad.toUpperCase());
      });
      postulacion.trabajos.forEach(trabajo => {
        if (this.trabajos.indexOf(trabajo.toUpperCase()) < 0)
          this.trabajos.push(trabajo.toUpperCase());
      });
    });
    this.postulacionesDescartadas.forEach(postulacion => {
      postulacion.palbras.forEach(habilidad => {
        if (this.habilidades.indexOf(habilidad.toUpperCase()) < 0)
          this.habilidades.push(habilidad.toUpperCase());
      });
      postulacion.trabajos.forEach(trabajo => {
        if (this.trabajos.indexOf(trabajo.toUpperCase()) < 0)
          this.trabajos.push(trabajo.toUpperCase());
      });
    });
    this.postulacionesEntrevistas.forEach(postulacion => {
      postulacion.palbras.forEach(habilidad => {
        if (this.habilidades.indexOf(habilidad.toUpperCase()) < 0)
          this.habilidades.push(habilidad.toUpperCase());
      });
      postulacion.trabajos.forEach(trabajo => {
        if (this.trabajos.indexOf(trabajo.toUpperCase()) < 0)
          this.trabajos.push(trabajo.toUpperCase());
      });
    });
  }

}


class ConfigDashboard {

  hombres: boolean = false;
  mujeres: boolean = false;
  otros: boolean = false;
  vacante: string = 'NA';
  idVacante: number = -1;
  nombre: string = '';
  habilidades: string[] = [];
  trabajos: string[] = [];
  ordenamiento: number = 0;
  mostrarMenu: boolean = true;
  sueldoMinimo: string = '0';
  sueldoMaximo: string = '500000';
  revisadas: boolean = false;
  noRevisadas: boolean = false;
  sinTest: boolean = false;
}
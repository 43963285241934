import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlujoService {

  $titulos: EventEmitter<string[]> = new EventEmitter();

  $icons: EventEmitter<string[]> = new EventEmitter();

  constructor() { }

  setIcons(icons: string[]): void{
    this.$icons.emit(icons);
  }

}

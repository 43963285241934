import { DireccionModel } from "./direccion.model";
import { EmpleosModel, PostulcionModel } from "./empleos.model";
import { HabilidaesModel } from "./habilidades.model";

export class UsuarioModel{

    id: number;
	nombre: string;
	password: string;
	sexo: 'H' | 'M' | 'O' = 'H';
	fechaNacimiento: Date;
	estadoCivil: EstadoCivilModel;
	viveCon: string;
	dependenPadres: boolean;
	dependenHijos: boolean;
	dependeConyuge: boolean;
	dependenOtros: boolean;
	descripcion: string;
	direccion: DireccionModel;
	facebook: string;
	linkedin: string;
	google: string;
	twitter: string;
	razonAplicacion: string;
	cvCompleto: boolean;
	activo: boolean;
	passTemporal: boolean;
	apellidoPaterno: string;
	apellidoMaterno: string;
	habilidades: UsuarioHabildadModel[];
	correos: CorreoModel[] = [];
	telefonos: TelefonoModel[] = [];
	estudios: EstudiosModel[];
	experienciasLaborales: ExperienciasLaboralesModel[];
	familiares: FamiliaresModel[];
	postulaciones: EmpleosModel[];
	postulaciones2: PostulcionModel[];

}

export class EstadoCivilModel{

    id: number;
	nombre: string;
    activo: boolean;
    
}

export class CorreoModel{

	id: number;
	correo: string;
	usuario: UsuarioModel;
	
	constructor(correo: string, usuario: UsuarioModel){
		this.correo = correo;
		this.usuario = usuario;
	}

}

export class TelefonoModel{

	id: number;
	numero: string;
	usuario: UsuarioModel;
	
	constructor(numero: string, usuario: UsuarioModel){
		this.numero = numero;
		this.usuario = usuario;
	}

}

export class EstudiosModel{

    id: number;
    titulo: string;
    escuela: string;
    desde: Date;
    hasta: Date;
	nivel: string;
	actualidad: boolean;
	ultimo: boolean;
	activo: boolean;
	
}

export class ExperienciasLaboralesModel{

	id: number;
	puesto: string;
	empresa: string;
	municipio: string;
	estado: string;
	sueldoInicial: number;
	sueldoFinal: number;
	descripcionProblema: string;
	solucionProblema: string;
	desde: Date;
	hasta: Date;
	actual: boolean;
	descripcion: string;
	idUsuario: number;
	activo: boolean;

}

export class FamiliaresModel{

	id: number;
	nombre: string = '';
	ocupacion: string = '';
	vive: boolean = false;
	parentesco: string;
	idUsuario: number; 
	activo: boolean = true;	

}

export class UsuarioHabildadModel {

    habilidad: HabilidaesModel = new HabilidaesModel();
	porcentaje: number;

}

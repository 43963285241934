import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AsyncValidacionesService  implements AsyncValidator{

  constructor(private http: HttpClient) { }

  validate = (control: AbstractControl) => {
    return this.http.get(environment.urlApi+`/usuarios/exist/correo?correo=${control.value}`).pipe(
      map(existCorreo => {
        if ( existCorreo ){
          Swal.fire({
          text: 'El correo selccionado ya existe',
          icon: 'error'
        })
        }
        return (existCorreo === true ? { existCorreo: true } : null)},
      catchError(() => of(null))
    ));
  }
    
}

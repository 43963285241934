import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import { Router } from '@angular/router';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-camara',
  templateUrl: './camara.component.html',
  styleUrls: ['./camara.component.css']
})
export class CamaraComponent implements OnInit {

  @Input()
  tiempoCaptura: number = null;

  @Input()
  cantidad: number = 3;

  intervalo: any;

  @Output()
  public pictureTaken = new EventEmitter<WebcamImage>();
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
  // width: {ideal: 1024},
  // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  constructor(private router: Router){}

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public ngOnInit(): void {

    if (this.tiempoCaptura != null){
      this.intervalo = setInterval( () => {

        if (this.cantidad > 0){
          this.triggerSnapshot();
          this.cantidad--;
        }else{
          clearInterval(this.intervalo)
        }
        
      }, this.tiempoCaptura)
    }

  WebcamUtil.getAvailableVideoInputs()
  .then((mediaDevices: MediaDeviceInfo[]) => {
  this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
  });
  }
  public triggerSnapshot(): void {
  this.trigger.next();
  }
  public toggleWebcam(): void {
  this.showWebcam = !this.showWebcam;
  }
  public handleInitError(error: WebcamInitError): void {
  this.errors.push(error);
  console.log(error)
  let message: string;
  if (error.message === 'Permission denied'){
    message = 'Por favor da acceso a la camara y reintenta'
  }else if (error.message == 'Could not start video source'){
    message = 'No se pudo iniciar video, es probable que otra app este utilizandola o que no cuentes con una'
  }else{
    message = error.message;
  }
  Swal.fire({
    icon: 'error',
    title: 'Un error a ocurrido',
    text:  message,
    showCancelButton: true,
    cancelButtonText: 'Volver al perfil',
    showConfirmButton: true,
    confirmButtonText: 'Reintentar',
    allowOutsideClick: false
  }).then(result => {
    if (result.isConfirmed){
      window.location.reload();
    }else{
      window.location.replace(environment.urlApp+'/perfil')
    }
  })
  }
  public showNextWebcam(directionOrDeviceId: boolean|string): void {
  // true => move forward through devices
  // false => move backwards through devices
  // string => move to device with given deviceId
  this.nextWebcam.next(directionOrDeviceId);
  }
  public handleImage(webcamImage: WebcamImage): void {
  this.pictureTaken.emit(webcamImage);
  }
  public cameraWasSwitched(deviceId: string): void {
  this.deviceId = deviceId;
  }
  public get triggerObservable(): Observable<void> {
  return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<boolean|string> {
  return this.nextWebcam.asObservable();
  }
  }

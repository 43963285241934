import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

// Modelos
import { CorreoModel, TelefonoModel, UsuarioModel } from 'src/app/models/usuario.model';

// Sevicios
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Router } from '@angular/router';
import { ValidacionesService } from 'src/app/services/validaciones.service';

import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { AsyncValidacionesService } from 'src/app/services/async-validaciones.service';

/**
 * @fileoverview Lógica del componente de Login y registro
 *
 * @version 1.0
 * @author Carlos Guzmán
 * @copyright TruemedGroup
 *
 * History
 * v1.0 – Primera versión
 * ----
 * La primera versión fue escrita por Carlos Guzmán
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  /**
   * Propiedad que indica si el dispositivo es móvil o web
   * se usa 1 para movil, 2 pantalla media y 3 web
   * @type {number}
   */
  esMovil: number;

  /**
   * Propiedad que indica en la versión móvil
   * si se debe visualizar el forulario d registro
   * o el foulario d login
   * @type {boolean}
   */
  loginVisible: boolean;

  /**
   * Prpiedad que contiene el modelo de Usuario que
   * se recibira o generará
   * 
   * @type {UsuarioModel}
   */
  usuario: UsuarioModel;

  /**
   * Formulario reactivo que contiene la información
   * de registro de un nuevo usuario
   * 
   * @type {FormGroup}
   */
  formularioRegistro: FormGroup;

  /**
   * Formulario reactivo que contiene la información
   * para el inicio de sesión
   * 
   * @type {FormGroup}
   */
  formularioLogin: FormGroup;

  tipoBotonPassword: string = 'password';

  /**
   * Constructor de la clase donde se inicializan
   * servicios y clases necesarias
   * @constructor
   * @param {FormBuilder} formBuilder Para creación de formularios reactivos
   * @param {AuthService} authService Servicio con funciones de autenticación
   * @param {UsuarioService} usuarioService Sevicio funciones relacionadas al usuario*/
  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private usuarioService: UsuarioService,
    private router: Router,
    private validaciones: ValidacionesService,
    private socialAuthService: SocialAuthService,
    private asyncValdaciones: AsyncValidacionesService) {

    this.esMovil = (screen.width < 768) ? 2 : 3;
    if (screen.width < 576) this.esMovil = 1;

    this.loginVisible = true;
    this.usuario = new UsuarioModel();
    this.usuario.correos = [];
    this.crarFormularios();

    if (authService.isAuth()) {

      if (this.authService.getRoles() && this.authService.getRoles().indexOf("ROLE_ADMIN") >= 0)
        this.router.navigate(['dashboard']);

      else
        this.router.navigate(['perfil']);

    }

  }

  /**
   * Funcion que se ejecuta cuando el código comienza
   * 
   * @method ngOnInit */
  ngOnInit(): void {
  }


  checkLoginState() {
    //@ts-ignore
    FB.getLoginStatus(function (response) {
      //@ts-ignore
      statusChangeCallback(response);
    });
  }

  public campoRegistroNoValido(campo: string): boolean {
    return this.formularioRegistro.get(campo).invalid && this.formularioRegistro.get(campo).touched;
  }


  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {

    Swal.fire({
      text: 'Cargando',
      allowOutsideClick: false
    });
    Swal.showLoading();

    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(resp => {

      this.authService.loginFacebook(resp.authToken, resp.email).subscribe(() => {

        Swal.fire({
          icon: 'success',
          text: 'Acceso Correcto',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {

          this.router.navigate(['perfil']);

        });

      }, err => {
        Swal.fire({
          icon: 'error',
          text: 'Hemos detectado que tu cuenta no tiene ligado una dirección de correo electrónico, por favor intenta registrarte con otra opción'
        })
      });

    }).catch(() => {
      Swal.fire({
        icon: 'error',
        text: 'Ocurrió un error, por favor reintenta'
      })
    });

  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  /**
   * Funcion para inicializar lo formularios
   * y configurar sus validaciones
   */
  crarFormularios(): void {

    /* Creación y configuración del formulario de registro */
    this.formularioRegistro = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.minLength(10)]],
      correo: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')], this.asyncValdaciones.validate],
      telefono: ['', [Validators.required, this.validaciones.numero10Digitos, this.validaciones.numeroNo0]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirm: ['', [Validators.required, Validators.minLength(8)]]
    }, {
      validators: this.validaciones.passwordsIguales('password', 'confirm')
    });

    /* Creación y configuración del formulario de login */
    this.formularioLogin = this.formBuilder.group({
      correo: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

  }

  public registrarUsuario(): void {

    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();
    if (this.formularioRegistro.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Datos incorrectos'
      });
      return Object.values(this.formularioRegistro.controls).forEach(control => control.markAsTouched());
    }

    this.usuario.correos = [];
    this.usuario.correos.push(new CorreoModel(this.formularioRegistro.get('correo').value, null));
    this.usuario.telefonos = [];
    this.usuario.telefonos.push(new TelefonoModel(this.formularioRegistro.get('telefono').value.toString(), null));
    this.usuario.nombre = this.formularioRegistro.get('nombre').value;
    this.usuario.cvCompleto = false;
    this.usuario.activo = true;

    if (this.formularioRegistro.get('password').value === this.formularioRegistro.get('confirm').value) {
      this.usuario.password = this.formularioRegistro.get('password').value;
      this.usuarioService.crearUsuario(this.usuario)
        .subscribe(response => {

          this.authService.login(this.usuario)
            .subscribe(response => {
              Swal.fire({
                icon: 'success',
                text: 'Usuario Registrado',
                showConfirmButton: false,
                timer: 1500
              }).then((result) => {
                this.router.navigate(['verificar']);
              });
            }, (err) => {

              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: err
              });

            });

        }, (err) => {

          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Datos incorrectos'
          });

        });

    } else {

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'La constraseña y su confrmación no son iguales'
      });

    }

  }

  public iniciarSesion(): void {
    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();
    this.usuario.password = this.formularioLogin.get('password').value;
    this.usuario.correos = [];
    this.usuario.correos.push(new CorreoModel(this.formularioLogin.get('correo').value, null))

    this.authService.login(this.usuario)
      .subscribe(response => {

        if (this.authService.getRoles() && this.authService.getRoles().indexOf("ROLE_ADMIN") >= 0) {
          Swal.fire({
            icon: 'success',
            text: 'Acceso Correcto',
            showConfirmButton: false,
            timer: 1500
          }).then((result) => {

            this.router.navigate(['dashboard']);

          });
        } else {
          Swal.fire({
            icon: 'success',
            text: 'Acceso Correcto',
            showConfirmButton: false,
            timer: 1500
          }).then((result) => {

            this.router.navigate(['perfil']);

          });
        }

      }, (err) => {

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Usuario o contraseña incorrectos'
        });

      });

  }

  recuperacionPassword() {
    Swal.mixin({
      icon: 'info',
      iconHtml: '<i class="fas fa-at"></i>',
      iconColor: 'rgb(2, 163, 181)',
      input: 'email',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      inputPlaceholder: 'Correo electrónico',

    }).queue([
      {
        text: 'Ingresa el correo electrónico de la cuenta'
      }
    ]).then((result) => {

      //@ts-ignore
      if (result.value) {

        Swal.fire({
          text: 'Cargando',
          allowOutsideClick: false,
        });
        Swal.showLoading();

        //@ts-ignore
        this.usuarioService.solicitarRecuperacionPassword(result.value[0]).subscribe(
          result => {
            if (result) {
              this.recuperacionAceptada();
            } else {
              Swal.fire({
                icon: 'error',
                allowOutsideClick: false,
                text: 'El correo no existe en nuestro sistema'
              });
            }
          }, err => {
            Swal.fire({
              icon: 'error',
              allowOutsideClick: false,
              text: 'Ocurrio un error, reintente más tarde'
            })
          })
      }
    })
  }

  private recuperacionAceptada(): void {
    Swal.fire({
      icon: 'success',
      allowOutsideClick: false,
      text: 'Por favor, revisa tu correo, hemos enviado un link de recuperación, ' +
        'si no lo ves directamente es posible que lo encuentras en spam o correo no deseado' +
        'En caso de no haber recibido el correo presiona reenviar',
      showCancelButton: true,
      cancelButtonText: 'Aceptar',
      confirmButtonText: 'Reenviar Correo'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Cargando',
          allowOutsideClick: false
        });
        Swal.showLoading();
        this.usuarioService.reenviarCorreo().subscribe(() => {
          this.recuperacionAceptada();
        });
      }
    });
  }

  cambiarInputPassword(): void {

    if (this.tipoBotonPassword === 'password')
      this.tipoBotonPassword = 'text';

    else
      this.tipoBotonPassword = 'password';

  }

}

import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntentosModel, PreguntaResultadoModel, SeccionResultadoModel, UsuarioPruebaModel } from 'src/app/models/resultados.model';
import { PreguntaModel, PruebaModel, SeccionModel } from 'src/app/models/test.model';
import { PruebaService } from 'src/app/services/prueba.service';
import { TestService } from 'src/app/services/test.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inglesb12',
  templateUrl: './inglesb12.component.html',
  styleUrls: ['./inglesb12.component.css']
})
export class Inglesb12Component implements OnInit {

  instruccionesPrueba: boolean = true;
  testIniciado: boolean = false; 
  tiempoTotal: number = 0;
  esMovil:boolean;
  seccionActual: number;


  
  prueba: PruebaModel;
  seccion: SeccionModel = new SeccionModel();
  iniciarTest: boolean;
  preguntaActual: number;
  intervalo: any;
  segundos: number = 0;
  segundosReloj: number = 0;
  minutosReloj: number = 0;
  dosRespuestas: number[] = [];

  resultado: UsuarioPruebaModel;
  intento: number = 1;


  constructor(private testService: TestService, 
            private router: Router, private usuarioService: UsuarioService  , 
              private pruebasService: PruebaService  ,  private activeRoute: ActivatedRoute,
             ) {

       // Hace la validacion para saber si es movil 
    this.esMovil = (screen.width < 576) ? true : false;

    // Modelo para la prueba
    this.prueba = new PruebaModel();
    // Indicamos que la seccion actual va a ser la 1
    this.seccionActual = 1;
    this.seccion.instrucciones = '';
    // Modelo para las preguntas
    let p: PreguntaModel = new PreguntaModel();
    // Inicializamos las opciones como vacias
    p.opciones = [];
    this.seccion.preguntas=[p];
    this.iniciarTest = false;
    this.preguntaActual = 0;
    this.resultado = new UsuarioPruebaModel();
    
    this.esMovil = (screen.width < 576) ? true : false;

    Swal.fire({
      allowOutsideClick: false,
      text: 'Cargando'
    });
    Swal.showLoading();
    
    testService.getTestIngles().subscribe(test => { 
      this.prueba = test;
      this.seccion = this.prueba.secciones[this.seccionActual-1];
      this.resultado.idPrueba = test.id;
      this.resultado.secciones = [];
      this.resultado.secciones.push(new SeccionResultadoModel());
      this.resultado.secciones[this.seccionActual-1].numeroSeccion=this.seccionActual;
      this.resultado.secciones[this.seccionActual-1].preguntas=[];
      this.resultado.secciones[this.seccionActual-1].preguntas.push(new PreguntaResultadoModel());
      this.resultado.secciones[this.seccionActual-1].preguntas[0].intentos=[];
      this.resultado.secciones[this.seccionActual-1].preguntas[0].numeroPregunta=1;
      
      this.prueba.secciones.forEach(seccion => {
        this.tiempoTotal += seccion.tiempoSegundos;
      });
      //this.tiempoTotal += 600;
      Swal.close();

      //console.log(this.prueba)
      //console.log(this.resultado)

    });

    usuarioService.usuario.subscribe(usuario => this.resultado.idUsuario = usuario.id);
  }

  ngOnInit(): void {
  }


  // *****************************************************
  startTest(): void{

    // Marca que ya iniciamos el test
    this.testIniciado = true;

    //this.preguntaActual++;

    this.intervalo = setInterval( () => {
      
      this.segundos++;
      this.segundosReloj++;

      if(this.segundos === this.seccion.tiempoSegundos){
        this.intento = 1;
        this.clickSiguienteSeccion();
      }
      if ( this.segundosReloj === 60 ){
        this.segundosReloj = 0;
      }
      this.minutosReloj = Math.floor(this.segundos/60);
    }, 1000);

  }

  clickSiguientePregunta(): void{

    // Obtiene la respuesta que deberia de ser 
    var respuesta =  this.prueba.secciones[this.seccionActual-1].preguntas[this.preguntaActual].respuesta;
    var respuestaObtenida = "";
    
    if (this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].intentos.length > 0){
      respuestaObtenida =  this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].intentos[ 
        this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].intentos.length - 1
       ].respuesta
    }

    if (this.seccionActual == 2){
      //@ts-ignore
      respuestaObtenida = document.getElementById("Respuesta1").value;
    }

    if(respuesta.trim().toLowerCase() === respuestaObtenida.trim().toLowerCase()){
      // Es una respuesta correcta
      this.resultado.calificacion ++;
      this.resultado.secciones[this.seccionActual-1].calificacion ++;
      this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].correcta = true;
    } 

    // Incrementa 
    this.preguntaActual = this.preguntaActual  + 1;
    this.intento =  1;

    // Agrega el nuevo esquema de la pregunta 
    this.resultado.secciones[this.seccionActual-1].preguntas.push(new PreguntaResultadoModel());
    this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].intentos=[];
    this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].numeroPregunta=this.preguntaActual;

    // console.log(this.preguntaActual)
    // console.log(this.seccion.preguntas.length)
    if (this.seccionActual == 2)
    {
      //@ts-ignore
      document.getElementById("Respuesta1").value='';
    }
  }

  clickSiguienteSeccion(): void{

    if(this.seccionActual == 1){


      // Obtiene el ultimo resultado 
       // Obtiene la respuesta que deberia de ser 
       var respuesta =  this.prueba.secciones[this.seccionActual-1].preguntas[this.preguntaActual].respuesta;
       var respuestaObtenida = "";
       
       if (this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].intentos.length > 0){
         respuestaObtenida =  this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].intentos[ 
           this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].intentos.length - 1
          ].respuesta
       }
    
       if(respuesta.trim().toLowerCase() === respuestaObtenida.trim().toLowerCase()){
         // Es una respueszta correcta
         this.resultado.calificacion ++;
         this.resultado.secciones[this.seccionActual-1].calificacion ++;
         this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].correcta = true;
       } 

      this.preguntaActual = 0;
      this.seccionActual = this.seccionActual + 1 ; 
      // Reasigna los valores de la seccion 
      this.seccion = this.prueba.secciones[this.seccionActual-1];
      // Agregamos la nueva seccion
      this.resultado.secciones.push(new SeccionResultadoModel());
      this.resultado.secciones[this.seccionActual-1].numeroSeccion=this.seccionActual;
      this.resultado.secciones[this.seccionActual-1].preguntas=[];
      this.resultado.secciones[this.seccionActual-1].preguntas.push(new PreguntaResultadoModel());
      this.resultado.secciones[this.seccionActual-1].preguntas[0].intentos=[];
      this.resultado.secciones[this.seccionActual-1].preguntas[0].numeroPregunta=1;
    }else{

      // Captura la respuesta 
      var respuesta =  this.prueba.secciones[this.seccionActual-1].preguntas[this.preguntaActual].respuesta;
      var respuestaObtenida = "";

      if (this.seccionActual == 2){
        //@ts-ignore
        respuestaObtenida = document.getElementById("Respuesta1").value;
      }
  
      if(respuesta.trim().toLowerCase() === respuestaObtenida.trim().toLowerCase()){
        // Es una respuesta correcta
        this.resultado.calificacion ++;
        this.resultado.secciones[this.seccionActual-1].calificacion ++;
        this.resultado.secciones[this.seccionActual-1].preguntas[this.preguntaActual].correcta = true;
      } 
      // Hace la validacion del 
      // Es la segunda seccion, manda a que se termine 
      this.clickTerminarTest();
    }
  }
  
  clickOpcion(value: number): void{
    //console.log(this.resultado.secciones);
    this.resultado.secciones[this.seccionActual-1]
          .preguntas[this.preguntaActual]
          .intentos
          .push(new IntentosModel(this.seccion.preguntas[this.preguntaActual].opciones[value].opcion, this.intento));
    this.intento++;
  }

  clickTerminarTest() : void {
    //console.log("Se termino test" , this.resultado);
    this.enviarResultados();
  }

  public enviarResultados(): void{
    this.testService.postResultado(this.resultado);
  }


}

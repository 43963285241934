import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import * as pluginAnnotations from '../../../assets/chartjs-plugin-annotation-0.5.7/chartjs-plugin-annotation.js';
@Component({
  selector: 'app-grafica-cleaver',
  templateUrl: './grafica-cleaver.component.html',
  styleUrls: ['./grafica-cleaver.component.css']
})
export class GraficaCleaverComponent implements OnInit {

  @Input() DISC: number[] = [50, 70, 3, 99];

  @Input() titulo: string = 'Motivación (M)';

  public lineChartData: ChartDataSets[];

  public lineChartLabels: Label[] = ['', 'D', 'I', 'S', 'C', ''];
  public lineChartOptions: (ChartOptions & { annotation: any });
  public lineChartColors: Color[] = [
    { // dark grey
      backgroundColor: 'transparent',
      borderWidth: 2,
      borderColor: 'rgb(2, 163, 181)',
      pointBackgroundColor: '#000',
      pointHoverBackgroundColor: '#000',
      pointHoverBorderColor: '#000',
      pointBorderColor: '#000'
    }
  ];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() { 
  }

  ngOnInit(): void {
    this.lineChartData =  [ { data: [null].concat(this.DISC).concat(null), lineTension: 0 } ];
    this.lineChartOptions = {
      responsive: true, maintainAspectRatio: false,
      scales: {
        // We use this empty structure as a placeholder for dynamic theming.
        xAxes: [],
        yAxes: [
          {
            id: 'y-axis-1',
            position: 'left',
            stacked: true,
            bounds: 'yAlign: center',
            ticks: {
              max: 99,
              min: 0
          }
          }
        ]
      },title: {
        text: this.titulo,
        display: true,
        fontSize: 14,
        fontColor: 'black',
        lineHeight: '35px'
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-1',
            value: '50',
            borderColor: 'red',
            borderWidth: 1
          },{
            type: 'box',
            yScaleID: 'y-axis-1',
            yMin: 0,
            yMax: 5,
            backgroundColor: 'rgba(187,187,187, .6)',
            borderColor: 'transparent',
            borderWidth: 1
          },{
            type: 'box',
            yScaleID: 'y-axis-1',
            yMin: 95,
            yMax: 100,
            backgroundColor: 'rgba(187,187,187, .6)',
            borderColor: 'transparent',
            borderWidth: 1
          }
        ],
      }
    };
  }

  public actualizar(): void{
    this.lineChartData =  [ { data: [null].concat(this.DISC).concat(null), lineTension: 0 } ];
    this.chart.update();
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'undefined'
})
export class UndefinedPipe implements PipeTransform {

  transform(value: string): string {

    if (value === undefined || value === null)
      return '';
    
    return this.checkNull(value);
    
  }

  checkNull(value: string): string{

    if (value.indexOf('null') > 0){
      return this.checkNull(value.replace('null',''))
    } else
      return value;
      

  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PostulcionModel } from '../models/empleos.model';
import { UsuarioPruebaModel } from '../models/resultados.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ResultadosService {


  /**
   * Url base del API que maneja la información
   * del sistema
   * 
   * @type {string}
   */
  //private urlBase: string = 'http://localhost:8090/usuarios'
  private urlBase: string = environment.urlApi+'/usuarios'


  constructor(private http: HttpClient, private authService: AuthService) { }


  getResultados(): Observable<any[]>{
    
    const urlEndpoint = this.urlBase + '/resultados';

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<any[]>(urlEndpoint,{headers: httpHeaders});

  }

  getResultado(id: number, idPostulacion: number): Observable<UsuarioPruebaModel>{
    
    const urlEndpoint = this.urlBase + '/resultados/'+id+'/'+idPostulacion;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });

    return this.http.get<UsuarioPruebaModel>(urlEndpoint,{headers: httpHeaders});

  }

  getPostulacion(id: number): Observable<PostulcionModel>{
    const urlEndpoint = this.urlBase + '/postulaciones/'+id;

    const httpHeaders = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.readToken()
    });
    return this.http.get<PostulcionModel>(urlEndpoint,{headers: httpHeaders});
  }

}

<app-navbar></app-navbar>
<!-- Modal -->
<div class="modal fade" id="sueldoPretendidoModal" tabindex="-1" aria-labelledby="sueldoPretendidoModal" data-bs-backdrop="static" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Sueldo Mensual</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cancelarPostulacion()"></button>
            </div>
            <div class="modal-body">
                <label class="mt-0 mb-2 fs-5"> ¿Cuánto desea ganar al mes? </label>
                <input type="number" class="form-control" min="0" max="150000" [value]=0 #SP [class.is-invalid]="montoNoValido">
                <small *ngIf="montoNoValido" class="text-danger">Ingrese monto válido</small>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="btnCloseModalSueldo" (click)="cancelarPostulacion()">Cancelar</button>
                <button type="button" class="btn btn-secondary d-none" data-bs-dismiss="modal" id="btnCloseModalSueldo2">Cancelar</button>
                <button type="button" class="btn btn-primary bg-tm" (click)="clickPostularse(SP.value)">Postularse</button>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="details" tabindex="-1" aria-labelledby="details" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Detalles de la vacante</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h5 class="card-title mb-3">{{empleoInfo.puesto}}</h5>
                <h6 class="card-subtitle mb-2">Sueldo</h6>
                <p class="card-text fs-6 fw-bold">{{empleoInfo.sueldoMinimo|currency}}-{{empleoInfo.sueldoMaximo|currency}}</p>
                <h6 class="card-subtitle mb-2">Empresa</h6>
                <p class="card-text">TruemeGroup</p>
                <h6 class="card-subtitle mb-2">Ubicación</h6>
                <p class="card-text">{{empleoInfo.municipio+ ', '+ empleoInfo.estado}}</p>
                <h6 class="card-subtitle mb-2">Descripion</h6>
                <p class="card-text">{{empleoInfo.descripcion}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="closeDetalle">Close</button>
                <button type="button" class="btn btn-info bg-tm text-white" data-bs-toggle="modal" data-bs-target="#sueldoPretendidoModal" (click)="postular()">Postularse</button>
            </div>
        </div>
    </div>
</div>
<button type="button" id="opendetalle" class="d-none" data-bs-toggle="modal" data-bs-target="#details"></button>

<div class="container mt-2 mb-4">
    <div class="alert alert-danger" role="alert" *ngIf="!usuario.cvCompleto">
        Completa tu perfil para poder postular a las vacantes
    </div>
    <div class="d-flex justify-content-center">
        <app-flujo class="my-2" [icons]="['success','none', 'here']" [titulos]="['Crear cuenta', 'Completar perfil', 'Postularse']"></app-flujo>
    </div>
    <div class="row mt-3">
        <div class="col-12 col-md-4" style="overflow-y: scroll;" [ngStyle]="{'max-height': screenHeight+'px'}">
            <h4>Vacantes disponibles</h4>
            <div class="card shadow mb-3 mt-3" *ngFor="let empleo of empleos; let i = index">
                <div class="card-body">
                    <h5 class="card-title">{{empleo.puesto}}</h5>
                    <h6 class="card-subtitle mb-2 text-muted">TruemedGroup</h6>
                    <h6 class="card-subtitle mb-2 text-muted">{{empleoInfo.municipio+ ', '+ empleoInfo.estado}}</h6>
                    <p class="card-text">{{empleo.descripcion|reduceText}}</p>
                    <!-- <button class="btn btn-info bg-tm text-white float-end" 
                            data-bs-toggle="modal" data-bs-target="#sueldoPretendidoModal" (click)="clickMasInformacion(i)">Postularse</button> -->
                    <button class="btn btn-primary me-2 float-end" (click)="clickMasInformacion(i)">
                      Más Información
                    </button>
                </div>
            </div>
        </div>
        <div class="col-8 d-none d-md-block align-self-center">
            <h4>Detalles de la vacante</h4>
            <div class="card mb-4 shadow" [ngStyle]="{'height': screenHeight+'px'}">
                <div class="card-body overflow-scroll">
                    <h5 class="card-title mb-3">{{empleoInfo.puesto}}</h5>
                    <h6 class="card-subtitle mb-2">Sueldo</h6>
                    <p class="card-text fs-6 fw-bold">{{empleoInfo.sueldoMinimo|currency}}-{{empleoInfo.sueldoMaximo|currency}}</p>
                    <h6 class="card-subtitle mb-2">Rango de edad</h6>
                    <p class="card-text fs-6">{{empleoInfo.edadMinima + ' años'}}-{{empleoInfo.edadMaxima + ' años'}}</p>
                    <h6 class="card-subtitle mb-2">Empresa</h6>
                    <p class="card-text">TruemeGroup</p>
                    <h6 class="card-subtitle mb-2">Ubicación</h6>
                    <p class="card-text">{{empleoInfo.municipio+ ', '+ empleoInfo.estado}}</p>
                    <h6 class="card-subtitle mb-2">Descripion</h6>
                    <p class="card-text">{{empleoInfo.descripcion}}</p>
                    <button class="btn btn-info bg-tm text-white float-end me-4" data-bs-toggle="modal" data-bs-target="#sueldoPretendidoModal">Postularse</button>
                </div>
            </div>
        </div>
    </div>
</div>
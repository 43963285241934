import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FlujoService } from 'src/app/services/flujo.service';

@Component({
  selector: 'app-flujo',
  templateUrl: './flujo.component.html',
  styleUrls: ['./flujo.component.css']
})
export class FlujoComponent implements OnInit, OnDestroy {


  anchoScreen = screen.width-50;

  @Input()
  icons = []

  @Input()
  titulos: string[] = []

  @Input()
  size: string = "medium";

  suscripcionIcons : Subscription;

  intervalo: any;

  constructor(private flujoService: FlujoService) { 
  }

  ngOnInit(): void {

    this.suscripcionIcons = this.flujoService.$icons.subscribe(icons => this.icons = icons);

  }

  ngOnDestroy(): void {

    this.suscripcionIcons.unsubscribe();
  }

}

<app-navbar></app-navbar>

<div class="container mt-2 mb-4" [ngClass]="{'d-none':postulacionEnviada}">
    <div class="row mt-3 d-flex justify-content-center">
        <div class="col-12 col-md-4 col-xxl-4">
            <h4 class="mb-3">Captura tu información:</h4>
            <label for="nombre" class="form-label">Nombre</label>
            <input type="text" id="nombre" class="form-control form-control-sm" [(ngModel)]="postulacion.nombre">
            <label for="apellidoPaterno" class="form-label">Apellido Paterno</label>
            <input type="text" id="apellidoPaterno" class="form-control form-control-sm" [(ngModel)]="postulacion.apellidoPaterno">
            <label for="apellidoMaterno" class="form-label">Apelido Materno</label>
            <input type="text" id="apellidoMaterno" class="form-control form-control-sm" [(ngModel)]="postulacion.apellidoMaterno">
            <label for="telefono" class="form-label">Número telefónico</label>
            <br>
            <input type="tel" id="telephone" class="form-control form-control-sm w-100" maxLength="10">
            <h4 class="mb-3">Sube tu currículum / CV *(.pdf):</h4>
            <input id="archivo" type="file" accept="application/pdf" class="form-control mt-2" name="archivo" (change)="subirArchivo($event)">
            <button type="button" class="btn btn-success d-none d-md-block m-2" (click)="enviarPostulacion()" *ngIf="mostrarBotonEnviar && !testRapido">Enviar mi postulación</button>
        </div>
        <div class="col-12 col-md-8 col-xxl-8">
            <h4 class="mb-3">Selecciona una vacante disponible donde deseas postularte</h4>
            <select (change)="seleccionarVacante($event)" name="vaccante" id="vacante" class="form-select">
                <option selected disabled>Selecciona una opción</option>
                <option *ngFor="let empleo of empleos; let idx = index" [value]="idx">
                    {{ empleo.puesto }}
                </option>
            </select>
            <div class="card m-4 shadow" *ngIf="vacanteSeleccionada != null">
                <div class="card-body">
                    <h5 class="card-title mb-3">{{vacanteSeleccionada.puesto}}</h5>
                    <h6 class="card-subtitle mb-2">Sueldo</h6>
                    <p class="card-text fs-6 fw-bold">{{vacanteSeleccionada.sueldoMinimo|currency}}-{{vacanteSeleccionada.sueldoMaximo|currency}}</p>
                    <h6 class="card-subtitle mb-2">Rango de edad</h6>
                    <p class="card-text fs-6">{{vacanteSeleccionada.edadMinima + ' años'}}-{{vacanteSeleccionada.edadMaxima + ' años'}}</p>
                    <h6 class="card-subtitle mb-2">Empresa</h6>
                    <p class="card-text">TruemeGroup</p>
                    <h6 class="card-subtitle mb-2">Ubicación</h6>
                    <p class="card-text">{{vacanteSeleccionada.municipio+ ', '+ vacanteSeleccionada.estado}}</p>
                    <h6 class="card-subtitle mb-2">Descripion</h6>
                    <p class="card-text">{{vacanteSeleccionada.descripcion}}</p>
                </div>
            </div>
        </div>
        <div class="col-12 m-4" *ngIf="testRapido">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Responde  las siguientes preguntas:</h5>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" [ngClass]="{'active': currentIndexPagination == idx, 'disabled': currentIndexPagination != idx}" *ngFor="total of testRapido.preguntas; let idx = index"><span class="page-link" (click)="seleccionarPregunta(idx)">{{idx + 1}}</span></li>
                        </ul>
                    </nav>
                    <p class="fst-italic" [innerHTML]="preguntaSeleccionada.pregunta"></p>
                    <img class="img-fluid row" [src]="preguntaSeleccionada.archivos[0].base64" alt="" *ngIf="preguntaSeleccionada.archivos.length" style="width: 250px;">
                    <ng-template [ngIf]="preguntaSeleccionada.idTipo == 1">
                        <div class="form-check form-check-inline" *ngFor="let opcion of opcionesPregunta; let idx = index">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="{{'inlineRadio' + idx}}" value="{{opcion.id}}" [(ngModel)]="opcion.id" (ngModelChange)="radioChangue(idx)">
                            <label class="form-check-label" for="{{'inlineRadio' + idx}}">{{opcion.respuesta}}</label>
                            <img class="img-fluid row" [src]="opcion.archivos[0].base64" alt="" *ngIf="opcion.archivos.length" style="width: 250px;">
                        </div>
                    </ng-template>
                    <ng-template [ngIf]="preguntaSeleccionada.idTipo == 2">
                        <div class="form-check form-check-inline" *ngFor="let opcion of opcionesPregunta; let idx = index">
                            <input class="form-check-input" type="checkbox" id="{{'inlineCheckbox' + idx}}" value="{{opcion.esCorrecta}}" [(ngModel)]="opcion.checked" (ngModelChange)="checkboxChangue($event, idx)">
                            <label class="form-check-label" for="{{'inlineCheckbox' + idx}}">{{opcion.respuesta}}</label>
                            <img class="img-fluid row" [src]="opcion.archivos[0].base64" alt="" *ngIf="opcion.archivos.length" style="width: 250px;">
                        </div>
                    </ng-template>

                    <button type="button" class="btn btn-success m-2" (click)="guardarRespuesta()" *ngIf="!mostrarBotonEnviar">{{currentIndexPagination == (testRapido.preguntas.length - 1) ? 'Resultado' : 'Continuar'}}</button>
                    <button type="button" class="btn btn-success m-2" (click)="enviarPostulacion()" *ngIf="mostrarBotonEnviar">Enviar mi postulación</button>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-success d-block d-md-none m-2" (click)="enviarPostulacion()" *ngIf="mostrarBotonEnviar && testRapido == null">Enviar mi postulación</button>
    </div>
</div>

<div class="container mt-2 mb-4" *ngIf="postulacionEnviada">
    <div class="row mt-3 d-flex justify-content-center flex-column align-items-center">
        <div class="col-6 col-xxl-6 d-flex justify-content-center">
            <h4>Agradecemos tu interés, tu postulación ha sido enviada...</h4>
        </div>
        <div class="col-3 col-xxl-3 d-flex justify-content-center">
            <h5>Se encuentra en revisión.</h5>
        </div>
        <img class="img-fluid align-self-center m-2" 
                     style="width: 500px;" 
                     src="../../../assets/img/review.png">
    </div>
</div>